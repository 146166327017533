import { useEffect } from 'react';
import { useTour } from '@reactour/tour';

const GuidedTourComponent = props => {
  const { children, name } = props;
  const { setIsOpen, currentStep, steps } = useTour();

  useEffect(() => {
    if (currentStep >= steps.length) {
      setIsOpen(false);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [currentStep, steps]);

  useEffect(() => {
    const keyFromLocalStorage = localStorage.getItem(name);
    if (!keyFromLocalStorage) {
      setIsOpen(true);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [name]);

  return children;
};

export default GuidedTourComponent;
