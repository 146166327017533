import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import HomeCards from './HomeCards';
import Loader from './elements/Loader';
import { checkTaxServiceCredential } from '../store/actions/taxServiceActions';
import {
  JOB_STATUSES,
  DEFAULT_STATE_CASES,
  DIRECT_FINANCING,
  EARLY_PAYMENT,
} from '../helpers/constants';
import DefaultState from './DefaultState';
import FinanceTabs from './FinanceTabs';
import HomeCart from './HomeCart';
import TaxServiceCredentialsDialog from './dialogs/TaxServiceCredentialsDialog';

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridTemplateRows: '201px 48px auto',
  },
  cardsArea: {
    gridRow: '1 / 2',
    gridColumn: '1 / 2',
    background: '#FFF',
    width: 'calc(100% - 140px)',
    zIndex: 30,
    position: 'fixed',
  },
  defaultStateArea: {
    gridRow: '3 / 4',
    gridColumn: '1 / 2',
  },
  tabsArea: {
    gridRow: '2 / 3',
    gridColumn: '1 / 2',
    background: '#FFF',
    width: '100%',
    zIndex: 30,
    position: 'fixed',
    top: 247,
  },
  contentArea: {
    gridRow: '3 / 4',
    gridColumn: '1 / 2',
    justifyContent: 'flex-end',
  },
});

const HomeFinance = () => {
  const { QUEUE, WORKING, FAILED, SUCCESS } = JOB_STATUSES;
  const { DF_DEFAULT, DF_FAILED, DF_LOADING, DF_UPDATE } = DEFAULT_STATE_CASES;

  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const { countryId, id: businessId } = useSelector(
    state => state.businessState.business
  );
  const wsLoadStatus = useSelector(state => state.wsState.loadStatus);
  const {
    checkTaxServiceCredentialIsLoading,
    taxStatus = {},
    taxServiceCredentialCreated,
  } = useSelector(state => state.taxServiceState);

  const [showDefaultState, setShowDefaultState] = useState(false);
  const [showDemo, setShowDemo] = useState(false);
  const [loadingCheck, setLoadingCheck] = useState(true);
  const [dfSetting, setDFSetting] = useState(DF_DEFAULT);
  const [showTaxServiceCredentialsDialog, setShowTaxServiceCredentialsDialog] =
    useState(false);
  const [operationType, setOperationType] = useState(
    location.pathname.includes('directo') ? DIRECT_FINANCING : EARLY_PAYMENT
  );

  const {
    hasCredential = false,
    isCredentialActive = false,
    jobState = '',
  } = taxStatus;

  useEffect(() => {
    if (countryId) {
      dispatch(checkTaxServiceCredential(businessId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (taxServiceCredentialCreated) {
      setShowTaxServiceCredentialsDialog(false);
    }
  }, [taxServiceCredentialCreated]);

  useEffect(() => {
    // for strange behavior of useEffect, 'updateDFSetting' function is inside of useEffect
    const updateDFSetting = () => {
      let settingDefaultState = DF_DEFAULT;
      if (hasCredential) {
        if (isCredentialActive) {
          if (
            jobState === QUEUE ||
            jobState === WORKING ||
            wsLoadStatus === WORKING
          ) {
            settingDefaultState = DF_LOADING;
          }
          if (jobState === FAILED || wsLoadStatus === FAILED) {
            settingDefaultState = DF_FAILED;
          }
        } else {
          settingDefaultState = DF_UPDATE;
        }
      }
      setDFSetting(settingDefaultState);
    };

    if (!hasCredential && !!wsLoadStatus && countryId) {
      dispatch(checkTaxServiceCredential(businessId));
    }
    const newState =
      !hasCredential ||
      !isCredentialActive ||
      (jobState !== SUCCESS && wsLoadStatus !== SUCCESS);
    setShowDefaultState(newState);

    updateDFSetting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxStatus, wsLoadStatus, dispatch]);

  useEffect(() => {
    setLoadingCheck(checkTaxServiceCredentialIsLoading);
  }, [checkTaxServiceCredentialIsLoading]);

  const handleSetOperationType = newOperationType => {
    if (newOperationType !== operationType) {
      setOperationType(newOperationType);
    }
  };

  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.cardsArea}>
        <HomeCards />
      </Grid>
      {loadingCheck ? (
        <Grid item className={classes.defaultStateArea}>
          <Loader />
        </Grid>
      ) : !showDemo && showDefaultState ? (
        <Grid item className={classes.defaultStateArea}>
          {showTaxServiceCredentialsDialog && (
            <TaxServiceCredentialsDialog
              open={showTaxServiceCredentialsDialog}
              handleCloseDialog={() =>
                setShowTaxServiceCredentialsDialog(false)
              }
            />
          )}
          <DefaultState
            showDemoOnClick={() => setShowDemo(!showDemo)}
            openCredentialClick={() => setShowTaxServiceCredentialsDialog(true)}
            setting={dfSetting}
          />
        </Grid>
      ) : (
        <>
          <Grid item className={classes.tabsArea}>
            <FinanceTabs
              setOperationType={handleSetOperationType}
              showDemo={showDemo}
            />
          </Grid>
          <Grid item className={classes.contentArea}>
            <HomeCart operationType={operationType} demo={showDemo} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default HomeFinance;
