const transformCustomPayrollStatus = data => {
  const payrollData = [...data];
  return payrollData.map(payroll => {
    let newPayroll = { ...payroll };
    const { hasBankAccount } = newPayroll;
    if (!hasBankAccount) {
      newPayroll = {
        ...newPayroll,
        status: 'INCOMPLETE_DATA',
      };
    }
    return newPayroll;
  });
};

export default transformCustomPayrollStatus;
