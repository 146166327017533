import React, { useState } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Icon } from '@material-ui/core';

const useDropdownDotsMenu = actionButtons => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMenuInvoice, setSelectedMenuInvoice] = useState(null);

  const handleOpenMenu = (event, invoice) => {
    event.persist();
    setAnchorEl(event.currentTarget);
    setSelectedMenuInvoice(invoice);
  };

  const handleCloseMenu = event => {
    if (event) event.persist();
    setAnchorEl(null);
    setSelectedMenuInvoice(null);
  };

  const actions =
    actionButtons.length > 0
      ? [
          {
            icon: () => (
              <Icon
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
              >
                <MoreHorizIcon />
              </Icon>
            ),
            tooltip: 'Más opciones',
            position: 'row',
            onClick: (event, rowData) => handleOpenMenu(event, rowData),
          },
        ]
      : null;

  return {
    actions,
    anchorEl,
    selectedMenuInvoice,
    handleOpenMenu,
    handleCloseMenu,
  };
};

export default useDropdownDotsMenu;
