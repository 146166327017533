import React from 'react';
import { Transition } from 'react-transition-group';
import PropTypes from 'prop-types';
import TopbarPublic from './TopbarPublic';
import TopbarPrivate from './TopbarPrivate';

const defaultStyle = {
  transition: 'opacity 0.2s ease-in-out',
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const Topbar = props => {
  const { isAuth, businessId } = props;

  return (
    <>
      <Transition
        in={!isAuth || (isAuth && !businessId)}
        mountOnEnter
        timeout={200}
      >
        {state => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            <TopbarPublic />
          </div>
        )}
      </Transition>

      <Transition in={isAuth && !!businessId} mountOnEnter timeout={200}>
        {state => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            <TopbarPrivate />
          </div>
        )}
      </Transition>
    </>
  );
};

Topbar.propTypes = {
  businessId: PropTypes.number.isRequired,
  isAuth: PropTypes.bool.isRequired,
};

export default Topbar;
