import {
  WS_CREATE_CONNECTION_MIDDLEWARE,
  WS_CONNECT_MIDDLEWARE,
  WS_RECONNECT_MIDDLEWARE,
  WS_DISCONNECT_MIDDLEWARE,
  WS_CONNECTED_STORE,
  WS_DISCONNECTED_STORE,
  WS_TAX_SCRAPE_UPDATED_STORE,
  WS_BUSINESS_NOTIFIED_STORE,
} from './types';

export const wsConnect = () => ({ type: WS_CONNECT_MIDDLEWARE });

export const wsReconnect = () => ({ type: WS_RECONNECT_MIDDLEWARE });

export const wsDisconnect = () => ({ type: WS_DISCONNECT_MIDDLEWARE });

export const wsCreateConnection = businessId => ({
  type: WS_CREATE_CONNECTION_MIDDLEWARE,
  payload: { businessId },
});

// EXCLUSIVE USE IN MIDDLEWARE

export const wsConnected = () => ({ type: WS_CONNECTED_STORE });

export const wsDisconnected = () => ({ type: WS_DISCONNECTED_STORE });

export const wsTaxScrapeUpdated = payload => ({
  type: WS_TAX_SCRAPE_UPDATED_STORE,
  payload,
});

export const wsBusinessNotified = () => ({
  type: WS_BUSINESS_NOTIFIED_STORE,
});
