import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles, Container, FormGroup } from '@material-ui/core';
import BaseDialog from './BaseDialog';
import Dropzone from '../elements/Dropzone/Dropzone';
import AlertForm from '../elements/AlertForm';
import LoadingButton from '../elements/LoadingButton';
import { registerPfx } from '../../store/actions/pfxActions';
import { toBase64 } from '../../helpers/fileHandling';

const useStyles = makeStyles({
  error: {
    marginTop: 20,
  },
  dropzone: {
    marginBottom: 15,
  },
});
const UploadPfxFileDialog = props => {
  const { open, handleCloseDialog } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [password, setPassword] = useState('');
  const [pfxFile, setPfxFile] = useState(null);

  const { id: businessId } = useSelector(state => state.authState.business);
  const { registerPfxIsLoading, registerPfxError } = useSelector(
    state => state.pfxState
  );

  const handleChangeStatus = (file, status) => {
    if (status === 'done') {
      setPfxFile(file.file);
    } else if (status === 'removed') {
      setPfxFile(null);
    }
  };

  const handleSubmit = async () => {
    const pfxBase64 = await toBase64(pfxFile);
    dispatch(registerPfx(businessId, pfxBase64, password));
  };

  return (
    <BaseDialog
      size="lg"
      isOpen={open}
      handleClose={handleCloseDialog}
      title="Carga tu certificado digital"
    >
      <Grid item xs={12} align="center">
        <ValidatorForm onSubmit={handleSubmit} className={classes.form}>
          <Container justify="center" width="100%" className={classes.dropzone}>
            <Dropzone
              accept=".pfx"
              maxFiles={1}
              minSize={100}
              isLoading={registerPfxIsLoading}
              onChangeStatus={handleChangeStatus}
              SubmitButtonComponent={null}
            />
          </Container>
          <Container justify="center">
            <FormGroup className={classes.root}>
              <TextValidator
                fullWidth
                variant="outlined"
                label="Clave certificado"
                type="password"
                autoComplete="new-password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                validators={['required']}
                errorMessages={['Campo requerido']}
              />
            </FormGroup>
          </Container>
          {registerPfxError && (
            <Grid item xs={12} align="center" className={classes.error}>
              <AlertForm variant="error" message={t(registerPfxError)} />
            </Grid>
          )}
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={!password || !pfxFile}
            isLoading={registerPfxIsLoading}
          >
            Subir
          </LoadingButton>
        </ValidatorForm>
      </Grid>
    </BaseDialog>
  );
};

UploadPfxFileDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
};

export default UploadPfxFileDialog;
