/* eslint-disable react/prop-types */
import React from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Grid,
  Container,
  Box,
  Typography,
  IconButton,
} from '@material-ui/core';
import {
  makeStyles,
  ThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import StepperContext from './StepperContext';
import CloseModalIcon from '../icons/CloseModalIcon';
import CustomStepConnector from './CustomStepConnector';
import CustomStepLabel from './CustomStepLabel';

import { themeOverrides } from '../../theme/themeOverrides';
import { palette } from '../../theme/palette';
import { typography } from '../../theme/typography';

const theme = createTheme({
  palette: {
    ...palette,
    primary: {
      ...palette.primary,
      main: palette.secondary.main,
      dark: palette.secondary.dark,
      light: palette.secondary.light,
    },
  },
  typography,
  overrides: {
    ...themeOverrides,
    MuiOutlinedInput: {
      root: {
        ...themeOverrides.MuiOutlinedInput.root,
        '& fieldset': {
          borderRadius: 8,
          borderColor: '#DAD9DB !important',
        },
      },
    },
    MuiInputLabel: {
      ...themeOverrides.MuiInputLabel,
      outlined: {
        transform: 'translate(14px, 18px) scale(1)',
      },
    },
  },
});

const useStyles = makeStyles(() => ({
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: 'transparent',
    width: 40,
    height: 40,
    padding: '0',
    margin: '40px 0 0 0',
  },
  root: {
    background: 'linear-gradient(90deg, #F5F5F6 50%, #fff 50%)',
    minHeight: '100%',
    width: '100%',
  },
  container: {
    minHeight: '100vh',
    height: '100%',
  },
  grid: {
    minHeight: '100vh',
    height: '100%',
  },
  stepperBox: {
    width: '100%',
    maxWidth: '44.12%',
    height: '100%',
  },
  childBox: {
    position: 'relative',
    background: '#fff',
    width: '100%',
  },
  title: {
    fontWeight: 600,
    lineHeight: '56px',
    fontStyle: 'normal',
    color: '#44414C',
  },
  stepper: {
    background: 'transparent',
    padding: 0,
    margin: '32px 0 0 0',
  },
  steps: {
    maxWidth: 288,
    width: '100%',
    margin: '112px auto 0 auto',
  },
  childs: {
    position: 'relative',
    maxWidth: 600,
    width: '100%',
    margin: '112px auto 0 auto',
  },
  stepRoot: {
    '& $label': {
      color: '#DAD9DB',
      fontSize: '14px',
      lineHeight: '28px',
      fontWeight: 'bold',
    },
    '& $completed': {
      color: '#828795',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 'bold',
    },
    '& $active': {
      color: '#F87348',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 'bold',
    },
    '& $iconContainer': {
      padding: '0 16px 0 0',
    },
  },
  label: {},
  active: {},
  completed: {},
  iconContainer: {},
}));

const StepperFlow = props => {
  const classes = useStyles();
  const {
    currentStep,
    childs,
    isStepsDone,
    getCurrentChild,
    getLastChild,
    title,
    closeModal,
  } = props;
  return (
    <ThemeProvider theme={theme}>
      <StepperContext.Provider value={props}>
        <Box className={classes.root}>
          <Container fixed className={classes.container}>
            <Grid
              container
              spacing={0}
              className={classes.grid}
              direction="row"
              wrap="nowrap"
            >
              <Grid item className={classes.stepperBox}>
                <Box className={classes.steps}>
                  <Typography variant="h3" className={classes.title}>
                    {title}
                  </Typography>
                  <Stepper
                    activeStep={currentStep}
                    orientation="vertical"
                    className={classes.stepper}
                    connector={<CustomStepConnector />}
                  >
                    {childs.map((child, index) => {
                      const { label } = child;
                      if (childs.length === index + 1) return null;
                      return (
                        <Step key={label}>
                          <StepLabel
                            classes={{
                              root: classes.stepRoot,
                              active: classes.active,
                              completed: classes.completed,
                              label: classes.label,
                              iconContainer: classes.iconContainer,
                            }}
                            StepIconComponent={CustomStepLabel}
                          >
                            {label}
                          </StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Box>
              </Grid>
              <Grid item className={classes.childBox}>
                <Box className={classes.childs}>
                  {!isStepsDone() && getCurrentChild()}
                  {isStepsDone() && getLastChild()}
                </Box>
                <IconButton
                  disableRipple
                  type="button"
                  className={classes.close}
                  onClick={closeModal}
                >
                  <CloseModalIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </StepperContext.Provider>
    </ThemeProvider>
  );
};

export default StepperFlow;
