import React from 'react';
import PropTypes from 'prop-types';
import HighlightOffIcon from '@material-ui/icons/HighlightOffRounded';
import { Chip as MuiChip, Box, emphasize } from '@material-ui/core';
import { CHIP_VARIANTS } from '../../theme/otherColors';

const Chip = React.forwardRef((props, ref) => {
  const { variant, noBold, label } = props;
  const { color } = CHIP_VARIANTS[variant];

  const getBackground = (hex, a) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);

    return `rgba(${r},${g},${b},${a})`;
  };

  return (
    <MuiChip
      deleteIcon={<HighlightOffIcon style={{ color }} />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      label={
        <Box fontWeight={noBold ? 'fontWeightRegular' : 'fontWeightBold'}>
          {label}
        </Box>
      }
      ref={ref}
      variant="default"
      style={{
        background: getBackground(color, 0.2),
        color,
        border: 0,
        '&:hover, &:focus': {
          backgroundColor: emphasize('#000', 0.08),
        },
      }}
    />
  );
});

Chip.defaultProps = {
  variant: 'default',
  noBold: false,
};

Chip.propTypes = {
  label: PropTypes.element.isRequired,
  variant: PropTypes.oneOf(
    Object.keys(CHIP_VARIANTS).map(variant => CHIP_VARIANTS[variant].code)
  ),
  noBold: PropTypes.bool,
};

export default Chip;
