import {
  FETCH_WALLET_STATUS_START,
  FETCH_WALLET_STATUS_SUCCESS,
  FETCH_WALLET_STATUS_ERROR,
  CREATE_WALLET_REQUEST_START,
  CREATE_WALLET_REQUEST_SUCCESS,
  CREATE_WALLET_REQUEST_ERROR,
} from '../actions/types';

const initialState = {
  balance: 0,
  lastPendingWalletRequest: null,
  walletStatusIsLoading: false,
  walletStatusError: null,

  createWalletRequestIsLoading: false,
  createWalletRequestIsDone: false,
  createWalletRequestError: null,
};

const fetchDebtServiceStart = state => {
  return {
    ...state,
    lastPendingWalletRequest: null,
    walletStatusIsLoading: true,
    walletStatusError: null,
  };
};
const fetchDebtServiceSuccess = (state, payload) => {
  return {
    ...state,
    ...payload,
    walletStatusIsLoading: false,
  };
};
const fetchDebtServiceFailure = (state, payload) => {
  return {
    ...state,
    balance: 0,
    lastPendingWalletRequest: null,
    walletStatusIsLoading: false,
    walletStatusError: payload.errorCode,
  };
};

const createWalletRequestStart = state => {
  return {
    ...state,
    createWalletRequestIsLoading: true,
    createWalletRequestIsDone: false,
    createWalletRequestError: null,
  };
};

const createWalletRequestSuccess = (state, payload) => {
  return {
    ...state,
    createWalletRequestIsLoading: false,
    lastPendingWalletRequest: payload.walletRequest,
    createWalletRequestIsDone: true,
  };
};

const createWalletRequestError = (state, payload) => {
  return {
    ...state,
    createWalletRequestIsLoading: false,
    createWalletRequestError: payload.errorCode,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_WALLET_STATUS_START:
      return fetchDebtServiceStart(state);
    case FETCH_WALLET_STATUS_SUCCESS:
      return fetchDebtServiceSuccess(state, payload);
    case FETCH_WALLET_STATUS_ERROR:
      return fetchDebtServiceFailure(state, payload);
    case CREATE_WALLET_REQUEST_START:
      return createWalletRequestStart(state);
    case CREATE_WALLET_REQUEST_SUCCESS:
      return createWalletRequestSuccess(state, payload);
    case CREATE_WALLET_REQUEST_ERROR:
      return createWalletRequestError(state, payload);
    default:
      return state;
  }
};
