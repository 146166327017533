import { PURGE } from 'redux-persist';
import moment from 'moment';
import {
  FETCH_CART_INVOICES_START,
  FETCH_CART_INVOICES_SUCCESS,
  FETCH_CART_INVOICES_ERROR,
  FETCH_CART_INVOICES_COUNT_START,
  FETCH_CART_INVOICES_COUNT_SUCCESS,
  FETCH_CART_INVOICES_COUNT_ERROR,
  FETCH_CART_INVOICES_TOTAL_AMOUNT_START,
  FETCH_CART_INVOICES_TOTAL_AMOUNT_SUCCESS,
  FETCH_CART_INVOICES_TOTAL_AMOUNT_ERROR,
  HANDLE_INVOICES_SELECTION_CHANGE,
  HANDLE_INVOICES_DATE_CHANGE,
  RESET_CART_INVOICES,
  REMOVE_FROM_CART,
  CART_SIMULATE_START,
  CART_SIMULATE_SUCCESS,
  CART_SIMULATE_ERROR,
  SET_IS_FIRST_SIMULATION,
  ORDER_SUBMIT_START,
  ORDER_SUBMIT_SUCCESS,
  ORDER_SUBMIT_ERROR,
  LOCAL_PURGE,
  RESET_SELECTED_INVOICES,
} from '../actions/types';
import { t13s } from '../../translationKeys';
import { DIRECT_FINANCING, EARLY_PAYMENT } from '../../helpers/constants';
import { getFirstBusinessDayFrom } from '../../helpers/dateHelpers';

const initialState = {
  fetchCartInvoicesIsLoading: false,
  fetchCartInvoicesError: null,
  cartInvoices: [],

  fetchCartInvoicesCountIsLoading: false,
  fetchCartInvoicesCountError: null,
  earlyPaymentCount: 0,
  directFinancingCount: 0,

  fetchCartInvoicesTotalAmountIsLoading: false,
  fetchCartInvoicesTotalAmountError: null,
  totalCartInvoices: 0,

  selectedCartInvoices: [],
  totalSelectedCartInvoices: 0,
  allInvoicesWithDate: false,

  simulation: {},
  simulationIsLoading: false,
  simulationError: false,
  isFirstSimulation: true,

  orderSubmitIsLoading: false,
  orderSubmitError: null,
  submitedOrder: false,

  pagination: {
    page: 1,
    pageSize: 20,
    totalData: 0,
  },

  flagChangeInvoiceExpirationDate: false,
};

const fetchCartInvoicesStart = state => {
  return {
    ...state,
    fetchCartInvoicesIsLoading: true,
    fetchCartInvoicesError: null,
  };
};

const fetchCartInvoicesSuccess = (state, payload) => {
  const { data, pagination, holidays } = payload;
  const { selectedCartInvoices } = state;

  let newData = data.map(invoice => {
    return {
      ...invoice,
      expirationDate: getFirstBusinessDayFrom(invoice.expirationDate, holidays),
    };
  });
  if (selectedCartInvoices.length) {
    const unselectedInvoices = data.filter(
      invoice => !selectedCartInvoices.some(({ id }) => id === invoice.id)
    );
    const invoicesSelected = selectedCartInvoices.map(invoice => {
      return { ...invoice, tableData: { checked: true } };
    });
    newData = [...invoicesSelected, ...unselectedInvoices];
  }

  return {
    ...state,
    pagination,
    cartInvoices: newData,
    totalSelectedCartInvoices: selectedCartInvoices.reduce(
      (acc, invoice) => acc + Number(invoice.amount),
      0
    ),
    fetchCartInvoicesIsLoading: false,
  };
};

const fetchCartInvoicesError = (state, payload) => {
  return {
    ...state,
    cartInvoices: [],
    fetchCartInvoicesIsLoading: false,
    fetchCartInvoicesError: payload,
  };
};

const fetchCartInvoicesCountStart = state => {
  return {
    ...state,
    fetchCartInvoicesCountIsLoading: true,
    fetchCartInvoicesCountError: null,
  };
};

const fetchCartInvoicesCountSuccess = (state, payload) => {
  const { orderType, count } = payload;
  let newDirectFinancingCount = state.directFinancingCount;
  let newEarlyPaymentCount = state.earlyPaymentCount;

  switch (orderType) {
    case DIRECT_FINANCING:
      newDirectFinancingCount = count;
      break;
    case EARLY_PAYMENT:
      newEarlyPaymentCount = count;
      break;
    default:
      break;
  }

  return {
    ...state,
    fetchCartInvoicesCountIsLoading: false,
    earlyPaymentCount: newEarlyPaymentCount,
    directFinancingCount: newDirectFinancingCount,
  };
};

const fetchCartInvoicesCountError = (state, payload) => {
  return {
    ...state,
    fetchCartInvoicesCountIsLoading: false,
    fetchCartInvoicesCountError: payload.errorCode,
  };
};

const fetchCartInvoicesTotalAmountStart = state => {
  return {
    ...state,
    fetchCartInvoicesTotalAmountIsLoading: true,
    fetchCartInvoicesTotalAmountError: null,
  };
};

const fetchCartInvoicesTotalAmountSuccess = (state, payload) => {
  const { totalAmount } = payload;

  return {
    ...state,
    fetchCartInvoicesTotalAmountIsLoading: false,
    totalCartInvoices: totalAmount,
  };
};

const fetchCartInvoicesTotalAmountError = (state, payload) => {
  return {
    ...state,
    fetchCartInvoicesTotalAmountIsLoading: false,
    fetchCartInvoicesTotalAmountError: payload.errorCode,
  };
};

const handleInvoicesSelectionChange = (state, payload) => {
  const selectedCartInvoices = [...payload.selectedInvoices];
  const totalSelectedCartInvoices = selectedCartInvoices.reduce(
    (acc, invoice) => acc + Number(invoice.amount),
    0
  );
  const allInvoicesWithDate = !selectedCartInvoices.some(
    invoice => !invoice.expirationDate
  );

  return {
    ...state,
    selectedCartInvoices,
    totalSelectedCartInvoices,
    allInvoicesWithDate,
  };
};

const removeFromCart = (state, payload) => {
  const selectedCartInvoices = state.selectedCartInvoices.filter(
    item => !payload.invoices.some(invoice => invoice.id === item.id)
  );
  const totalSelectedCartInvoices = selectedCartInvoices.reduce(
    (acc, invoice) => acc + Number(invoice.amount),
    0
  );
  const allInvoicesWithDate = !selectedCartInvoices.some(
    invoice => !invoice.expirationDate
  );
  if (selectedCartInvoices.length) {
    return {
      ...state,
      selectedCartInvoices,
      totalSelectedCartInvoices,
      allInvoicesWithDate,
    };
  }
  return {
    ...state,
    selectedCartInvoices,
    totalSelectedCartInvoices,
    allInvoicesWithDate,
    simulation: {},
  };
};

const handleInvoicesDateChange = (state, payload) => {
  let selectedCartInvoices = [...state.selectedCartInvoices];
  const { date, invoices } = payload;
  if (date) {
    selectedCartInvoices = state.selectedCartInvoices.map(stateInvoice => {
      if (
        invoices.length === 0 ||
        invoices.some(payloadInvoice => payloadInvoice.id === stateInvoice.id)
      ) {
        return {
          ...stateInvoice,
          expirationDate: moment.utc(date),
        };
      }
      return stateInvoice;
    });
  }
  const allInvoicesWithDate = !selectedCartInvoices.some(
    invoice => !invoice.expirationDate
  );

  return {
    ...state,
    selectedCartInvoices,
    allInvoicesWithDate,
    flagChangeInvoiceExpirationDate: !state.flagChangeInvoiceExpirationDate,
  };
};

const cartSimulateStart = state => {
  return {
    ...state,
    simulationIsLoading: true,
    simulationError: null,
  };
};

const cartSimulateSuccess = (state, payload) => {
  const { invoicesData } = payload.simulation;
  const newSelectedInvoices = state.selectedCartInvoices.map(invoice => {
    const invoiceFound = invoicesData.find(
      ({ invoiceId }) => invoiceId === invoice.id
    );
    return {
      ...invoice,
      rate: invoiceFound?.rate || null,
      retentionPct: invoiceFound?.retentionPct || null,
    };
  });
  return {
    ...state,
    simulationIsLoading: false,
    simulation: payload.simulation,
    selectedCartInvoices: newSelectedInvoices,
  };
};

const cartSimulateError = (state, payload) => {
  return {
    ...state,
    simulationIsLoading: false,
    simulationError: payload,
  };
};

const orderSubmitStart = state => {
  return {
    ...state,
    submitedOrder: false,
    orderSubmitIsLoading: true,
    orderSubmitError: false,
  };
};

const orderSubmitSuccess = state => {
  return {
    ...state,
    submitedOrder: true,
    orderSubmitIsLoading: false,
    orderSubmitError: null,
  };
};

const orderSubmitError = (state, payload) => {
  return {
    ...state,
    submitedOrder: false,
    orderSubmitIsLoading: false,
    orderSubmitError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const setIsFirstSimulation = (state, payload) => {
  return {
    ...state,
    isFirstSimulation: payload.isFirstSimulation,
  };
};

const resetSelectedInvoices = state => {
  return {
    ...state,
    selectedCartInvoices: [],
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CART_INVOICES_START:
      return fetchCartInvoicesStart(state);
    case FETCH_CART_INVOICES_SUCCESS:
      return fetchCartInvoicesSuccess(state, payload);
    case FETCH_CART_INVOICES_ERROR:
      return fetchCartInvoicesError(state, payload);
    case FETCH_CART_INVOICES_COUNT_START:
      return fetchCartInvoicesCountStart(state);
    case FETCH_CART_INVOICES_COUNT_SUCCESS:
      return fetchCartInvoicesCountSuccess(state, payload);
    case FETCH_CART_INVOICES_COUNT_ERROR:
      return fetchCartInvoicesCountError(state, payload);
    case FETCH_CART_INVOICES_TOTAL_AMOUNT_START:
      return fetchCartInvoicesTotalAmountStart(state);
    case FETCH_CART_INVOICES_TOTAL_AMOUNT_SUCCESS:
      return fetchCartInvoicesTotalAmountSuccess(state, payload);
    case FETCH_CART_INVOICES_TOTAL_AMOUNT_ERROR:
      return fetchCartInvoicesTotalAmountError(state, payload);
    case HANDLE_INVOICES_SELECTION_CHANGE:
      return handleInvoicesSelectionChange(state, payload);
    case REMOVE_FROM_CART:
      return removeFromCart(state, payload);
    case HANDLE_INVOICES_DATE_CHANGE:
      return handleInvoicesDateChange(state, payload);
    case CART_SIMULATE_START:
      return cartSimulateStart(state);
    case CART_SIMULATE_SUCCESS:
      return cartSimulateSuccess(state, payload);
    case CART_SIMULATE_ERROR:
      return cartSimulateError(state, payload);
    case ORDER_SUBMIT_START:
      return orderSubmitStart(state);
    case ORDER_SUBMIT_SUCCESS:
      return orderSubmitSuccess(state);
    case ORDER_SUBMIT_ERROR:
      return orderSubmitError(state, payload);
    case SET_IS_FIRST_SIMULATION:
      return setIsFirstSimulation(state, payload);
    case RESET_SELECTED_INVOICES:
      return resetSelectedInvoices(state);
    case RESET_CART_INVOICES:
      return initialState;
    case LOCAL_PURGE:
      return initialState;
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
