import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import CustomButton from '../../../../components/elements/CustomButton';
import CountryFormatHelper from '../../../../components/elements/CountryFormatHelper';
import { AVAILABLE_COUNTRIES } from '../../../../helpers/constants';
import { selectedInvoicesPopUpStyles } from './styles';
import { Text1 } from '../../../commons/formatters';

const RFC_KEY = 'identifier';

const SelectedInvoicesPopUp = React.memo(props => {
  const classes = selectedInvoicesPopUpStyles();
  const [providerQuantity, setProviderQuantity] = useState(0);
  const [invoiceQuantity, setInvoiceQuantity] = useState(0);
  const { countryId, selectedInvoices, totalSelectedInvoices, openStepper } =
    props;

  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      const cloneResult = { ...result };
      cloneResult[currentValue[key]] = cloneResult[currentValue[key]] || [];
      cloneResult[currentValue[key]].push(currentValue);
      return cloneResult;
    }, {});
  };

  useEffect(() => {
    if (selectedInvoices.length > 0) {
      const filtered = groupBy(selectedInvoices, RFC_KEY);
      setProviderQuantity(Object.keys(filtered).length);
      setInvoiceQuantity(selectedInvoices.length);
    }
  }, [selectedInvoices]);

  if (selectedInvoices.length <= 0) return null;

  return (
    <Box className={classes.root}>
      <Grid container className={classes.grid}>
        <Grid item>
          <Text1
            text={`Has seleccionado ${providerQuantity} proveedores y ${invoiceQuantity} facturas`}
            className={classes.totalLine1}
          />
          <Text1
            text="Tu costo de operación para estos pagos es de $0"
            className={classes.totalLine2}
          />
        </Grid>
        <Grid item>
          <CustomButton
            variant="all-white"
            className={classes.scheduleButton}
            onClick={openStepper}
          >
            <>
              Programar pago por&nbsp;
              <CountryFormatHelper
                value={totalSelectedInvoices}
                countryId={countryId}
                variant="currency"
              />
            </>
          </CustomButton>
        </Grid>
      </Grid>
    </Box>
  );
});

SelectedInvoicesPopUp.defaultProps = {};

SelectedInvoicesPopUp.propTypes = {
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  selectedInvoices: PropTypes.arrayOf(
    PropTypes.shape({
      identifier: PropTypes.string.isRequired,
    })
  ).isRequired,
  totalSelectedInvoices: PropTypes.number.isRequired,
  openStepper: PropTypes.func.isRequired,
};

export default SelectedInvoicesPopUp;
