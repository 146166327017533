import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Table, TableToolbar } from '../../../commons/components';
import Card from '../../../../components/elements/Card';
import { SEGMENT_EVENTS } from '../../../../helpers/segment/constants';
import { trackSegment } from '../../../../helpers/segment/trackingSegment';
import { paidTableStyles } from './styles';
import { usePaidTable, usePaidTableHeaders } from '../../adapters/PaidTable';

const PaidTable = React.memo(props => {
  const { businessId } = props;
  const classes = paidTableStyles();

  const rows = usePaidTableHeaders();
  const { actions, onChangeSearch, fetchData, reloadValue } =
    usePaidTable(businessId);

  useEffect(() => {
    trackSegment(SEGMENT_EVENTS.PAYMENT_PAID_VIEWED, {
      businessId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container item className={classes.tableInvoicesContainer}>
      <TableToolbar
        title="Revisa el historial de facturas pagadas"
        onChangeSearch={onChangeSearch}
      />

      <Card variant="white" border className={classes.card}>
        <Table
          reload={reloadValue}
          columns={rows}
          actionButtons={actions}
          fetchData={fetchData}
        />
      </Card>
    </Grid>
  );
});

PaidTable.propTypes = {
  businessId: PropTypes.number.isRequired,
};

export default PaidTable;
