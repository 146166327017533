import { PURGE } from 'redux-persist';
import {
  REGISTER_PFX_START,
  REGISTER_PFX_SUCCESS,
  REGISTER_PFX_FAILURE,
  CHECK_PFX_START,
  CHECK_PFX_SUCCESS,
  CHECK_PFX_FAILURE,
  LOCAL_PURGE,
} from '../actions/types';

const initialState = {
  pfxRegistered: false,
  registerPfxIsLoading: false,
  registerPfxError: null,

  hasPfx: false,
  checkPfxIsLoading: false,
  checkPfxError: null,
};

const registerPfxStart = state => {
  return {
    ...state,
    registerPfxIsLoading: true,
    registerPfxError: null,
    pfxRegistered: false,
  };
};

const registerPfxSuccess = state => {
  return {
    ...state,
    registerPfxIsLoading: false,
    registerPfxError: null,
    pfxRegistered: true,
  };
};
const registerPfxError = (state, payload) => {
  return {
    ...state,
    registerPfxIsLoading: false,
    registerPfxError: payload.errorCode,
  };
};

const checkPfxStart = state => {
  return {
    ...state,
    hasPfx: false,
    checkPfxIsLoading: true,
    checkPfxError: null,
  };
};

const checkPfxSuccess = (state, payload) => {
  return {
    ...state,
    hasPfx: payload.hasPfxCertificate,
    checkPfxIsLoading: false,
    checkPfxError: null,
  };
};
const checkPfxError = (state, payload) => {
  return {
    ...state,
    checkPfxIsLoading: false,
    checkPfxError: payload.errorCode,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case REGISTER_PFX_START:
      return registerPfxStart(state);
    case REGISTER_PFX_SUCCESS:
      return registerPfxSuccess(state);
    case REGISTER_PFX_FAILURE:
      return registerPfxError(state, payload);
    case CHECK_PFX_START:
      return checkPfxStart(state);
    case CHECK_PFX_SUCCESS:
      return checkPfxSuccess(state, payload);
    case CHECK_PFX_FAILURE:
      return checkPfxError(state, payload);
    case LOCAL_PURGE:
      return initialState;
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
