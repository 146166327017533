import { makeStyles } from '@material-ui/core/styles';

const supplierBookStyles = makeStyles(() => ({
  root: {
    height: '100%',
    padding: 40,
    overflow: 'auto',
  },
  search: {
    marginBottom: 30,
  },
  bookIndexChar: {
    marginTop: 15,
    marginBottom: 15,
  },
}));

const supplierBookEntryStyles = makeStyles(() => ({
  card: {
    marginBottom: 10,
  },
  cardAction: {
    borderRadius: 16,
    boxSizing: 'border-box',
    padding: 20,
  },
}));

export { supplierBookStyles, supplierBookEntryStyles };
