import { useSelector } from 'react-redux';
import axios from '../../../../helpers/axios';

const useFetchData = ({ externalSearch, option }) => {
  const { id: businessId } = useSelector(state => state.businessState.business);

  const fetchData = async ({ page, pageSize, search, orderBy }) => {
    return axios
      .get(`/api/payment/business/${businessId}/invoice`, {
        params: {
          option,
          orderBy,
          page,
          search: externalSearch || search,
          size: pageSize,
        },
      })
      .then(res => {
        return res.data;
      });
  };

  return { fetchData };
};

export default useFetchData;
