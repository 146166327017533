import { NOTIFICATION } from './notification';
import { ERROR } from './error';
import { INPUT_ERROR } from './inputError';
import { LABEL } from './label';

// eslint-disable-next-line import/prefer-default-export
export const t13s = {
  NOTIFICATION,
  ERROR,
  INPUT_ERROR,
  LABEL,
};
