import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const BuroIcon = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 15.9 23.8">
      <g>
        <path
          d="M15.9,16.4c0,4.6-3.8,7.4-7,7.4H2c-1.1,0-2-0.9-2-2V2c0-1.1,0.9-2,2-2h5.5c0.2,0,1.6,0.1,3.1,0.8
		c2.2,1.1,3.3,3.1,3.3,5.7c0,1.4-0.4,2.7-1.1,3.7C14.8,11.5,15.9,13.7,15.9,16.4z M4,8.9h3c0.5,0,3-0.1,3-2.5C9.9,4.2,8.4,4,7.7,4
		C7.5,4,7.4,4,7.4,4H4V8.9z M11.9,16.4c0-1.3-0.8-3.5-3.2-3.5H4v6.9h4.7c0,0,1,0,1.8-0.5C11.8,18.6,11.9,17.3,11.9,16.4z"
        />
      </g>
    </SvgIcon>
  );
};

export default BuroIcon;
