import React from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: 6,
  },
  amountTitle: {
    margin: '4px 0',
  },
  bar: {
    marginTop: 11,
  },
}));

const PaymentsCreditLineSkeleton = () => {
  const classes = useStyles();

  return (
    <Grid item direction="column" container>
      <Grid item>
        <Box className={classes.title}>
          <Skeleton animation="wave" variant="rect" width={136} height={24} />
        </Box>
      </Grid>
      <Grid item>
        <Skeleton animation="wave" variant="rect" width={127} height={34} />
      </Grid>
      <Grid item>
        <Grid item className={classes.bar}>
          <Skeleton animation="wave" variant="rect" width={244} height={8} />
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Box className={classes.amountTitle}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={77}
                height={16}
              />
            </Box>
            <Skeleton animation="wave" variant="rect" width={63} height={20} />
          </Grid>
          <Grid item>
            <Box textAlign="right">
              <Box className={classes.amountTitle}>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={85}
                  height={16}
                />
              </Box>
              <Skeleton
                animation="wave"
                variant="rect"
                width={75}
                height={20}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaymentsCreditLineSkeleton;
