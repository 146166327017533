import React from 'react';
import { Radio } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { SWITCH_BACKGROUND } from '../../theme/otherColors';

const GreenRadioButton = withStyles({
  root: {
    color: SWITCH_BACKGROUND,
    '&$checked': {
      color: SWITCH_BACKGROUND,
    },
  },
  checked: {},
  // eslint-disable-next-line react/jsx-props-no-spreading
})(props => <Radio color="default" {...props} />);

export default GreenRadioButton;
