import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CloseModalIcon = props => {
  return (
    <SvgIcon
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      style={{ width: '40px', height: '40px' }}
      viewBox="0 0 40 40"
    >
      <rect
        x="0.5"
        y="-0.5"
        width="39"
        height="39"
        rx="19.5"
        transform="matrix(1 0 0 -1 0 39)"
        stroke="#DAD9DB"
      />
      <path
        d="M27.0913 24.9088C27.5696 25.3872 27.5696 26.1629 27.0913 26.6413C26.6129 27.1196 25.8372 27.1196 25.3588 26.6413L20.225 21.5074L15.0912 26.6413C14.6128 27.1196 13.8372 27.1196 13.3588 26.6413C12.8804 26.1629 12.8804 25.3872 13.3588 24.9088L18.4926 19.775L13.3588 14.6413C12.8805 14.1629 12.8805 13.3872 13.3588 12.9088C13.8372 12.4305 14.6129 12.4305 15.0913 12.9088L20.225 18.0426L25.3588 12.9088C25.8372 12.4305 26.6128 12.4305 27.0912 12.9088C27.5696 13.3872 27.5696 14.1629 27.0912 14.6413L21.9574 19.775L27.0913 24.9088Z"
        fill="#DAD9DB"
      />
    </SvgIcon>
  );
};

export default CloseModalIcon;
