import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { Grid, makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import { AVAILABLE_COUNTRIES } from '../helpers/constants';

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
});

const defaultFormsStyle = {
  transition: 'opacity 0.15s ease-in-out',
  position: 'absolute',
  left: 'calc(33.6667% - 186px)',
  maxWidth: '372px',
  width: '372px',
  marginLeft: 'auto',
  marginRight: 'auto',
};

const transitionFormStyles = {
  entering: { opacity: 0.75 },
  entered: { opacity: 1 },
  exiting: { opacity: 0.25 },
  exited: { opacity: 0 },
};

const HomeStartLeft = ({ country }) => {
  const classes = useStyles();
  const location = useLocation();

  const [showForgotPassword, setShowForgotPassword] = useState(
    location.pathname.includes('/forgotpassword')
  );

  useEffect(() => {
    setShowForgotPassword(location.pathname.includes('/forgotpassword'));
  }, [location]);

  return (
    <Grid item xs={8} className={classes.root}>
      <Transition in={!showForgotPassword} unmountOnExit timeout={150}>
        {state => (
          <div
            style={{
              ...defaultFormsStyle,
              ...transitionFormStyles[state],
            }}
          >
            <Login
              onForgotPasswordClick={show => setShowForgotPassword(show)}
              country={country}
            />
          </div>
        )}
      </Transition>
      <Transition in={showForgotPassword} unmountOnExit timeout={150}>
        {state => (
          <div
            style={{
              ...defaultFormsStyle,
              ...transitionFormStyles[state],
            }}
          >
            <ForgotPassword />
          </div>
        )}
      </Transition>
    </Grid>
  );
};

HomeStartLeft.propTypes = {
  country: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
};

export default HomeStartLeft;
