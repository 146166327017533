import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Link } from '@material-ui/core/';
import { ReactComponent as LogoBlanco } from '../assets/logo-blanco.svg';

const useStyles = makeStyles({
  toolbar: {
    height: 76,
    margin: 'auto',
  },
  appBar: {
    background: '#FFF',
    boxShadow: '0px 10px 20px 0px rgba(79, 35, 35, 0.08)',
    top: 0,
  },
});

const TopbarPublic = () => {
  const classes = useStyles();

  return (
    <AppBar className={classes.appBar} elevation={1} position="fixed">
      <Toolbar className={classes.toolbar}>
        <Link href="/">
          <LogoBlanco height="51px" />
        </Link>
      </Toolbar>
    </AppBar>
  );
};

export default TopbarPublic;
