import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const InfoIcon = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 6 9">
      <path d="M1.47003 0.803223L0.530029 1.74322L3.58336 4.80322L0.530028 7.86322L1.47003 8.80322L5.47003 4.80322L1.47003 0.803223Z" />
    </SvgIcon>
  );
};

export default InfoIcon;
