import axios from '../../helpers/axios';
import {
  FETCH_DOCUMENT_START,
  FETCH_DOCUMENT_SUCCESS,
  FETCH_DOCUMENT_ERROR,
  UPLOAD_DOCUMENT_START,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_ERROR,
  DOWNLOAD_DOCUMENT_START,
  DOWNLOAD_DOCUMENT_SUCCESS,
  DOWNLOAD_DOCUMENT_ERROR,
  VERIFY_DOCUMENTS_START,
  VERIFY_DOCUMENTS_SUCCESS,
  VERIFY_DOCUMENTS_FAILURE,
  VERIFY_DOCUMENTS_RESET,
} from './types';
import { b64toBlob, download } from '../../helpers/fileHandling';
import { t13s } from '../../translationKeys';
import { enqueueSnackbar } from './notificationActions';

// eslint-disable-next-line import/prefer-default-export
export const fetchDocument = (documentableId, documentableType) => dispatch => {
  dispatch({
    type: FETCH_DOCUMENT_START,
    payload: { documentableType, documentableId },
  });
  axios
    .get(`/api/${documentableType}/${documentableId}/document`)
    .then(res => {
      const documents = res.data;
      dispatch({
        type: FETCH_DOCUMENT_SUCCESS,
        payload: { documents, documentableType },
      });
    })
    .catch(error => {
      const errorCode = error?.response?.data?.errorCode;
      dispatch({
        type: FETCH_DOCUMENT_ERROR,
        payload: { errorCode },
      });
    });
};

export const uploadDocument =
  (document, file, documentableType) => dispatch => {
    const { documentableId, id: documentId } = document;
    dispatch({ type: UPLOAD_DOCUMENT_START });
    axios
      .put(
        `/api/${documentableType}/${documentableId}/document/${documentId}/sign`,
        {
          file,
        }
      )
      .then(res => {
        const updatedDocument = res.data;
        dispatch({
          type: UPLOAD_DOCUMENT_SUCCESS,
          payload: { documentableType, updatedDocument },
        });
        dispatch(
          enqueueSnackbar({
            message: t13s.NOTIFICATION.UPLOAD_DOCUMENT_SUCCESS,
            options: {
              variant: 'success',
            },
          })
        );
      })
      .catch(error => {
        const errorCode = error?.response?.data?.errorCode;
        dispatch({
          type: UPLOAD_DOCUMENT_ERROR,
          payload: { errorCode },
        });
      });
  };

export const downloadDocument =
  (document, documentableType, file = 'signed') =>
  dispatch => {
    const { documentableId, id: documentId, title } = document;
    dispatch({ type: DOWNLOAD_DOCUMENT_START });
    axios
      .get(
        `/api/${documentableType}/${documentableId}/document/${documentId}/download`,
        {
          params: {
            file,
          },
        }
      )
      .then(res => {
        const resData = res.data;
        const { file } = resData;
        dispatch({
          type: DOWNLOAD_DOCUMENT_SUCCESS,
        });
        let blob;
        let extension = 'pdf';
        if (file.includes('base64')) {
          const fileData = file.split(';base64,');
          extension = fileData[0].split(/\//)?.[1];
          blob = b64toBlob(
            fileData[1].replace(/={1,2}$/, ''),
            extension === 'pdf' ? 'application/pdf' : `image/${extension}`
          );
        } else {
          blob = b64toBlob(file, 'application/pdf');
        }
        const name = `${title}.${extension}`;
        return download(blob, name);
      })

      .catch(error => {
        const errorCode = error?.response?.data?.errorCode;
        dispatch({
          type: DOWNLOAD_DOCUMENT_ERROR,
          payload: { errorCode },
        });
        dispatch(
          enqueueSnackbar({
            message: t13s.NOTIFICATION.DOWNLOAD_DOCUMENT_ERROR,
            options: {
              variant: 'error',
            },
          })
        );
      });
  };

export const verifyStatusDocuments =
  (documentableId, documentableType) => dispatch => {
    dispatch({
      type: VERIFY_DOCUMENTS_START,
      payload: { documentableType, documentableId },
    });

    axios
      .get(`/api/${documentableType}/${documentableId}/document/completed`)
      .then(res => {
        const resData = res.data;
        dispatch({
          type: VERIFY_DOCUMENTS_SUCCESS,
          payload: { documentableType, documentableId, ...resData },
        });
      })
      .catch(err => {
        const errorCode = err?.response?.data?.error?.errorCode;
        dispatch({
          type: VERIFY_DOCUMENTS_FAILURE,
          payload: {
            documentableType,
            documentableId,
            error: { errorCode },
          },
        });
      });
  };

export const resetDocumentsVerify = documentableType => dispatch => {
  dispatch({ type: VERIFY_DOCUMENTS_RESET, payload: { documentableType } });
};
