/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import imgFooterCL from '../assets/footerChile.png';
import imgFooterMX from '../assets/footerMexico.png';
import FlagMx from '../assets/FlagMx.svg';
import FlagCl from '../assets/FlagCl.svg';
import mapa from '../assets/mapa.svg';
import FacebookIcon from './icons/FacebookIcon';
import LinkedinIcon from './icons/LinkedinIcon';
import TwitterIcon from './icons/TwitterIcon';
import CustomButton from './elements/CustomButton';
import {
  AVAILABLE_COUNTRIES,
  COUNTRY_CODE_CL,
  COUNTRY_CODE_MX,
  LINKS,
} from '../helpers/constants';
import Buro2Icon from './icons/Buro2Icon';
import {
  HOME_START_FOOTER_BACKGROUND,
  HOME_START_FOOTER_LINK,
} from '../theme/otherColors';

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 248px 115px',
    gridTemplateColumns: '122px repeat(3, 1fr) 122px',
    color: '#FFF',
  },
  footerImg: {
    gridRow: 1,
    gridColumn: '1 / 6',
  },
  background: {
    background: HOME_START_FOOTER_BACKGROUND,
    gridRow: 2,
    gridColumn: '1 / 6',
  },
  disclaimerRoot: {
    background: HOME_START_FOOTER_BACKGROUND,
    gridRow: 3,
    gridColumn: '1 / 6',
  },
  disclaimerText: {
    lineHeight: '28px',
  },
  addressRoot: {
    gridRow: 2,
    gridColumn: 2,
    padding: '41px 0',
    alignItems: 'center',
  },
  menuRoot: {
    gridRow: 2,
    gridColumn: 3,
    padding: '41px 0',
    alignItems: 'center',
  },
  menuItem: {
    marginBottom: 10,
  },
  link: {
    cursor: 'pointer',
    color: '#fff',
    textDecoration: 'none',
    '&:hover, &:active': {
      color: HOME_START_FOOTER_LINK,
      textDecoration: 'none',
    },
  },
  rrssRoot: {
    gridRow: 2,
    gridColumn: 4,
    padding: '41px 0',
    alignItems: 'center',
  },
  msgPyme: {
    marginTop: 30,
  },
  flag: {
    width: 30,
    height: 30,
    margin: '0 10px',
    cursor: 'pointer',
  },
  addressTxt: {
    margin: '10px 0',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  wtspButton: {
    marginRight: 5,
  },
  legalTxt: {
    height: 30,
    fontSize: 14,
  },
  logoRrss: {
    fontSize: 35,
    margin: '0 20px 20px 10px',
    cursor: 'pointer',
    '&:hover': {
      fill: HOME_START_FOOTER_LINK,
    },
  },
  logoBuro: {
    fontSize: 35,
    margin: '0 20px 20px 10px',
    cursor: 'pointer',
  },
  mapaImg: {
    cursor: 'pointer',
    role: 'button',
  },
  postHelpBtn: {
    marginTop: 15,
  },
});

const texts = {
  CL: {
    address: (
      <>
        Mariano Sánchez Fontecilla 310
        <br />
        Las Condes, Santiago de Chile
      </>
    ),
    solutions: 'Soluciones',
    disclaimer: (
      <>Con amor para las pymes ❤ © {new Date().getFullYear()} Xepelin</>
    ),
  },
  MX: {
    address: (
      <>
        Calle Manuel E. Izaguirre 2, 101
        <br />
        53100 Ciudad Satélite, Estado de México, México
      </>
    ),
    solutions: 'Financiamiento',
    disclaimer: (
      <>
        Se informa que OPERADORA FALCON, S.A.P.I. de C.V., SOFOM E.N.R. es una
        Sociedad Financiera de Objeto Múltiple, Entidad No Regulada, que para su
        constitución y operación con tal carácter,
        <br />
        no requiere de autorización de la Secretaría de Hacienda y Crédito
        Público, no obstante, se encuentra sujeta a la supervisión de la
        Comisión Nacional Bancaria y de Valores, únicamente para
        <br />
        efectos de lo dispuesto por el artículo 56 de la Ley General de
        Organizaciones y Actividades Auxiliares del Crédito. Con amor para las
        pymes ❤ © {new Date().getFullYear()} Xepelin
      </>
    ),
  },
};

const image = {
  CL: imgFooterCL,
  MX: imgFooterMX,
};

const Footer = ({ country }) => {
  const classes = useStyles();
  const countryLinks = LINKS[country];
  const countryTexts = texts[country];

  const redirectTo = path => {
    window.location = path;
  };

  return (
    <Grid container direction="row" align="center" className={classes.root}>
      <img
        className={classes.footerImg}
        src={image[country]}
        width="100%"
        alt="footer"
      />
      <div className={classes.background} />
      <Grid item className={classes.addressRoot}>
        <img
          className={classes.mapaImg}
          src={mapa}
          width={60}
          alt="footer"
          onClick={() => {
            redirectTo(countryLinks.location);
          }}
        />
        <Typography
          variant="body1"
          className={classes.addressTxt}
          onClick={() => {
            redirectTo(countryLinks.location);
          }}
        >
          {countryTexts.address}
        </Typography>
        <img
          src={FlagMx}
          className={classes.flag}
          alt="Mexico flag"
          onClick={() => redirectTo('https://www.xepelin.com/mx')}
        />
        <img
          src={FlagCl}
          className={classes.flag}
          alt="Chile flag"
          onClick={() => redirectTo('https://www.xepelin.com')}
        />
      </Grid>
      <Grid item className={classes.menuRoot}>
        <Typography
          variant="h5"
          className={clsx(classes.menuItem, classes.link)}
          onClick={() => redirectTo('https://www.xepelin.com/soluciones')}
        >
          {countryTexts.solutions}
        </Typography>

        <Typography
          variant="h5"
          className={clsx(classes.menuItem, classes.link)}
          onClick={() => redirectTo(countryLinks.aboutUs)}
        >
          Sobre nosotros
        </Typography>
        <Typography
          variant="h5"
          className={clsx(classes.menuItem, classes.link)}
          onClick={() => redirectTo(countryLinks.resources)}
        >
          Recursos
        </Typography>
        <Typography
          variant="h5"
          className={clsx(classes.menuItem, classes.link)}
          onClick={() => redirectTo(countryLinks.help)}
        >
          Ayuda
        </Typography>
        <Typography
          variant="h5"
          className={clsx(classes.menuItem, classes.link)}
          onClick={() => redirectTo(countryLinks.jobBoard)}
        >
          Trabaja con nosotros
        </Typography>
      </Grid>
      <Grid item className={classes.rrssRoot}>
        <LinkedinIcon
          className={classes.logoRrss}
          onClick={() =>
            redirectTo('https://www.linkedin.com/company/xepelinofficial/')
          }
        />
        <FacebookIcon
          className={classes.logoRrss}
          onClick={() =>
            redirectTo('https://www.facebook.com/Xepelinofficial/')
          }
        />
        <TwitterIcon
          className={classes.logoRrss}
          onClick={() => redirectTo('https://twitter.com/xepelinofficial')}
        />
        {country === COUNTRY_CODE_MX && (
          <Buro2Icon
            className={classes.logoBuro}
            onClick={() =>
              redirectTo(
                'https://www.xepelin.com/mx/buro-de-entidades-financieras'
              )
            }
          />
        )}
        <br />
        {country === COUNTRY_CODE_CL && (
          <CustomButton
            variant="all-orange"
            onClick={() => redirectTo('https://www.xepelin.com/ayuda')}
          >
            ¿Necesitas ayuda? Obtenla aquí
          </CustomButton>
        )}
        <Typography
          variant="body1"
          onClick={() => {
            redirectTo(countryLinks.terms);
          }}
          className={clsx(
            classes.link,
            classes.legalTxt,
            country === COUNTRY_CODE_CL ? classes.postHelpBtn : null
          )}
        >
          Términos y Condiciones
        </Typography>
        <Typography
          variant="body1"
          onClick={() => {
            redirectTo(countryLinks.privacy);
          }}
          className={clsx(classes.link, classes.legalTxt)}
        >
          Aviso de Privacidad
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.disclaimerRoot}>
        <Typography variant="body1" className={classes.disclaimerText}>
          {countryTexts.disclaimer}
        </Typography>
      </Grid>
    </Grid>
  );
};

Footer.propTypes = {
  country: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
};

export default Footer;
