import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Divider } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/PersonOutlineOutlined';
import DollarIcon from '@material-ui/icons/AttachMoneyOutlined';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import RightArrowIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import NotificationsIcon from '@material-ui/icons/NotificationsNoneOutlined';
import LogoutIcon from '@material-ui/icons/ExitToAppRounded';
import SideBarNavigationButton from './elements/SideBarNavigationButton';
import FolderIcon from './icons/FolderIcon';
import { features } from '../config/features';

import SatIcon from '../assets/sat-icon.svg';
import { AVAILABLE_COUNTRIES } from '../helpers/constants';

const useStyles = makeStyles({
  container: {
    display: 'grid',
    gridGap: 10,
    gridAutoRows: 'min-content',
  },
});

const UserProfileSideBar = ({ handleLogout, countryId }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { profileSidebar } = features[countryId];

  const handleButtonClick = newLocation => {
    history.push(newLocation, { wasInUserProfile: true });
  };

  const getIcon = key => {
    switch (key) {
      case 'profile':
        return <PersonIcon />;
      case 'legal':
        return <FolderIcon />;
      case 'bankAccounts':
        return <DollarIcon />;
      case 'sii':
        return <DescriptionIcon />;
      case 'sat':
        return <img src={SatIcon} width={20} alt="sat" />;
      case 'previred':
        return <RightArrowIcon />;
      case 'notifications':
        return <NotificationsIcon />;
      case 'logout':
        return <LogoutIcon />;
      default:
        return '';
    }
  };

  return (
    <Box className={classes.container}>
      {profileSidebar.map(({ key, label, path }) => (
        <SideBarNavigationButton
          key={key}
          onClick={() => handleButtonClick(path)}
          active={location.pathname === path}
          icon={getIcon(key)}
          dataQa={`sidebar-navigation-button-${label}-action`}
        >
          {label}
        </SideBarNavigationButton>
      ))}
      <Divider light />

      <SideBarNavigationButton
        onClick={() => handleLogout()}
        icon={getIcon('logout')}
        dataQa="sidebar-navigation-button-cerrar-sesion-action"
      >
        Cerrar Sesión
      </SideBarNavigationButton>
    </Box>
  );
};

UserProfileSideBar.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
};

export default UserProfileSideBar;
