import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, makeStyles } from '@material-ui/core';
import CountryFormatHelper from '../../../components/elements/CountryFormatHelper';

const useStyles = makeStyles({
  totalAmountColumn: {
    minWidth: 100,
  },
});

const AmountByCountry = React.memo(props => {
  const { text, countryId } = props;
  const classes = useStyles();
  return (
    <Typography
      variant="body1"
      color="textSecondary"
      component="div"
      noWrap
      className={classes.totalAmountColumn}
    >
      <Box fontWeight="fontWeightBold">
        <CountryFormatHelper
          value={text}
          variant="currency"
          countryId={countryId}
        />
      </Box>
    </Typography>
  );
});

AmountByCountry.defaultProps = {};

AmountByCountry.propTypes = {
  text: PropTypes.string.isRequired,
  countryId: PropTypes.string.isRequired,
};

export default AmountByCountry;
