import React, { useEffect, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { BODY_BACKGROUND } from '../../theme/otherColors';
import { getElementHeight, getElementPosition } from '../../helpers/DOMHelper';

const useStyles = makeStyles({
  containerHeader: {
    width: props => props.width || 940,
    zIndex: 999,
    padding: '20px 0',
    backgroundColor: BODY_BACKGROUND,
  },
  headerWithScroll: {
    position: 'fixed',
    top: 56,
  },
});

const UserSectionHeader = props => {
  const { children, setHeaderHeight } = props;
  const classes = useStyles(props);
  const [customHeaderClass, setCustomHeaderClass] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const elementHeight = getElementHeight('containerHeader');
    const { y: positionY } = getElementPosition('containerHeader');

    window.onscroll = () => {
      if (document.documentElement.scrollTop > positionY - 56) {
        setHeaderHeight(`${elementHeight}px`);
        setCustomHeaderClass(classes.headerWithScroll);
      } else {
        setHeaderHeight(0);
        setCustomHeaderClass(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      className={clsx(classes.containerHeader, customHeaderClass)}
      id="containerHeader"
    >
      {children}
    </Box>
  );
};

UserSectionHeader.defaultProps = {
  width: 940,
};

UserSectionHeader.propTypes = {
  children: PropTypes.element.isRequired,
  setHeaderHeight: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default UserSectionHeader;
