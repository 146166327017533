import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Typography,
  InputAdornment,
  Box,
  Link,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import { Check, ErrorOutline } from '@material-ui/icons';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LoopIcon from '@material-ui/icons/Loop';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import EyeOpenIcon from './icons/EyeOpenIcon';
import EyeCloseIcon from './icons/EyeCloseIcon';
import LoadingButton from './elements/LoadingButton';
import AlertForm from './elements/AlertForm';
import CheckboxValidator from './elements/CheckboxValidator';
import {
  verifyEmail,
  verifyEmailReset,
} from '../store/actions/authenticationActions';
import { setWasInLogin } from '../store/actions/uiStateActions';
import isEmpty from '../helpers/validations/isEmpty';
import { AVAILABLE_COUNTRIES } from '../helpers/constants';

const useStyles = makeStyles(theme => ({
  buttonLogin: {
    fontSize: 14,
    height: 40,
    marginTop: 7,
  },
  checkbox: {
    marginBottom: 20,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  showPassword: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  warning: {
    color: theme.palette.warning.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  sugestionButton: {
    color: '#FFF',
    padding: 0,
  },
}));

const Register = props => {
  const { onSubmit, registerUserError, registerUserIsLoading, country } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [acceptConditions, setAcceptConditions] = useState(false);
  const [timer, setTimer] = useState(null);
  const [verifyEmailMsg, setVerifyEmailMsg] = useState(null);

  const emailVerification = useSelector(
    state => state.authState.emailVerification
  );
  const verifyEmailIsLoading = useSelector(
    state => state.authState.verifyEmailIsLoading
  );
  const verifyEmailError = useSelector(
    state => state.authState.verifyEmailError
  );

  const links = {
    CL: {
      terms: 'https://www.xepelin.com/terminos-y-condiciones/',
      privacy: 'https://www.xepelin.com/politica-de-privacidad/',
    },
    MX: {
      terms: 'https://www.xepelin.com/mx/terminos-y-condiciones',
      privacy: 'https://www.xepelin.com/mx/aviso-de-privacidad',
    },
  };

  useEffect(() => {
    dispatch(verifyEmailReset());
  }, [dispatch]);

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', value => {
      if (password !== value) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule('isTruthy', value => value);
  }, [password]);

  useEffect(() => {
    ValidatorForm.addValidationRule('isNotEmpty', value => !isEmpty(value));
  }, []);

  useEffect(() => {
    clearTimeout(timer);
    if (isEmailValid) {
      setTimer(setTimeout(() => dispatch(verifyEmail(email)), 1000));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, isEmailValid, dispatch]);

  useEffect(() => {
    if (emailVerification) {
      if (!emailVerification?.validEmail || verifyEmailError) {
        setVerifyEmailMsg('No se pudo verificar email');
      } else if (
        emailVerification?.validEmail &&
        emailVerification?.emailSuggestion
      ) {
        setVerifyEmailMsg(`Quiso decir ${emailVerification.emailSuggestion}`);
      } else {
        setVerifyEmailMsg(null);
      }
    } else {
      setVerifyEmailMsg(null);
    }
  }, [emailVerification, verifyEmailError]);

  const handleSubmit = () => {
    dispatch(setWasInLogin(false));
    onSubmit({ email, password, confirmPassword });
  };

  const emailValidationSpy = isValid => {
    setIsEmailValid(isValid);
  };

  const changeEmail = value => {
    setEmail(value);
  };

  const acceptSugestion = () => {
    setEmail(emailVerification?.emailSuggestion);
    dispatch(verifyEmailReset());
  };
  const getEmailAdornmentIcon = () => {
    let icon;
    if (verifyEmailIsLoading) {
      icon = <CircularProgress size={24} color="inherit" />;
    } else if (emailVerification?.validEmail && !verifyEmailMsg) {
      icon = <Check className={classes.success} />;
    } else if (verifyEmailMsg) {
      icon = <ErrorOutline className={classes.warning} />;
    }
    return icon ? <InputAdornment position="end">{icon}</InputAdornment> : null;
  };

  const redirectTo = path => {
    window.location = path;
  };

  return (
    <ValidatorForm onSubmit={handleSubmit}>
      <TextValidator
        fullWidth
        id="email"
        name="email"
        type="text"
        variant="outlined"
        value={email}
        onChange={e => changeEmail(e.target.value)}
        validatorListener={emailValidationSpy}
        validators={['required', 'isEmail']}
        errorMessages={['Campo requerido', 'Email inválido']}
        placeholder="Email"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MailOutlineIcon />
            </InputAdornment>
          ),
          endAdornment: getEmailAdornmentIcon(),
        }}
      />
      <TextValidator
        fullWidth
        id="password"
        name="password"
        type={showPassword ? 'text' : 'password'}
        variant="outlined"
        value={password}
        onChange={e => setPassword(e.target.value)}
        placeholder="Contraseña"
        validators={['required', 'minStringLength:6', 'isNotEmpty']}
        errorMessages={[
          'Campo requerido',
          'La contaseña debe tener a lo menos 6 caracteres',
          'No puede dejar texto en blanco',
        ]}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockOutlinedIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {showPassword ? (
                <EyeCloseIcon
                  className={classes.showPassword}
                  onClick={() => setShowPassword(false)}
                />
              ) : (
                <EyeOpenIcon
                  className={classes.showPassword}
                  onClick={() => setShowPassword(true)}
                />
              )}
            </InputAdornment>
          ),
        }}
      />
      <TextValidator
        fullWidth
        id="confirmPassword"
        name="confirmPassword"
        type={showPassword ? 'text' : 'password'}
        variant="outlined"
        value={confirmPassword}
        onChange={e => setConfirmPassword(e.target.value)}
        placeholder="Repetir contraseña"
        validators={['required', 'isPasswordMatch']}
        errorMessages={['Campo requerido', 'Las contraseñas no coinciden']}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockOutlinedIcon />
            </InputAdornment>
          ),
        }}
      />
      <div className={classes.checkbox}>
        <CheckboxValidator
          validators={['isTruthy']}
          errorMessages="Campo requerido"
          checked={acceptConditions}
          value={acceptConditions}
          onChange={e => setAcceptConditions(e.target.checked)}
          label={
            <Typography variant="caption" align="left" component="div">
              Acepto los{' '}
              <Link
                color="secondary"
                onClick={() => redirectTo(links[country].terms)}
              >
                <Box display="inline" fontWeight="fontWeightBold">
                  Términos y Condiciones
                </Box>
              </Link>{' '}
              y{' '}
              <Link
                color="secondary"
                onClick={() => redirectTo(links[country].privacy)}
              >
                <Box display="inline" fontWeight="fontWeightBold">
                  Aviso de Privacidad
                </Box>
              </Link>
            </Typography>
          }
        />
      </div>

      {!!verifyEmailMsg && (
        <AlertForm
          message={
            <Box>
              {verifyEmailMsg}{' '}
              <IconButton
                onClick={acceptSugestion}
                className={classes.sugestionButton}
              >
                <LoopIcon className={classes.iconArrow} />
              </IconButton>
            </Box>
          }
          variant="warning"
        />
      )}
      {!!registerUserError && (
        <AlertForm message={t(registerUserError)} variant="error" />
      )}
      <LoadingButton
        type="submit"
        variant="contained"
        color="primary"
        isLoading={registerUserIsLoading}
        className={classes.buttonLogin}
        fullWidth
      >
        Continuar
      </LoadingButton>
    </ValidatorForm>
  );
};

Register.defaultProps = {
  registerUserError: false,
};

Register.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  registerUserIsLoading: PropTypes.bool.isRequired,
  registerUserError: PropTypes.string,
  country: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
};

export default Register;
