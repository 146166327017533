import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, makeStyles } from '@material-ui/core';
// import MaterialTableDTE from './elements/MaterialTableDTE';
import CartResume from './elements/CartResume';
import InvoiceTableStepOne from './tables/InvoiceTableStepOne';
import { DIRECT_FINANCING, EARLY_PAYMENT } from '../helpers/constants';
import Panel from './elements/Panel';

// import BotAdvice from './elements/BotAdvice';
// import FireIcon from './icons/FireIcon';
import SearchBar from './elements/SearchBar';
import {
  fetchCartInvoices,
  fetchCartInvoicesTotalAmount,
} from '../store/actions/cartActions';

const useStyles = makeStyles({
  root: {
    display: 'grid',
    padding: '0 30px 30px 30px',
    gridTemplateRows: 'auto',
    gridTemplateColumns: 'auto 270px',
    gridColumnGap: 17,
  },
  invoicesRoot: {
    gridColumn: '1 / 2',
    gridRow: '1 / 2',
  },
  resumeRoot: {
    gridColumn: '2 / 3',
    gridRow: '1 / 2',
  },
  fireIconTitle: {
    fontSize: 12,
  },
  botAdvice: {
    marginTop: 15,
  },
});

const CartStepOne = ({ handleStepChange, operationType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    selectedCartInvoices,
    totalCartInvoices,
    totalSelectedCartInvoices,
    cartInvoices,
    fetchCartInvoicesIsLoading,
    fetchCartInvoicesTotalAmountIsLoading,
  } = useSelector(state => state.cartState);
  const { countryId, id: businessId } = useSelector(
    state => state.businessState.business
  );
  const holidays = useSelector(state => state.holidaysState.holidays);
  const { isSudoUser } = useSelector(state => state.uiState);

  const [filter, setFilter] = useState(null);
  const [size, setSize] = useState(20);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState(null);

  const searcher = () => {
    dispatch(
      fetchCartInvoices(
        businessId,
        countryId,
        operationType,
        {
          filter,
          size,
          page,
          order: sort,
        },
        holidays
      )
    );
  };

  useEffect(() => {
    handleStepChange(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    searcher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, filter, operationType, sort]);

  useEffect(() => {
    dispatch(fetchCartInvoicesTotalAmount(businessId, operationType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationType]);

  const handleChangePage = (page, size) => {
    setSize(size);
    setPage(page);
  };

  const handleChangeSearchInput = value => {
    setFilter(value);
    setPage(1);
  };

  const handleChangeSort = value => {
    const newSort = sort === `${value}:asc` ? `${value}:desc` : `${value}:asc`;
    setSort(newSort);
  };

  const handleNextButtonClick = () => {
    const trackPayload = {
      operationType: operationType === DIRECT_FINANCING ? 'FD' : 'PP',
      invoiceQuantity: selectedCartInvoices.length,
      availableInvoiceAmount: totalCartInvoices,
      selectedInvoiceAmount: totalSelectedCartInvoices,
    };
    if (isSudoUser) {
      trackPayload.source = 'sudo backoffice';
    }

    handleStepChange(1);
  };

  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.invoicesRoot}>
        <Panel
          title="Selecciona una o más facturas que quieras financiar"
          titlePadding="xl xl lg"
          contentPadding="zero zero sm"
          actions={
            <SearchBar
              handleChangeInput={value => handleChangeSearchInput(value)}
            />
          }
        >
          <InvoiceTableStepOne
            tableData={cartInvoices}
            fetchCartInvoicesIsLoading={fetchCartInvoicesIsLoading}
            countryId={countryId}
            handleChangePage={handleChangePage}
            handleChangeSort={handleChangeSort}
          />
        </Panel>
      </Grid>
      <Grid item className={classes.resumeRoot}>
        <CartResume
          title="Financiamiento"
          totalCartInvoices={totalCartInvoices || '-'}
          totalSelectedCartInvoices={totalSelectedCartInvoices || '-'}
          onClick={handleNextButtonClick}
          disabled={!totalSelectedCartInvoices}
          countryId={countryId}
          fetchCartInvoicesTotalAmountIsLoading={
            fetchCartInvoicesTotalAmountIsLoading
          }
        />
        {/* <div className={classes.botAdvice}>
          <BotAdvice
            title={
              <>
                <FireIcon size="small" className={classes.fireIconTitle} />{' '}
                Facturas destacadas{' '}
                <FireIcon size="small" className={classes.fireIconTitle} />
              </>
            }
            message="Según mi análisis de riesgo, las facturas destacadas tienen mayor probabilidad de ser financiadas rápidamente."
          />
        </div> */}
      </Grid>
    </Grid>
  );
};

CartStepOne.propTypes = {
  handleStepChange: PropTypes.func.isRequired,
  operationType: PropTypes.oneOf([DIRECT_FINANCING, EARLY_PAYMENT]).isRequired,
};

export default CartStepOne;
