import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { cleanupBankAccount } from '../../../../store/actions/bankAccountsActions';
import { cleanupContactDetail } from '../../../../store/actions/contactDetailActions';
import {
  setSupplier,
  updateSupplier,
} from '../../../../store/actions/suppliersActions';

const useSupplierProfile = () => {
  const dispatch = useDispatch();

  const { supplier, supplierUpdateIsLoading } =
    useSelector(state => state.suppliersState) || {};

  const { id: sourceId, businessId } = supplier || {};

  const handleUpdateSupplierPaymentTerm = value => {
    dispatch(updateSupplier(businessId, sourceId, { paymentTerm: value }));
  };

  const handleUnmount = useCallback(() => {
    dispatch(setSupplier(null));
    dispatch(cleanupBankAccount('supplier'));
    dispatch(cleanupContactDetail('supplier'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    supplier: supplier || {},
    supplierUpdateIsLoading,
    handleUnmount,
    handleUpdateSupplierPaymentTerm,
  };
};

export default useSupplierProfile;
