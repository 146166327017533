import { PURGE } from 'redux-persist';
import {
  FETCH_SETTINGS_RESUME_START,
  FETCH_SETTINGS_RESUME_ERROR,
  FETCH_SETTINGS_RESUME_SUCCESS,
  NAME_BY_IDENTIFIER_START,
  NAME_BY_IDENTIFIER_SUCCESS,
  NAME_BY_IDENTIFIER_ERROR,
  NAME_BY_IDENTIFIER_RESET,
  FETCH_BUSINESS_START,
  FETCH_BUSINESS_ERROR,
  FETCH_BUSINESS_SUCCESS,
  LOCAL_PURGE,
  UPDATE_BUSINESS_START,
  UPDATE_BUSINESS_SUCCESS,
  UPDATE_BUSINESS_ERROR,
  FETCH_TGR_DEBT_START,
  FETCH_TGR_DEBT_SUCCESS,
  FETCH_TGR_DEBT_ERROR,
} from '../actions/types';
import { t13s } from '../../translationKeys';

const initialState = {
  settingsResume: [],
  settingsResumeIsLoading: false,
  settingsResumeError: null,

  nameByIdentifier: null,
  nameByIdentifierIsLoading: false,
  nameByIdentifierError: null,
  nameByIdentifierConsulted: false,

  business: null,
  businessIsLoading: false,
  businessError: null,

  updateBusinessIsLoading: false,
  updateBusinessError: null,

  tgrDebt: {},
  tgrDebtIsLoading: false,
  tgrDebtError: null,
};

const fetchSettingsResumeStart = state => {
  return {
    ...state,
    settingsResume: [],
    settingsResumeIsLoading: true,
    settingsResumeError: null,
  };
};

const fetchSettingsResumeSuccess = (state, payload) => {
  return {
    ...state,
    settingsResumeIsLoading: false,
    settingsResumeError: null,
    settingsResume: payload.settingsResume,
  };
};
const fetchSettingsResumeError = (state, payload) => {
  return {
    ...state,
    settingsResume: [],
    settingsResumeIsLoading: false,
    settingsResumeError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const nameByIdentifierStart = state => {
  return {
    ...state,
    nameByIdentifier: null,
    nameByIdentifierIsLoading: true,
    nameByIdentifierError: null,
    nameByIdentifierConsulted: false,
  };
};

const nameByIdentifierSuccess = (state, payload) => {
  return {
    ...state,
    nameByIdentifierIsLoading: false,
    nameByIdentifierError: null,
    nameByIdentifier: payload.name,
    nameByIdentifierConsulted: true,
  };
};
const nameByIdentifierError = (state, payload) => {
  return {
    ...state,
    nameByIdentifierIsLoading: false,
    nameByIdentifierError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
    nameByIdentifier: null,
    nameByIdentifierConsulted: true,
  };
};
const nameByIdentifierReset = state => {
  return {
    ...state,
    nameByIdentifier: null,
    nameByIdentifierIsLoading: false,
    nameByIdentifierError: null,
  };
};

const fetchBusinessStart = state => {
  return {
    ...state,
    business: null,
    businessIsLoading: true,
    businessError: null,
  };
};

const fetchBusinessSuccess = (state, payload) => {
  return {
    ...state,
    businessIsLoading: false,
    businessError: null,
    business: payload,
  };
};
const fetchBusinessError = (state, payload) => {
  return {
    ...state,
    business: null,
    businessIsLoading: false,
    businessError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const updateBusinessStart = state => {
  return {
    ...state,
    updateBusinessIsLoading: true,
    updateBusinessError: null,
  };
};

const updateBusinessSuccess = (state, payload) => {
  return {
    ...state,
    updateBusinessIsLoading: false,
    updateBusinessError: null,
    business: payload.business,
  };
};

const updateBusinessError = (state, payload) => {
  return {
    ...state,
    updateBusinessError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
    updateBusinessIsLoading: false,
  };
};

const fetchTgrDebtStart = state => {
  return {
    ...state,
    tgrDebtIsLoading: true,
    tgrDebtError: null,
  };
};

const fetchTgrDebtSuccess = (state, payload) => {
  return {
    ...state,
    tgrDebtIsLoading: false,
    tgrDebtError: null,
    tgrDebt: payload.tgrDebt,
  };
};

const fetchTgrDebtError = (state, payload) => {
  return {
    ...state,
    tgrDebtError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
    tgrDebtIsLoading: false,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_SETTINGS_RESUME_START:
      return fetchSettingsResumeStart(state);
    case FETCH_SETTINGS_RESUME_SUCCESS:
      return fetchSettingsResumeSuccess(state, payload);
    case FETCH_SETTINGS_RESUME_ERROR:
      return fetchSettingsResumeError(state, payload);
    case NAME_BY_IDENTIFIER_START:
      return nameByIdentifierStart(state);
    case NAME_BY_IDENTIFIER_SUCCESS:
      return nameByIdentifierSuccess(state, payload);
    case NAME_BY_IDENTIFIER_ERROR:
      return nameByIdentifierError(state, payload);
    case NAME_BY_IDENTIFIER_RESET:
      return nameByIdentifierReset(state);
    case FETCH_BUSINESS_START:
      return fetchBusinessStart(state);
    case FETCH_BUSINESS_SUCCESS:
      return fetchBusinessSuccess(state, payload);
    case FETCH_BUSINESS_ERROR:
      return fetchBusinessError(state, payload);
    case UPDATE_BUSINESS_START:
      return updateBusinessStart(state);
    case UPDATE_BUSINESS_SUCCESS:
      return updateBusinessSuccess(state, payload);
    case UPDATE_BUSINESS_ERROR:
      return updateBusinessError(state, payload);
    case FETCH_TGR_DEBT_START:
      return fetchTgrDebtStart(state);
    case FETCH_TGR_DEBT_SUCCESS:
      return fetchTgrDebtSuccess(state, payload);
    case FETCH_TGR_DEBT_ERROR:
      return fetchTgrDebtError(state, payload);
    case PURGE:
      return initialState;
    case LOCAL_PURGE:
      return initialState;
    default:
      return state;
  }
};
