import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ErrorCircleIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Typography,
  Box,
  Tooltip,
} from '@material-ui/core';
import { FLOWS_ORDER } from '../../helpers/constants';
import {
  ORDER_STEP_COMPLETED,
  ORDER_STEP_WARNING,
} from '../../theme/otherColors';

const connectorStyles = makeStyles({
  alternativeLabel: {
    left: 'calc(-50%)',
    right: 'calc(50%)',
    top: 8,
  },
  line: {
    borderTopWidth: 0,
    height: 16,
    width: 'calc(100% + 10px)',
    borderRadius: 50,
    marginTop: -1,
    background: '#F0EDF5',
  },
});

const QontoConnector = React.forwardRef((props, ref) => {
  const classes = connectorStyles();
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <StepConnector {...props} ref={ref} classes={{ ...classes }} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& .MuiPaper-root': {
      background: 'transparent',
    },
  },
  icon: {
    borderRadius: '14px',
    background: 'white',
  },
  step: {
    padding: '2px 2px',
    width: 75,
    '& $completed': {
      color: ORDER_STEP_COMPLETED,
    },
    '& $warning': {
      color: ORDER_STEP_WARNING,
    },
    '& $error': {
      color: theme.palette.error.main,
    },
    '& $disabled': {
      background: theme.palette.grey[600],
    },
    '& .MuiStepLabel-label': {
      background: 'transparent',
      border: 0,
      marginTop: 2,
    },
    '& .MuiStepLabel-iconContainer': {
      height: 25,
      width: 25,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 20,
    },
    '& $xs': {
      borderRadius: 14,
      width: 8,
      height: 8,
    },
  },
  alternativeLabel: {},
  completed: {},
  disabled: {},
  error: {},
  warning: {},
  xs: {},
}));

const getStepIcon = (variant, size, label, classes) => {
  let icon;
  switch (variant) {
    case 'error':
    case 'warning':
      icon = (
        <ErrorCircleIcon
          fontSize={size}
          className={clsx(classes[variant], classes.icon)}
        />
      );
      break;
    case 'completed':
    case 'primary':
      icon = (
        <CheckCircleIcon
          fontSize={size}
          className={clsx(classes[variant], classes.icon)}
        />
      );
      break;
    default:
      icon = <span className={clsx(classes[variant], classes[size])} />;
      break;
  }
  return (
    <Tooltip title={size === 'small' ? label : ''} placement="bottom">
      {icon}
    </Tooltip>
  );
};

const TransferStatusSteps = ({ order }) => {
  const classes = useStyles();
  const flowKey = order.pymeStatus;
  const flowSteps = FLOWS_ORDER[flowKey];
  const activeStep = flowSteps.reduce((sum, step) => sum + step.active, -1);

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<QontoConnector />}
        classes={{
          root: classes.step,
        }}
      >
        {flowSteps.map(step => {
          const { label, variant, size } = step;

          return (
            <Step key={label}>
              <StepLabel
                classes={{
                  root: classes.step,
                  completed: classes.active,
                  active: classes[variant],
                  error: classes.error,
                }}
                StepIconComponent={() =>
                  getStepIcon(variant, size, label, classes)
                }
              >
                {size !== 'small' && (
                  <Typography variant="caption" component="div">
                    <Box fontWeight="fontWeightBold">{label}</Box>
                  </Typography>
                )}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

TransferStatusSteps.propTypes = {
  order: PropTypes.objectOf(Object).isRequired,
};

export default TransferStatusSteps;
