import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '../icons/SearchIcon';
import CloseIcon from '../icons/CloseIcon';

const SEARCHBAR_TIMEOUT = 500;

const SearchBar = ({ placeholder, handleChangeInput }) => {
  const [timer, setTimer] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const changeInput = e => {
    const {
      target: { value },
    } = e;
    clearTimeout(timer);
    setInputValue(value);
    const targetValue = value.trim();

    setTimer(
      setTimeout(() => {
        if (!targetValue && inputValue) handleChangeInput(null);
        if (targetValue) handleChangeInput(targetValue);
      }, SEARCHBAR_TIMEOUT)
    );
  };

  const clearInput = () => {
    setInputValue('');
    handleChangeInput(null);
  };

  return (
    <TextField
      value={inputValue}
      onChange={e => changeInput(e)}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={clearInput}>
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

SearchBar.defaultProps = {
  placeholder: 'Buscar',
};

SearchBar.propTypes = {
  handleChangeInput: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default SearchBar;
