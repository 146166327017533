// eslint-disable-next-line import/prefer-default-export
export const palette = {
  primary: {
    light: '#8f33ff',
    main: '#7300FF',
    dark: '#5000b2',
    contrastText: '#FFF',
  },
  secondary: {
    light: '#f98f6c',
    main: '#F87348',
    dark: '#ad5032',
    contrastText: '#FFF',
  },
  error: {
    light: '#e0584f',
    main: '#D92F24',
    dark: '#972019',
    contrastText: '#FFF',
  },
  warning: {
    light: '#ffc033',
    main: '#FFB100',
    dark: '#b27b00',
    contrastText: '#FFF',
  },
  info: {
    light: '#478feb',
    main: '#1A73E7',
    dark: '#1250a1',
    contrastText: '#FFF',
  },
  success: {
    light: '#90c633',
    main: '#75B801',
    dark: '#518000',
    contrastText: '#FFF',
  },
  grey: {
    50: '#FEFCFF',
    100: '#F7F5FA',
    200: '#F1EFF5',
    300: '#E9E6F0',
    400: '#CECAD9',
    500: '#C1BDCE',
    600: '#A8A4B3',
    700: '#9692A0',
    800: '#645F6E',
    900: '#45414D',
  },
  text: {
    primary: 'rgba(69, 65, 77, 1)',
    secondary: 'rgba(141, 138, 150, 1)',
    disabled: 'rgba(193, 189, 206, 1)',
    contrast1: 'rgba(117, 184, 1, 1)',
    contrast2: 'rgba(26, 115, 231, 1)',
  },
  transparent: 'transparent',
};
