// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const COUNTRIES = [
  { label: 'Afganistán', code: 'AF' },
  { label: 'Albania', code: 'AL' },
  { label: 'Alemania', code: 'DE' },
  { label: 'Andorra', code: 'AD' },
  { label: 'Angola', code: 'AO' },
  { label: 'Anguila', code: 'AI' },
  { label: 'Antártida', code: 'AQ' },
  { label: 'Antigua y Barbuda', code: 'AG' },
  { label: 'Arabia Saudí', code: 'SA' },
  { label: 'Argelia', code: 'DZ' },
  { label: 'Argentina', code: 'AR' },
  { label: 'Armenia', code: 'AM' },
  { label: 'Aruba', code: 'AW' },
  { label: 'Australia', code: 'AU' },
  { label: 'Austria', code: 'AT' },
  { label: 'Azerbaiyán', code: 'AZ' },
  { label: 'Bahamas', code: 'BS' },
  { label: 'Bahrein', code: 'BH' },
  { label: 'Bangladesh', code: 'BD' },
  { label: 'Barbados', code: 'BB' },
  { label: 'Bélgica', code: 'BE' },
  { label: 'Belice', code: 'BZ' },
  { label: 'Benín', code: 'BJ' },
  { label: 'Bermudas', code: 'BM' },
  { label: 'Bhután', code: 'BT' },
  { label: 'Bielorrusia', code: 'BY' },
  { label: 'Birmania', code: 'MM' },
  { label: 'Bolivia', code: 'BO' },
  { label: 'Bosnia y Herzegovina', code: 'BA' },
  { label: 'Botsuana', code: 'BW' },
  { label: 'Brasil', code: 'BR' },
  { label: 'Brunei', code: 'BN' },
  { label: 'Bulgaria', code: 'BG' },
  { label: 'Burkina Faso', code: 'BF' },
  { label: 'Burundi', code: 'BI' },
  { label: 'Cabo Verde', code: 'CV' },
  { label: 'Camboya', code: 'KH' },
  { label: 'Camerún', code: 'CM' },
  { label: 'Canadá', code: 'CA' },
  { label: 'Chad', code: 'TD' },
  { label: 'Chile', code: 'CL' },
  { label: 'China', code: 'CN' },
  { label: 'Chipre', code: 'CY' },
  { label: 'Vaticano', code: 'VA' },
  { label: 'Colombia', code: 'CO' },
  { label: 'Comores', code: 'KM' },
  { label: 'Congo', code: 'CG' },
  { label: 'Corea', code: 'KR' },
  { label: 'Corea del Norte', code: 'KP' },
  { label: 'Costa del Marfíl', code: 'CI' },
  { label: 'Costa Rica', code: 'CR' },
  { label: 'Croacia', code: 'HR' },
  { label: 'Cuba', code: 'CU' },
  { label: 'Dinamarca', code: 'DK' },
  { label: 'Djibouri', code: 'DJ' },
  { label: 'Dominica', code: 'DM' },
  { label: 'Ecuador', code: 'EC' },
  { label: 'Egipto', code: 'EG' },
  { label: 'El Salvador', code: 'SV' },
  { label: 'Emiratos Arabes Unidos', code: 'AE' },
  { label: 'Eritrea', code: 'ER' },
  { label: 'Eslovaquia', code: 'SK' },
  { label: 'Eslovenia', code: 'SI' },
  { label: 'España', code: 'ES' },
  { label: 'Estados Unidos', code: 'US' },
  { label: 'Estonia', code: 'EE' },
  { label: 'Etiopía', code: 'ET' },
  { label: 'Macedonia del Norte', code: 'MK' },
  { label: 'Filipinas', code: 'PH' },
  { label: 'Finlandia', code: 'FI' },
  { label: 'Francia', code: 'FR' },
  { label: 'Gabón', code: 'GA' },
  { label: 'Gambia', code: 'GM' },
  { label: 'Georgia', code: 'GE' },
  { label: 'Georgia del Sur', code: 'GS' },
  { label: 'Ghana', code: 'GH' },
  { label: 'Gibraltar', code: 'GI' },
  { label: 'Granada', code: 'GD' },
  { label: 'Grecia', code: 'GR' },
  { label: 'Groenlandia', code: 'GL' },
  { label: 'Guadalupe', code: 'GP' },
  { label: 'Guam', code: 'GU' },
  { label: 'Guatemala', code: 'GT' },
  { label: 'Guayana', code: 'GY' },
  { label: 'Guayana francesa', code: 'GF' },
  { label: 'Guinea', code: 'GN' },
  { label: 'Guinea Ecuatorial', code: 'GQ' },
  { label: 'Guinea-Bissau', code: 'GW' },
  { label: 'Haití', code: 'HT' },
  { label: 'Holanda', code: 'NL' },
  { label: 'Honduras', code: 'HN' },
  { label: 'Hong Kong', code: 'HK' },
  { label: 'Hungría', code: 'HU' },
  { label: 'India', code: 'IN' },
  { label: 'Indonesia', code: 'ID' },
  { label: 'Irak', code: 'IQ' },
  { label: 'Irán', code: 'IR' },
  { label: 'Irlanda', code: 'IE' },
  { label: 'Isla Bouvet', code: 'BV' },
  { label: 'Isla Christmas', code: 'CX' },
  { label: 'Isla Heard e Islas McDonald', code: 'HM' },
  { label: 'Islandia', code: 'IS' },
  { label: 'Islas Caimán', code: 'KY' },
  { label: 'Islas Cook', code: 'CK' },
  { label: 'Islas de Cocos o Keeling', code: 'CC' },
  { label: 'Islas Faroe', code: 'FO' },
  { label: 'Islas Fiyi', code: 'FJ' },
  { label: 'Islas Malvinas Islas Falkland', code: 'FK' },
  { label: 'Islas Marianas del norte', code: 'MP' },
  { label: 'Islas Marshall', code: 'MH' },
  { label: 'Islas menores de Estados Unidos', code: 'UM' },
  { label: 'Islas Palau', code: 'PW' },
  { label: 'Islas Salomón', code: 'SB' },
  { label: 'Islas Tokelau', code: 'TK' },
  { label: 'Islas Turks y Caicos', code: 'TC' },
  { label: 'Islas Vírgenes EE.UU.', code: 'VI' },
  { label: 'Islas Vírgenes Reino Unido', code: 'VG' },
  { label: 'Israel', code: 'IL' },
  { label: 'Italia', code: 'IT' },
  { label: 'Jamaica', code: 'JM' },
  { label: 'Japón', code: 'JP' },
  { label: 'Jordania', code: 'JO' },
  { label: 'Kazajistán', code: 'KZ' },
  { label: 'Kenia', code: 'KE' },
  { label: 'Kirguizistán', code: 'KG' },
  { label: 'Kiribati', code: 'KI' },
  { label: 'Kuwait', code: 'KW' },
  { label: 'Laos', code: 'LA' },
  { label: 'Lesoto', code: 'LS' },
  { label: 'Letonia', code: 'LV' },
  { label: 'Líbano', code: 'LB' },
  { label: 'Liberia', code: 'LR' },
  { label: 'Libia', code: 'LY' },
  { label: 'Liechtenstein', code: 'LI' },
  { label: 'Lituania', code: 'LT' },
  { label: 'Luxemburgo', code: 'LU' },
  { label: 'Macao', code: 'MO' },
  { label: 'Madagascar', code: 'MG' },
  { label: 'Malasia', code: 'MY' },
  { label: 'Malawi', code: 'MW' },
  { label: 'Maldivas', code: 'MV' },
  { label: 'Malí', code: 'ML' },
  { label: 'Malta', code: 'MT' },
  { label: 'Marruecos', code: 'MA' },
  { label: 'Martinica', code: 'MQ' },
  { label: 'Mauricio', code: 'MU' },
  { label: 'Mauritania', code: 'MR' },
  { label: 'Mayotte', code: 'YT' },
  { label: 'México', code: 'MX' },
  { label: 'Micronesia', code: 'FM' },
  { label: 'Moldavia', code: 'MD' },
  { label: 'Mónaco', code: 'MC' },
  { label: 'Mongolia', code: 'MN' },
  { label: 'Montserrat', code: 'MS' },
  { label: 'Mozambique', code: 'MZ' },
  { label: 'Namibia', code: 'NA' },
  { label: 'Nauru', code: 'NR' },
  { label: 'Nepal', code: 'NP' },
  { label: 'Nicaragua', code: 'NI' },
  { label: 'Níger', code: 'NE' },
  { label: 'Nigeria', code: 'NG' },
  { label: 'Niue', code: 'NU' },
  { label: 'Norfolk', code: 'NF' },
  { label: 'Noruega', code: 'NO' },
  { label: 'Nueva Caledonia', code: 'NC' },
  { label: 'Nueva Zelanda', code: 'NZ' },
  { label: 'Omán', code: 'OM' },
  { label: 'Panamá', code: 'PA' },
  { label: 'Papua Nueva Guinea', code: 'PG' },
  { label: 'Paquistán', code: 'PK' },
  { label: 'Paraguay', code: 'PY' },
  { label: 'Perú', code: 'PE' },
  { label: 'Pitcairn', code: 'PN' },
  { label: 'Polinesia francesa', code: 'PF' },
  { label: 'Polonia', code: 'PL' },
  { label: 'Portugal', code: 'PT' },
  { label: 'Puerto Rico', code: 'PR' },
  { label: 'Qatar', code: 'QA' },
  { label: 'Reino Unido', code: 'GB' },
  { label: 'República Centroafricana', code: 'CF' },
  { label: 'República Checa', code: 'CZ' },
  { label: 'República de Sudáfrica', code: 'ZA' },
  { label: 'República Democrática del Congo Zaire', code: 'CD' },
  { label: 'República Dominicana', code: 'DO' },
  { label: 'Reunión', code: 'RE' },
  { label: 'Ruanda', code: 'RW' },
  { label: 'Rumania', code: 'RO' },
  { label: 'Rusia', code: 'RU' },
  { label: 'Samoa', code: 'WS' },
  { label: 'Samoa occidental', code: 'AS' },
  { label: 'San Kitts y Nevis', code: 'KN' },
  { label: 'San Marino', code: 'SM' },
  { label: 'San Pierre y Miquelon', code: 'PM' },
  { label: 'San Vicente e Islas Granadinas', code: 'VC' },
  { label: 'Santa Helena', code: 'SH' },
  { label: 'Santa Lucía', code: 'LC' },
  { label: 'Santo Tomé y Príncipe', code: 'ST' },
  { label: 'Senegal', code: 'SN' },
  { label: 'Serbia', code: 'RS' },
  { label: 'Montenegro', code: 'ME' },
  { label: 'Sychelles', code: 'SC' },
  { label: 'Sierra Leona', code: 'SL' },
  { label: 'Singapur', code: 'SG' },
  { label: 'Siria', code: 'SY' },
  { label: 'Somalia', code: 'SO' },
  { label: 'Sri Lanka', code: 'LK' },
  { label: 'Suazilandia', code: 'SZ' },
  { label: 'Sudán', code: 'SD' },
  { label: 'Suecia', code: 'SE' },
  { label: 'Suiza', code: 'CH' },
  { label: 'Surinam', code: 'SR' },
  { label: 'Svalbard', code: 'SJ' },
  { label: 'Tailandia', code: 'TH' },
  { label: 'Taiwán', code: 'TW' },
  { label: 'Tanzania', code: 'TZ' },
  { label: 'Tayikistán', code: 'TJ' },
  { label: 'Territorios británicos del océano Indico', code: 'IO' },
  { label: 'Territorios franceses del sur', code: 'TF' },
  { label: 'Timor Oriental', code: 'TL' },
  { label: 'Togo', code: 'TG' },
  { label: 'Tonga', code: 'TO' },
  { label: 'Trinidad y Tobago', code: 'TT' },
  { label: 'Túnez', code: 'TN' },
  { label: 'Turkmenistán', code: 'TM' },
  { label: 'Turquía', code: 'TR' },
  { label: 'Tuvalu', code: 'TV' },
  { label: 'Ucrania', code: 'UA' },
  { label: 'Uganda', code: 'UG' },
  { label: 'Uruguay', code: 'UY' },
  { label: 'Uzbekistán', code: 'UZ' },
  { label: 'Vanuatu', code: 'VU' },
  { label: 'Venezuela', code: 'VE' },
  { label: 'Vietnam', code: 'VN' },
  { label: 'Wallis y Futuna', code: 'WF' },
  { label: 'Yemen', code: 'YE' },
  { label: 'Zambia', code: 'ZM' },
  { label: 'Zimbabue', code: 'ZW' },
];

export const COMMUNES_PER_REGION_CL = {
  'Arica y Parinacota': [
    { name: 'Arica' },
    { name: 'Camarones' },
    { name: 'General Lagos' },
    { name: 'Putre' },
  ],
  Tarapacá: [
    { name: 'Alto Hospicio' },
    { name: 'Camiña' },
    { name: 'Colchane' },
    { name: 'Huara' },
    { name: 'Iquique' },
    { name: 'Pica' },
    { name: 'Pozo Almonte' },
  ],
  Antofagasta: [
    { name: 'Antofagasta' },
    { name: 'Calama' },
    { name: 'María Elena' },
    { name: 'Mejillones' },
    { name: 'Ollagüe' },
    { name: 'San Pedro de Atacama' },
    { name: 'Sierra Gorda' },
    { name: 'Taltal' },
    { name: 'Tocopilla' },
  ],
  Atacama: [
    { name: 'Alto del Carmen' },
    { name: 'Caldera' },
    { name: 'Chañaral' },
    { name: 'Copiapó' },
    { name: 'Diego de Almagro' },
    { name: 'Freirina' },
    { name: 'Huasco' },
    { name: 'Tierra Amarilla' },
    { name: 'Vallenar' },
  ],
  Coquimbo: [
    { name: 'Andacollo' },
    { name: 'Canela' },
    { name: 'Combarbalá' },
    { name: 'Coquimbo' },
    { name: 'Illapel' },
    { name: 'La Higuera' },
    { name: 'La Serena' },
    { name: 'Los Vilos' },
    { name: 'Monte Patria' },
    { name: 'Ovalle' },
    { name: 'Paiguano' },
    { name: 'Punitaqui' },
    { name: 'Río Hurtado' },
    { name: 'Salamanca' },
    { name: 'Vicuña' },
  ],
  Valparaíso: [
    { name: 'Algarrobo' },
    { name: 'Cabildo' },
    { name: 'Calera' },
    { name: 'Calle Larga' },
    { name: 'Cartagena' },
    { name: 'Casablanca' },
    { name: 'Catemu' },
    { name: 'Concón' },
    { name: 'El Quisco' },
    { name: 'El Tabo' },
    { name: 'Hijuelas' },
    { name: 'Isla de Pascua' },
    { name: 'Juan Fernández' },
    { name: 'La Cruz' },
    { name: 'La Ligua' },
    { name: 'Limache' },
    { name: 'Llaillay' },
    { name: 'Los Andes' },
    { name: 'Nogales' },
    { name: 'Olmué' },
    { name: 'Panquehue' },
    { name: 'Papudo' },
    { name: 'Petorca' },
    { name: 'Puchuncaví' },
    { name: 'Putaendo' },
    { name: 'Quillota' },
    { name: 'Quilpué' },
    { name: 'Quintero' },
    { name: 'Rinconada' },
    { name: 'San Antonio' },
    { name: 'San Esteban' },
    { name: 'San Felipe' },
    { name: 'Santa María' },
    { name: 'Santo Domingo' },
    { name: 'Valparaíso' },
    { name: 'Villa Alemana' },
    { name: 'Viña del Mar' },
    { name: 'Zapallar' },
  ],
  'Metropolitana de Santiago': [
    { name: 'Alhué' },
    { name: 'Buin' },
    { name: 'Calera de Tango' },
    { name: 'Cerrillos' },
    { name: 'Cerro Navia' },
    { name: 'Colina' },
    { name: 'Conchalí' },
    { name: 'Curacaví' },
    { name: 'El Bosque' },
    { name: 'El Monte' },
    { name: 'Estación Central' },
    { name: 'Huechuraba' },
    { name: 'Independencia' },
    { name: 'Isla de Maipo' },
    { name: 'La Cisterna' },
    { name: 'La Florida' },
    { name: 'La Granja' },
    { name: 'La Pintana' },
    { name: 'La Reina' },
    { name: 'Lampa' },
    { name: 'Las Condes' },
    { name: 'Lo Barnechea' },
    { name: 'Lo Espejo' },
    { name: 'Lo Prado' },
    { name: 'Macul' },
    { name: 'Maipú' },
    { name: 'María Pinto' },
    { name: 'Melipilla' },
    { name: 'Ñuñoa' },
    { name: 'Padre Hurtado' },
    { name: 'Paine' },
    { name: 'Pedro Aguirre Cerda' },
    { name: 'Peñaflor' },
    { name: 'Peñalolén' },
    { name: 'Pirque' },
    { name: 'Providencia' },
    { name: 'Pudahuel' },
    { name: 'Puente Alto' },
    { name: 'Quilicura' },
    { name: 'Quinta Normal' },
    { name: 'Recoleta' },
    { name: 'Renca' },
    { name: 'San Bernardo' },
    { name: 'San Joaquín' },
    { name: 'San José de Maipo' },
    { name: 'San Miguel' },
    { name: 'San Pedro' },
    { name: 'San Ramón' },
    { name: 'Santiago' },
    { name: 'Talagante' },
    { name: 'Tiltil' },
    { name: 'Vitacura' },
  ],
  "Libertador Gral. Bernardo O'Higgins": [
    { name: 'Chimbarongo' },
    { name: 'Chépica' },
    { name: 'Codegua' },
    { name: 'Coinco' },
    { name: 'Coltauco' },
    { name: 'Doñihue' },
    { name: 'Graneros' },
    { name: 'La Estrella' },
    { name: 'Las Cabras' },
    { name: 'Litueche' },
    { name: 'Lolol' },
    { name: 'Machalí' },
    { name: 'Malloa' },
    { name: 'Marchihue' },
    { name: 'Nancagua' },
    { name: 'Navidad' },
    { name: 'Olivar' },
    { name: 'Palmilla' },
    { name: 'Paredones' },
    { name: 'Peralillo' },
    { name: 'Peumo' },
    { name: 'Pichidegua' },
    { name: 'Pichilemu' },
    { name: 'Placilla' },
    { name: 'Pumanque' },
    { name: 'Quinta de Tilcoco' },
    { name: 'Rancagua' },
    { name: 'Rengo' },
    { name: 'Requínoa' },
    { name: 'San Fernando' },
    { name: 'San Francisco de Mostazal' },
    { name: 'San Vicente de Tagua Tagua' },
    { name: 'Santa Cruz' },
  ],
  Maule: [
    { name: 'Cauquenes' },
    { name: 'Chanco' },
    { name: 'Colbún' },
    { name: 'Constitución' },
    { name: 'Curepto' },
    { name: 'Curicó' },
    { name: 'Empedrado' },
    { name: 'Hualañé' },
    { name: 'Licantén' },
    { name: 'Linares' },
    { name: 'Longaví' },
    { name: 'Maule' },
    { name: 'Molina' },
    { name: 'Parral' },
    { name: 'Pelarco' },
    { name: 'Pelluhue' },
    { name: 'Pencahue' },
    { name: 'Rauco' },
    { name: 'Retiro' },
    { name: 'Romeral' },
    { name: 'Río Claro' },
    { name: 'Sagrada Familia' },
    { name: 'San Clemente' },
    { name: 'San Javier de Loncomilla' },
    { name: 'San Rafael' },
    { name: 'Talca' },
    { name: 'Teno' },
    { name: 'Vichuquén' },
    { name: 'Villa Alegre' },
    { name: 'Yerbas Buenas' },
  ],
  Ñuble: [
    { name: 'Bulnes' },
    { name: 'Chillán Viejo' },
    { name: 'Chillán' },
    { name: 'Cobquecura' },
    { name: 'Coelemu' },
    { name: 'Coihueco' },
    { name: 'El Carmen' },
    { name: 'Ninhue' },
    { name: 'Ñiquén' },
    { name: 'Pemuco' },
    { name: 'Pinto' },
    { name: 'Portezuelo' },
    { name: 'Quillón' },
    { name: 'Quirihue' },
    { name: 'Ránquil' },
    { name: 'San Carlos' },
    { name: 'San Fabián' },
    { name: 'San Ignacio' },
    { name: 'San Nicolás' },
    { name: 'Treguaco' },
    { name: 'Yungay' },
  ],
  Biobío: [
    { name: 'Alto Biobío' },
    { name: 'Antuco' },
    { name: 'Arauco' },
    { name: 'Cabrero' },
    { name: 'Cañete' },
    { name: 'Chiguayante' },
    { name: 'Concepción' },
    { name: 'Contulmo' },
    { name: 'Coronel' },
    { name: 'Curanilahue' },
    { name: 'Florida' },
    { name: 'Hualpén' },
    { name: 'Hualqui' },
    { name: 'Laja' },
    { name: 'Lebu' },
    { name: 'Los Álamos' },
    { name: 'Los Ángeles' },
    { name: 'Lota' },
    { name: 'Mulchén' },
    { name: 'Nacimiento' },
    { name: 'Negrete' },
    { name: 'Penco' },
    { name: 'Quilaco' },
    { name: 'Quilleco' },
    { name: 'San Pedro de la Paz' },
    { name: 'San Rosendo' },
    { name: 'Santa Bárbara' },
    { name: 'Santa Juana' },
    { name: 'Talcahuano' },
    { name: 'Tirúa' },
    { name: 'Tomé' },
    { name: 'Tucapel' },
    { name: 'Yumbel' },
  ],
  Araucanía: [
    { name: 'Angol' },
    { name: 'Carahue' },
    { name: 'Cholchol' },
    { name: 'Collipulli' },
    { name: 'Cunco' },
    { name: 'Curacautín' },
    { name: 'Curarrehue' },
    { name: 'Ercilla' },
    { name: 'Freire' },
    { name: 'Galvarino' },
    { name: 'Gorbea' },
    { name: 'Lautaro' },
    { name: 'Loncoche' },
    { name: 'Lonquimay' },
    { name: 'Los Sauces' },
    { name: 'Lumaco' },
    { name: 'Melipeuco' },
    { name: 'Nueva Imperial' },
    { name: 'Padre las Casas' },
    { name: 'Perquenco' },
    { name: 'Pitrufquén' },
    { name: 'Pucón' },
    { name: 'Purén' },
    { name: 'Renaico' },
    { name: 'Saavedra' },
    { name: 'Temuco' },
    { name: 'Teodoro Schmidt' },
    { name: 'Toltén' },
    { name: 'Traiguén' },
    { name: 'Victoria' },
    { name: 'Vilcún' },
    { name: 'Villarrica' },
  ],
  'Los Ríos': [
    { name: 'Corral' },
    { name: 'Futrono' },
    { name: 'La Unión' },
    { name: 'Lago Ranco' },
    { name: 'Lanco' },
    { name: 'Los Lagos' },
    { name: 'Mariquina' },
    { name: 'Máfil' },
    { name: 'Paillaco' },
    { name: 'Panguipulli' },
    { name: 'Río Bueno' },
    { name: 'Valdivia' },
  ],
  'Los Lagos': [
    { name: 'Ancud' },
    { name: 'Calbuco' },
    { name: 'Castro' },
    { name: 'Chaitén' },
    { name: 'Chonchi' },
    { name: 'Cochamó' },
    { name: 'Curaco de Vélez' },
    { name: 'Dalcahue' },
    { name: 'Fresia' },
    { name: 'Frutillar' },
    { name: 'Futaleufú' },
    { name: 'Hualaihué' },
    { name: 'Llanquihue' },
    { name: 'Los Muermos' },
    { name: 'Maullín' },
    { name: 'Osorno' },
    { name: 'Palena' },
    { name: 'Puerto Montt' },
    { name: 'Puerto Octay' },
    { name: 'Puerto Varas' },
    { name: 'Puqueldón' },
    { name: 'Purranque' },
    { name: 'Puyehue' },
    { name: 'Queilén' },
    { name: 'Quellón' },
    { name: 'Quemchi' },
    { name: 'Quinchao' },
    { name: 'Río Negro' },
    { name: 'San Juan de la Costa' },
    { name: 'San Pablo' },
  ],
  'Aisén del Gral. Carlos Ibáñez del Campo': [
    { name: 'Aisén' },
    { name: 'Chile Chico' },
    { name: 'Cisnes' },
    { name: 'Cochrane' },
    { name: 'Coyhaique' },
    { name: 'Guaitecas' },
    { name: 'Lago Verde' },
    { name: "O'Higgins" },
    { name: 'Río Ibáñez' },
    { name: 'Tortel' },
  ],
  'Magallanes y de la Antártica Chilena': [
    { name: 'Antártica' },
    { name: 'Cabo de Hornos (Ex Navarino)' },
    { name: 'Laguna Blanca' },
    { name: 'Natales' },
    { name: 'Porvenir' },
    { name: 'Primavera' },
    { name: 'Punta Arenas' },
    { name: 'Río Verde' },
    { name: 'San Gregorio' },
    { name: 'Timaukel' },
    { name: 'Torres del Paine' },
  ],
};

export const REGIONS_CL = [
  {
    name: 'Arica y Parinacota',
    romanNumber: 'XV',
    code: '15',
  },
  {
    name: 'Tarapacá',
    romanNumber: 'I',
    code: '1',
  },
  {
    name: 'Antofagasta',
    romanNumber: 'II',
    code: '2',
  },
  {
    name: 'Atacama',
    romanNumber: 'III',
    code: '3',
  },
  {
    name: 'Coquimbo',
    romanNumber: 'IV',
    code: '4',
  },
  {
    name: 'Valparaíso',
    romanNumber: 'V',
    code: '5',
  },
  {
    name: 'Metropolitana de Santiago',
    romanNumber: 'XIII',
    code: '13',
  },
  {
    name: "Libertador Gral. Bernardo O'Higgins",
    romanNumber: 'VI',
    code: '6',
  },
  {
    name: 'Maule',
    romanNumber: 'VII',
    code: '7',
  },
  {
    name: 'Ñuble',
    romanNumber: 'XVI',
    code: '16',
  },
  {
    name: 'Biobío',
    romanNumber: 'VIII',
    code: '8',
  },
  {
    name: 'Araucanía',
    romanNumber: 'IX',
    code: '9',
  },
  {
    name: 'Los Ríos',
    romanNumber: 'XIV',
    code: '14',
  },
  {
    name: 'Los Lagos',
    romanNumber: 'X',
    code: '10',
  },
  {
    name: 'Aisén del Gral. Carlos Ibáñez del Campo',
    romanNumber: 'XI',
    code: '11',
  },
  {
    name: 'Magallanes y de la Antártica Chilena',
    romanNumber: 'XII',
    code: '12',
  },
];
