import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  dropzoneRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  previewsRoot: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
    height: '225px',
    width: '100%',
    marginLeft: '20px',
  },
  submitRoot: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  inputRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
});

const Layout = ({
  input: InputComponent,
  previews: PreviewsComponent,
  submitButton: SubmitButtonComponent,
  dropzoneProps,
  files,
  extra: { maxFiles: maxSelectableFiles },
}) => {
  const selectedFiles = files.length;
  const classes = useStyles();

  return (
    // InputRoot
    //   |_ Dropzone lateral sólo si son +1 archivos solicitados
    //   |_ Vista previa sólo si ya hay +1 archivo seleccionado
    // SubmitButton
    <>
      <div className={classes.inputRoot}>
        {selectedFiles < maxSelectableFiles && maxSelectableFiles > 1 && (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <div className={classes.dropzoneRoot} {...dropzoneProps}>
            {InputComponent}
          </div>
        )}
        {!!selectedFiles && (
          <div className={classes.previewsRoot}>{PreviewsComponent}</div>
        )}
      </div>
      <div className={classes.submitRoot}>
        {!!selectedFiles && SubmitButtonComponent}
      </div>
    </>
  );
};

Layout.propTypes = {
  input: PropTypes.func.isRequired,
  previews: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitButton: PropTypes.func.isRequired,
  dropzoneProps: PropTypes.shape({
    className: PropTypes.string.isRequired,
    onDragEnter: PropTypes.func.isRequired,
    onDragOver: PropTypes.func.isRequired,
    onDragLeave: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
  }).isRequired,
  files: PropTypes.arrayOf(PropTypes.any).isRequired,
  extra: PropTypes.shape({
    maxFiles: PropTypes.number.isRequired,
  }).isRequired,
};

export default Layout;
