import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ViewIcon = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 16 12">
      <path d="M7.87941 0C11.4741 0 14.4647 2.58667 15.0921 6C14.4654 9.41333 11.4741 12 7.87941 12C4.28475 12 1.29408 9.41333 0.666748 6C1.29341 2.58667 4.28475 0 7.87941 0ZM7.87941 10.6667C9.23906 10.6664 10.5583 10.2045 11.6213 9.35678C12.6843 8.50901 13.428 7.32552 13.7307 6C13.4269 4.67554 12.6827 3.49334 11.6198 2.64668C10.557 1.80003 9.23828 1.33902 7.87941 1.33902C6.52055 1.33902 5.20187 1.80003 4.139 2.64668C3.07612 3.49334 2.33193 4.67554 2.02808 6C2.33081 7.32552 3.07453 8.50901 4.13751 9.35678C5.20049 10.2045 6.51977 10.6664 7.87941 10.6667ZM7.87941 9C7.08377 9 6.3207 8.68393 5.75809 8.12132C5.19549 7.55871 4.87941 6.79565 4.87941 6C4.87941 5.20435 5.19549 4.44129 5.75809 3.87868C6.3207 3.31607 7.08377 3 7.87941 3C8.67506 3 9.43813 3.31607 10.0007 3.87868C10.5633 4.44129 10.8794 5.20435 10.8794 6C10.8794 6.79565 10.5633 7.55871 10.0007 8.12132C9.43813 8.68393 8.67506 9 7.87941 9ZM7.87941 7.66667C8.32144 7.66667 8.74537 7.49107 9.05793 7.17851C9.37049 6.86595 9.54608 6.44203 9.54608 6C9.54608 5.55797 9.37049 5.13405 9.05793 4.82149C8.74537 4.50893 8.32144 4.33333 7.87941 4.33333C7.43739 4.33333 7.01346 4.50893 6.7009 4.82149C6.38834 5.13405 6.21275 5.55797 6.21275 6C6.21275 6.44203 6.38834 6.86595 6.7009 7.17851C7.01346 7.49107 7.43739 7.66667 7.87941 7.66667Z" />
    </SvgIcon>
  );
};

export default ViewIcon;
