import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core/';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import LoadingButton from './LoadingButton';
import { DRAWER_BACKGROUND } from '../../theme/otherColors';

const useStyles = makeStyles({
  baseConfirm: {
    borderRadius: 50,
    background: DRAWER_BACKGROUND,
    padding: '0 0 0 30px',
    width: 'fit-content',
  },
});

const ConfirmAction = props => {
  const { buttonLabel, buttonOnAccept, checked, onChangeCheck, type, loading } =
    props;
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={classes.baseConfirm}
    >
      <Grid item>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={onChangeCheck}
                size="small"
                value="checked"
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                data-qa="confirm-action-checkbox"
              />
            }
            label={
              <Typography
                variant="body1"
                color={checked ? 'secondary' : 'textSecondary'}
              >
                Confirmo
              </Typography>
            }
          />
        </FormGroup>
      </Grid>
      <Grid item>
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={!checked}
          onClick={buttonOnAccept}
          type={type}
          isLoading={loading}
          data-qa="confirm-action-button-action"
        >
          {buttonLabel}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

ConfirmAction.defaultProps = {
  type: 'button',
  loading: false,
  buttonOnAccept: () => {},
};

ConfirmAction.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  buttonOnAccept: PropTypes.func,
  checked: PropTypes.bool.isRequired,
  onChangeCheck: PropTypes.func.isRequired,
  type: PropTypes.string,
  loading: PropTypes.bool,
};

export default ConfirmAction;
