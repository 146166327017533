import { makeStyles } from '@material-ui/core';

const paidTableStyles = makeStyles(theme => ({
  card: {
    width: '97.5%',
    padding: '1rem',
  },
  tableInvoicesContainer: {
    alignContent: 'flex-start',
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
    height: '100%',
    paddingBottom: '4rem',
  },
  invoicePayrollColumn: {
    minWidth: 160,
    color: theme.palette.text.secondary,
  },
  invoicePayrollLink: {
    textDecoration: 'underline',
  },
}));

// eslint-disable-next-line import/prefer-default-export
export { paidTableStyles };
