/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export default Component => {
  return () => {
    const isAuth = useSelector(state => state.authState.isAuthenticated);
    const business = useSelector(state => state.authState.business);
    const history = useHistory();

    const validate = () => {
      let path;
      if (isAuth && business?.id) {
        path = '/finance';
      } else if (isAuth && !business.id) {
        path = '/completeregister';
      }

      if (path) {
        history.push(path + history.location.search);
      }
    };

    useEffect(() => {
      validate();
    }, []);

    useEffect(() => {
      validate();
    });

    return <Component />;
  };
};
