import { makeStyles } from '@material-ui/core';

const inboxTableStyles = makeStyles(theme => ({
  card: {
    width: '97.5%',
    padding: '1rem',
  },
  tableInvoicesContainer: {
    alignContent: 'flex-start',
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
    height: '100%',
    paddingBottom: '4rem',
  },
  loadCSVButton: {
    margin: '0 16px 0 0',
    ...theme.typography.button,
  },
}));

const selectedInvoicesPopUpStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minHeight: 88,
    background: theme.palette.primary.main,
    borderRadius: '8px 8px 0px 0px',
    padding: '16px 28px 0 32px',
    boxSizing: 'border-box',
    position: 'sticky',
    bottom: 0,
    zIndex: 40,
    marginTop: 'auto',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'auto 400px',
  },
  totalLine1: {
    ...theme.typography.h5,
    margin: '0 0 8px 0',
    color: theme.palette.primary.contrastText,
  },
  totalLine2: {
    ...theme.typography.subtitle1,
    margin: 0,
    color: theme.palette.primary.contrastText,
  },
  scheduleButton: {
    width: '100%',
    padding: '16px 48px',
    minHeight: 56,
    ...theme.typography.button,
    fontSize: theme.typography.h6.fontSize,
  },
}));

export { inboxTableStyles, selectedInvoicesPopUpStyles };
