import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, Typography, Box } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Transition } from 'react-transition-group';
import queryString from 'query-string';
import HomeStartLeft from './HomeStartLeft';
import HomeStartRight from './HomeStartRight';
import CustomButton from './elements/CustomButton';
import * as colors from '../theme/otherColors';
import { setUtmInfo, setReferralCode } from '../store/actions/uiStateActions';
import Footer from './Footer';

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: '700px auto',
    gridTemplateColumns: 'auto',
  },
  loginArea: {
    gridRow: '1 / 2',
    gridColumn: '1 / 2',
  },
  registerArea: {
    gridRow: '1 / 2',
    gridColumn: '1 / 2',
    justifyContent: 'flex-end',
  },
  purpleArea: {
    gridRow: '1 / 3',
    gridColumn: '1 / 3',
    background: colors.HOME_START_MAIN_PURPLE,
    height: '100%',
  },
  footerArea: {
    gridRow: '2',
    gridColumn: '1 / 2',
    zIndex: 2,
  },
  titleWhite: {
    color: '#FFF',
    marginTop: 280,
    fontSize: 21,
  },
  subtitle: {
    marginTop: 10,
    marginBottom: 15,
    color: '#FFF',
    fontSize: 14,
  },
});

const defaultFormsStyle = {
  transition: 'opacity 0.25s ease-in-out',
};

const transitionFormStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1, zIndex: 0 },
  exiting: { opacity: 0 },
  exited: { opacity: 0, zIndex: -1 },
};

const defaultPurpleTextStyle = {
  transition: 'opacity 0.25s ease-in-out',
  position: 'absolute',
  margin: '0 auto',
  width: '100%',
};
const transitionPurpleTextStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0 },
};

const defaultPurpleAreaStyle = loginOption => {
  const initialPosition = loginOption ? '200%' : '0px';
  return {
    transition: 'transform 0.5s ease-in-out',
    transform: `translateX(${initialPosition})`,
  };
};

const HomeStart = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const countryIdFromConfig = process.env.REACT_APP_COUNTRY_ID;

  const [loginOption, setLoginOption] = useState(
    location.pathname.includes('/login') ||
      location.pathname.includes('/forgotpassword')
  );

  useEffect(() => {
    const {
      utm_campaign: utmCampaign,
      utm_medium: utmMedium,
      utm_source: utmSource,
      referral_code: referralCode,
    } = queryString.parse(location.search);
    dispatch(setUtmInfo({ utmCampaign, utmMedium, utmSource }));
    dispatch(setReferralCode(referralCode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setStartOption = path => {
    setLoginOption(!loginOption);
    history.push(path);
  };

  return (
    <Grid container className={classes.root}>
      <Transition in={loginOption} mountOnEnter timeout={500}>
        {state => (
          <div
            className={classes.loginArea}
            style={{
              ...defaultFormsStyle,
              ...transitionFormStyles[state],
            }}
          >
            <HomeStartLeft country={countryIdFromConfig} />
          </div>
        )}
      </Transition>
      <Transition in={!loginOption} mountOnEnter timeout={500}>
        {state => (
          <div
            className={classes.registerArea}
            style={{
              ...defaultFormsStyle,
              ...transitionFormStyles[state],
            }}
          >
            <HomeStartRight country={countryIdFromConfig} />
          </div>
        )}
      </Transition>
      <Transition in mountOnEnter timeout={500}>
        <Grid
          item
          xs={4}
          className={classes.purpleArea}
          align="center"
          style={{
            ...defaultPurpleAreaStyle(loginOption),
          }}
        >
          <Transition in={loginOption} unmountOnExit timeout={250}>
            {state => (
              <div
                className={classes.registerArea}
                style={{
                  ...defaultPurpleTextStyle,
                  ...transitionPurpleTextStyles[state],
                }}
              >
                <Typography
                  variant="h3"
                  component="div"
                  className={classes.titleWhite}
                >
                  <Box fontWeight="fontWeightBold">¿Eres nuevo?</Box>
                </Typography>
                <Typography variant="body1" className={classes.subtitle}>
                  Empieza a disfrutar de todos
                  <br />
                  nuestros servicios
                </Typography>
                <CustomButton
                  variant="white-lined"
                  onClick={() => {
                    setStartOption('/start/register');
                  }}
                >
                  Regístrate aquí
                </CustomButton>
              </div>
            )}
          </Transition>
          <Transition in={!loginOption} unmountOnExit timeout={250}>
            {state => (
              <div
                className={classes.registerArea}
                style={{
                  ...defaultPurpleTextStyle,
                  ...transitionPurpleTextStyles[state],
                }}
              >
                <Typography
                  variant="h3"
                  component="div"
                  className={classes.titleWhite}
                >
                  <Box fontWeight="fontWeightBold">¿Ya tienes cuenta?</Box>
                </Typography>
                <div className={classes.separatorWhite} />
                <Typography variant="h3" className={classes.subtitle}>
                  ¡Bienvenido de vuelta!
                </Typography>
                <CustomButton
                  variant="white-lined"
                  onClick={() => {
                    setStartOption('/start/login');
                  }}
                >
                  Ingresa aquí
                </CustomButton>
              </div>
            )}
          </Transition>
        </Grid>
      </Transition>
      <Grid item className={classes.footerArea}>
        <Footer country={countryIdFromConfig} />
      </Grid>
    </Grid>
  );
};

export default HomeStart;
