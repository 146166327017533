import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Avatar,
  Box,
  Card,
  Divider,
  Grid,
  IconButton,
  Link,
  Typography,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { AVAILABLE_COUNTRIES } from '../../helpers/constants';
import ArrowRightIcon from '../../components/icons/ArrowRightIcon';

import CustomButton from '../../components/elements/CustomButton';
import PayrollIcon from '../../components/icons/PayrollIcon';
import CountryFormatHelper from '../../components/elements/CountryFormatHelper';
import { useStyles } from './styles';
import { SCHEDULED_READY_TO_PAY, statusStyles } from './statusStyles';

const PayrollCard = React.memo(
  ({
    payroll,
    countryId,
    handlePayInvoicesButton,
    handleClickMenu,
    hasActions,
  }) => {
    const {
      id: payrollId,
      name,
      paymentDate,
      status,
      totalInvoices,
      totalSuppliers,
      summedAmount,
      hasBankAccount,
    } = payroll;

    const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation();

    return (
      <>
        <Card key={payrollId} className={classes.card}>
          <Grid container justifyContent="space-between">
            <Grid item className={classes.cardLeftPanel}>
              <Grid container justifyContent="flex-start" alignItems="center">
                <Grid item>
                  <Avatar className={classes.avatar}>
                    <Box color="grey.900">
                      <PayrollIcon color="inherit" />
                    </Box>
                    <Box
                      className={classes.dotAlert}
                      bgcolor={
                        status in statusStyles
                          ? statusStyles[status].color
                          : null
                      }
                    />
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography variant="h5" component="div">
                    <Box fontWeight="fontWeightBold">{name}</Box>
                  </Typography>
                  <Link
                    variant="subtitle1"
                    component="button"
                    onClick={() => history.push(`/payrolls/${payrollId}`)}
                    color="inherit"
                  >
                    <Box
                      fontWeight="fontWeightBold"
                      className={classes.payrollDetails}
                    >
                      Ver Facturas{' '}
                      <ArrowRightIcon className={classes.payrollDetailsArrow} />
                    </Box>
                  </Link>
                </Grid>
              </Grid>
              <Grid container className={classes.cardLeftPanelBottom}>
                <Grid item className={classes.cardLeftPanelBottomStatus}>
                  <Typography variant="subtitle1" color="text.secondary">
                    Estado
                  </Typography>
                  <Typography variant="subtitle1" component="div">
                    <Box
                      fontWeight="fontWeightBold"
                      color={
                        status in statusStyles
                          ? statusStyles[status].color
                          : null
                      }
                    >
                      {t(status)}
                    </Box>
                  </Typography>
                </Grid>
                <Divider
                  orientation="vertical"
                  flexItem
                  className={classes.cardLeftPanelBottomDivider}
                />
                <Grid item>
                  <Typography variant="subtitle1" color="text.secondary">
                    Facturas
                  </Typography>
                  <Typography variant="subtitle1">{totalInvoices}</Typography>
                </Grid>
                <Divider
                  orientation="vertical"
                  flexItem
                  className={classes.cardLeftPanelBottomDivider}
                />
                <Grid item>
                  <Typography variant="subtitle1" color="text.secondary">
                    Proveedores
                  </Typography>
                  <Typography variant="subtitle1">{totalSuppliers}</Typography>
                </Grid>
                <Divider
                  orientation="vertical"
                  flexItem
                  className={classes.cardLeftPanelBottomDivider}
                />
                <Grid item>
                  <Typography variant="subtitle1" color="text.secondary">
                    Fecha de Pago
                  </Typography>
                  <Typography variant="subtitle1">
                    <CountryFormatHelper
                      value={paymentDate}
                      variant="longdate"
                      countryId={countryId}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Divider orientation="vertical" flexItem />

            <Grid container item className={classes.cardRightPanel}>
              <Grid item xs>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  className={classes.cardRightPanelContent}
                >
                  <Grid item>
                    <Typography variant="subtitle1" color="text.secondary">
                      Total
                    </Typography>
                    <Typography variant="h4" component="div">
                      <Box fontWeight="fontWeightMedium">
                        <CountryFormatHelper
                          value={summedAmount}
                          variant="currency"
                          countryId={countryId}
                        />
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <CustomButton
                      variant="orange-lined"
                      className={classes.button}
                      onClick={() =>
                        handlePayInvoicesButton({
                          payrollId,
                          totalInvoices,
                          totalSuppliers,
                          paymentDate,
                          summedAmount,
                        })
                      }
                      disabled={
                        !hasBankAccount || status !== SCHEDULED_READY_TO_PAY
                      }
                    >
                      Pagar Facturas
                    </CustomButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                {hasActions && (
                  <IconButton
                    onClick={event => handleClickMenu(event, payrollId)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </>
    );
  }
);

PayrollCard.defaultProps = {};

PayrollCard.propTypes = {
  payroll: PropTypes.objectOf(Object).isRequired,
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  handlePayInvoicesButton: PropTypes.func.isRequired,
  handleClickMenu: PropTypes.func.isRequired,
  hasActions: PropTypes.bool.isRequired,
};

export default PayrollCard;
