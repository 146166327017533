import { DIRECT_FINANCING } from '../../constants';
import { SEGMENT_EVENTS } from '../constants';
import { trackSegment } from '../trackingSegment';

export const trackOrderSubmitted = (
  orderId,
  operationType,
  selectedInvoices,
  simulation,
  availableInvoicesCount,
  availableInvoicesAmount
) => {
  const selectedInvoicesCount = selectedInvoices.length;
  const selectedInvoicesAmount = selectedInvoices.reduce(
    (sum, invoice) => sum + invoice.amount,
    0
  );
  const {
    retentionPct,
    retention,
    operationCost: fee,
    interest,
    baseRate: rate,
  } = simulation;

  const advancePercentage = !Number.isNaN(retentionPct)
    ? 100 - retentionPct
    : null;
  const eventPayload = {
    order_id: orderId,
    type: operationType === DIRECT_FINANCING ? 'FD' : 'PP',
    num_invoices_selected: selectedInvoicesCount,
    amt_invoices_selected: selectedInvoicesAmount,
    num_invoices_available: availableInvoicesCount,
    amt_invoices_available: availableInvoicesAmount,
    order_rate: rate,
    pct_advancement: advancePercentage,
    amt_interest: interest,
    amt_fee: fee,
    amt_excess: retention,
  };
  trackSegment(SEGMENT_EVENTS.ORDER_SUBMITTED, eventPayload);
};

export default trackOrderSubmitted;
