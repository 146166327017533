import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Grid, Button, makeStyles } from '@material-ui/core';
import BaseDialog from './BaseDialog';
import { setWasInLogin } from '../../store/actions/uiStateActions';
import image from '../../assets/completaperfil.svg';

const useStyles = makeStyles({
  title: {
    marginBottom: 12,
  },
  button: {
    width: 181,
  },
});

const CompleteRegisterDialog = ({ open, handleCloseDialog }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleButtonClick = () => {
    dispatch(setWasInLogin(false));
    handleCloseDialog();
  };

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      topIcon={image}
      title="Bienvenido otra vez"
      description={
        <>
          Termina de completar tu perfil para <br />
          empezar a financiarte
        </>
      }
    >
      <Grid item xs={12} align="center">
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleButtonClick()}
          className={classes.button}
        >
          Continuar
        </Button>
      </Grid>
    </BaseDialog>
  );
};

CompleteRegisterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
};

export default CompleteRegisterDialog;
