import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  card: {
    borderRadius: 15,
    padding: 25,
    marginBottom: 30,
  },
  cardLeftPanel: {
    paddingRight: 25,
    width: '70%',
  },
  cardRightPanel: {
    paddingLeft: 25,
    width: '29%',
  },
  cardRightPanelContent: {
    height: '100%',
  },
  cardLeftPanelBottom: {
    alignItems: 'center',
    paddingLeft: 55,
    paddingTop: 20,
  },
  cardLeftPanelBottomDivider: {
    marginLeft: 30,
    marginRight: 30,
  },
  cardLeftPanelBottomStatus: {
    minWidth: 180,
  },
  avatar: {
    position: 'relative',
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.primary.main,
    marginRight: 16,
    overflow: 'visible',
    padding: 8,
  },
  button: {
    width: '100%',
  },
  title: {
    ...theme.typography.h5,
    paddingBottom: 32,
  },
  description: {
    ...theme.typography.h6,
    margin: 0,
  },
  dotAlert: {
    width: 12,
    height: 12,
    borderRadius: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  payrollDetails: {
    display: 'flex',
    alignItems: 'center',
  },
  payrollDetailsArrow: {
    width: 7,
    marginLeft: 8,
  },
}));

// eslint-disable-next-line import/prefer-default-export
export { useStyles };
