import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import orderError from '../../assets/orderError.svg';
import BaseDialog from './BaseDialog';

const SubmitedOrderErrorDialog = ({ open, handleClose }) => {
  const history = useHistory();

  const handleConfirm = () => {
    history.push('/');
  };

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleClose}
      topIcon={orderError}
      topIconWidth={169}
      title="No se pudo enviar la solicitud"
      description={
        <>
          Te pondremos en contacto con soporte para que
          <br />
          te ayuden a continuar con tu operación
        </>
      }
    >
      <Grid item xs={12} align="center">
        <Button variant="contained" color="primary" onClick={handleConfirm}>
          Contactar soporte
        </Button>
      </Grid>
    </BaseDialog>
  );
};

SubmitedOrderErrorDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default SubmitedOrderErrorDialog;
