import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InfiniteScroll from 'react-infinite-scroll-component';

import Loader from '../../../../components/elements/Loader';
import SearchBar from '../../../../components/elements/SearchBar';
import { useSuppliersBook } from '../../adapters/SupplierBook';
import SupplierBookEntry from './SupplierBookEntry';
import { supplierBookStyles } from './styles';

const SuppliersBook = React.memo(() => {
  const classes = supplierBookStyles();

  const { suppliers, hasMore, handleChangeSearchInput, fetchMoreSuppliers } =
    useSuppliersBook();

  const suppliersByLetter = suppliers.reduce((acc, supplier) => {
    const firstChar = supplier.name.toUpperCase()[0];
    if (!(firstChar in acc)) acc[firstChar] = [];
    acc[firstChar].push(supplier);
    return acc;
  }, {});

  return (
    <Grid
      container
      alignContent="flex-start"
      className={classes.root}
      id="scrollableDiv"
    >
      <Grid item xs={12} className={classes.search}>
        <SearchBar handleChangeInput={handleChangeSearchInput} />
      </Grid>

      <Grid item xs={12}>
        <InfiniteScroll
          dataLength={suppliers.length}
          next={fetchMoreSuppliers}
          hasMore={hasMore}
          loader={<Loader />}
          scrollableTarget="scrollableDiv"
        >
          {Object.entries(suppliersByLetter).map(([key, supps]) => (
            <div Grid key={key}>
              <Grid item xs={12} className={classes.bookIndexChar}>
                <Typography variant="h5" component="div" color="textPrimary">
                  <Box fontWeight="fontWeightBold">{key}</Box>
                </Typography>
              </Grid>
              {supps.map(supplier => (
                <SupplierBookEntry supplier={supplier} />
              ))}
            </div>
          ))}
        </InfiniteScroll>
      </Grid>
    </Grid>
  );
});

export default SuppliersBook;
