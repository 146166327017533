import {
  UPLOAD_VOUCHER_START,
  UPLOAD_VOUCHER_SUCCESS,
  UPLOAD_VOUCHER_FAILURE,
} from '../actions/types';

import { t13s } from '../../translationKeys';

const initialState = {
  documentId: null,
  loadDocumentLoading: false,
  loadDocumentFailure: null,

  voucherUploaded: false,
  uploadVoucherLoading: false,
  uploadVoucherFailure: null,
};

const uploadVoucherStart = state => {
  return {
    ...state,
    voucherUploaded: false,
    uploadVoucherLoading: true,
    uploadVoucherFailure: null,
  };
};

const uploadVoucherSuccess = state => {
  return {
    ...state,
    voucherUploaded: true,
    uploadVoucherLoading: false,
  };
};

const uploadVoucherFailure = (state, payload) => {
  const { errorCode } = payload;
  return {
    ...state,
    voucherUploaded: false,
    uploadVoucherLoading: false,
    uploadVoucherFailure: errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPLOAD_VOUCHER_START:
      return uploadVoucherStart(state);
    case UPLOAD_VOUCHER_SUCCESS:
      return uploadVoucherSuccess(state);
    case UPLOAD_VOUCHER_FAILURE:
      return uploadVoucherFailure(state, payload);
    default:
      return state;
  }
};
