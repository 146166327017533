import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

const Text1 = React.memo(props => {
  const { text, color, noWrap, className } = props;
  return (
    <Typography
      variant="body1"
      color={color}
      component="div"
      noWrap={noWrap}
      className={className}
    >
      <Box fontWeight="fontWeightBold">{text}</Box>
    </Typography>
  );
});

Text1.defaultProps = {
  color: 'textPrimary',
  noWrap: false,
  className: '',
};

Text1.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  noWrap: PropTypes.boolean,
  className: PropTypes.string,
};

export default Text1;
