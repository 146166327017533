import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import {
  makeStyles,
  InputAdornment,
  Fade,
  Grid,
  Typography,
  Box,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import EyeOpenIcon from './icons/EyeOpenIcon';
import EyeCloseIcon from './icons/EyeCloseIcon';
import LoadingButton from './elements/LoadingButton';
import AlertForm from './elements/AlertForm';
import { resetPassword } from '../store/actions/authenticationActions';
import { setWasInLogin } from '../store/actions/uiStateActions';
import isEmpty from '../helpers/validations/isEmpty';
import * as colors from '../theme/otherColors';

const useStyles = makeStyles({
  root: {
    justifyContent: 'center',
    width: 532,
    height: 700,
    margin: '0 auto',
  },
  card: {
    width: 372,
    margin: 'auto auto',
  },
  separatorPurple: {
    width: 56,
    height: 4,
    margin: '15px auto 30px auto',
    background: colors.HOME_START_MAIN_PURPLE,
    borderRadius: 3,
  },
  form: {
    marginTop: 20,
  },
  buttonLogin: {
    fontSize: 14,
    height: 40,
    marginTop: 15,
  },
});

const Register = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { hash } = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const passwordReseted = useSelector(state => state.authState.passwordReseted);
  const resetPasswordError = useSelector(
    state => state.authState.resetPasswordError
  );
  const resetPasswordIsLoading = useSelector(
    state => state.authState.resetPasswordIsLoading
  );

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', value => {
      if (password !== value) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule('isTruthy', value => value);
  }, [password]);

  useEffect(() => {
    ValidatorForm.addValidationRule('isNotEmpty', value => !isEmpty(value));
  }, []);

  useEffect(() => {
    if (passwordReseted) {
      history.push('/start/login');
    }
  }, [passwordReseted, history]);

  const handleSubmit = () => {
    dispatch(setWasInLogin(false));
    dispatch(resetPassword({ password, confirmPassword }, hash));
  };

  return (
    <Fade in timeout={400}>
      <>
        <Grid container className={classes.root} align="center">
          <Grid container direction="column" className={classes.card}>
            <Typography
              variant="h5"
              component="div"
              color="textPrimary"
              className={classes.title}
            >
              <Box fontWeight="fontWeightBold">Ingresa tu nueva contraseña</Box>
            </Typography>
            <div className={classes.separatorPurple} />
            <Grid item>
              <ValidatorForm className={classes.form} onSubmit={handleSubmit}>
                <TextValidator
                  fullWidth
                  id="password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  size="small"
                  variant="outlined"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  placeholder="Contraseña"
                  validators={['required', 'minStringLength:6', 'isNotEmpty']}
                  errorMessages={[
                    'Campo requerido',
                    'La contaseña debe tener a lo menos 6 caracteres',
                    'No puede dejar texto en blanco',
                  ]}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlinedIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {showPassword ? (
                          <EyeCloseIcon
                            className={classes.showPassword}
                            onClick={() => setShowPassword(false)}
                          />
                        ) : (
                          <EyeOpenIcon
                            className={classes.showPassword}
                            onClick={() => setShowPassword(true)}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <TextValidator
                  fullWidth
                  id="confirmPassword"
                  name="confirmPassword"
                  type={showPassword ? 'text' : 'password'}
                  size="small"
                  variant="outlined"
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                  placeholder="Repetir contraseña"
                  validators={['required', 'isPasswordMatch']}
                  errorMessages={[
                    'Campo requerido',
                    'Las contraseñas no coinciden',
                  ]}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                {!!resetPasswordError && (
                  <AlertForm message={t(resetPasswordError)} variant="error" />
                )}
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  isLoading={resetPasswordIsLoading}
                  className={classes.buttonLogin}
                  fullWidth
                >
                  Enviar
                </LoadingButton>
              </ValidatorForm>
            </Grid>
          </Grid>
        </Grid>
      </>
    </Fade>
  );
};

export default Register;
