/* eslint-disable react/jsx-props-no-spreading */
import MomentUtils from '@date-io/moment';
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import {
  CIVILSTATUS,
  COUNTRY_CODE_CL,
  DATE_FORMAT,
} from '../../helpers/constants';
import {
  COMMUNES_PER_REGION_CL,
  COUNTRIES,
  REGIONS_CL,
} from '../../helpers/addressesHelper';
import { verifyEmail } from '../../helpers/validations/email';
import {
  rutCleaner,
  validateIdentifier,
} from '../../helpers/validations/businessIdentifier';
import ConfirmAction from '../elements/ConfirmAction';
import BaseDialog from './BaseDialog';
import { dateWithoutFormatter } from '../../helpers/dateHelpers';
import AlertForm from '../elements/AlertForm';
import { resetStakeholdersErrors } from '../../store/actions/stakeholdersActions';

const useStyles = makeStyles(theme => ({
  expand: {
    transform: 'rotate(-90deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
  sectionHeader: {
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 5,
  },
  title: {
    marginLeft: 10,
  },
  inputContainer: {
    maxWidth: 'calc(50% - 10px)',
    flexBasis: 'calc(50% - 10px)',
  },
  formSection: {
    marginLeft: 42,
    width: 'calc(100% - 42px)',
  },
  flag: {
    marginRight: 10,
  },
  confirm: {
    marginTop: 15,
  },
}));

const StakeholderDialogCL = ({
  selectedStakeholder,
  isOpen,
  handleClose,
  onSubmit,
  disableSave,
  error,
  loading,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [personalInfoOpen, setPersonalInfoOpen] = useState(true);
  const [contactInfoOpen, setContactOpen] = useState(false);
  const [addressInfoOpen, setAddressOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [stakeholder, setStakeholder] = useState({
    name: '',
    fatherLastName: '',
    motherLastName: '',
    identifier: '',
    birthday: '',
    gender: '',
    citizenship: '',
    civilStatus: '',
    profession: '',
    phone: '',
    email: '',
    address: '',
    commune: '',
    region: '',
    postalCode: '',
    ...selectedStakeholder,
  });

  const {
    name,
    fatherLastName,
    motherLastName,
    identifier,
    birthday = null,
    gender,
    citizenship,
    civilStatus,
    profession,
    phone,
    email,
    address,
    commune,
    region,
    postalCode,
  } = stakeholder;

  const communes = COMMUNES_PER_REGION_CL[region] || [];

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidIdentifier', value =>
      validateIdentifier(value, COUNTRY_CODE_CL)
    );

    ValidatorForm.addValidationRule('isValidEmail', value =>
      verifyEmail(value)
    );
  }, []);

  const handleInputChange = e => {
    setStakeholder({
      ...stakeholder,
      [e.target.name]: e.target.value,
    });
  };

  const handleBusinessIdentifierChange = ({ target: { name, value } }) => {
    setStakeholder({
      ...stakeholder,
      [name]: rutCleaner(value),
    });
  };

  const handleChangeOtherInput = (inputName, value) => {
    setStakeholder({
      ...stakeholder,
      [inputName]: value,
    });
  };

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const handleSubmit = () => {
    onSubmit({ ...stakeholder, postalCode: stakeholder.postalCode || null });
  };

  const countryToFlag = isoCode => {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode
          .toUpperCase()
          .replace(/./g, char =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
      : isoCode;
  };

  const header = (title, headerIsOpen, handleOpen) => {
    return (
      <Box
        className={classes.sectionHeader}
        onClick={() => {
          handleOpen(!headerIsOpen);
        }}
      >
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: headerIsOpen,
          })}
          onClick={() => {
            handleOpen(!headerIsOpen);
          }}
          aria-expanded={headerIsOpen}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
        <Typography
          variant="body1"
          color="textPrimary"
          component="div"
          className={classes.title}
        >
          <Box fontWeight="fontWeightBold">{title}</Box>
        </Typography>
      </Box>
    );
  };

  return (
    <BaseDialog
      title="Información Representante Legal"
      description="Aquí puedes ver y editar toda la información correspondiente al Representante Legal."
      isOpen={isOpen}
      handleClose={() => {
        handleClose();
        dispatch(resetStakeholdersErrors());
      }}
      size="lg"
    >
      <ValidatorForm onSubmit={handleSubmit}>
        {header('Personal', personalInfoOpen, setPersonalInfoOpen)}
        <Collapse in={personalInfoOpen} timeout="auto" unmountOnExit>
          <Grid container className={classes.formSection}>
            <Grid item xs={12}>
              <TextValidator
                fullWidth
                variant="outlined"
                label="Nombres"
                name="name"
                value={name}
                disabled={disableSave}
                onChange={handleInputChange}
                validators={['required']}
                errorMessages={['Campo requerido']}
              />
            </Grid>

            <Grid container justifyContent="space-between">
              <Grid item xs={6} className={classes.inputContainer}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  label="Apellido Paterno"
                  type="string"
                  name="fatherLastName"
                  value={fatherLastName}
                  disabled={disableSave}
                  onChange={handleInputChange}
                  validators={['required']}
                  errorMessages={['Campo requerido']}
                />
              </Grid>
              <Grid item xs={6} className={classes.inputContainer}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  label="Apellido Materno"
                  type="string"
                  name="motherLastName"
                  value={motherLastName}
                  disabled={disableSave}
                  onChange={handleInputChange}
                  validators={['required']}
                  errorMessages={['Campo requerido']}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent="space-between">
              <Grid item xs={6} className={classes.inputContainer}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  label="Rut"
                  type="string"
                  name="identifier"
                  value={identifier}
                  disabled={disableSave}
                  onChange={handleBusinessIdentifierChange}
                  validators={['required', 'isValidIdentifier']}
                  errorMessages={['Campo requerido', 'Ingrese un Rut válido']}
                />
              </Grid>
              <Grid item xs={6} className={classes.inputContainer}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                  <DatePicker
                    fullWidth
                    autoOk
                    inputVariant="outlined"
                    label="Fecha nacimiento"
                    name="birthday"
                    format={DATE_FORMAT}
                    disabled={disableSave}
                    value={dateWithoutFormatter(birthday)}
                    onChange={date => handleChangeOtherInput('birthday', date)}
                    TextFieldComponent={props => {
                      return (
                        <TextValidator
                          {...props}
                          validators={['required']}
                          errorMessages={['Campo requerido']}
                        />
                      );
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>

            <Grid container justifyContent="space-between">
              <Grid item xs={6} className={classes.inputContainer}>
                <SelectValidator
                  variant="outlined"
                  label="Género"
                  value={gender}
                  name="gender"
                  onChange={handleInputChange}
                  fullWidth
                  disabled={disableSave}
                  validators={['required']}
                  errorMessages={['Campo requerido']}
                >
                  <MenuItem key="F" value="Femenino">
                    Femenino
                  </MenuItem>
                  <MenuItem key="M" value="Masculino">
                    Masculino
                  </MenuItem>
                </SelectValidator>
              </Grid>
              <Grid item xs={6} className={classes.inputContainer}>
                <Autocomplete
                  options={COUNTRIES}
                  autoHighlight
                  value={COUNTRIES.find(c => c.label === citizenship)}
                  disabled={disableSave}
                  onChange={(e, value) => {
                    handleChangeOtherInput('citizenship', value?.label);
                  }}
                  getOptionLabel={option => option.label}
                  renderOption={option => (
                    <>
                      <span className={classes.flag}>
                        {countryToFlag(option.code)}
                      </span>
                      {option.label}
                    </>
                  )}
                  renderInput={params => (
                    <TextValidator
                      {...params}
                      value={citizenship}
                      name="citizenship"
                      label="Nacionalidad"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off', // disable autocomplete and autofill
                      }}
                      validators={['required']}
                      errorMessages={['Campo requerido']}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent="space-between">
              <Grid item xs={6} className={classes.inputContainer}>
                <SelectValidator
                  variant="outlined"
                  label="Estado civil"
                  value={civilStatus}
                  name="civilStatus"
                  onChange={handleInputChange}
                  disabled={disableSave}
                  fullWidth
                  validators={['required']}
                  errorMessages={['Campo requerido']}
                >
                  {CIVILSTATUS.filter(c => c.country === COUNTRY_CODE_CL).map(
                    i => (
                      <MenuItem value={i.value} key={i.value}>
                        {i.label}
                      </MenuItem>
                    )
                  )}
                </SelectValidator>
              </Grid>
              <Grid item xs={6} className={classes.inputContainer}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  label="Profesión"
                  type="string"
                  name="profession"
                  value={profession}
                  disabled={disableSave}
                  onChange={handleInputChange}
                  validators={['required']}
                  errorMessages={['Campo requerido']}
                />
              </Grid>
            </Grid>
          </Grid>
        </Collapse>

        {header('Contacto', contactInfoOpen, setContactOpen)}
        <Collapse in={contactInfoOpen} timeout="auto" unmountOnExit>
          <Grid container className={classes.formSection}>
            <Grid container justifyContent="space-between">
              <Grid item xs={6} className={classes.inputContainer}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  label="Teléfono"
                  type="string"
                  name="phone"
                  value={phone}
                  disabled={disableSave}
                  onChange={handleInputChange}
                  validators={['required']}
                  errorMessages={['Campo requerido']}
                />
              </Grid>
              <Grid item xs={6} className={classes.inputContainer}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  label="Email"
                  type="string"
                  name="email"
                  value={email}
                  disabled={disableSave}
                  onChange={handleInputChange}
                  validators={['required', 'isValidEmail']}
                  errorMessages={['Campo requerido', 'Ingrese un email válido']}
                />
              </Grid>
            </Grid>
          </Grid>
        </Collapse>

        {header('Domicilio', addressInfoOpen, setAddressOpen)}
        <Collapse in={addressInfoOpen} timeout="auto" unmountOnExit>
          <Grid container className={classes.formSection}>
            <Grid container justifyContent="space-between">
              <Grid item xs={6} className={classes.inputContainer}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  label="Calle y número"
                  type="string"
                  name="address"
                  value={address}
                  disabled={disableSave}
                  onChange={handleInputChange}
                  validators={['required']}
                  errorMessages={['Campo requerido']}
                />
              </Grid>
              <Grid item xs={6} className={classes.inputContainer}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  label="Código postal"
                  type="string"
                  name="postalCode"
                  value={postalCode}
                  disabled={disableSave}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent="space-between">
              <Grid item xs={6} className={classes.inputContainer}>
                <Autocomplete
                  name="region"
                  options={REGIONS_CL}
                  value={REGIONS_CL.find(c => c.name === region)}
                  onChange={(e, value) => {
                    handleChangeOtherInput('region', value?.name);
                  }}
                  autoHighlight
                  disabled={disableSave}
                  getOptionLabel={option => option.name}
                  renderOption={option => option.name}
                  renderInput={params => (
                    <TextValidator
                      {...params}
                      value={region}
                      label="Región"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off', // disable autocomplete and autofill
                      }}
                      validators={['required']}
                      errorMessages={['Campo requerido']}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} className={classes.inputContainer}>
                <Autocomplete
                  options={communes}
                  value={communes.find(c => c.name === commune)}
                  autoHighlight
                  disabled={disableSave}
                  onChange={(e, value) => {
                    handleChangeOtherInput('commune', value?.name);
                  }}
                  getOptionLabel={option => option.name}
                  renderOption={option => option.name}
                  renderInput={params => (
                    <TextValidator
                      {...params}
                      value={commune}
                      name="commune"
                      label="Comuna"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off', // disable autocomplete and autofill
                      }}
                      validators={['required']}
                      errorMessages={['Campo requerido']}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Collapse>

        {error && <AlertForm message={error} variant="error" />}
        {!disableSave && (
          <Grid container item className={classes.confirm} justify="center">
            <ConfirmAction
              checked={checked}
              onChangeCheck={handleCheckboxChange}
              buttonLabel="Guardar cambios"
              type="submit"
              loading={loading}
            />
          </Grid>
        )}
      </ValidatorForm>
    </BaseDialog>
  );
};

StakeholderDialogCL.defaultProps = {
  selectedStakeholder: null,
  disableSave: false,
  error: null,
  loading: false,
};

StakeholderDialogCL.propTypes = {
  selectedStakeholder: PropTypes.shape({
    name: PropTypes.string,
    fatherLastName: PropTypes.string,
    motherLastName: PropTypes.string,
    identifier: PropTypes.string,
    birthday: PropTypes.string,
    gender: PropTypes.string,
    citizenship: PropTypes.string,
    civilStatus: PropTypes.string,
    profession: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.string,
    commune: PropTypes.string,
    region: PropTypes.string,
    postalCode: PropTypes.string,
  }),
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disableSave: PropTypes.bool,
  error: PropTypes.string,
  loading: PropTypes.bool,
};

export default StakeholderDialogCL;
