import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as formatters from '../../../commons/formatters';
import { useSharedTableHeaders } from '../commons';
import filterHeaderByCountry from '../../utils/filterHeaderByCountry';

const usePaidTableHeaders = () => {
  const { countryId } = useSelector(state => state.businessState.business);
  const history = useHistory();
  const { PayrollLink } = formatters;

  const {
    folio,
    supplierName,
    identifier,
    paymentDate,
    paymentMethod,
    totalAmount,
  } = useSharedTableHeaders();

  const payroll = {
    title: 'Nomina',
    field: 'payroll',
    type: 'string',
    align: 'left',
    hide: false,
    render: invoice => (
      <PayrollLink
        onClick={() => history.push(`/payrolls/${invoice.payroll.id}`)}
        text={invoice?.payroll?.name}
      />
    ),
  };

  const headers = {
    folio,
    supplierName,
    identifier,
    paymentDate,
    payroll,
    paymentMethod,
    totalAmount,
  };

  return filterHeaderByCountry({ countryId, headers });
};

export default usePaidTableHeaders;
