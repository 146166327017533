import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@material-ui/core';

const DropdownDotsMenu = React.memo(props => {
  const { anchorEl, selectedMenuInvoice, handleCloseMenu, actionButtons } =
    props;

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
    >
      {actionButtons.map(({ text, action }) => (
        <MenuItem
          key={text}
          onClick={() =>
            action({
              invoice: selectedMenuInvoice,
              closeMenu: handleCloseMenu,
            })
          }
        >
          {text}
        </MenuItem>
      ))}
    </Menu>
  );
});

DropdownDotsMenu.defaultProps = {};

DropdownDotsMenu.propTypes = {
  actionButtons: PropTypes.arrayOf(PropTypes.object).isRequired,
  anchorEl: PropTypes.string.isRequired,
  selectedMenuInvoice: PropTypes.string.isRequired,
  handleCloseMenu: PropTypes.func.isRequired,
};

export default DropdownDotsMenu;
