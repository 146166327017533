import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';

const useStyles = makeStyles({
  carousel: {
    background: 'rgb(84, 71, 181, 0.15)',
    borderRadius: 35,
    width: 90,
    height: 20,
    margin: 30,
    padding: '3px 10px',
    justifyContent: 'space-between',
  },
  carouselStep: {
    width: 14,
    height: 14,
    borderRadius: 50,
    background: '#FFF',
  },
  active: {
    background: '#901DFF',
  },
});

const Carrousel = props => {
  const { bullets, activeBullet } = props;
  const classes = useStyles();

  const getBullets = () => {
    const bulletsJsx = [];
    for (let i = 0; i < bullets; i++) {
      const classNames =
        i + 1 === activeBullet
          ? clsx(classes.carouselStep, classes.active)
          : classes.carouselStep;
      bulletsJsx.push(<Grid item className={classNames} />);
    }
    return bulletsJsx;
  };

  return (
    <Grid container direction="row" className={classes.carousel}>
      {getBullets()}
    </Grid>
  );
};

Carrousel.propTypes = {
  bullets: PropTypes.number.isRequired,
  activeBullet: PropTypes.number.isRequired,
};

export default Carrousel;
