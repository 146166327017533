import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box, makeStyles } from '@material-ui/core';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';

const ERROR = 'error';
const WARNING = 'warning';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: convertSpacingToCss('md'),
  },
  alert: {
    color: '#FFF',
    padding: convertSpacingToCss('sm'),
    borderRadius: 50,
  },
  variant: {
    background: props => theme.palette[props.variant].main,
  },
}));
const AlertForm = props => {
  const classes = useStyles(props);
  const { message } = props;
  const { t } = useTranslation();

  return (
    <Grid item xs={12} className={classes.root}>
      <Typography
        variant="body1"
        align="center"
        className={`${classes.alert} ${classes.variant}`}
      >
        <Box fontWeight="fontWeightBold">{t(message)}</Box>
      </Typography>
    </Grid>
  );
};

AlertForm.propTypes = {
  message: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  variant: PropTypes.oneOf([ERROR, WARNING]).isRequired,
};

export default AlertForm;
