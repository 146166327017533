import axios from '../../helpers/axios';
import {
  FETCH_WALLET_STATUS_START,
  FETCH_WALLET_STATUS_SUCCESS,
  FETCH_WALLET_STATUS_ERROR,
  CREATE_WALLET_REQUEST_START,
  CREATE_WALLET_REQUEST_SUCCESS,
  CREATE_WALLET_REQUEST_ERROR,
} from './types';
import { t13s } from '../../translationKeys';
import { enqueueSnackbar } from './notificationActions';

// eslint-disable-next-line import/prefer-default-export
export const fetchWalletStatus = businessId => dispatch => {
  dispatch({ type: FETCH_WALLET_STATUS_START });
  axios
    .get(`api/business/${businessId}/wallet/status`)
    .then(res => {
      const resData = res.data;
      dispatch({ type: FETCH_WALLET_STATUS_SUCCESS, payload: resData });
    })
    .catch(err => {
      const errorCode = err?.response?.data?.errorCode;
      dispatch({ type: FETCH_WALLET_STATUS_ERROR, payload: errorCode });
    });
};

export const createWalletRequest = (businessId, activity) => dispatch => {
  dispatch({ type: CREATE_WALLET_REQUEST_START });
  axios
    .post(`api/business/${businessId}/wallet/request`, {
      activity,
    })
    .then(res => {
      const resData = res.data;
      dispatch({ type: CREATE_WALLET_REQUEST_SUCCESS, payload: resData });
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.CREATE_WALLET_REQUEST_SUCCESS,
          options: {
            variant: 'success',
          },
        })
      );
    })
    .catch(err => {
      const errorCode = err?.response?.data?.errorCode;
      dispatch({ type: CREATE_WALLET_REQUEST_ERROR, payload: errorCode });
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.CREATE_WALLET_REQUEST_FAILURE,
          options: {
            variant: 'error',
          },
        })
      );
    });
};
