import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles, Grid } from '@material-ui/core';
import InfoField from './InfoField';
import CountryFormatHelper from './CountryFormatHelper';
import { AVAILABLE_COUNTRIES } from '../../helpers/constants';
import Panel from './Panel';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';

const useStyles = makeStyles({
  nextButton: {
    padding: convertSpacingToCss('lg xl zero xl'),
  },
});

const CartResume = ({
  title,
  totalCartInvoices,
  totalSelectedCartInvoices,
  onClick,
  disabled,
  countryId,
  fetchCartInvoicesTotalAmountIsLoading,
}) => {
  const classes = useStyles();
  return (
    <Panel
      title={title}
      titleAlign="center"
      titlePadding="xl xl xs"
      contentPadding="zero lg lg"
    >
      <InfoField
        align="center"
        label="Facturas disponibles"
        labelVariant="body1"
        labelColor="contrast1"
        loading={fetchCartInvoicesTotalAmountIsLoading}
        data={
          <CountryFormatHelper
            value={totalCartInvoices}
            variant="currency"
            countryId={countryId}
            dataQa="cart-resume-total-cart-invoices-string"
          />
        }
        dataColor="textPrimary"
        dataVariant="h5"
        dataFontWeight="fontWeightBold"
      />

      <InfoField
        align="center"
        label="Facturas Seleccionadas"
        labelVariant="body1"
        labelColor="contrast1"
        data={
          <CountryFormatHelper
            value={totalSelectedCartInvoices}
            variant="currency"
            countryId={countryId}
            dataQa="cart-resume-total-selected-cart-invoices-string"
          />
        }
        dataColor="textPrimary"
        dataVariant="h5"
        dataFontWeight="fontWeightBold"
      />

      <Grid className={classes.nextButton} align="center">
        <Button
          variant="contained"
          color="primary"
          onClick={onClick}
          disabled={disabled}
          fullWidth
          data-qa="cart-resume-button-next-step-action"
        >
          Siguiente
        </Button>
      </Grid>
    </Panel>
  );
};

CartResume.defaultProps = {
  disabled: false,
  title: '',
};

CartResume.propTypes = {
  title: PropTypes.string,
  totalCartInvoices: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  totalSelectedCartInvoices: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  fetchCartInvoicesTotalAmountIsLoading: PropTypes.bool.isRequired,
};

export default CartResume;
