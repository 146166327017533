import { makeStyles } from '@material-ui/core';

const homeStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    position: 'relative',
    alignContent: 'flex-start',
    height: '100%',
    minHeight: 'calc(100vh - 56px)',
  },
  invoicesWrapper: {
    position: 'relative',
    padding: '0 20px 0 20px',
    width: '100%',
    height: '100%',
    alignContent: 'flex-start',
    display: 'flex',
    flexFlow: 'column nowrap',
  },
}));

const homeTabStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 60,
    padding: '0 20px',
    background: theme.palette.primary.contrastText,
    position: 'sticky',
    top: 56,
    alignItems: 'center',
    zIndex: 9,
  },
  appBar: {
    background: theme.palette.primary.contrastText,
    color: theme.palette.text.primary,
    zIndex: 1,
    width: '100%',
  },
  tab: {
    height: 60,
    alignItems: 'center',
    padding: '15px 0px',
  },
}));

export { homeStyles, homeTabStyles };
