/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  makeStyles,
  Typography,
  InputAdornment,
  Box,
  Link,
} from '@material-ui/core';
import { Link as LinkRouter } from 'react-router-dom';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import EyeOpenIcon from './icons/EyeOpenIcon';
import EyeCoseIcon from './icons/EyeCloseIcon';
import AlertForm from './elements/AlertForm';
import { setWasInLogin } from '../store/actions/uiStateActions';
import { loginUser } from '../store/actions/authenticationActions';
import LoadingButton from './elements/LoadingButton';
import * as colors from '../theme/otherColors';
import isEmpty from '../helpers/validations/isEmpty';
import FlagsRedirect from './elements/FlagsRedirect';
import { AVAILABLE_COUNTRIES } from '../helpers/constants';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    margin: '0 auto',
  },
  title: {
    color: colors.HOME_START_TEXT_PRIMARY,
    marginTop: 160,
  },
  separatorPurple: {
    width: 56,
    height: 4,
    margin: '15px 0 30px 0',
    background: colors.HOME_START_MAIN_PURPLE,
    borderRadius: 3,
  },
  form: {
    marginTop: 40,
  },
  buttonLogin: {
    fontSize: 14,
    height: 40,
  },
  link: {
    display: 'block',
    marginTop: 15,
  },
  showPassword: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
}));

const Login = ({ country }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isMigrationEnabled = process.env.REACT_APP_MIGRATION_ENABLED === 'true';

  const { loginIsLoading, loginError } = useSelector(state => state.authState);

  const [showPassword, setShowPassword] = useState(false);
  const [userLogin, setUserLogin] = useState({
    email: '',
    password: '',
  });
  const { email, password } = userLogin;

  const onChangeInput = event => {
    const { value, name } = event.target;
    setUserLogin({ ...userLogin, [name]: value });
  };

  const loginSubmit = () => {
    dispatch(setWasInLogin(true));
    dispatch(loginUser(userLogin, true));
  };

  useEffect(() => {
    ValidatorForm.addValidationRule('isNotEmpty', value => !isEmpty(value));
  }, []);

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item xs={12} align="center">
        <Typography
          variant="h4"
          component="div"
          color="textPrimary"
          className={classes.title}
        >
          <Box fontWeight="fontWeightBold">¡Bienvenido otra vez!</Box>
        </Typography>
        <div className={classes.separatorPurple} />
        {isMigrationEnabled && (
          <Grid item>
            <FlagsRedirect country={country} path="/start/login" />
          </Grid>
        )}
        <Grid item xs={12} className={classes.form}>
          <ValidatorForm onSubmit={loginSubmit}>
            <TextValidator
              fullWidth
              id="email"
              name="email"
              type="text"
              variant="outlined"
              value={email}
              onChange={e => onChangeInput(e)}
              validators={['required', 'isEmail']}
              errorMessages={['Campo requerido', 'Email inválido']}
              placeholder="Email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlineIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextValidator
              fullWidth
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              value={password}
              onChange={e => onChangeInput(e)}
              placeholder="Contraseña"
              validators={['required', 'isNotEmpty']}
              errorMessages={[
                'Campo requerido',
                'No puede dejar texto en blanco',
              ]}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {showPassword ? (
                      <EyeCoseIcon
                        className={classes.showPassword}
                        onClick={() => setShowPassword(false)}
                      />
                    ) : (
                      <EyeOpenIcon
                        className={classes.showPassword}
                        onClick={() => setShowPassword(true)}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />

            {!!loginError && (
              <AlertForm message={t(loginError)} variant="error" />
            )}

            <LoadingButton
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              isLoading={loginIsLoading}
              className={classes.buttonLogin}
              fullWidth
            >
              Ingresar
            </LoadingButton>
            <Link
              variant="body1"
              color="secondary"
              to="/start/forgotpassword"
              component={LinkRouter}
              className={classes.link}
            >
              <Box fontWeight="fontWeightBold">Olvidé mi contraseña</Box>
            </Link>
          </ValidatorForm>
        </Grid>
      </Grid>
    </Grid>
  );
};

Login.propTypes = {
  country: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
};

export default Login;
