import { useState } from 'react';

const useSearchAndReload = () => {
  const [reloadValue, setReloadValue] = useState(new Date());
  const [search, setSearch] = useState(null);

  const onChangeSearch = value => {
    setSearch(value);
    setReloadValue(new Date());
  };

  const reloadTable = () => setReloadValue(new Date());

  return {
    search,
    onChangeSearch,
    reloadTable,
    reloadValue,
  };
};

export default useSearchAndReload;
