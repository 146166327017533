import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const UploadIcon = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M1 14.5022C0.999385 13.3977 1.28042 12.3112 1.81655 11.3455C2.35268 10.3798 3.1262 9.56676 4.064 8.98321C4.31151 7.05329 5.2538 5.27971 6.71452 3.99436C8.17524 2.70902 10.0543 2 12 2C13.9457 2 15.8248 2.70902 17.2855 3.99436C18.7462 5.27971 19.6885 7.05329 19.936 8.98321C21.0992 9.70689 22.0041 10.7796 22.5213 12.0482C23.0385 13.3168 23.1417 14.7163 22.8161 16.0471C22.4906 17.3778 21.7528 18.5716 20.7083 19.4581C19.6638 20.3445 18.366 20.8783 17 20.9832L7 21.0022C3.644 20.7282 1 17.9242 1 14.5022ZM16.848 18.9892C17.7938 18.9165 18.6925 18.5467 19.4156 17.9327C20.1387 17.3188 20.6493 16.492 20.8745 15.5704C21.0996 14.6489 21.0278 13.6798 20.6692 12.8016C20.3107 11.9233 19.6837 11.1809 18.878 10.6802L18.071 10.1772L17.951 9.23521C17.7641 7.78889 17.0569 6.46014 15.9616 5.49729C14.8663 4.53444 13.4578 4.00339 11.9995 4.00339C10.5412 4.00339 9.13272 4.53444 8.03742 5.49729C6.94213 6.46014 6.23493 7.78889 6.048 9.23521L5.928 10.1772L5.123 10.6802C4.3173 11.1808 3.69038 11.9232 3.3318 12.8014C2.97321 13.6795 2.90129 14.6485 3.12631 15.57C3.35133 16.4915 3.86177 17.3183 4.58475 17.9324C5.30773 18.5464 6.20625 18.9163 7.152 18.9892L7.325 19.0022H16.675L16.848 18.9892ZM13 13.0022V17.0022H11V13.0022H8L12 8.00221L16 13.0022H13Z" />
    </SvgIcon>
  );
};

export default UploadIcon;
