import React from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { SuppliersBook, SupplierProfile } from '../payments/suppliers/ui';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: 'calc(100vh - 56px)',
    flexWrap: 'nowrap',
  },
}));

const HomeSuppliers = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={4}>
        <SuppliersBook />
      </Grid>
      <Grid item xs={8}>
        <SupplierProfile />
      </Grid>
    </Grid>
  );
};

export default HomeSuppliers;
