import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Fade, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import imgDFLoading from '../assets/defaultstate-loading.gif';
import imgDFError from '../assets/defaultstate-error.gif';
import imgDFCredentials from '../assets/defaultstate-credentials.gif';
import CircularProgressWithLabel from './elements/CircularProgressWithLabel';
import { DEFAULT_STATE_CASES } from '../helpers/constants';
import { t13s } from '../translationKeys';
import Card from './elements/Card';

const useStyles = makeStyles({
  card: {
    padding: 20,
    minWidth: 238,
    width: '100%',
  },
  root: {
    padding: 20,
  },
  title: {
    marginTop: 115,
  },
  body: {
    fontSize: 14,
    marginTop: 20,
  },
  action: {
    marginTop: 30,
  },
});

const DefaultState = props => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { openCredentialClick, setting } = props;
  const { DF_DEFAULT, DF_FAILED, DF_LOADING, DF_UPDATE } = DEFAULT_STATE_CASES;

  const taxStatus = useSelector(state => state.taxServiceState.taxStatus);
  const loadPercentage = useSelector(state => state.wsState.loadPercentage);

  const [progress, setProgress] = useState(0);

  const { jobProgress = 0 } = taxStatus || {};

  useEffect(() => {
    setProgress(Math.max(jobProgress, loadPercentage));
  }, [jobProgress, loadPercentage]);

  const getTitle = () => {
    switch (setting) {
      case DF_LOADING:
        return (
          <Box fontWeight="fontWeightBold">Estamos cargando tus facturas</Box>
        );
      case DF_UPDATE:
        return <Box fontWeight="fontWeightBold">Actualiza tu información</Box>;
      case DF_FAILED:
        return (
          <Box fontWeight="fontWeightBold">No podemos ver tus facturas</Box>
        );
      default:
        return <Box fontWeight="fontWeightBold">Comienza a financiarte</Box>;
    }
  };

  const getImage = () => {
    switch (setting) {
      case DF_LOADING:
        return imgDFLoading;
      case DF_FAILED:
        return imgDFError;
      default:
        return imgDFCredentials;
    }
  };

  const getBody = () => {
    switch (setting) {
      case DF_LOADING:
        return (
          <>
            Este proceso puede tardar hasta 5 minutos, esto dependerá de la
            cantidad de facturas que has emitido durante los últimos días.
          </>
        );
      case DF_UPDATE:
        return (
          <>
            Tus credenciales del {t(t13s.LABEL.TAX_SERVICE_SHORT_NAME)} no
            coinciden con nuestros registros.
            <br />
            Actualiza tus datos y ve tus facturas disponibles para
            financiamiento.
          </>
        );
      case DF_FAILED:
        return (
          <>
            Ha ocurrido un error al cargar tus facturas, te pondremos en
            <br />
            contacto con soporte para poder solucionarlo.
          </>
        );
      default:
        return (
          <>
            Ingresa tus credenciales del {t(t13s.LABEL.TAX_SERVICE_SHORT_NAME)}{' '}
            y ve aquí tus facturas disponibles para financiamiento.
          </>
        );
    }
  };

  const getAction = () => {
    let action;
    switch (setting) {
      case DF_DEFAULT:
        action = (
          <Button
            variant="contained"
            color="primary"
            fullWidth={false}
            onClick={openCredentialClick}
          >
            Ingresar credenciales
          </Button>
        );
        break;
      case DF_UPDATE:
        action = (
          <Button
            variant="contained"
            color="primary"
            fullWidth={false}
            onClick={openCredentialClick}
          >
            Ingresar credenciales
          </Button>
        );
        break;
      case DF_LOADING:
        action = <CircularProgressWithLabel value={progress} />;
        break;
      case DF_FAILED:
        action = null;
        break;
      default:
        action = null;
        break;
    }
    return action;
  };

  return (
    <Fade timeout={400} in>
      <div>
        <Grid container spacing={2} className={classes.root}>
          <Card variant="white" border className={classes.card}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={6}>
                <img
                  src={getImage()}
                  width={585}
                  className={classes.imageTour}
                  alt="Logo"
                />
              </Grid>
              <Grid item xs={4} align="center">
                <Typography
                  variant="h4"
                  color="primary"
                  component="div"
                  className={classes.title}
                >
                  {getTitle()}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  component="div"
                  className={classes.body}
                >
                  <Box fontWeight="fontWeightBold">{getBody()}</Box>
                </Typography>
                <div className={classes.action}>{getAction()}</div>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </div>
    </Fade>
  );
};

DefaultState.propTypes = {
  openCredentialClick: PropTypes.func.isRequired,
  setting: PropTypes.string.isRequired,
};

export default DefaultState;
