import moment from 'moment';

export const invoices = {
  CL: [
    {
      id: 123400,
      folio: 1,
      payerName: 'Nombre del Pagador',
      payerIdentifier: '17009552-9',
      issueDate: moment().add(-10, 'days'),
      statusGroup: 'PAID',
      amount: 150000,
      rate: 1.3,
      advancePercentage: 98,
      expirationDate: '2021-01-30',
      averageDaysLate: 30,
      preselected: true,
      extraData: [
        {
          code: 'INVOICE_STATE',
          value: '',
        },
      ],
    },
    {
      id: 123401,
      folio: 10,
      payerName: 'Nombre del Pagador',
      payerIdentifier: '17009552-9',
      issueDate: moment().add(-10, 'days'),
      statusGroup: 'IN_REVIEW',
      amount: 233000,
      rate: 1.3,
      advancePercentage: 98,
      expirationDate: '2021-01-30',
      averageDaysLate: 30,
      preselected: true,
      extraData: [
        {
          code: 'INVOICE_STATE',
          value: '',
        },
      ],
    },
    {
      id: 123402,
      folio: 112311,
      payerName: 'Nombre del Pagador',
      payerIdentifier: '17009552-9',
      issueDate: moment().add(-10, 'days'),
      statusGroup: 'RENEGOTIATION',
      amount: 430500,
      rate: 1.3,
      advancePercentage: 98,
      expirationDate: '2021-01-30',
      averageDaysLate: 30,
      preselected: false,
      extraData: [
        {
          code: 'INVOICE_STATE',
          value: 'Acuse Recibo',
        },
      ],
    },
    {
      id: 123403,
      folio: 100,
      payerName: 'Nombre del Pagador',
      payerIdentifier: '17009552-9',
      issueDate: moment().add(-10, 'days'),
      statusGroup: 'TRANSFERRED',
      amount: 540321,
      rate: 1.3,
      advancePercentage: 98,
      expirationDate: '2021-01-30',
      averageDaysLate: 30,
      preselected: false,
      extraData: [
        {
          code: 'INVOICE_STATE',
          value: 'Acuse Recibo',
        },
      ],
    },
    {
      id: 123404,
      folio: 112311,
      payerName: 'Nombre del Pagador',
      payerIdentifier: '17009552-9',
      issueDate: moment().add(-10, 'days'),
      statusGroup: 'APPROVED',
      amount: 430500,
      rate: 1.3,
      advancePercentage: 98,
      expirationDate: '2021-01-30',
      averageDaysLate: 30,
      preselected: false,
      extraData: [
        {
          code: 'INVOICE_STATE',
          value: 'Acuse Recibo',
        },
      ],
    },
    {
      id: 123405,
      folio: 100,
      payerName: 'Nombre del Pagador',
      payerIdentifier: '17009552-9',
      issueDate: moment().add(-10, 'days'),
      statusGroup: 'REJECTED',
      amount: 540321,
      rate: 1.3,
      advancePercentage: 98,
      expirationDate: '2021-01-30',
      averageDaysLate: 30,
      preselected: false,
      extraData: [
        {
          code: 'INVOICE_STATE',
          value: 'Acuse Recibo',
        },
      ],
    },
  ],
  MX: [
    {
      id: 123404,
      folio: 1,
      payerName: 'Cooperativa La Cruz Azul, S.C.L.',
      payerIdentifier: 'CCA950819TGA',
      issueDate: moment().add(-10, 'days'),
      statusGroup: 'PAID',
      amount: 150000,
      rate: 1.3,
      advancePercentage: 98,
      expirationDate: '2021-01-30',
      averageDaysLate: 30,
      preselected: true,
      extraData: [
        {
          code: 'INVOICE_TYPE',
          value: 'PPD',
        },
      ],
    },
    {
      id: 123405,
      folio: 123121,
      payerName: 'Cooperativa La Cruz Azul, S.C.L.',
      payerIdentifier: 'CCA950819TGA',
      issueDate: moment().add(-10, 'days'),
      statusGroup: 'IN_REVIEW',
      amount: 233000,
      rate: 1.3,
      advancePercentage: 98,
      expirationDate: '2021-01-30',
      averageDaysLate: 30,
      preselected: true,
      extraData: [
        {
          code: 'INVOICE_TYPE',
          value: 'PPD',
        },
      ],
    },
    {
      id: 123406,
      folio: 11,
      payerName: 'Seguros Atlas',
      payerIdentifier: 'SAT841024518',
      issueDate: moment().add(-10, 'days'),
      statusGroup: 'TRANSFERRED',
      amount: 430500,
      rate: 1.3,
      advancePercentage: 98,
      expirationDate: '2021-01-30',
      averageDaysLate: 30,
      preselected: false,
      extraData: [
        {
          code: 'INVOICE_TYPE',
          value: 'PUE',
        },
      ],
    },
    {
      id: 123407,
      folio: 100,
      payerName: 'Grupo Lala, S.A.B. de C.V.',
      payerIdentifier: 'LME500321317',
      issueDate: moment().add(-10, 'days'),
      statusGroup: 'RENEGOTIATION',
      amount: 540321,
      rate: 1.3,
      advancePercentage: 98,
      expirationDate: '2021-01-30',
      averageDaysLate: 30,
      preselected: false,
      extraData: [
        {
          code: 'INVOICE_TYPE',
          value: 'PUE',
        },
      ],
    },
  ],
};

export const extraColumns = {
  CL: [
    {
      code: 'INVOICE_STATE',
      type: 'string',
      label: 'Estado',
    },
  ],
  MX: [
    {
      code: 'INVOICE_TYPE',
      type: 'string',
      label: 'Tipo',
    },
  ],
};
