import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Box, Grid, Fade, Tooltip } from '@material-ui/core';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CustomButton from './elements/CustomButton';
import Panel from './elements/Panel';
import { checkTaxServiceCredential } from '../store/actions/taxServiceActions';
import TaxServiceCredentialsDialog from './dialogs/TaxServiceCredentialsDialog';
import { convertSpacingToCss } from '../helpers/stylesHelpers';
import UserSectionHeader from './elements/UserSectionHeader';
import UploadPfxFileDialog from './dialogs/UploadPfxFileDialog';
import { checkPfx } from '../store/actions/pfxActions';

const useStyles = makeStyles(theme => ({
  containerBody: {
    display: 'grid',
    gridGap: convertSpacingToCss('lg'),
  },
  subtitle: {
    marginTop: 10,
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  chipIcon: {
    fill: theme.palette.success.main,
  },
}));

const UserSii = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showTaxCredentialsDialog, setShowTaxCredentialsDialog] =
    useState(false);
  const [showUploadPfxFile, setShowUploadPfxFile] = useState(false);

  const [headerHeight, setHeaderHeight] = useState(0);

  const { taxServiceCredentialCreated, taxStatus = {} } = useSelector(
    state => state.taxServiceState
  );
  const { pfxRegistered, hasPfx } = useSelector(state => state.pfxState);
  const { id: businessId } = useSelector(state => state.businessState.business);
  const { hasCredential = false, isCredentialActive = false } = taxStatus;

  useEffect(() => {
    if (businessId) {
      dispatch(checkTaxServiceCredential(businessId));
      dispatch(checkPfx(businessId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId]);

  useEffect(() => {
    if (taxServiceCredentialCreated) {
      setShowTaxCredentialsDialog(false);
    }
  }, [taxServiceCredentialCreated]);

  useEffect(() => {
    if (pfxRegistered) {
      setShowUploadPfxFile(false);
    }
  }, [pfxRegistered]);

  return (
    <Fade in timeout={400}>
      <Grid item>
        {showTaxCredentialsDialog && (
          <TaxServiceCredentialsDialog
            open={showTaxCredentialsDialog}
            handleCloseDialog={() => setShowTaxCredentialsDialog(false)}
          />
        )}

        {showUploadPfxFile && (
          <UploadPfxFileDialog
            open={showUploadPfxFile}
            handleCloseDialog={() => setShowUploadPfxFile(false)}
          />
        )}

        <UserSectionHeader setHeaderHeight={setHeaderHeight}>
          <Typography variant="h5" color="textPrimary">
            <Box fontWeight="fontWeightBold">
              Servicio de Impuestos Internos
            </Box>
          </Typography>
        </UserSectionHeader>

        <Box className={classes.containerBody} marginTop={headerHeight}>
          <Typography
            variant="h6"
            color="textPrimary"
            component="div"
            className={classes.subtitle}
          >
            <Box fontWeight="fontWeightBold" className={classes.box}>
              Clave consulta
              <Tooltip title="La clave del SII te permite acceder a información tributaria, operar con Facturas Electrónicas, y consultar sobre tu situación tributaria, entre otras cosas.">
                <HelpOutlineRoundedIcon color="secondary" fontSize="small" />
              </Tooltip>
            </Box>
          </Typography>

          <Typography
            variant="body1"
            color="textSecondary"
            className={classes.subtitle}
          >
            Recuerda que con tu clave del SII sólo podemos ver tu accionar
            tributario. No podemos emitir facturas ni generar cambios en tu
            nombre.
          </Typography>

          <Panel
            variant="green-title"
            title="Clave de consulta SII"
            actions={
              <>
                {hasCredential && isCredentialActive ? (
                  <CustomButton variant="success" startIcon={<CheckIcon />}>
                    Clave ingresada
                  </CustomButton>
                ) : (
                  <CustomButton
                    variant="orange-lined"
                    onClick={() => setShowTaxCredentialsDialog(true)}
                  >
                    Ingresa clave SII
                  </CustomButton>
                )}
              </>
            }
          />

          <Typography
            variant="h6"
            color="textPrimary"
            className={classes.subtitle}
            component="div"
          >
            <Box fontWeight="fontWeightBold" className={classes.box}>
              Certificado digital
              <Tooltip title="El Certificado Digital es un archivo electrónico autenticado por el SII que te permite firmar Documentos Tributarios electrónicos (DTE), para así poder realizar la Cesión de Facturas.">
                <HelpOutlineRoundedIcon color="secondary" fontSize="small" />
              </Tooltip>
            </Box>
          </Typography>

          <Typography
            variant="body1"
            color="textSecondary"
            className={classes.subtitle}
          >
            Cede automáticamente tus facturas para comenzar tu financiamiento.
            Para esto necesitamos que subas tu certificado digital (.PFX) en
            esta sección, el mismo será tratado de forma confidencial.
          </Typography>

          <Panel
            variant="green-title"
            title="Certificado digital (.PFX)"
            actions={
              <>
                {hasPfx ? (
                  <CustomButton variant="success" startIcon={<CheckIcon />}>
                    Certificado registrado
                  </CustomButton>
                ) : (
                  <CustomButton
                    variant="orange-lined"
                    onClick={() => setShowUploadPfxFile(true)}
                  >
                    Subir Certificado Digital
                  </CustomButton>
                )}
              </>
            }
          />
        </Box>
      </Grid>
    </Fade>
  );
};

export default UserSii;
