import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, makeStyles, Typography, Box } from '@material-ui/core';
import Register from './Register';
import * as colors from '../theme/otherColors';
import { registerUser } from '../store/actions/authenticationActions';
import { AVAILABLE_COUNTRIES } from '../helpers/constants';
import FlagsRedirect from './elements/FlagsRedirect';

const useStyles = makeStyles({
  flags: {
    marginBottom: 40,
  },
  root: {
    width: 372,
  },
  title: {
    color: colors.HOME_START_TEXT_PRIMARY,
    marginTop: 160,
  },
  separatorPurple: {
    width: 56,
    height: 4,
    margin: '15px auto 30px auto',
    background: colors.HOME_START_MAIN_PURPLE,
    borderRadius: 3,
  },
});

const HomeStartRight = ({ country }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMigrationEnabled = process.env.REACT_APP_MIGRATION_ENABLED === 'true';

  const registerUserError = useSelector(
    state => state.authState.registerUserError
  );
  const registerUserIsLoading = useSelector(
    state => state.authState.registerUserIsLoading
  );

  const submitStepOne = user => {
    dispatch(registerUser(user));
  };

  return (
    <Grid container direction="row-reverse">
      <Grid item xs={8} align="center">
        <Grid container direction="column" className={classes.root}>
          <Typography variant="h4" component="div" className={classes.title}>
            <Box fontWeight="fontWeightBold">Crea tu cuenta</Box>
          </Typography>

          <div className={classes.separatorPurple} />
          {isMigrationEnabled && (
            <Grid item className={classes.flags}>
              <FlagsRedirect country={country} path="/start/register" />
            </Grid>
          )}
          <Grid item>
            <Register
              onSubmit={submitStepOne}
              registerUserError={registerUserError}
              registerUserIsLoading={registerUserIsLoading}
              country={country}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

HomeStartRight.propTypes = {
  country: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
};

export default HomeStartRight;
