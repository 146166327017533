import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
  Link,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SmileyIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import { ReactComponent as LogoBlanco } from '../assets/logo-horizontal-blanco.svg';
import TasksDrawer from './TasksDrawer';
import TasksCenterIcon from './icons/TasksCenterIcon';
import { logoutUser } from '../store/actions/authenticationActions';

const useStyles = makeStyles({
  appBar: {
    background: 'linear-gradient(45deg, #F87C54 30%, #FAB584 100%)',
    height: 56,
    alignContent: 'center',
  },
  buttonIcon: {
    fontSize: '22px !important',
  },
  avatarButton: {
    boxShadow: 'none',
    borderRadius: 36,
    background: '#FABD98',
    color: 'white',
    padding: '5px 10px',
    height: 30,
  },
  identifier: {
    margin: '0 10px',
  },
  userName: {
    textTransform: 'capitalize',
  },
  profileMenu: {
    marginLeft: 'auto',
  },
  toolbar: {
    minHeight: 56,
    height: 56,
  },
  logoButton: {
    justifyContent: 'flex-start',
  },
});

const TopbarPrivate = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorElDrawer, setAnchorElDrawer] = useState(null);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const tasks = useSelector(state => state.tasksState.tasks);
  const { user, business } = useSelector(state => state.authState);

  const openDrawer = Boolean(anchorElDrawer);
  const id = openDrawer ? 'simple-popover' : undefined;

  const { name: userName } = user;
  const { name: businessName, identifier } = business;

  const handleClickTasks = event => {
    setAnchorElDrawer(event.currentTarget);
  };

  const handleCloseDrawer = () => {
    setAnchorElDrawer(null);
  };

  const handleClickMenu = event => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const logoutClick = () => {
    handleCloseMenu();
    dispatch(logoutUser());
  };

  const redirectTo = path => {
    handleCloseMenu();
    if (path) {
      history.push(path);
    }
  };

  return (
    <AppBar position="fixed" className={classes.appBar} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Box className={classes.logoButton}>
          <Link href="/">
            <LogoBlanco height="20px" />
          </Link>
        </Box>

        <Typography
          className={classes.profileMenu}
          variant="body1"
          component="div"
        >
          <Box fontWeight="fontWeightMedium">
            <span>{businessName}</span>
            <span className={classes.identifier}>{identifier}</span>
          </Box>
        </Typography>
        <div>
          <Button
            className={classes.avatarButton}
            endIcon={<SmileyIcon className={classes.buttonIcon} />}
            size="small"
            onClick={handleClickMenu}
            aria-controls="menu-appbar"
            aria-haspopup="true"
          >
            <Typography variant="body1" component="div">
              Hola,{' '}
              <Box
                fontWeight="fontWeightBold"
                display="inline-block"
                className={classes.userName}
              >
                {userName}
              </Box>
            </Typography>
          </Button>
          <Menu
            id="menu-appbar"
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            anchorEl={anchorElMenu}
            keepMounted
            open={Boolean(anchorElMenu)}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={() => redirectTo('/user/profile')}>
              Perfil
            </MenuItem>
            <MenuItem onClick={logoutClick}>Cerrar sesión</MenuItem>
          </Menu>
        </div>
        {tasks.length > 0 && (
          <>
            <IconButton onClick={handleClickTasks} aria-describedby={id}>
              <TasksCenterIcon />
            </IconButton>

            <TasksDrawer
              open={openDrawer}
              toggleDrawer={handleCloseDrawer}
              tasks={tasks}
            />
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default TopbarPrivate;
