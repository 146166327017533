import axios from '../../helpers/axios';
import {
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  FETCH_USER_START,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  UPDATE_PASSWORD_START,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  UPDATE_PASSWORD_RESET,
} from './types';
import { enqueueSnackbar } from './notificationActions';
import { t13s } from '../../translationKeys';

// eslint-disable-next-line import/prefer-default-export
export const updateUser = (userId, user) => dispatch => {
  dispatch({ type: UPDATE_USER_START });
  axios
    .put(`/api/user/${userId}`, user)
    .then(res => {
      const resData = res.data;
      dispatch({ type: UPDATE_USER_SUCCESS, payload: { user: resData } });
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.UPDATE_SUCCESS,
          options: {
            variant: 'success',
          },
        })
      );
    })
    .catch(err => {
      const errorCode = err?.response?.data?.errorCode;
      dispatch({ type: UPDATE_USER_ERROR, payload: errorCode });
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.UPDATE_ERROR,
          options: {
            variant: 'error',
          },
        })
      );
    });
};

export const fetchUser = id => dispatch => {
  dispatch({ type: FETCH_USER_START });
  axios
    .get(`/api/user/${id}`)
    .then(res => {
      dispatch({
        type: FETCH_USER_SUCCESS,
        payload: { user: res.data },
      });
    })
    .catch(error => {
      const errorCode = error?.response?.data?.errorCode;
      dispatch({
        type: FETCH_USER_ERROR,
        payload: { errorCode },
      });
    });
};

export const updatePassword = (userId, passwords) => dispatch => {
  dispatch({ type: UPDATE_PASSWORD_START });
  axios
    .put(`/api/user/${userId}/password`, { ...passwords })
    .then(res => {
      const resData = res.data;
      dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: { user: resData } });
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.UPDATE_PASSWORD_SUCCESS,
          options: {
            variant: 'success',
          },
        })
      );
    })
    .catch(err => {
      const errorCode = err?.response?.data?.errorCode;
      dispatch({ type: UPDATE_PASSWORD_ERROR, payload: { errorCode } });
    });
};

export const updatePasswordReset = () => dispatch => {
  dispatch({ type: UPDATE_PASSWORD_RESET });
};
