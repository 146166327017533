import React from 'react';
import { useParams } from 'react-router-dom';

import PayrollDetails from './PayrollDetails';

const HomePayrollDetails = () => {
  const { payrollId } = useParams();
  return <PayrollDetails payrollId={Number(payrollId)} />;
};

export default HomePayrollDetails;
