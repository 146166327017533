import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  makeStyles,
  Box,
  Typography,
  Button,
  Tooltip,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckIcon from '@material-ui/icons/Check';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import TimerIcon from '@material-ui/icons/Timer';
import ExternalLink from '../icons/ExternalLink';

const useStyles = makeStyles({
  root: {
    minHeight: props => (props.variant === 'default' ? 65 : 50),
  },
  icon: {
    width: props => (props.variant === 'default' ? 90 : 42),
  },
  title: {
    width: props => (props.variant === 'default' ? 570 : 217),
  },
  action: {
    width: 200,
    display: 'flex',
    justifyContent: props =>
      props.variant === 'default' ? 'center' : 'flex-start',
  },
});

const BusinessDocumentItem = props => {
  const {
    document,
    handleUploadClick,
    handleDownloadClick,
    variant,
    descriptionComponent = null,
  } = props;
  const classes = useStyles(props);

  const {
    title,
    description,
    approved,
    action,
    signedDocument,
    document: fileDocument,
  } = document;

  const getIcon = () => {
    if (approved) {
      return (
        <Box color="success.main" display="inline-flex">
          <CheckIcon color="inherit" />
        </Box>
      );
    }
    if (variant === 'default') {
      if (action === 'UPLOAD' && !signedDocument) {
        return <FiberManualRecordIcon fontSize="small" color="secondary" />;
      }
    }
    return null;
  };

  const getAction = () => {
    if (approved && action === 'LINK') {
      return (
        <Box color="text.primary" display="inline-flex">
          <Button
            variant="text"
            color="inherit"
            startIcon={<ExternalLink />}
            onClick={handleDownloadClick}
          >
            Ver documento
          </Button>
        </Box>
      );
    }
    if (approved) {
      return (
        <Box color="text.primary" display="inline-flex">
          <Button
            variant="text"
            color="inherit"
            startIcon={<CloudDownloadOutlinedIcon />}
            onClick={handleDownloadClick}
          >
            Descargar documento
          </Button>
        </Box>
      );
    }

    if (signedDocument) {
      return (
        <Tooltip title="Un ejecutivo validará el documento" placement="left">
          <span>
            <Button color="inherit" disabled startIcon={<TimerIcon />}>
              Esperando revisión
            </Button>
          </span>
        </Tooltip>
      );
    }

    if (action === 'UPLOAD') {
      return (
        <Button
          variant="text"
          color="secondary"
          startIcon={<CloudUploadOutlinedIcon />}
          onClick={handleUploadClick}
        >
          Subir documento
        </Button>
      );
    }

    if (action === 'MIFIEL_ON' && fileDocument) {
      return (
        <Tooltip
          title="Esperando firma electrónica enviada por correo"
          placement="left"
        >
          <span>
            <Button color="inherit" disabled startIcon={<TimerIcon />}>
              Esperando E-firmas
            </Button>
          </span>
        </Tooltip>
      );
    }

    if (action === 'DOWNLOAD') {
      return (
        <Tooltip
          title="Pronto estará disponible para descarga"
          placement="left"
        >
          <span>
            <Button color="inherit" disabled startIcon={<TimerIcon />}>
              Preparando documentos
            </Button>
          </span>
        </Tooltip>
      );
    }

    return (
      <Tooltip title="Un ejecutivo se contactará contigo" placement="left">
        <span>
          <Button color="inherit" disabled startIcon={<TimerIcon />}>
            Preparando documentos
          </Button>
        </span>
      </Tooltip>
    );
  };

  return (
    <Grid
      container
      direction="row"
      className={classes.root}
      alignItems="center"
    >
      <Box
        className={classes.icon}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {getIcon()}
      </Box>
      <Box display="flex" flexDirection="column" className={classes.title}>
        <Typography
          variant={variant === 'default' ? 'body1' : 'body2'}
          color="textPrimary"
          component="div"
        >
          <Box
            fontWeight={
              variant === 'default' ? 'fontWeightBold' : 'fontWeightRegular'
            }
          >
            {title}
          </Box>
        </Typography>
        {descriptionComponent ??
          (description && variant === 'default' && (
            <Typography variant="body2" color="textSecondary">
              {description}
            </Typography>
          ))}
      </Box>
      <Box className={classes.action}>{getAction()}</Box>
    </Grid>
  );
};

BusinessDocumentItem.defaultProps = {
  variant: 'default',
  descriptionComponent: null,
};

BusinessDocumentItem.propTypes = {
  document: PropTypes.shape({
    action: PropTypes.string.isRequired,
    approved: PropTypes.bool.isRequired,
    description: PropTypes.string,
    document: PropTypes.string,
    signedDocument: PropTypes.string,
    title: PropTypes.string.isRequired,
  }).isRequired,
  handleUploadClick: PropTypes.func.isRequired,
  handleDownloadClick: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['small', 'default']),
  descriptionComponent: PropTypes.element,
};

export default BusinessDocumentItem;
