import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';

const useStyles = makeStyles(() => ({
  subtitle: {
    marginBottom: 48,
  },
}));

const StepperSubtitle = ({ children }) => {
  const classes = useStyles();
  return (
    <Typography
      variant="h6"
      color="textPrimary"
      component="p"
      className={classes.subtitle}
    >
      {children}
    </Typography>
  );
};

StepperSubtitle.propTypes = {
  children: PropTypes.string.isRequired,
};

export default StepperSubtitle;
