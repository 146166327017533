import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import BaseDialog from './BaseDialog';
import LoadingButton from '../elements/LoadingButton';
import { getEmailMask } from '../../helpers/validations/email';
import {
  updatePassword,
  updatePasswordReset,
} from '../../store/actions/userActions';
import AlertForm from '../elements/AlertForm';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';

const useStyles = makeStyles({
  customInputText: {
    margin: 6,
  },
  button: {
    marginTop: convertSpacingToCss('lg'),
  },
  form: {
    width: 300,
  },
});

const UserPasswordDialog = ({ open, handleCloseDialog }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  const { id: userId, email } = useSelector(state => state.authState.user);
  const updatePasswordError = useSelector(
    state => state.userState.updatePasswordError
  );

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', () => {
      return newPassword === newPasswordConfirm;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPasswordConfirm]);

  useEffect(() => {
    if (open) {
      dispatch(updatePasswordReset());
      setCurrentPassword('');
      setNewPassword('');
      setNewPasswordConfirm('');
    }
  }, [open, dispatch]);

  const handleSubmitPassword = () => {
    const passwords = {
      oldPassword: currentPassword,
      password: newPassword,
      confirmPassword: newPasswordConfirm,
    };
    dispatch(updatePassword(userId, passwords));
  };

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title="Cambiar contraseña"
      description={`Vas a cambiar la contraseña para el usuario ${getEmailMask(
        email
      )}`}
    >
      <Grid container direction="column" alignContent="center">
        <ValidatorForm onSubmit={handleSubmitPassword} className={classes.form}>
          <Grid item>
            <Typography
              variant="body1"
              component="div"
              className={classes.customInputText}
            >
              <Box fontWeight="fontWeightMedium">
                Confirma la contraseña actual
              </Box>
            </Typography>
          </Grid>
          <Grid item>
            <TextValidator
              type="password"
              name="currentPassword"
              label="Contraseña actual"
              variant="outlined"
              value={currentPassword}
              onChange={e => setCurrentPassword(e.target.value)}
              validators={['required']}
              errorMessages={['Campo requerido']}
              fullWidth
            />
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              component="div"
              className={classes.customInputText}
            >
              <Box fontWeight="fontWeightMedium">
                Ingresa la nueva contraseña
              </Box>
            </Typography>
          </Grid>
          <Grid item>
            <TextValidator
              variant="outlined"
              fullWidth
              type="password"
              name="newPassword"
              label="Contraseña nueva"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              validators={['required', 'minStringLength:6']}
              errorMessages={['Campo requerido', 'Mínimo 6 caracteres']}
            />
          </Grid>
          <Grid item>
            <TextValidator
              variant="outlined"
              fullWidth
              type="password"
              name="newPasswordConfirm"
              label="Confirma tu nueva contraseña"
              value={newPasswordConfirm}
              onChange={e => setNewPasswordConfirm(e.target.value)}
              validators={['required', 'isPasswordMatch']}
              errorMessages={['Campo requerido', 'La contraseña no coincide']}
            />
          </Grid>

          {!!updatePasswordError && (
            <AlertForm message={t(updatePasswordError)} variant="error" />
          )}

          <Grid item>
            <LoadingButton
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              isLoading={false}
              className={classes.button}
            >
              Cambiar
            </LoadingButton>
          </Grid>
        </ValidatorForm>
      </Grid>
    </BaseDialog>
  );
};

UserPasswordDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
};

export default UserPasswordDialog;
