import axios from '../../helpers/axios';
import { t13s } from '../../translationKeys';
import { enqueueSnackbar } from './notificationActions';
import {
  REGISTER_PFX_START,
  REGISTER_PFX_SUCCESS,
  REGISTER_PFX_FAILURE,
  CHECK_PFX_START,
  CHECK_PFX_SUCCESS,
  CHECK_PFX_FAILURE,
} from './types';

export const registerPfx = (businessId, pfxBase64, password) => dispatch => {
  dispatch({ type: REGISTER_PFX_START });

  const base64 = pfxBase64.replace('data:application/x-pkcs12;base64,', '');
  axios
    .post(`/api/business/${businessId}/pfx`, {
      password,
      pfxBase64: base64,
    })
    .then(() => {
      dispatch({ type: REGISTER_PFX_SUCCESS });
      dispatch({
        type: CHECK_PFX_SUCCESS,
        payload: { hasPfxCertificate: true },
      });
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.REGISTER_PFX_SUCCESS,
          options: {
            variant: 'success',
          },
        })
      );
    })
    .catch(error => {
      const errorCode = error?.response?.data?.errorCode;
      dispatch({ type: REGISTER_PFX_FAILURE, payload: { errorCode } });
    });
};

export const checkPfx = businessId => dispatch => {
  dispatch({ type: CHECK_PFX_START });
  axios
    .get(`/api/business/${businessId}/pfx/check`)
    .then(res => {
      const hasPfxCertificate = res.data;
      dispatch({
        type: CHECK_PFX_SUCCESS,
        payload: hasPfxCertificate,
      });
    })
    .catch(error => {
      const errorCode = error?.response?.data?.errorCode;
      dispatch({ type: CHECK_PFX_FAILURE, payload: { errorCode } });
    });
};
