import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import PayrollResumeTable from './tables/PayrollResumeTable';
import StepperTitle from './elements/StepperTitle';

const useStyles = makeStyles(() => ({
  resumeTable: {
    margin: '25px 0 20px 0',
  },
}));

const PayInvoicesStep1 = props => {
  const {
    invoicesTotalAmount,
    payrollPaymentDate,
    invoicesQuantities,
    providersQuantities,
  } = props;
  const { countryId } = useSelector(state => state.businessState.business);
  const classes = useStyles();

  return (
    <Grid container item>
      <StepperTitle>Detalle de facturas por pagar</StepperTitle>

      <Grid container item className={classes.resumeTable}>
        <PayrollResumeTable
          countryId={countryId}
          invoicesTotalAmount={invoicesTotalAmount}
          payrollPaymentDate={payrollPaymentDate}
          invoicesQuantities={invoicesQuantities}
          providersQuantities={providersQuantities}
        />
      </Grid>
    </Grid>
  );
};

PayInvoicesStep1.propTypes = {
  invoicesTotalAmount: PropTypes.number.isRequired,
  payrollPaymentDate: PropTypes.string.isRequired,
  invoicesQuantities: PropTypes.number.isRequired,
  providersQuantities: PropTypes.number.isRequired,
};

export default PayInvoicesStep1;
