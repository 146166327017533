import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, makeStyles, Box } from '@material-ui/core';
import Card from './Card';
import bot from '../../assets/bot.svg';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';

const useStyles = makeStyles(theme => ({
  root: {
    padding: convertSpacingToCss('md'),
  },
  title: {
    marginBottom: convertSpacingToCss('xs'),
  },
  textColor: {
    color: theme.palette.text.contrast2,
  },
  tail: {
    width: 0,
    marginLeft: convertSpacingToCss('xl'),
    borderTop: '15px solid rgba(26,115,231, 0.17)',
    borderLeft: '10px solid transparent',
    borderRight: '18px solid transparent',
  },
}));
const BotAdvice = props => {
  const { title, message } = props;
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Card variant="blue">
        <Grid className={classes.root} align="center">
          <Typography
            variant="caption"
            className={`${classes.textColor} ${classes.title}`}
            component="div"
          >
            <Box fontWeight="fontWeightBold">{title}</Box>
          </Typography>
          <Typography variant="caption" className={classes.textColor}>
            {message}
          </Typography>
        </Grid>
      </Card>
      <div className={classes.tail} />
      <img src={bot} alt="bot advice" />
    </Grid>
  );
};

BotAdvice.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
};

export default BotAdvice;
