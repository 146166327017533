import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  IconButton,
  Tooltip,
  Box,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/EditOutlined';
import { useSelector } from 'react-redux';
import MenuMoreOptions from './elements/MenuMoreOptions';
import { TRANSFORMED_ACCOUNT_TYPES } from '../helpers/constants';
import { convertSpacingToCss } from '../helpers/stylesHelpers';
import CopyIcon from './icons/CopyIcon';
import DeleteIcon from './icons/DeleteIcon';
import GreenRadioButton from './elements/GreenRadioButton';

const useStyles = makeStyles({
  container: {
    padding: convertSpacingToCss('lg'),
    '&:hover': {
      background: '#F7F5FA',
    },
  },
  holder: {
    justifyContent: 'space-between',
  },
  holderText: {
    paddingTop: 10,
    marginBottom: 5,
  },
  menuItemIcon: {
    marginRight: 10,
  },
  menuButton: {
    alignSelf: 'center',
  },
});

const BankAccountItem = ({
  bankAccount,
  handleShowDeleteAccountDialog,
  handleShowDefaultAccountDialog,
  handleShowEditDialog,
  handleCopyData,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    Bank: { name: bankName },
    accountNumber,
    accountHolder,
    alias,
    isDefault,
    id,
    sourceIdentifier,
    accountType,
    accountEmail,
    isXepelin,
  } = bankAccount;
  const classes = useStyles();
  const { bankAccountUpdated } = useSelector(
    state => state.businessBankAccountsState
  );

  const copyDataButton = (
    <Tooltip title="Copiar datos" placement="top">
      <IconButton onClick={() => handleCopyData(id)}>
        <CopyIcon />
      </IconButton>
    </Tooltip>
  );

  useEffect(() => {
    if (bankAccountUpdated) setAnchorEl(null);
  }, [bankAccountUpdated]);

  const handleClickMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const getMenuOptions = () => {
    const editAccountOption = (
      <MenuItem
        key="editaccount"
        onClick={() => handleShowEditDialog(id)}
        data-qa="bank-account-item-delete-button-action"
      >
        <Typography variant="subtitle1" color="textPrimary" component="div">
          <Box display="inline-flex" alignItems="center">
            <EditIcon fontSize="small" className={classes.menuItemIcon} />
            Editar cuenta
          </Box>
        </Typography>
      </MenuItem>
    );
    const options = [editAccountOption];
    if (!isDefault) {
      const deleteAccountOption = (
        <MenuItem
          key="deleteaccount"
          onClick={() => handleShowDeleteAccountDialog(id)}
          data-qa="bank-account-item-delete-button-action"
        >
          <Typography variant="subtitle1" color="textPrimary" component="div">
            <Box display="inline-flex" alignItems="center">
              <DeleteIcon fontSize="small" className={classes.menuItemIcon} />
              Eliminar cuenta
            </Box>
          </Typography>
        </MenuItem>
      );
      options.push(deleteAccountOption);
    }

    return options;
  };

  return (
    <Grid
      container
      alignItems="center"
      direction="row"
      className={classes.container}
    >
      <Grid container item direction="row" justify="center" xs={1}>
        {!isXepelin && (
          <Tooltip
            title={
              isDefault
                ? 'Cuenta predeterminada'
                : 'Usar como cuenta predeterminada'
            }
            placement="top"
          >
            <div>
              <GreenRadioButton
                checked={isDefault}
                onChange={() => handleShowDefaultAccountDialog(id)}
              />
            </div>
          </Tooltip>
        )}
      </Grid>

      <Grid item xs={isXepelin ? 11 : 10}>
        <Typography
          variant="body1"
          className={classes.holderText}
          color="textPrimary"
          component="div"
        >
          <Box
            fontWeight="fontWeightBold"
            data-qa="bank-account-item-bank-name-and-alias-string"
          >
            {bankName}
            {alias && ` · ${alias}`}
          </Box>
          <Box
            fontWeight={isDefault ? 'fontWeightBold' : 'fontWeightRegular'}
            display="flex"
            alignItems="center"
            color={!isXepelin && !isDefault && '#8D8A96'}
            data-qa="bank-account-item-account-name-and-number-string"
          >
            {`${TRANSFORMED_ACCOUNT_TYPES[accountType]} ${accountNumber} `}
            {isXepelin && copyDataButton}
          </Box>
          <Box
            color={!isXepelin && '#8D8A96'}
            data-qa="bank-account-item-account-holder-and-identifier-and-email-string"
          >
            {`  ${accountHolder} · ${sourceIdentifier} · ${accountEmail}`}
          </Box>
        </Typography>
      </Grid>
      {!isXepelin && (
        <Grid
          item
          container
          xs={1}
          className={classes.menuButton}
          justify="flex-end"
        >
          <MenuMoreOptions
            options={getMenuOptions()}
            id="simple-MenuMoreOptions"
            anchorEl={anchorEl}
            handleCloseMenu={() => setAnchorEl(null)}
            handleClickMenu={handleClickMenu}
          />
        </Grid>
      )}
    </Grid>
  );
};

BankAccountItem.defaultProps = {
  handleShowDefaultAccountDialog: () => {},
  handleShowDeleteAccountDialog: () => {},
  handleShowEditDialog: () => {},
  handleCopyData: () => {},
};

BankAccountItem.propTypes = {
  bankAccount: PropTypes.objectOf(Object).isRequired,
  handleShowDefaultAccountDialog: PropTypes.func,
  handleShowDeleteAccountDialog: PropTypes.func,
  handleShowEditDialog: PropTypes.func,
  handleCopyData: PropTypes.func,
};

export default BankAccountItem;
