import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from '@material-ui/core';
import { AVAILABLE_COUNTRIES } from '../../helpers/constants';
import { CHIP_VARIANTS } from '../../theme/otherColors';
import Chip from './Chip';
import CountryFormatHelper from './CountryFormatHelper';

const SurplusChip = ({ currentSurplus, percentageSurplus, countryId }) => {
  const variant =
    percentageSurplus >= 100
      ? CHIP_VARIANTS.lightgreen.code
      : percentageSurplus >= 75
      ? CHIP_VARIANTS.yellow.code
      : percentageSurplus >= 50
      ? CHIP_VARIANTS.orange.code
      : CHIP_VARIANTS.red.code;

  return !currentSurplus ? (
    <Typography variant="body1" color="textPrimary" align="right">
      -
    </Typography>
  ) : (
    <Tooltip
      title={
        <Typography component="span">
          <CountryFormatHelper
            value={percentageSurplus}
            countryId={countryId}
            variant="percentage"
          />{' '}
          disponible de retención
        </Typography>
      }
    >
      <Chip
        variant={variant}
        label={
          <CountryFormatHelper
            value={currentSurplus}
            countryId={countryId}
            variant="currency"
          />
        }
      />
    </Tooltip>
  );
};

SurplusChip.propTypes = {
  currentSurplus: PropTypes.number.isRequired,
  percentageSurplus: PropTypes.number.isRequired,
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
};

export default SurplusChip;
