import axios from '../../helpers/axios';
import { enqueueSnackbar } from './notificationActions';
import {
  FETCH_CREDIT_LINE_START,
  FETCH_CREDIT_LINE_SUCCESS,
  FETCH_CREDIT_LINE_FAILURE,
} from './types';

import { t13s } from '../../translationKeys';

// eslint-disable-next-line import/prefer-default-export
export const fetchCreditLine = businessId => async dispatch => {
  dispatch({ type: FETCH_CREDIT_LINE_START });

  try {
    const onboardResponse = await axios.get(
      `/api/payment/business/${businessId}/account/credit-line`
    );
    const { data: creditLine } = onboardResponse;

    dispatch({
      type: FETCH_CREDIT_LINE_SUCCESS,
      payload: { creditLine },
    });
  } catch (error) {
    const errorCode = error?.response?.data?.errorCode;

    dispatch({ type: FETCH_CREDIT_LINE_FAILURE, payload: errorCode });
    dispatch(
      enqueueSnackbar({
        message: t13s.NOTIFICATION.FETCH_CREDIT_LINE_FAILURE,
        options: {
          variant: 'error',
        },
      })
    );
  }
};
