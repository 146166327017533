import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Tabs, makeStyles } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  DIRECT_FINANCING,
  EARLY_PAYMENT,
  DIRECT_FINANCING_LABEL,
  EARLY_PAYMENT_LABEL,
} from '../helpers/constants';
import { setWasInFinance } from '../store/actions/uiStateActions';
import Tab from './elements/Tab';

const useStyles = makeStyles(theme => ({
  hidden: {
    display: 'none',
  },
  content: {
    padding: 30,
  },
  root: {
    flexGrow: 1,
    marginTop: 60,
  },
  appBar: {
    background: '#FFF',
    color: theme.palette.text.primary,
    zIndex: 1,
  },
  cards: {
    background: '#FFF',
    position: 'sticky',
    top: 50,
    zIndex: 100,
  },
  appBarRoot: {
    position: 'sticky',
    top: 230,
    zIndex: 100,
  },
}));

const EARLY_PAYMENT_TAB = 'EARLY_PAYMENT_TAB';
const DIRECT_FINANCING_TAB = 'DIRECT_FINANCING_TAB';

const FinanceTabs = ({ showDemo, setOperationType }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    earlyPaymentCount,
    directFinancingCount,
    fetchCartInvoicesTotalAmountIsLoading,
  } = useSelector(state => state.cartState);

  const [activeTab, setActiveTab] = useState(
    location.pathname.includes('directo')
      ? DIRECT_FINANCING_TAB
      : EARLY_PAYMENT_TAB
  );

  const handleTabChange = (event, value) => {
    const oldActiveTab = activeTab;
    let newPathname;
    switch (value) {
      case DIRECT_FINANCING_TAB:
        newPathname = '/finance/directo';
        setOperationType(DIRECT_FINANCING);
        break;

      case EARLY_PAYMENT_TAB:
        newPathname = '/finance/prontopago';
        setOperationType(EARLY_PAYMENT);
        break;

      default:
        newPathname = '/';
    }
    setActiveTab(value);
    history.push(newPathname, { showDemo, wasInFinance: true, oldActiveTab });
    dispatch(setWasInFinance(true));
  };

  return (
    <AppBar position="static" className={classes.appBar} elevation={0}>
      <Tabs
        value={activeTab}
        indicatorColor="secondary"
        textColor="inherit"
        onChange={handleTabChange}
      >
        <Tab
          value={DIRECT_FINANCING_TAB}
          label={DIRECT_FINANCING_LABEL}
          isLoading={fetchCartInvoicesTotalAmountIsLoading}
          pillContent={showDemo ? 4 : directFinancingCount.toString()}
          data-qa="finance-tab-direct-financing-action"
          pillDataQa="finance-tab-pill-direct-financing-text"
        />
        <Tab
          value={EARLY_PAYMENT_TAB}
          label={EARLY_PAYMENT_LABEL}
          isLoading={fetchCartInvoicesTotalAmountIsLoading}
          pillContent={showDemo ? 0 : earlyPaymentCount.toString()}
          data-qa="finance-tab-early-payment-action"
          pillDataQa="finance-tab-pill-eaerly-payment-text"
        />
      </Tabs>
    </AppBar>
  );
};

FinanceTabs.propTypes = {
  showDemo: PropTypes.bool.isRequired,
  setOperationType: PropTypes.func.isRequired,
};

export default FinanceTabs;
