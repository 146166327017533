/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';
import setAuthToken from '../../helpers/setAuthToken';
import { enqueueSnackbar } from '../../store/actions/notificationActions';
import {
  logoutUser,
  fetchEntityFeature,
} from '../../store/actions/authenticationActions';
import { t13s } from '../../translationKeys';
import { fetchBusiness } from '../../store/actions/businessActions';

export default Component => {
  const checkJwtTokenExp = () => {
    // not logged yet
    if (!localStorage.jwtToken) return false;
    const decoded = jwtDecode(localStorage.jwtToken);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      return true;
    }
    return false;
  };

  return () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isAuth = useSelector(state => state.authState.isAuthenticated);
    const authBusiness = useSelector(state => state.authState.business);
    const fetchedBusiness = useSelector(state => state.businessState.business);
    const token = useSelector(state => state.authState.token);

    useEffect(() => {
      if (token) {
        setAuthToken(token);
      }

      if (checkJwtTokenExp()) {
        dispatch(
          enqueueSnackbar({
            message: t13s.NOTIFICATION.SESSION_EXPIRED,
            options: {
              variant: 'warning',
            },
          })
        );
        dispatch(logoutUser());
      }

      if (isAuth && !fetchedBusiness) {
        dispatch(fetchBusiness(authBusiness.id));
        dispatch(fetchEntityFeature(authBusiness.id));
      }

      let path;
      if (!isAuth) {
        path = '/start/login';
      } else if (isAuth && !authBusiness.id) {
        path = '/completeregister';
      }
      if (path) {
        history.push(path + history.location.search);
      }
    }, []);

    return isAuth ? <Component /> : <Redirect to="/start" />;
  };
};
