import React, { useContext } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import StepperContext from './StepperContext';
import CustomButton from '../elements/CustomButton';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 28,
    marginBottom: 28,
  },
  backButton: {
    marginRight: 22,
  },
}));

const StepperItem = ({ children, ...props }) => {
  const { nextButtonText } = props;
  const classes = useStyles();
  const { goNext, goBack, currentStep, isStepsDone } =
    useContext(StepperContext);

  return (
    <Grid container item>
      {children}

      {!isStepsDone() && (
        <Grid container className={classes.root}>
          {currentStep > 0 && (
            <Grid item>
              <CustomButton
                variant="simple-gray"
                color="secondary"
                size="large"
                onClick={goBack}
                className={classes.backButton}
              >
                Volver
              </CustomButton>
            </Grid>
          )}
          <Grid item>
            <CustomButton
              variant="all-orange"
              color="secondary"
              size="large"
              onClick={goNext}
            >
              {nextButtonText || 'Siguiente'}
            </CustomButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

StepperItem.defaultProps = {
  nextButtonText: null,
};

StepperItem.propTypes = {
  children: PropTypes.element.isRequired,
  nextButtonText: PropTypes.string,
};

export default StepperItem;
