import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Box } from '@material-ui/core';

import { AVAILABLE_COUNTRIES } from '../../helpers/constants';
import {
  fetchPayrolls,
  deletePayroll,
} from '../../store/actions/payrollActions';
import Loader from '../../components/elements/Loader';
import PayInvoicesStepper from '../../components/PayInvoicesStepper';
import { uniqueByKeepLast, removeByKey } from '../../helpers/storeUtils';

import { SEGMENT_EVENTS } from '../../helpers/segment/constants';
import { trackSegment } from '../../helpers/segment/trackingSegment';
import { useStyles } from './styles';
import PayrollCard from './PayrollCard';
import { Text1 } from '../commons/formatters';
import DropdownDotsMenu from './DropdownDotsMenu';
import { useFetchData, usePayInvoices } from './hooks';
import transformCustomPayrollStatus from './utils/transformCustomPayrollStatus';

const ScheduledPayrollCards = React.memo(({ countryId, businessId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { page, totalPages, pageSize, fetchedPayrolls, fetchMorePayrolls } =
    useFetchData({
      businessId,
    });

  const {
    dataInvoicesToPay,
    showPayInvoicesStepper,
    handleClosePayModal,
    handlePayInvoicesButton,
  } = usePayInvoices({ businessId });

  const [actionsByPayroll, setActionsByPayroll] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [payrolls, setPayrolls] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [selectedPayrollId, setSelectedPayrollId] = useState(null);

  const handleClickMenu = (event, payrollId) => {
    event.persist();
    setAnchorEl(event.currentTarget);
    setSelectedPayrollId(payrollId);
  };

  const handleCloseMenu = event => {
    if (event) event.persist();
    setAnchorEl(null);
  };

  const payrollMenuButtons = [
    /* {
      id: 1,
      text: 'Editar Facturas',
      action: (payrollId, closeMenu) => {
        closeMenu();
      },
    }, */
    {
      id: 2,
      text: <Box color="warning.dark">Eliminar lista de facturas</Box>,
      action: (payrollId, closeMenu) => {
        dispatch(deletePayroll(businessId, payrollId));
        setPayrolls(removeByKey(payrolls, e => e.id, payrollId));
        closeMenu();
      },
      showCondition: status => status !== 'SCHEDULED_READY_TO_PAY',
    },
  ];

  useEffect(() => {
    setHasMore(page < totalPages);
  }, [page, totalPages]);

  useEffect(() => {
    if (businessId) {
      dispatch(fetchPayrolls({ businessId, pageSize, option: 'not-paid' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId, dispatch]);

  useEffect(() => {
    const uniquePayrolls = uniqueByKeepLast(
      [...payrolls, ...fetchedPayrolls],
      e => e.id
    );
    const actionsByPayrollParsed = uniquePayrolls.map(payroll => {
      const { id, status } = payroll;
      const totalActions = payrollMenuButtons.filter(({ showCondition }) => {
        if (showCondition === undefined) return true;
        if (showCondition(status)) return false;
        return true;
      }).length;
      return [id, totalActions];
    });
    setActionsByPayroll(Object.fromEntries(actionsByPayrollParsed));
    setPayrolls(transformCustomPayrollStatus(uniquePayrolls));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedPayrolls]);

  useEffect(() => {
    trackSegment(SEGMENT_EVENTS.PAYMENT_SCHEDULED_VIEWED, {
      businessId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.root}>
      <DropdownDotsMenu
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
        payrollMenuButtons={payrollMenuButtons}
        selectedPayrollId={selectedPayrollId}
        fetchedPayrolls={fetchedPayrolls}
      />

      <Text1
        text="Selecciona el listado de facturas que quieras pagar"
        className={classes.title}
      />

      <InfiniteScroll
        dataLength={payrolls.length}
        next={fetchMorePayrolls}
        hasMore={hasMore}
        loader={<Loader />}
      >
        {payrolls.map(payroll => (
          <PayrollCard
            payroll={payroll}
            countryId={countryId}
            handlePayInvoicesButton={handlePayInvoicesButton}
            handleClickMenu={handleClickMenu}
            hasActions={actionsByPayroll[payroll.id] > 0}
          />
        ))}
      </InfiniteScroll>

      <PayInvoicesStepper
        open={showPayInvoicesStepper}
        closeModal={handleClosePayModal}
        dataInvoicesToPay={dataInvoicesToPay}
      />
    </Box>
  );
});

ScheduledPayrollCards.defaultProps = {};

ScheduledPayrollCards.propTypes = {
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  businessId: PropTypes.number.isRequired,
};

export default ScheduledPayrollCards;
