import axios from '../../helpers/axios';
import {
  FETCH_ORDERS_START,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_ERROR,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const fetchOrders = (businessId, pageSize, page) => dispatch => {
  dispatch({ type: FETCH_ORDERS_START });
  axios
    .get(`/api/business/${businessId}/order?size=${pageSize}&page=${page}`)
    .then(res => {
      const { data: orders, pagination = {} } = res.data;
      dispatch({
        type: FETCH_ORDERS_SUCCESS,
        payload: {
          orders,
          pagination,
        },
      });
    })
    .catch(err => {
      dispatch({ type: FETCH_ORDERS_ERROR, payload: err });
    });
};
