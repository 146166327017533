import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ExternalLink = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M10.0044 6.00104V8.00174H5.00125V18.9993H16.0019V13.9976H18.0031V19.9997C18.0031 20.265 17.8977 20.5194 17.71 20.707C17.5224 20.8946 17.2679 21 17.0025 21H4.00063C3.73524 21 3.48073 20.8946 3.29308 20.707C3.10542 20.5194 3 20.265 3 19.9997V7.00139C3 6.73608 3.10542 6.48164 3.29308 6.29404C3.48073 6.10644 3.73524 6.00104 4.00063 6.00104H10.0044ZM21 3V11.0028H18.9987V6.41244L11.2076 14.2039L9.79299 12.7909L17.5854 5.00069H13.0063V3H21Z" />
    </SvgIcon>
  );
};

export default ExternalLink;
