import moment from 'moment';
// eslint-disable-next-line import/prefer-default-export
export const simulateDemo = invoices => {
  if (invoices.length === 0) {
    return {};
  }

  const baseRate = 2.8;
  const operationCost = 500;
  const retentionPct = 30;

  const interesPerDay = baseRate / 30;

  const totalInvoices = invoices.reduce(
    (sum, invoice) => sum + invoice.amount,
    0
  );

  const retention = (totalInvoices * retentionPct) / 100;

  const interest = invoices.reduce((sum, invoice) => {
    const today = moment();
    const expiration = moment(invoice.expirationDate);
    const diffInDays = expiration.diff(today, 'days');
    const proportionalRate = diffInDays * interesPerDay;
    return sum + (proportionalRate * invoice.amount) / 100;
  }, 0);

  const transfer = totalInvoices - interest - operationCost - retention;

  return {
    transfer,
    retention,
    interest,
    retentionPct,
    baseRate,
    operationCost,
  };
};
