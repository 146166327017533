import { PURGE } from 'redux-persist';
import { t13s } from '../../translationKeys';

import {
  LOCAL_PURGE,
  FETCH_ONBOARD_START,
  FETCH_ONBOARD_SUCCESS,
  FETCH_ONBOARD_FAILURE,
  FETCH_ONBOARD_SUPPLIERS_START,
  FETCH_ONBOARD_SUPPLIERS_SUCCESS,
  FETCH_ONBOARD_SUPPLIERS_FAILURE,
  SAVE_ONBOARD_START,
  SAVE_ONBOARD_SUCCESS,
  SAVE_ONBOARD_FAILURE,
  RESET_ONBOARD,
} from '../actions/types';

const initialState = {
  isOnboarded: false,
  suppliers: [],

  fetchOnboardLoading: true,
  fetchOnboardError: null,

  fetchOnboardSuppliersLoading: false,
  fetchOnboardSuppliersError: null,

  saveOnboardSuccess: false,
  saveOnboardLoading: false,
  saveOnboardError: null,
};

const fetchOnboardStart = state => {
  return {
    ...state,
    fetchOnboardLoading: true,
    fetchOnboardError: null,
  };
};

const fetchOnboardSuccess = (state, payload) => {
  const { isOnboarded } = payload;
  return {
    ...state,
    fetchOnboardLoading: false,
    isOnboarded,
  };
};

const fetchOnboardFailure = (state, payload) => {
  const { errorCode } = payload;
  return {
    ...state,
    fetchOnboardLoading: false,
    fetchOnboardError: errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const fetchOnboardSuppliersStart = state => {
  return {
    ...state,
    fetchOnboardSuppliersLoading: true,
    fetchOnboardSuppliersError: null,
  };
};

const fetchOnboardSuppliersSuccess = (state, payload) => {
  const { suppliers } = payload;
  return {
    ...state,
    fetchOnboardSuppliersLoading: false,
    suppliers,
  };
};

const fetchOnboardSuppliersFailure = (state, payload) => {
  const { errorCode } = payload;
  return {
    ...state,
    fetchOnboardSuppliersLoading: false,
    fetchOnboardSuppliersError: errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const saveOnboardStart = state => {
  return {
    ...state,
    saveOnboardSuccess: false,
    saveOnboardLoading: true,
    saveOnboardError: null,
  };
};

const saveOnboardSuccess = state => {
  return {
    ...state,
    saveOnboardLoading: false,
    saveOnboardSuccess: true,
  };
};

const saveOnboardFailure = (state, payload) => {
  const { errorCode } = payload;
  return {
    ...state,
    saveOnboardLoading: false,
    saveOnboardError: errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const resetOnboard = state => {
  return {
    ...state,
    suppliers: [],
    fetchOnboardSuppliersLoading: false,
    fetchOnboardSuppliersError: null,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ONBOARD_START:
      return fetchOnboardStart(state);
    case FETCH_ONBOARD_SUCCESS:
      return fetchOnboardSuccess(state, payload);
    case FETCH_ONBOARD_FAILURE:
      return fetchOnboardFailure(state, payload);

    case FETCH_ONBOARD_SUPPLIERS_START:
      return fetchOnboardSuppliersStart(state);
    case FETCH_ONBOARD_SUPPLIERS_SUCCESS:
      return fetchOnboardSuppliersSuccess(state, payload);
    case FETCH_ONBOARD_SUPPLIERS_FAILURE:
      return fetchOnboardSuppliersFailure(state, payload);

    case SAVE_ONBOARD_START:
      return saveOnboardStart(state);
    case SAVE_ONBOARD_SUCCESS:
      return saveOnboardSuccess(state);
    case SAVE_ONBOARD_FAILURE:
      return saveOnboardFailure(state, payload);

    case RESET_ONBOARD:
      return resetOnboard(state);
    case LOCAL_PURGE:
      return initialState;
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
