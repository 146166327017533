import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { AVAILABLE_COUNTRIES } from '../../../../helpers/constants';
import Card from '../../../../components/elements/Card';
import { SEGMENT_EVENTS } from '../../../../helpers/segment/constants';
import { trackSegment } from '../../../../helpers/segment/trackingSegment';
import ToolbarButton from './ToolbarButton';
import SelectedInvoicesPopUp from './SelectedInvoicesPopUp';
import { useInboxTableHeaders, useInboxTable } from '../../adapters/InboxTable';
import { inboxTableStyles } from './styles';
import {
  Table,
  TableToolbar,
  SchedulePaymentModal,
} from '../../../commons/components';

const InboxTable = React.memo(props => {
  const { countryId, businessId } = props;
  const classes = inboxTableStyles();

  const rows = useInboxTableHeaders();
  const {
    onChangeSearch,
    fetchData,
    reloadValue,
    handleArchived,
    handlePaid,
    selectedInvoices,
    totalSelectedInvoices,
  } = useInboxTable();

  const [showScheduleInvoiceStepper, setShowScheduleInvoiceStepper] =
    useState(false);

  const handleShowScheduleStepper = () => {
    setShowScheduleInvoiceStepper(true);
    trackSegment(SEGMENT_EVENTS.PAYMENT_INVOICE_SELECTED, {
      businessId,
    });
  };

  useEffect(() => {
    trackSegment(SEGMENT_EVENTS.PAYMENT_INBOX_VIEWED, {
      businessId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toolbarButtons = [
    <ToolbarButton
      id="archive-button"
      label="Archivar"
      active={selectedInvoices.length > 0}
      onClick={handleArchived}
    />,
    <ToolbarButton
      id="paid-button"
      label="Marcar como pagada"
      active={selectedInvoices.length > 0}
      onClick={handlePaid}
    />,
    null,
  ];

  return (
    <Grid container item className={classes.tableInvoicesContainer}>
      <TableToolbar
        title="Selecciona una o más facturas que quieras programar"
        description="Puedes programar el pago de estas facturas para hoy mismo o el día que crees conveniente."
        buttons={toolbarButtons}
        onChangeSearch={onChangeSearch}
      />

      <Card variant="white" border className={classes.card}>
        <Table
          reload={reloadValue}
          columns={rows}
          actionButtons={[]}
          fetchData={fetchData}
        />
      </Card>

      <SelectedInvoicesPopUp
        countryId={countryId}
        selectedInvoices={selectedInvoices}
        totalSelectedInvoices={totalSelectedInvoices}
        openStepper={handleShowScheduleStepper}
      />

      <SchedulePaymentModal
        open={showScheduleInvoiceStepper}
        closeModal={() => setShowScheduleInvoiceStepper(false)}
      />
    </Grid>
  );
});

InboxTable.defaultProps = {};

InboxTable.propTypes = {
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  businessId: PropTypes.number.isRequired,
};

export default InboxTable;
