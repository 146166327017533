import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PaymentsIcon = props => {
  return (
    <SvgIcon
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      style={{ width: '48px', height: '48px' }}
      viewBox="0 0 48 48"
      fill="none"
    >
      <circle cx="24" cy="24" r="23.5" fill="white" stroke="#F87348" />
      <path
        d="M33.5999 22.8V25.2H19.1999L23.9999 30L22.7999 32.4L14.3999 24L22.7999 15.6L23.9999 18L19.1999 22.8H33.5999Z"
        fill="#F87348"
      />
    </SvgIcon>
  );
};

export default PaymentsIcon;
