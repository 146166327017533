import { useDispatch } from 'react-redux';
import {
  paymentsRestoreInvoice,
  requestVoucherInvoice,
} from '../../../../store/actions/paymentsActions';
import { useFetchData, useSearchAndReload } from '../commons';

const usePaidTable = businessId => {
  const dispatch = useDispatch();
  const { search, onChangeSearch, reloadTable, reloadValue } =
    useSearchAndReload();
  const { fetchData } = useFetchData({
    externalSearch: search,
    option: 'paid',
  });

  const requestInvoice = ({ invoice, closeMenu }) => {
    const { id: invoiceId, folio } = invoice;
    dispatch(requestVoucherInvoice(businessId, invoiceId, folio));
    closeMenu();
  };

  const actions = [
    {
      text: 'Devolver a mis facturas',
      action: ({ invoice, closeMenu }) => {
        dispatch(
          paymentsRestoreInvoice(businessId, [invoice], 'paid', reloadTable)
        );
        closeMenu();
      },
    },
    {
      text: 'Solicitar comprobante',
      action: requestInvoice,
    },
  ];

  return {
    actions,
    onChangeSearch,
    reloadTable,
    fetchData,
    reloadValue,
  };
};

export default usePaidTable;
