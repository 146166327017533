/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Typography,
  Grid,
  Box,
  Fade,
  CircularProgress,
  InputAdornment,
  Link,
} from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import AlertForm from './elements/AlertForm';
import LoadingButton from './elements/LoadingButton';
import FlagMx from '../assets/FlagMx.svg';
import FlagCl from '../assets/FlagCl.svg';
import * as colors from '../theme/otherColors';
import { t13s } from '../translationKeys';
import { COUNTRY_CODE_MX, COUNTRY_CODE_CL } from '../helpers/constants';
import {
  completeRegister,
  logoutUser,
} from '../store/actions/authenticationActions';
import {
  validateIdentifier,
  rutCleaner,
} from '../helpers/validations/businessIdentifier';
import {
  getNameByIdentifier,
  getNameByIdentifierReset,
} from '../store/actions/businessActions';
import CompleteRegisterDialog from './dialogs/CompleteRegisterDialog';
import isEmpty from '../helpers/validations/isEmpty';
import Footer from './Footer';

const useStyles = makeStyles({
  root: {
    justifyContent: 'center',
    width: 532,
    height: 700,
    margin: '0 auto',
  },
  card: {
    width: 372,
    margin: 'auto auto',
  },
  separatorPurple: {
    width: 56,
    height: 4,
    margin: '15px auto 30px auto',
    background: colors.HOME_START_MAIN_PURPLE,
    borderRadius: 3,
  },
  flag: {
    width: 30,
    height: 30,
    margin: '0 5px',
    cursor: 'pointer',
  },
  form: {
    marginTop: 20,
  },
  orangeText: {
    color: colors.HOME_START_FOOTER_LINK,
  },
  buttonLogin: {
    fontSize: 14,
    height: 40,
    marginTop: 15,
  },
  label: {
    marginBottom: 10,
  },
  label2: {
    marginTop: 15,
    marginBottom: 10,
  },
  link: {
    display: 'block',
    marginTop: 15,
  },
});

const CompleteRegister = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { completeRegisterIsLoading, completeRegisterError } = useSelector(
    state => state.authState
  );
  const { user } = useSelector(state => state.authState);
  const { wasInLogin, referralCode } = useSelector(state => state.uiState);
  const {
    nameByIdentifier,
    nameByIdentifierIsLoading,
    nameByIdentifierConsulted,
  } = useSelector(state => state.businessState);

  const countryId = process.env.REACT_APP_COUNTRY_ID;
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [isBusinnesIdentifierValid, setIsBusinnesIdentifierValid] =
    useState(false);
  const [inputBusinessNameDisabled, setInputBusinessNameDisabled] =
    useState(true);
  const [showDialogCompleRegister, setShowDialogCompleRegister] =
    useState(false);

  const onSubmit = () => {
    user.name = name;
    user.phone = phone;
    dispatch(
      completeRegister(
        {
          user,
          businessName,
          identifier,
          countryId,
        },
        referralCode
      )
    );
  };

  useEffect(() => {
    if (wasInLogin) {
      setShowDialogCompleRegister(true);
    }
  }, [wasInLogin]);

  const handleBusinessIdentifierChange = event => {
    let value = event?.target?.value;
    if (countryId === COUNTRY_CODE_CL) {
      value = rutCleaner(value);
    }
    setIdentifier(value);
  };

  const handlePhoneChange = event => {
    let value = event?.target?.value;
    value = value.replaceAll(' ', '');
    setPhone(value);
  };

  const identifierValidationSpy = isValid => {
    setIsBusinnesIdentifierValid(isValid);
    if (isValid) {
      dispatch(getNameByIdentifier(identifier, countryId));
    } else {
      setInputBusinessNameDisabled(true);
      setBusinessName('');
      if (nameByIdentifier) {
        dispatch(getNameByIdentifierReset());
      }
    }
  };

  useEffect(() => {
    if (nameByIdentifierConsulted && nameByIdentifier) {
      setBusinessName(nameByIdentifier);
    }
  }, [nameByIdentifierConsulted, nameByIdentifier]);

  useEffect(() => {
    if (nameByIdentifier) {
      setInputBusinessNameDisabled(true);
    } else if (
      !nameByIdentifier &&
      identifier &&
      isBusinnesIdentifierValid &&
      !nameByIdentifierIsLoading
    ) {
      setInputBusinessNameDisabled(false);
    }
  }, [
    nameByIdentifier,
    nameByIdentifierIsLoading,
    identifier,
    isBusinnesIdentifierValid,
  ]);

  useEffect(() => {
    if (!identifier) {
      setBusinessName('');
      if (nameByIdentifier) {
        dispatch(getNameByIdentifierReset());
      }
    }
  }, [identifier, nameByIdentifier, dispatch]);

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidIdentifier', value =>
      validateIdentifier(value, countryId)
    );
    ValidatorForm.addValidationRule('isNotEmpty', value => !isEmpty(value));
  }, [countryId]);

  const logoutClick = () => {
    dispatch(logoutUser());
  };

  return (
    <Fade in timeout={400}>
      <>
        {showDialogCompleRegister && (
          <CompleteRegisterDialog
            open={showDialogCompleRegister}
            handleCloseDialog={() => setShowDialogCompleRegister(false)}
          />
        )}
        <Grid container className={classes.root} align="center">
          <Grid container direction="column" className={classes.card}>
            <Typography
              variant="h5"
              component="div"
              color="textPrimary"
              className={classes.title}
            >
              <Box fontWeight="fontWeightBold">
                Completa tu perfil y se parte de
                <br />
                esta nueva experiencia financiera
              </Box>
            </Typography>
            <div className={classes.separatorPurple} />
            <Grid item>
              {countryId === COUNTRY_CODE_MX && (
                <img src={FlagMx} className={classes.flag} alt="Mexico flag" />
              )}
              {countryId === COUNTRY_CODE_CL && (
                <img src={FlagCl} className={classes.flag} alt="Chile flag" />
              )}
            </Grid>
            <ValidatorForm className={classes.form} onSubmit={onSubmit}>
              <Typography
                variant="body1"
                color="textSecondary"
                align="left"
                className={classes.label}
                component="div"
              >
                Información personal
              </Typography>
              <TextValidator
                fullWidth
                id="name"
                name="name"
                type="text"
                variant="outlined"
                value={name}
                onChange={e => setName(e.target.value)}
                validators={['required', 'isNotEmpty']}
                errorMessages={[
                  'Campo requerido',
                  'No puede dejar texto en blanco',
                ]}
                label="Nombre completo"
              />
              <TextValidator
                fullWidth
                id="phone"
                name="phone"
                type="text"
                variant="outlined"
                value={phone}
                label="Número de contacto"
                validators={[
                  'required',
                  'isNumber',
                  countryId === COUNTRY_CODE_MX
                    ? 'minStringLength:10'
                    : 'minStringLength:9',
                ]}
                errorMessages={[
                  'Campo requerido',
                  'Teléfono incorrecto',
                  t(t13s.INPUT_ERROR.LENGTH_PHONE),
                ]}
                onChange={handlePhoneChange}
                InputProps={{
                  startAdornment: countryId === COUNTRY_CODE_MX ? '+52' : '+56',
                }}
              />

              <Typography
                variant="body1"
                color="textSecondary"
                align="left"
                className={classes.label2}
                component="div"
              >
                Información de la empresa
              </Typography>

              <TextValidator
                fullWidth
                id="businessIdentifier"
                name="businessIdentifier"
                type="text"
                variant="outlined"
                value={identifier}
                onChange={e => handleBusinessIdentifierChange(e)}
                validatorListener={isValid => identifierValidationSpy(isValid)}
                label={`${t(t13s.LABEL.BUSINESS_IDENTIFIER)} empresa`}
                validators={['required', 'isValidIdentifier', 'isNotEmpty']}
                errorMessages={[
                  'Campo requerido',
                  t(t13s.INPUT_ERROR.BUSINESS_IDENTIFIER),
                  'No puede dejar texto en blanco',
                ]}
                InputProps={{
                  endAdornment: nameByIdentifierIsLoading ? (
                    <InputAdornment position="end">
                      <CircularProgress size={24} color="inherit" />
                    </InputAdornment>
                  ) : null,
                }}
              />

              <TextValidator
                fullWidth
                id="businessName"
                name="businessName"
                type="text"
                variant="outlined"
                value={businessName}
                disabled={inputBusinessNameDisabled}
                onChange={e => setBusinessName(e.target.value)}
                label="Nombre empresa"
                validators={['required', 'isNotEmpty']}
                errorMessages={[
                  'Campo requerido',
                  'No puede dejar texto en blanco',
                ]}
              />

              {!!completeRegisterError && (
                <AlertForm message={t(completeRegisterError)} variant="error" />
              )}
              <LoadingButton
                variant="contained"
                color="primary"
                fullWidth
                className={classes.buttonLogin}
                type="submit"
                isLoading={completeRegisterIsLoading}
              >
                Continuar
              </LoadingButton>
              <Link
                variant="button"
                color="secondary"
                className={classes.link}
                onClick={logoutClick}
              >
                Cerrar sesión
              </Link>
            </ValidatorForm>
          </Grid>
        </Grid>
        <Footer country={countryId} />
      </>
    </Fade>
  );
};

export default CompleteRegister;
