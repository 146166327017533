import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Link, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  invoicePayrollColumn: {
    minWidth: 160,
    color: theme.palette.text.secondary,
  },
  invoicePayrollLink: {
    textDecoration: 'underline',
  },
}));

const PayrollLink = React.memo(props => {
  const { text, onClick } = props;
  const classes = useStyles();

  return (
    <Typography
      variant="body1"
      color="textPrimary"
      component="div"
      className={classes.invoicePayrollColumn}
    >
      <Link
        variant="body1"
        component="button"
        onClick={onClick}
        color="inherit"
        className={classes.invoicePayrollLink}
      >
        <Box fontWeight="fontWeightBold" textAlign="left">
          {text}
        </Box>
      </Link>
    </Typography>
  );
});

PayrollLink.defaultProps = {};

PayrollLink.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PayrollLink;
