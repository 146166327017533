import { PURGE } from 'redux-persist';
import {
  FETCH_BUSINESS_BANK_ACCOUNTS_START,
  FETCH_BUSINESS_BANK_ACCOUNTS_SUCCESS,
  FETCH_BUSINESS_BANK_ACCOUNTS_FAILURE,
  CREATE_BUSINESS_BANK_ACCOUNT_START,
  CREATE_BUSINESS_BANK_ACCOUNT_SUCCESS,
  CREATE_BUSINESS_BANK_ACCOUNT_FAILURE,
  UPDATE_BUSINESS_BANK_ACCOUNT_START,
  UPDATE_BUSINESS_BANK_ACCOUNT_SUCCESS,
  UPDATE_BUSINESS_BANK_ACCOUNT_FAILURE,
  SET_DEFAULT_BANK_ACCOUNT_START,
  SET_DEFAULT_BANK_ACCOUNT_SUCCESS,
  SET_DEFAULT_BANK_ACCOUNT_FAILURE,
  DELETE_BANK_ACCOUNT_START,
  DELETE_BANK_ACCOUNT_SUCCESS,
  DELETE_BANK_ACCOUNT_FAILURE,
  BANK_ACCOUNT_RESET,
  FETCH_BANKS_START,
  FETCH_BANKS_SUCCESS,
  FETCH_BANKS_FAILURE,
  LOCAL_PURGE,
} from '../actions/types';
import { t13s } from '../../translationKeys';

const initialState = {
  bankAccounts: [],
  bankAccountsError: null,
  fetchingBankAccounts: false,
  fetchBankAccountError: null,
  creatingBankAccount: false,
  createBankAccountError: null,
  bankAccountCreated: false,
  updatingBankAccount: false,
  updateBankAccountError: null,
  bankAccountUpdated: false,
  settingDefaultBankAccount: false,
  setDefaultAccountError: null,
  banks: [],
  fetchingBanks: false,
  fetchBanksError: false,
};

const fetchBankAccountsStart = state => {
  return {
    ...state,
    fetchBankAccountError: null,
    fetchingBankAccounts: true,
  };
};

const fetchBankAccountsSuccess = (state, payload) => {
  return {
    ...state,
    fetchingBankAccounts: false,
    bankAccounts: payload,
  };
};

const fetchBankAccountsFailure = (state, payload) => {
  return {
    ...state,
    fetchingBankAccounts: false,
    fetchBankAccountError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const createBankAccountStart = state => {
  return {
    ...state,
    bankAccountCreated: false,
    createBankAccountError: null,
    creatingBankAccount: true,
  };
};

const createBankAccountSuccess = (state, payload) => {
  const {
    createdAt,
    updatedAt,
    sourceId,
    source,
    countryId,
    ...restOfBankAccount
  } = payload;

  const bank = state.banks.find(bank => bank.id === payload.bankId);

  const newBankAccount = {
    ...restOfBankAccount,
    Bank: { id: payload.bankId, name: bank.name },
  };

  return {
    ...state,
    bankAccountCreated: true,
    creatingBankAccount: false,
    bankAccounts: [...state.bankAccounts, newBankAccount],
  };
};

const createBankAccountFailure = (state, payload) => {
  return {
    ...state,
    creatingBankAccount: false,
    createBankAccountError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const updateBankAccountStart = state => {
  return {
    ...state,
    bankAccountUpdated: false,
    updateBankAccountError: null,
    updatingBankAccount: true,
  };
};

const updateBankAccountSuccess = (state, payload) => {
  const {
    createdAt,
    updatedAt,
    sourceId,
    source,
    countryId,
    ...restOfBankAccount
  } = payload;

  const bank = state.banks.find(bank => bank.id === payload.bankId);

  const newBankAccount = {
    ...restOfBankAccount,
    Bank: { id: payload.bankId, name: bank.name },
  };
  const bankAccounts = state.bankAccounts.filter(
    bankAccount => bankAccount.id !== newBankAccount.id
  );

  if (payload.isDefault) {
    bankAccounts.unshift(newBankAccount);
  } else {
    bankAccounts.push(newBankAccount);
  }

  return {
    ...state,
    bankAccountUpdated: true,
    updatingBankAccount: false,
    bankAccounts,
  };
};

const updateBankAccountFailure = (state, payload) => {
  return {
    ...state,
    updatingBankAccount: false,
    updateBankAccountError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const fetchBankssStart = state => {
  return {
    ...state,
    fetchBanksError: false,
    fetchingBanks: true,
  };
};

const fetchBanksSuccess = (state, payload) => {
  return {
    ...state,
    fetchBanksError: false,
    fetchingBanks: false,
    banks: payload,
  };
};

const fetchBanksError = (state, payload) => {
  return {
    ...state,
    fetchingBanks: false,
    fetchBanksError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const setDefaultBankAccountStart = state => {
  return {
    ...state,
    settingDefaultBankAccount: true,
    setDefaultAccountError: null,
  };
};

const setDefaultBankAccountSuccess = (state, payload) => {
  const newDefaultBankAccount = state.bankAccounts.find(
    account => account.id === payload
  );

  const previousDefaultBankAccount = state.bankAccounts.find(account =>
    newDefaultBankAccount.isXepelin
      ? account.isDefault && account.isXepelin
      : account.isDefault && !account.isXepelin
  );
  const bankAccounts = state.bankAccounts.filter(
    account =>
      account.id !== newDefaultBankAccount.id &&
      account.id !== previousDefaultBankAccount.id
  );
  newDefaultBankAccount.isDefault = true;
  previousDefaultBankAccount.isDefault = false;
  return {
    ...state,
    settingDefaultBankAccount: false,
    setDefaultAccountError: false,
    bankAccounts: [
      newDefaultBankAccount,
      previousDefaultBankAccount,
      ...bankAccounts,
    ],
  };
};

const setDefaultBankAccountFailure = (state, payload) => {
  return {
    ...state,
    settingDefaultBankAccount: false,
    bankAccountError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const deleteBankAccountStart = state => {
  return {
    ...state,
    deletingBankAccount: true,
    deleteBankAccountError: null,
  };
};

const deleteBankAccountSuccess = (state, payload) => {
  const updatedBankAccounts = state.bankAccounts.filter(
    account => account.id !== payload
  );
  return {
    ...state,
    deletingBankAccount: false,
    deleteBankAccountError: null,
    bankAccounts: updatedBankAccounts,
  };
};

const deleteBankAccountFailure = (state, payload) => {
  return {
    ...state,
    deletingBankAccount: false,
    deleteBankAccountError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const bankAccountReset = state => {
  return {
    ...state,
    creatingBankAccount: false,
    createBankAccountError: null,
    bankAccountCreated: false,
    updatingBankAccount: false,
    updateBankAccountError: null,
    bankAccountUpdated: false,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_BUSINESS_BANK_ACCOUNTS_START:
      return fetchBankAccountsStart(state);
    case FETCH_BUSINESS_BANK_ACCOUNTS_SUCCESS:
      return fetchBankAccountsSuccess(state, payload);
    case FETCH_BUSINESS_BANK_ACCOUNTS_FAILURE:
      return fetchBankAccountsFailure(state, payload);
    case CREATE_BUSINESS_BANK_ACCOUNT_START:
      return createBankAccountStart(state);
    case CREATE_BUSINESS_BANK_ACCOUNT_SUCCESS:
      return createBankAccountSuccess(state, payload);
    case CREATE_BUSINESS_BANK_ACCOUNT_FAILURE:
      return createBankAccountFailure(state, payload);
    case UPDATE_BUSINESS_BANK_ACCOUNT_START:
      return updateBankAccountStart(state);
    case UPDATE_BUSINESS_BANK_ACCOUNT_SUCCESS:
      return updateBankAccountSuccess(state, payload);
    case UPDATE_BUSINESS_BANK_ACCOUNT_FAILURE:
      return updateBankAccountFailure(state, payload);
    case SET_DEFAULT_BANK_ACCOUNT_START:
      return setDefaultBankAccountStart(state);
    case SET_DEFAULT_BANK_ACCOUNT_SUCCESS:
      return setDefaultBankAccountSuccess(state, payload);
    case SET_DEFAULT_BANK_ACCOUNT_FAILURE:
      return setDefaultBankAccountFailure(state, payload);
    case DELETE_BANK_ACCOUNT_START:
      return deleteBankAccountStart(state);
    case DELETE_BANK_ACCOUNT_SUCCESS:
      return deleteBankAccountSuccess(state, payload);
    case DELETE_BANK_ACCOUNT_FAILURE:
      return deleteBankAccountFailure(state, payload);
    case BANK_ACCOUNT_RESET:
      return bankAccountReset(state);
    case FETCH_BANKS_START:
      return fetchBankssStart(state);
    case FETCH_BANKS_SUCCESS:
      return fetchBanksSuccess(state, payload);
    case FETCH_BANKS_FAILURE:
      return fetchBanksError(state, payload);

    case LOCAL_PURGE:
      return initialState;
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
