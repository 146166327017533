import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchSuppliers } from '../../../../store/actions/suppliersActions';
import { uniqueByKeepLast } from '../../../../helpers/storeUtils';

const useSuppliersBook = () => {
  const dispatch = useDispatch();

  const [searchProviderTerm, setSearchProviderTerm] = useState('');
  const [suppliers, setSuppliers] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const business = useSelector(state => state.businessState.business);
  const {
    suppliers: fetchedSuppliers,
    pagination: { page, totalPages, pageSize },
  } = useSelector(state => state.suppliersState);

  const handleChangeSearchInput = value => {
    setSearchProviderTerm(value);
  };

  const fetchMoreSuppliers = () => {
    if (page < totalPages) {
      dispatch(
        fetchSuppliers({
          businessId: business.id,
          page: page + 1,
          pageSize,
          name: searchProviderTerm,
        })
      );
    }
  };

  useEffect(() => {
    setHasMore(page < totalPages);
  }, [page, totalPages]);

  useEffect(() => {
    setSuppliers([]);
    if (business) {
      dispatch(
        fetchSuppliers({
          businessId: business.id,
          name: searchProviderTerm,
          pageSize,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProviderTerm, business, dispatch]);

  useEffect(() => {
    setSuppliers(
      uniqueByKeepLast([...suppliers, ...fetchedSuppliers], s => s.id)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedSuppliers]);

  return {
    suppliers,
    hasMore,
    handleChangeSearchInput,
    fetchMoreSuppliers,
  };
};

export default useSuppliersBook;
