/* eslint-disable no-case-declarations */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Box, Grid, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Panel from './elements/Panel';
import CustomButton from './elements/CustomButton';
import {
  createStakeholder,
  fetchStakeholders,
} from '../store/actions/stakeholdersActions';
import { COUNTRY_CODE_CL, COUNTRY_CODE_MX, LINKS } from '../helpers/constants';
import { HashKey } from '../helpers/hashKeys';
import { convertSpacingToCss } from '../helpers/stylesHelpers';
import { fetchDocument } from '../store/actions/documentsActions';
import UserStakeholders from './UserStakeholders';
import UserDocumentSection from './UserDocumentSection';
import UserSectionHeader from './elements/UserSectionHeader';
import StakeholderDialogCL from './dialogs/StakeholderDialogCL';

const useStyles = makeStyles({
  containerBody: {
    display: 'grid',
    gridGap: convertSpacingToCss('lg'),
  },
  subtitle: {
    marginTop: convertSpacingToCss('sm'),
  },
});

const UserLegal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { id: businessId, countryId } = useSelector(
    state => state.businessState.business
  );
  const { businessDocuments, businessDocumentsIsLoading } = useSelector(
    state => state.documentsState
  );

  const {
    stakeholderCreated,
    createStakeholderError,
    createStakeholderIsLoading,
  } = useSelector(state => state.stakeholdersState);

  const [headerHeight, setHeaderHeight] = useState(0);
  const [showStakeholderCLDialog, setShowStakeholderCLDialog] = useState(false);

  const countryLinks = LINKS[countryId];
  const permanentDocs = [
    {
      id: 99998,
      title: 'Términos y condiciones',
      action: 'LINK',
      url: countryLinks.terms,
      approved: true,
      sequence: 1,
      active: true,
    },
    {
      id: 999999,
      title: 'Políticas de privacidad',
      action: 'LINK',
      url: countryLinks.privacy,
      approved: true,
      sequence: 2,
      active: true,
    },
  ];

  const documents = businessDocuments.filter(document => document.active);

  useEffect(() => {
    dispatch(fetchStakeholders(businessId));
    dispatch(fetchDocument(businessId, 'business'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stakeholderCreated) {
      setShowStakeholderCLDialog(false);
    }
  }, [stakeholderCreated]);

  const toggleCreateStakeholderDialog = () => {
    switch (countryId) {
      case COUNTRY_CODE_MX: {
        const hashedBusinessId = HashKey(businessId);
        const personType = HashKey(1);
        window.open(
          `${process.env.REACT_APP_CLIENT_LEGAL}/form?id=${hashedBusinessId}&type=${personType}`,
          '_blank'
        );
        break;
      }
      case COUNTRY_CODE_CL: {
        setShowStakeholderCLDialog(true);
        break;
      }
      default:
        break;
    }
  };

  const handleCreateStakeholder = stakeholder => {
    dispatch(createStakeholder(businessId, stakeholder));
  };

  return (
    <Fade in timeout={400}>
      <Grid item>
        <UserSectionHeader setHeaderHeight={setHeaderHeight}>
          <Typography variant="h5" color="textPrimary">
            <Box fontWeight="fontWeightBold">Información Legal</Box>
          </Typography>

          <Typography
            variant="body1"
            color="textSecondary"
            className={classes.subtitle}
          >
            Edita o completa la información de los representantes legales para
            poder enviar el contrato para la firma del mismo. Esto es necesario
            para poder transferir el dinero de tus operaciones.
          </Typography>
        </UserSectionHeader>

        <Box className={classes.containerBody} marginTop={headerHeight}>
          <Panel
            title="Representantes legales"
            variant="green-title"
            actions={
              <Grid item xs={6} align="right">
                <CustomButton
                  variant="orange-lined"
                  onClick={toggleCreateStakeholderDialog}
                >
                  Agregar
                </CustomButton>
              </Grid>
            }
          >
            <UserStakeholders />
          </Panel>

          <Panel
            title="Documentos adicionales"
            variant="green-title"
            titlePadding="xl xl lg"
          >
            <UserDocumentSection
              documents={documents}
              documentsAreLoading={businessDocumentsIsLoading}
              documentableType="business"
            />
          </Panel>

          <Panel
            title="Documentos Xepelin"
            variant="green-title"
            titlePadding="xl xl lg"
          >
            <UserDocumentSection
              documents={permanentDocs.filter(document => document.active)}
              documentsAreLoading={businessDocumentsIsLoading}
              documentableType="business"
            />
          </Panel>
        </Box>
        {showStakeholderCLDialog && (
          <StakeholderDialogCL
            isOpen={showStakeholderCLDialog}
            handleClose={() => {
              setShowStakeholderCLDialog(false);
            }}
            onSubmit={handleCreateStakeholder}
            error={createStakeholderError}
            loading={createStakeholderIsLoading}
          />
        )}
      </Grid>
    </Fade>
  );
};

export default UserLegal;
