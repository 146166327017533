export const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    const cloneResult = { ...result };
    cloneResult[currentValue[key]] = cloneResult[currentValue[key]] || [];
    cloneResult[currentValue[key]].push(currentValue);
    return cloneResult;
  }, {});
};

export default { groupBy };
