import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Check from '@material-ui/icons/Check';

import { Typography, Box } from '@material-ui/core';

const useCustomStepStyles = makeStyles({
  root: {
    backgroundColor: '#DAD9DB',
    zIndex: 1,
    border: '3px solid #DAD9DB',
    color: '#fff ',
    width: 32,
    height: 32,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: '#fff',
    color: '#F87348',
    borderColor: '#F87348',
  },
  completed: {
    backgroundColor: '#F87348',
    color: '#fff',
    borderColor: '#F87348',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '24px',
  },
});

const CustomStepLabel = props => {
  const classes = useCustomStepStyles();
  // eslint-disable-next-line react/prop-types
  const { active, completed, icon } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <Typography variant="body2" className={classes.title} component="div">
          <Box fontWeight="bold" fontSize="14px" lineHeight="24px">
            {icon}
          </Box>
        </Typography>
      )}
    </div>
  );
};

export default CustomStepLabel;
