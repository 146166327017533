import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography, makeStyles, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Card from './Card';
import { COUNTRY_CODE_CL } from '../../helpers/constants';
import CountryFormatHelper from './CountryFormatHelper';
import TgrIcon from '../icons/TgrIcon';

const useStyles = makeStyles({
  rootCard: {
    display: 'inline-flex',
    boxSizing: 'border-box',
    minHeight: 161,
    minWidth: 260,
    width: 260,
    padding: '20px 25px',
  },
  icon: {
    marginRight: 20,
  },
  title: {
    marginBottom: 15,
  },
  label: {
    marginTop: 20,
  },
});

const TgrCard = () => {
  const classes = useStyles();
  const { tgrDebt, tgrDebtIsLoading } = useSelector(
    state => state.businessState
  );
  const { totalTGRDebt: totalAmount, updatedAt } = tgrDebt || {};

  if (tgrDebtIsLoading) {
    return (
      <Card variant="white" border>
        <Box className={classes.rootCard}>
          <Box>
            <TgrIcon className={classes.icon} color="secondary" />
          </Box>
          <Grid container direction="column">
            <Typography
              variant="h6"
              color="textPrimary"
              component="div"
              className={classes.title}
            >
              <Box fontWeight="fontWeightBold">TGR</Box>
            </Typography>
            <Skeleton variant="text" />
          </Grid>
        </Box>
      </Card>
    );
  }

  return (
    <Card variant="white" border>
      <Box className={classes.rootCard}>
        <Box>
          <TgrIcon className={classes.icon} color="secondary" />
        </Box>
        <Grid container direction="column">
          <Typography
            variant="h6"
            color="textPrimary"
            component="div"
            className={classes.title}
          >
            <Box fontWeight="fontWeightBold">TGR</Box>
          </Typography>

          <Typography variant="subtitle1" color="textPrimary" component="div">
            <Box fontWeight="fontWeightBold">
              <CountryFormatHelper
                value={totalAmount}
                countryId={COUNTRY_CODE_CL}
                variant="currency"
              />
            </Box>
          </Typography>

          <Typography
            variant="caption"
            color="textPrimary"
            className={classes.label}
          >
            Última consulta
          </Typography>
          <Typography variant="body1" color="textPrimary">
            <CountryFormatHelper
              value={updatedAt}
              variant="longdate"
              countryId={COUNTRY_CODE_CL}
            />
          </Typography>
        </Grid>
      </Box>
    </Card>
  );
};

export default TgrCard;
