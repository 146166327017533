import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, IconButton, Grid, Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/CheckOutlined';
import TimesIcon from '@material-ui/icons/CloseRounded';
import { makeStyles } from '@material-ui/core/styles';
import {
  TYPOGRAPHY_VARIANTS,
  TYPOGRAPHY_COLORS,
  FONT_WEIGHTS,
} from '../../helpers/constants';
import {
  getThemeColor,
  convertSpacingToCss,
} from '../../helpers/stylesHelpers';

const styles = props => {
  const classes = makeStyles(theme => ({
    labelRoot: {
      minHeight: 32,
    },
    container: {
      textAlign: props.align || 'left',
    },
    editButton: {
      color: theme.palette.grey[700],
      '& svg': {
        fontSize: 16,
      },
      marginLeft: convertSpacingToCss('xs'),
      padding: 8,
    },
    label: {
      color: props => getThemeColor(theme, props.labelColor),
      padding: '10px 0',
      display: 'inline-block',
    },
    dataRoot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: props => props.align,
      padding: props =>
        props.direction === 'column'
          ? convertSpacingToCss('zero')
          : convertSpacingToCss('zero zero zero md'),
    },
    data: {
      color: props => getThemeColor(theme, props.dataColor),
      padding: props =>
        props.direction === 'column'
          ? convertSpacingToCss('zero zero sm zero')
          : convertSpacingToCss('zero'),
    },
    greenIcon: {
      color: theme.palette.success.main,
    },
    redIcon: {
      color: theme.palette.error.main,
    },
    gridInput: {
      display: 'grid',
      gridTemplateColumns: 'auto 60px',
    },
    inputRoot: {
      marginBottom: convertSpacingToCss('zero'),
    },
  }));
  return classes(props);
};

const InfoField = props => {
  const classes = styles(props);
  const [editing, setEditing] = useState(false);
  const {
    label,
    labelVariant,
    labelFontWeight,
    data,
    dataVariant,
    dataFontWeight,
    editable,
    loading,
    onAccept,
    direction,
    inputErrorMessages,
    inputValidators,
  } = props;
  const [value, setValue] = useState(data);

  useEffect(() => {
    setValue(data);
  }, [data]);

  const submitHandler = () => {
    onAccept(value);
    setEditing(false);
    setValue(data);
  };

  const cancelHandler = () => {
    setEditing(false);
    setValue(data);
  };

  const handleChange = event => {
    setValue(event.target.value);
  };

  return (
    <Grid container direction={direction} className={classes.container}>
      <Grid item className={classes.labelRoot}>
        <Typography
          variant={labelVariant}
          className={classes.label}
          component="div"
        >
          {!labelFontWeight ? (
            label
          ) : (
            <Box fontWeight={labelFontWeight}>{label}</Box>
          )}
        </Typography>
        {editable && !editing && (
          <IconButton
            aria-label="editar"
            className={classes.editButton}
            onClick={() => setEditing(true)}
            data-qa="info-field-edit-icon-button-action"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        )}
      </Grid>

      <Grid item className={classes.dataRoot}>
        {editing ? (
          <ValidatorForm onSubmit={submitHandler}>
            <Grid container direction="row" className={classes.gridInput}>
              <Grid item>
                <TextValidator
                  data-qa="info-field-text-validator-input"
                  name="data"
                  variant="outlined"
                  size="small"
                  value={value}
                  onChange={handleChange}
                  style={{ marginTop: 0 }}
                  classes={{
                    root: classes.inputRoot,
                  }}
                  validators={inputValidators}
                  errorMessages={inputErrorMessages}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <IconButton
                  type="submit"
                  size="small"
                  className={classes.greenIcon}
                  data-qa="info-field-editing-submit-button-action"
                >
                  <CheckIcon />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={cancelHandler}
                  className={classes.redIcon}
                  data-qa="info-field-editing-cancel-button-action"
                >
                  <TimesIcon />
                </IconButton>
              </Grid>
            </Grid>
          </ValidatorForm>
        ) : loading ? (
          <Skeleton variant="text" width={100} height={15} />
        ) : (
          <Typography
            variant={dataVariant}
            className={classes.data}
            component="div"
            data-qa="info-field-typography-data-string"
          >
            {!dataFontWeight ? (
              data
            ) : (
              <Box fontWeight={dataFontWeight}>{data}</Box>
            )}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

InfoField.defaultProps = {
  editable: false,
  direction: 'column',
  onAccept: null,
  inputErrorMessages: [],
  inputValidators: [],
  data: null,
  labelColor: 'initial',
  labelFontWeight: null,
  labelVariant: 'body1',
  dataColor: 'initial',
  dataFontWeight: null,
  dataVariant: 'body1',
  loading: false,
  align: 'left',
};

InfoField.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  align: PropTypes.oneOf(['center', 'left', 'right']),
  label: PropTypes.string.isRequired,
  labelVariant: PropTypes.oneOf(TYPOGRAPHY_VARIANTS),
  // eslint-disable-next-line react/no-unused-prop-types
  labelColor: PropTypes.oneOf(TYPOGRAPHY_COLORS),
  labelFontWeight: PropTypes.oneOf(FONT_WEIGHTS),
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  dataVariant: PropTypes.oneOf(TYPOGRAPHY_VARIANTS),
  // eslint-disable-next-line react/no-unused-prop-types
  dataColor: PropTypes.oneOf(TYPOGRAPHY_COLORS),
  dataFontWeight: PropTypes.oneOf(FONT_WEIGHTS),
  // eslint-disable-next-line react/no-unused-prop-types
  editable: PropTypes.bool,
  direction: PropTypes.oneOf(['row', 'column']),
  onAccept: PropTypes.func,
  inputErrorMessages: PropTypes.arrayOf(PropTypes.string),
  inputValidators: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
};

export default InfoField;
