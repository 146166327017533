import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  message: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
    color: '#FFF',
    padding: 8,
    textAlign: 'center',
    margin: '10px 10px 0 10px',
  },
}));

const ToolbarDatePicker = props => {
  const { message } = props;

  const classes = useStyles();

  return !!message && <div className={classes.message}>{message}</div>;
};

ToolbarDatePicker.defaultProps = {
  message: null,
};

ToolbarDatePicker.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default ToolbarDatePicker;
