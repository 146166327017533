import React from 'react';
import { Box } from '@material-ui/core';
import MaterialTable from '@material-table/core';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import PropTypes from 'prop-types';
import PaginationMaterialTable from './PaginationMaterialTable';
import { tableStyles } from './styles';
import { useTable, useDropdownDotsMenu } from '../hooks';
import DropdownDotsMenu from './DropdownDotsMenu';

const Table = React.memo(props => {
  const { columns, actionButtons, localization, pageSize, fetchData, reload } =
    props;
  const { pagination, isLoading, getData, handleSelectionChange, tableRef } =
    useTable({
      fetchData,
      reload,
    });

  const { actions, anchorEl, selectedMenuInvoice, handleCloseMenu } =
    useDropdownDotsMenu(actionButtons);

  const classes = tableStyles();

  const options = {
    emptyRowsWhenPaging: false,
    showFirstLastPageButtons: false,
    selection: true,
    sorting: true,
    pageSize,
    pageSizeOptions: [5, 10, 20, 50, 100],
    showSelectAllCheckbox: true,
    search: false,
    toolbar: false,
    actionsColumnIndex: -1,
    debounceInterval: 400,
    selectionProps: () => ({
      icon: <CircleUnchecked className={classes.leftSpace} />,
      checkedIcon: <CircleCheckedFilled className={classes.leftSpace} />,
    }),
    headerSelectionProps: {
      icon: <CircleUnchecked className={classes.leftSpace} />,
      checkedIcon: <CircleCheckedFilled className={classes.leftSpace} />,
      indeterminateIcon: <CircleUnchecked className={classes.leftSpace} />,
    },
    rowStyle: {},
  };

  return (
    <Box className={classes.tableWrapper}>
      {actionButtons.length > 0 && (
        <DropdownDotsMenu
          actionButtons={actionButtons}
          anchorEl={anchorEl}
          selectedMenuInvoice={selectedMenuInvoice}
          handleCloseMenu={handleCloseMenu}
        />
      )}
      <MaterialTable
        title=""
        tableRef={tableRef}
        columns={columns}
        data={getData}
        isLoading={isLoading}
        actions={actions}
        localization={localization}
        onSelectionChange={handleSelectionChange}
        options={options}
        components={{
          Pagination: paginationProps => (
            <PaginationMaterialTable
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...paginationProps}
              count={pagination.totalData}
              page={pagination.page - 1}
            />
          ),
        }}
      />
    </Box>
  );
});

Table.defaultProps = {
  actionButtons: [],
  localization: {
    pagination: {
      labelDisplayedRows: '{from}-{to} de {count}',
      labelRowsSelect: 'registros',
      labelRowsPerPage: 'Registros por página',
    },
    header: {
      actions: '',
    },
    body: {
      emptyDataSourceMessage: 'No hay datos para mostrar',
      filterRow: {
        filterTooltip: 'Filtro',
      },
    },
  },
  pageSize: 20,
  reload: new Date(),
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchData: PropTypes.func.isRequired,
  actionButtons: PropTypes.arrayOf(PropTypes.object),
  localization: PropTypes.objectOf(PropTypes.object),
  pageSize: PropTypes.number,
  reload: PropTypes.instanceOf(Date),
};

export default Table;
