import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PaymentsIcon = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon width="48" height="48" viewBox="0 0 48 48" fill="none" {...props}>
      <circle cx="24" cy="24" r="24" fill="#F5F5F6" />
      <path
        d="M31 32H17V21H31V32ZM28 14V16H20V14H18V16H17C16.4696 16 15.9609 16.2107 15.5858 16.5858C15.2107 16.9609 15 17.4696 15 18V32C15 32.5304 15.2107 33.0391 15.5858 33.4142C15.9609 33.7893 16.4696 34 17 34H31C31.5304 34 32.0391 33.7893 32.4142 33.4142C32.7893 33.0391 33 32.5304 33 32V18C33 17.4696 32.7893 16.9609 32.4142 16.5858C32.0391 16.2107 31.5304 16 31 16H30V14H28ZM22.88 25H19.27L22.19 27.11L21.08 30.56L24 28.43L26.92 30.56L25.8 27.12L28.72 25H25.12L24 21.56L22.88 25Z"
        fill="#44414C"
      />
    </SvgIcon>
  );
};

export default PaymentsIcon;
