import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import moment from 'moment';

import { AVAILABLE_COUNTRIES } from '../../helpers/constants';
import 'moment/locale/es';
import { settings } from '../../config/settings';

const CURRENCY = 'currency';
const NUMERIC = 'numeric';
const PERCENTAGE = 'percentage';
const LONG_DATE = 'longdate';
const SHORT_DATE = 'shortdate';

const CountryFormatHelper = props => {
  const { countryId, value, variant, useFixedDecimalPrecision, dataQa } = props;

  const {
    thousandSeparator,
    decimalSeparator,
    currencyDecimalPoint,
    currencySymbol,
    shordateformat,
    longdateformat,
  } = settings[countryId];

  const getFormatedValue = () => {
    switch (variant) {
      case CURRENCY: {
        // IF CURRENCY IS INTEGER => SHOW 0 DECIMAL, ELSE 2 DECIMAL
        const decimalScale =
          Number.parseFloat(value) && value % 1 === 0
            ? 0
            : currencyDecimalPoint;
        const finalValue = Number.parseFloat(value)
          ? parseFloat(value).toFixed(decimalScale)
          : value;
        return (
          <NumberFormat
            value={finalValue}
            thousandSeparator={thousandSeparator}
            decimalSeparator={decimalSeparator}
            prefix={currencySymbol}
            displayType="text"
            isNumericString
            data-qa={dataQa}
          />
        );
      }
      case NUMERIC: {
        let finalValue = value;
        if (value && useFixedDecimalPrecision > 0) {
          const rawValue = Number(value);
          finalValue =
            !rawValue % 1
              ? rawValue
              : rawValue.toFixed(useFixedDecimalPrecision);
        }
        return (
          <NumberFormat
            value={finalValue}
            thousandSeparator={thousandSeparator}
            decimalSeparator={decimalSeparator}
            displayType="text"
            isNumericString
            data-qa={dataQa}
          />
        );
      }
      case PERCENTAGE: {
        let finalValue;
        const maxDecimals =
          useFixedDecimalPrecision > 0 ? useFixedDecimalPrecision : 0;

        if (value) {
          const rawValue = Number(value);
          finalValue = !rawValue % 1 ? rawValue : rawValue.toFixed(maxDecimals);
        }
        return (
          <NumberFormat
            value={finalValue || '-'}
            thousandSeparator={thousandSeparator}
            decimalSeparator={decimalSeparator}
            suffix="%"
            displayType="text"
            isNumericString
            data-qa={dataQa}
          />
        );
      }

      case LONG_DATE:
        return moment.utc(value).format(longdateformat);
      case SHORT_DATE:
        return moment.utc(value).format(shordateformat);
      default:
        return null;
    }
  };
  return getFormatedValue();
};

CountryFormatHelper.defaultProps = {
  useFixedDecimalPrecision: -1,
};

CountryFormatHelper.propTypes = {
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any),
  ]),
  variant: PropTypes.oneOf([
    CURRENCY,
    PERCENTAGE,
    NUMERIC,
    LONG_DATE,
    SHORT_DATE,
  ]),
  dataQa: PropTypes.string,
  useFixedDecimalPrecision: PropTypes.number,
};

export default CountryFormatHelper;
