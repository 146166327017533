import React from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles, Box, Typography, Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';

const useStyles = makeStyles({
  root: {
    height: 50,
  },
  icon: {
    width: 42,
  },
  title: {
    width: 217,
  },
  action: {
    width: 200,
    display: 'flex',
    justifyContent: 'flex-start',
  },
});

const InvoiceDocumentItem = ({ document, handleDownloadClick }) => {
  const classes = useStyles();
  const { type } = document;

  return (
    <Grid
      container
      direction="row"
      className={classes.root}
      alignItems="center"
    >
      <Box
        className={classes.icon}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box color="success.main" display="inline-flex">
          <CheckIcon color="inherit" />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" className={classes.title}>
        <Typography variant="body1" color="textPrimary" component="div">
          <Box fontWeight="fontWeightBold">
            {type === 'interest' ? 'Factura Interés' : 'Factura Asesoría'}
          </Box>
        </Typography>
      </Box>
      <Box className={classes.action}>
        <Box color="text.primary" display="inline-flex">
          <Button
            variant="text"
            color="inherit"
            startIcon={<CloudDownloadOutlinedIcon />}
            onClick={handleDownloadClick}
          >
            Descargar documento
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};

InvoiceDocumentItem.propTypes = {
  document: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    folio: PropTypes.string.isRequired,
    pdf: PropTypes.string.isRequired,
    xml: PropTypes.string.isRequired,
  }).isRequired,
  handleDownloadClick: PropTypes.func.isRequired,
};

export default InvoiceDocumentItem;
