/* eslint-disable no-unused-vars */
import {
  Select,
  FormControl,
  MenuItem,
  makeStyles,
  Typography,
  TableCell,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import PropTypes from 'prop-types';
import * as React from 'react';
/* eslint-enable no-unused-vars */

const useStyles = makeStyles({
  root: {
    padding: 0,
    border: 0,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 25,
    paddingBottom: 15,
  },
  labelRowsPerPage: {
    marginRight: 5,
  },
  pagination: {
    margin: '0 40px',
  },
});

const PaginationMaterialTable = props => {
  const {
    count,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    onChangeRowsPerPage,
    labelRowsPerPage,
    labelDisplayedRows,
    onChangePage,
  } = props;
  const classes = useStyles();

  const maxPages = Math.ceil(count / rowsPerPage);
  const from = page * rowsPerPage + 1;
  const to = Math.min(page * rowsPerPage + rowsPerPage, count);

  const handleChangePage = (event, selectedPage) => {
    onChangePage(event, selectedPage - 1);
  };

  return (
    <TableCell className={classes.root}>
      <div className={classes.container}>
        <Typography
          variant="body2"
          color="textPrimary"
          className={classes.labelRowsPerPage}
        >
          {labelRowsPerPage}
        </Typography>
        <FormControl size="small" variant="outlined">
          <Select
            labelId="rows-per-page-label"
            id="rows-per-page"
            value={rowsPerPage}
            onChange={onChangeRowsPerPage}
            data-qa="material-table-pagination-select"
          >
            {rowsPerPageOptions.map(option => (
              <MenuItem
                key={option}
                value={option}
                selected={option === rowsPerPage}
              >
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Pagination
          count={maxPages}
          page={page + 1}
          onChange={handleChangePage}
          size="small"
          color="secondary"
          className={classes.pagination}
        />

        <Typography
          variant="body2"
          color="textPrimary"
          className={classes.labelRowsPerPage}
        >
          {labelDisplayedRows({ from, to, count })}
        </Typography>
      </div>
    </TableCell>
  );
};

PaginationMaterialTable.propTypes = {
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  labelDisplayedRows: PropTypes.func,
  labelRowsPerPage: PropTypes.string,
  onChangeRowsPerPage: PropTypes.func.isRequired,
};

PaginationMaterialTable.defaultProps = {
  labelDisplayedRows: () => '{count} registros totales',
  labelRowsPerPage: 'Registros por página',
  rowsPerPageOptions: [5, 10, 20, 50, 100],
  count: 0,
};

export default PaginationMaterialTable;
