import React from 'react';
import { Typography, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { titleDescriptionStyles } from './styles';

const TitleAndDescription = React.memo(props => {
  const classes = titleDescriptionStyles();
  const { title, description } = props;

  return (
    <Box>
      {title && (
        <Typography
          variant="body1"
          color="textPrimary"
          component="h2"
          className={classes.title}
        >
          <Box fontWeight="fontWeightBold">{title}</Box>
        </Typography>
      )}
      {description && (
        <Typography
          variant="body1"
          color="textPrimary"
          component="h4"
          className={classes.description}
        >
          <Box>{description}</Box>
        </Typography>
      )}
    </Box>
  );
});

TitleAndDescription.defaultProps = {
  description: <>&nbsp;</>,
};

TitleAndDescription.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default TitleAndDescription;
