import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import { resetContactDetail } from '../../store/actions/contactDetailActions';
import {
  FIELD_REQUIRED,
  INVALID_EMAIL,
  VALIDATOR_EMAIL,
  VALIDATOR_REQUIRED,
} from '../../helpers/constants';
import { phoneValidation } from '../../helpers/validations/phoneValidation';
import AlertForm from '../elements/AlertForm';
import LoadingButton from '../elements/LoadingButton';
import BaseDialog from './BaseDialog';

const ContactDetailDialog = ({
  contactDetail,
  type,
  open,
  onClose,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const contactableType =
    (contactDetail && contactDetail.contactableType) || type;
  const {
    [`${contactableType}CreatingContactDetail`]: creatingContactDetail,
    [`${contactableType}UpdatingContactDetail`]: updatingContactDetail,
    [`${contactableType}CreateContactDetailError`]: createContactDetailError,
    [`${contactableType}UpdateContactDetailError`]: updateContactDetailError,
    [`${contactableType}ContactDetailCreated`]: contactDetailCreated,
    [`${contactableType}ContactDetailUpdated`]: contactDetailUpdated,
  } = useSelector(state => state.contactDetailsState);

  const [contact, setContact] = useState({
    name: contactDetail?.name || '',
    position: contactDetail?.position || '',
    email: contactDetail?.email || '',
    phone: contactDetail?.phone || '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [contactDetailError, setContactDetailError] = useState(null);

  const resetForm = () => {
    setContact({
      name: '',
      position: '',
      email: '',
      phone: '',
    });
  };

  const handleClose = () => {
    resetForm();
    onClose();
    dispatch(resetContactDetail(contactableType));
  };

  const handleInputChange = e => {
    setContact({
      ...contact,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitForm = () => {
    onSubmit({ type, ...contactDetail, ...contact });
  };

  const { name, email, position, phone } = contact;

  useEffect(() => {
    setIsLoading(updatingContactDetail || creatingContactDetail);
  }, [updatingContactDetail, creatingContactDetail]);

  useEffect(
    () => {
      if (createContactDetailError || updateContactDetailError) {
        setContactDetailError(
          createContactDetailError ?? updateContactDetailError
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    createContactDetailError,
    updateContactDetailError
  );

  useEffect(() => {
    if (contactDetailCreated || contactDetailUpdated) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactDetailCreated, contactDetailUpdated]);

  useEffect(() => {
    ValidatorForm.addValidationRule('isPhoneValid', value => {
      return phoneValidation(value);
    });

    dispatch(resetContactDetail(type));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseDialog
      title={contactDetail ? 'Editar contacto' : 'Agregar contacto'}
      isOpen={open}
      handleClose={handleClose}
    >
      <ValidatorForm onSubmit={handleSubmitForm}>
        <TextValidator
          fullWidth
          variant="outlined"
          name="name"
          value={name}
          label="Nombre"
          validators={[VALIDATOR_REQUIRED, 'maxStringLength:130']}
          errorMessages={[FIELD_REQUIRED, 'Máximo 130 caracteres']}
          onChange={handleInputChange}
        />

        <TextValidator
          fullWidth
          variant="outlined"
          name="position"
          value={position}
          label="Cargo"
          validators={[VALIDATOR_REQUIRED, 'maxStringLength:50']}
          errorMessages={[FIELD_REQUIRED, 'Máximo 50 caracteres']}
          onChange={handleInputChange}
        />

        <TextValidator
          fullWidth
          variant="outlined"
          name="email"
          value={email}
          label="Mail"
          validators={[
            VALIDATOR_REQUIRED,
            'maxStringLength:50',
            VALIDATOR_EMAIL,
          ]}
          errorMessages={[
            FIELD_REQUIRED,
            'Máximo 50 caracteres',
            INVALID_EMAIL,
          ]}
          onChange={handleInputChange}
        />

        <TextValidator
          fullWidth
          variant="outlined"
          name="phone"
          value={phone}
          label="Teléfono"
          validators={[
            VALIDATOR_REQUIRED,
            'isPhoneValid',
            'maxStringLength:15',
          ]}
          errorMessages={[
            FIELD_REQUIRED,
            'telefono invalido',
            'Máximo 15 caracteres',
          ]}
          onChange={handleInputChange}
        />

        {contactDetailError && (
          <AlertForm message={t(contactDetailError)} variant="error" />
        )}

        <LoadingButton
          isLoading={isLoading}
          fullWidth
          color="primary"
          type="submit"
          variant="contained"
        >
          Guardar
        </LoadingButton>
      </ValidatorForm>
    </BaseDialog>
  );
};

ContactDetailDialog.defaultProps = {
  contactDetail: null,
};

ContactDetailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  contactDetail: PropTypes.shape({
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    contactableType: PropTypes.string.isRequired,
  }),
  type: PropTypes.string.isRequired,
};

export default ContactDetailDialog;
