import { useDispatch } from 'react-redux';

import {
  cleanupBankAccount,
  setBankAccountSourceObject,
} from '../../../../store/actions/bankAccountsActions';
import { cleanupContactDetail } from '../../../../store/actions/contactDetailActions';
import { setSupplier } from '../../../../store/actions/suppliersActions';

const useSupplierBookEntry = () => {
  const dispatch = useDispatch();

  const handleOnSelectSupplier = supplier => () => {
    dispatch(cleanupBankAccount('supplier'));
    dispatch(cleanupContactDetail('supplier'));
    dispatch(setSupplier(supplier));
    dispatch(setBankAccountSourceObject('supplier', supplier));
  };

  return {
    handleOnSelectSupplier,
  };
};

export default useSupplierBookEntry;
