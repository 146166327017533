import React from 'react';
import { Typography, Menu, Grid, IconButton, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withTheme, makeStyles } from '@material-ui/core/styles';
import MoreIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles(() => ({
  moreOptions: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  moreIcon: {
    marginTop: 2,
  },
  containerTypography: {
    height: 26,
    display: 'flex',
    alignItems: 'center',
  },
}));
const MenuMoreOptions = props => {
  const {
    type,
    label,
    anchorEl,
    options,
    handleCloseMenu,
    handleClickMenu,
    color,
  } = props;
  const classes = useStyles();
  // e.stopPropagation() es para que no se abra el collapsable en orderDetails
  return (
    <>
      <Grid item className={classes.moreOptions}>
        {label && (
          <Box className={classes.containerTypography}>
            <Typography color={color} variant="body2" component="div">
              <Box fontWeight="fontWeightBold">{label}</Box>
            </Typography>
          </Box>
        )}
        <Box>
          <IconButton
            size="small"
            color={color}
            ref={anchorEl}
            className={classes.moreIcon}
            onClick={e => {
              handleClickMenu(e, type);
              e.stopPropagation();
            }}
          >
            <MoreIcon />
          </IconButton>
        </Box>
      </Grid>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleCloseMenu()}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
      >
        {options.map(option => option)}
      </Menu>
    </>
  );
};

MenuMoreOptions.defaultProps = {
  color: 'textPrimary',
  label: '',
};

MenuMoreOptions.propTypes = {
  anchorEl: PropTypes.objectOf(Object).isRequired,
  handleCloseMenu: PropTypes.func.isRequired,
  handleClickMenu: PropTypes.func.isRequired,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(Object).isRequired,
  color: PropTypes.string,
};

export default withTheme(MenuMoreOptions);
