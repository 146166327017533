import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  ButtonBase,
  Typography,
  Box,
  makeStyles,
} from '@material-ui/core';
import { useTour } from '@reactour/tour';

const useStyles = makeStyles(theme => ({
  skip: {
    color: theme.palette.primary.light,
  },
  next: {
    borderRadius: 35,
    background: theme.palette.primary.light,
    padding: '10px 16px',
    marginLeft: 16,
  },
}));

const TourNavigation = props => {
  const { setCurrentStep, currentStep, setIsOpen } = props;
  const classes = useStyles();
  const { steps } = useTour();
  const isFinalStep = currentStep === steps.length - 1;

  return (
    <Grid container item justifyContent="space-between" alignItems="center">
      <Grid item>
        <ButtonBase className={classes.skip} onClick={() => setIsOpen(false)}>
          <Typography variant="body2" color="inherit" component="div">
            <Box fontWeight="fontWeightBold">Omitir tutorial</Box>
          </Typography>
        </ButtonBase>
      </Grid>
      <Grid item>
        {currentStep !== 0 && (
          <ButtonBase onClick={() => setCurrentStep(currentStep - 1)}>
            <Typography variant="body2" color="inherit" component="div">
              <Box fontWeight="fontWeightBold">Anterior</Box>
            </Typography>
          </ButtonBase>
        )}
        <ButtonBase
          className={classes.next}
          onClick={() => setCurrentStep(currentStep + 1)}
        >
          <Typography variant="body2" color="inherit" component="div">
            <Box fontWeight="fontWeightBold">
              {isFinalStep ? 'Finalizar' : 'Siguiente'}
            </Box>
          </Typography>
        </ButtonBase>
      </Grid>
    </Grid>
  );
};

TourNavigation.propTypes = {
  setCurrentStep: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default TourNavigation;
