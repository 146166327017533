import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  makeStyles,
  Collapse,
  Box,
  IconButton,
  Typography,
  Grid,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import Card from './elements/Card';
import { fetchDocument } from '../store/actions/documentsActions';
import UserDocumentSection from './UserDocumentSection';
import docsUnsigned from '../assets/docs-unsigned.svg';
import docsSigned from '../assets/docs-signed.svg';
import { getInvoiceDocuments } from '../store/actions/orderActions';

const ORDER = 'order';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    display: 'inline-flex',
    height: 55,
    margin: 20,
    alignItems: 'center',
  },
  icon: {
    margin: '0 15px',
  },
  title: {
    alignItems: 'center',
    display: 'flex',
  },
  expand: {
    transform: 'rotate(-90deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
  docs: {
    marginTop: -20,
  },
  checkDocs: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 90,
  },
}));

const OrderDocumentsSection = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { orderId } = props;

  const [openCollapse, setOpenCollapse] = useState(true);

  const { orderDocuments, orderDocumentsIsLoading, orderIdDocumentsOwner } =
    useSelector(state => state.documentsState);
  const {
    business: { id: businessId },
  } = useSelector(state => state.businessState);

  const { invoiceDocuments, fetchInvoiceDocumentsIsLoading } = useSelector(
    state => state.orderState
  );

  useEffect(() => {
    if (orderIdDocumentsOwner !== orderId) {
      dispatch(fetchDocument(orderId, ORDER));
      dispatch(getInvoiceDocuments(businessId, orderId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const getIcon = () => {
    const someDocUnsigned = orderDocuments.some(doc => !doc.approved);
    if (someDocUnsigned) {
      return (
        <img
          alt="Have documents unsined"
          src={docsUnsigned}
          width={52}
          height={55}
        />
      );
    }
    return (
      <Box className={classes.checkDocs}>
        <Box color="success.main" display="inline-flex">
          <CheckIcon color="inherit" />
        </Box>
        <img
          alt="Documents are signed"
          src={docsSigned}
          width={51}
          height={54}
        />
      </Box>
    );
  };

  return (
    <Card variant="white" padding="zero">
      <Box
        className={classes.titleRoot}
        onClick={() => {
          setOpenCollapse(!openCollapse);
        }}
      >
        <div>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: openCollapse,
            })}
            onClick={() => {
              setOpenCollapse(!openCollapse);
            }}
            aria-expanded={openCollapse}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
        <Box className={classes.icon}>{getIcon()}</Box>
        <Typography
          variant="subtitle1"
          color="textPrimary"
          component="div"
          className={classes.title}
        >
          <Box fontWeight="fontWeightBold">Documentos de la operación.</Box>
        </Typography>
      </Box>

      <Grid item xs={12}>
        <Collapse in={openCollapse}>
          <div className={classes.docs}>
            <UserDocumentSection
              documents={orderDocuments}
              invoiceDocuments={invoiceDocuments}
              documentsAreLoading={
                orderDocumentsIsLoading || fetchInvoiceDocumentsIsLoading
              }
              documentableType="order"
              variant="small"
            />
          </div>
        </Collapse>
      </Grid>
    </Card>
  );
};

OrderDocumentsSection.propTypes = {
  orderId: PropTypes.number.isRequired,
};

export default OrderDocumentsSection;
