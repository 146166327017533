import React, { useEffect } from 'react';
import { Grid, Icon } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import StatElement from './StatElement';
import PaymentsInboxIcon from '../../../../components/icons/PaymentsInboxIcon';
import PaymentsScheduledIcon from '../../../../components/icons/PaymentsScheduledIcon';
import PaymentsLateIcon from '../../../../components/icons/PaymentsLateIcon';
import { paymentsFetchStats } from '../../../../store/actions/paymentsStatsActions';
import PaymentsCreditLine from '../../../../components/PaymentsCreditLine';
import { statsStyles } from './styles';

const Stats = React.memo(() => {
  const { countryId, id: businessId } = useSelector(
    state => state.businessState.business
  );
  const { stats, isStatsLoading } = useSelector(
    state => state.paymentsStatsState
  );
  const classes = statsStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(paymentsFetchStats(businessId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container item className={classes.root}>
      <PaymentsCreditLine />
      <StatElement
        title="Pendientes"
        icon={<Icon component={PaymentsInboxIcon} />}
        stats={stats?.pending}
        countryId={countryId}
        isLoading={isStatsLoading}
      />
      <StatElement
        title="Programadas"
        icon={<Icon component={PaymentsScheduledIcon} />}
        stats={stats?.scheduled}
        countryId={countryId}
        isLoading={isStatsLoading}
      />
      <StatElement
        title="Atrasadas"
        icon={<Icon component={PaymentsLateIcon} />}
        stats={stats?.delayed}
        countryId={countryId}
        isLoading={isStatsLoading}
      />
    </Grid>
  );
});

export default Stats;
