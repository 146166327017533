/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import {
  AVAILABLE_COUNTRIES,
  COUNTRY_CODE_CL,
  COUNTRY_CODE_MX,
} from '../../helpers/constants';
import FlagMx from '../../assets/FlagMx.svg';
import FlagCl from '../../assets/FlagCl.svg';

const useStyles = makeStyles({
  flag: {
    width: 30,
    height: 30,
    margin: '0 5px',
    cursor: 'pointer',
  },
  inactiveFlag: {
    width: 30,
    height: 30,
    margin: '0 5px',
    cursor: 'pointer',
    filter: 'opacity(20%)',
  },
});

const FlagsRedirect = ({ country, path }) => {
  const classes = useStyles();

  const flagClass = countryId => {
    return countryId === country ? classes.flag : classes.inactiveFlag;
  };

  const redirectTo = countryId => {
    if (country !== countryId) {
      window.location.href = `${process.env.REACT_APP_OTHER_CLIENT}${path}`;
    }
  };

  return (
    <>
      <img
        src={FlagMx}
        className={flagClass(COUNTRY_CODE_MX)}
        onClick={() => redirectTo(COUNTRY_CODE_MX)}
        alt="Mexico flag"
      />

      <img
        src={FlagCl}
        className={flagClass(COUNTRY_CODE_CL)}
        onClick={() => redirectTo(COUNTRY_CODE_CL)}
        alt="Chile flag"
      />
    </>
  );
};

FlagsRedirect.defaultProps = {
  path: '',
};

FlagsRedirect.propTypes = {
  country: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  path: PropTypes.string,
};

export default FlagsRedirect;
