import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const FacebookIcon = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        id="Trazado_3932"
        data-name="Trazado 3932"
        d="M16.41,13.11l.52-3.43H13.65V7.46A1.71,1.71,0,0,1,15.2,5.6a1.61,1.61,0,0,1,.38,0h1.49V2.69a18.11,18.11,0,0,0-2.65-.23c-2.71,0-4.48,1.64-4.48,4.61V9.68h-3v3.43h3V21.4a12.15,12.15,0,0,0,3.71,0V13.11Z"
      />
    </SvgIcon>
  );
};

export default FacebookIcon;
