/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link as LinkRouter } from 'react-router-dom';
import {
  Grid,
  makeStyles,
  Typography,
  InputAdornment,
  Box,
  Link,
} from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import AlertForm from './elements/AlertForm';
import LoadingButton from './elements/LoadingButton';
import * as colors from '../theme/otherColors';
import { forgotPassword } from '../store/actions/authenticationActions';

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
  title: {
    color: colors.HOME_START_TEXT_PRIMARY,
    marginTop: 160,
  },
  separatorPurple: {
    width: 56,
    height: 4,
    margin: '15px 0 30px 0',
    background: colors.HOME_START_MAIN_PURPLE,
    borderRadius: 3,
  },
  form: {
    marginTop: 40,
  },
  buttonLogin: {
    fontSize: 14,
    height: 40,
  },
  bodyText: {
    marginBottom: 20,
  },
  link: {
    display: 'block',
    marginTop: 10,
  },
});

const ForgotPassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const forgotPasswordIsLoading = useSelector(
    state => state.authState.forgotPasswordIsLoading
  );
  const forgotPasswordError = useSelector(
    state => state.authState.forgotPasswordError
  );

  const loginSubmit = () => {
    dispatch(forgotPassword(email));
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item xs={12} align="center">
        <Typography
          variant="h4"
          component="div"
          color="textPrimary"
          className={classes.title}
        >
          <Box fontWeight="fontWeightBold">Recuperar contraseña</Box>
        </Typography>
        <div className={classes.separatorPurple} />

        <Grid item className={classes.form}>
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.bodyText}
          >
            Te enviaremos un email con instrucciones sobre cómo restablecer tu
            contraseña.
          </Typography>
          <ValidatorForm onSubmit={loginSubmit}>
            <TextValidator
              fullWidth
              id="email"
              name="email"
              type="text"
              variant="outlined"
              value={email}
              onChange={e => setEmail(e.target.value)}
              validators={['required', 'isEmail']}
              errorMessages={['Campo requerido', 'Email inválido']}
              placeholder="Email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlineIcon />
                  </InputAdornment>
                ),
              }}
            />

            {!!forgotPasswordError && (
              <AlertForm message={t(forgotPasswordError)} variant="error" />
            )}

            <LoadingButton
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              fullWidth
              isLoading={forgotPasswordIsLoading}
              className={classes.buttonLogin}
            >
              Enviar email
            </LoadingButton>
          </ValidatorForm>
          <Link
            variant="body1"
            color="secondary"
            to="/start/login"
            component={LinkRouter}
            className={classes.link}
          >
            <Box fontWeight="fontWeightBold">Iniciar sesión</Box>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
