import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';

const country = process.env.REACT_APP_COUNTRY_ID;

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: country,
    fallbackLng: {
      CL: ['es'],
      MX: ['es'],
    },
    debug: false,
    backend: {
      // loadPath: 'http://localhost:3001/api/locales/{{lng}}/translations',
      loadPath: '/locales/{{lng}}/translations.json',
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
