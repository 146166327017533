import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import StepperFlow from './StepperFlow';
import StepperItem from './StepperItem';

const StepperContainer = ({ steps, stepCompleted, ...props }) => {
  const [state, setState] = useState({
    payrollPaymentDate: null,
    payrollName: null,
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [childs, setChilds] = useState(null);

  const updateState = newData => {
    setState({ ...state, ...newData });
  };

  const isStepsDone = () => currentStep === childs.length - 1;

  const goNext = () => {
    if (isStepsDone()) return;
    setCurrentStep(prev => prev + 1);
  };

  const goBack = () => {
    if (isStepsDone()) return;
    setCurrentStep(prev => prev - 1);
  };

  const getCurrentChild = () => {
    const { component, customButtons, nextButtonText } = childs[currentStep];
    if (customButtons) return component;
    return (
      <StepperItem nextButtonText={nextButtonText}>{component}</StepperItem>
    );
  };

  const getLastChild = () => {
    const { component } = childs[childs.length - 1];
    return component;
  };

  const injectedProps = {
    state,
    updateState,
    isStepsDone,
    goNext,
    goBack,
    getCurrentChild,
    getLastChild,
    currentStep,
    childs,
    ...props,
  };

  useEffect(() => {
    if (steps && stepCompleted) {
      setChilds([...steps, stepCompleted]);
    }
  }, [steps, stepCompleted]);

  if (childs === null) return null;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <StepperFlow {...injectedProps} />;
};

StepperContainer.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.element.isRequired,
    })
  ).isRequired,
  stepCompleted: PropTypes.shape({
    component: PropTypes.element.isRequired,
  }).isRequired,
};

export default StepperContainer;
