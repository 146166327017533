import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Box, Button } from '@material-ui/core';
import clsx from 'clsx';
import Card from '../../../../components/elements/Card';
import CountryFormatHelper from '../../../../components/elements/CountryFormatHelper';
import { AVAILABLE_COUNTRIES } from '../../../../helpers/constants';
import EmptyStatElement from './EmptyStatElement';
import { statElementStyles } from './styles';

const FILTER_BUTTON_TEXT = {
  all: 'Todas',
};

const StatElement = React.memo(props => {
  const { title, icon, stats, countryId, isLoading } = props;
  const [daysToFilter, setDaysToFilter] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const classes = statElementStyles();

  useEffect(() => {
    if (stats) {
      const filters = Object.keys(stats);
      setDaysToFilter(filters);
      setSelectedFilter(filters[0]);
    }
  }, [stats]);

  const handleFilter = (event, filter) => {
    event.preventDefault();
    setSelectedFilter(filter);
  };

  const isValidNumber = number => !Number.isNaN(Number(number));

  const translateKeyToButtonText = key => {
    const wordToRemove = ['days', 'day'].join('|');
    let translatedKey = key.replace(new RegExp(`(${wordToRemove})`, 'g'), '');
    if (!isValidNumber(translatedKey)) {
      translatedKey = FILTER_BUTTON_TEXT[translatedKey];
    } else {
      translatedKey = Number(translatedKey);
    }
    return translatedKey;
  };

  return (
    <Grid item>
      <Card variant="white" border className={classes.card}>
        {stats && daysToFilter && !isLoading ? (
          <Box>
            <Grid item direction="column" container>
              <Grid item>
                <Typography
                  variant="body2"
                  className={classes.title}
                  component="div"
                >
                  <Box fontWeight="fontWeightMedium">{title}</Box>
                </Typography>
              </Grid>
              <Grid item>
                <Grid item container className={classes.filterContainer}>
                  {daysToFilter.map(filter => (
                    <Grid key={filter} item>
                      <Button
                        key={filter}
                        variant="text"
                        color="inherit"
                        className={clsx(classes.filterButton, {
                          active: filter === selectedFilter,
                        })}
                        onClick={event => handleFilter(event, filter)}
                      >
                        <Box
                          fontWeight="fontWeightBold"
                          fontSize="body2.fontSize"
                        >
                          {translateKeyToButtonText(filter)}&nbsp;
                          {isValidNumber(translateKeyToButtonText(filter)) &&
                            'días'}
                        </Box>
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container alignItems="center">
              <Grid item>
                <Box className={classes.icon}>{icon}</Box>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  className={classes.price}
                  component="div"
                >
                  <Box fontWeight="fontWeightMedium">
                    <CountryFormatHelper
                      value={stats[selectedFilter]}
                      countryId={countryId}
                      variant="currency"
                    />
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <EmptyStatElement />
        )}
      </Card>
    </Grid>
  );
});

StatElement.defaultProps = {
  stats: null,
};

StatElement.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  stats: PropTypes.objectOf(PropTypes.any),
  isLoading: PropTypes.bool.isRequired,
};

export default StatElement;
