import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import OnboardPageFillProfile from '../../../components/OnboardPageFillProfile';
import Loader from '../../../components/elements/Loader';
import Stats from './Stats/Stats';
import { checkTaxServiceCredential } from '../../../store/actions/taxServiceActions';
import { fetchOnboard } from '../../../store/actions/onboardActions';
import OnboardPageScheduleInvoices from '../../../components/OnboardPageScheduleInvoices';
import { GuidedTour } from '../../../components/GuidedTour';
import { homeStyles } from './styles';
import { TOUR_NAME, TOUR_STEPS } from './tour';
import HomeTabs from './HomeTabs';
import { ArchivedTable, InboxTable, PaidTable } from '../../invoices/ui';
import { PAYMENTS_TABS } from '../../../helpers/constants';
import ScheduledPayrollCards from '../../ScheduledPayrollCards/ScheduledPayrollCards';

const INVOICE_TABLES = {
  [PAYMENTS_TABS.INBOX]: (countryId, businessId) => (
    <InboxTable countryId={countryId} businessId={businessId} />
  ),
  [PAYMENTS_TABS.SCHEDULED]: (countryId, businessId) => (
    <ScheduledPayrollCards countryId={countryId} businessId={businessId} />
  ),
  [PAYMENTS_TABS.PAID]: (countryId, businessId) => (
    <PaidTable businessId={businessId} />
  ),
  [PAYMENTS_TABS.ARCHIVED]: (countryId, businessId) => (
    <ArchivedTable businessId={businessId} />
  ),
};

const HomePayments = React.memo(() => {
  const classes = homeStyles();
  const dispatch = useDispatch();

  const { activeTab } = useSelector(state => state.paymentsState);
  const { countryId, id: businessId } = useSelector(
    state => state.businessState.business
  );
  const { hasCredential, isCredentialActive } = useSelector(
    state => state.taxServiceState.taxStatus
  );
  const { checkTaxServiceCredentialIsLoading } = useSelector(
    state => state.taxServiceState
  );
  const { isOnboarded, fetchOnboardLoading } = useSelector(
    state => state.onboardState
  );

  useEffect(() => {
    if (countryId) {
      dispatch(checkTaxServiceCredential(businessId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isOnboarded && businessId) {
      dispatch(fetchOnboard(businessId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnboarded, businessId]);

  if (hasCredential === false || isCredentialActive === false) {
    return <OnboardPageFillProfile />;
  }

  if (checkTaxServiceCredentialIsLoading || fetchOnboardLoading) {
    return (
      <Grid container className={classes.root}>
        <Grid item>
          <Loader />
        </Grid>
      </Grid>
    );
  }

  if (!isOnboarded) {
    return <OnboardPageScheduleInvoices />;
  }

  return (
    <Grid container className={classes.root}>
      <GuidedTour name={TOUR_NAME} steps={TOUR_STEPS}>
        <HomeTabs activeTab={activeTab} />
        <Stats />
        <Grid container item className={classes.invoicesWrapper}>
          {INVOICE_TABLES[activeTab](countryId, businessId)}
        </Grid>
      </GuidedTour>
    </Grid>
  );
});

export default HomePayments;
