import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const ALL_ORANGE = 'all-orange';
const ALL_WHITE = 'all-white';
const WHITE_LINED = 'white-lined';
const SIMPLE_GRAY = 'simple-gray';
const ORANGE_LINED = 'orange-lined';
const SUCCESS = 'success';

const useStyles = makeStyles(theme => ({
  root: ({ variant }) => {
    switch (variant) {
      case ALL_ORANGE:
        return {
          background: theme.palette.secondary.main,
          color: 'white',
          border: `1px solid ${theme.palette.secondary.main}`,
          borderRadius: '30px',
          '& span': {
            paddingRight: 34,
            paddingLeft: 34,
          },
          '&:hover': {
            background: theme.palette.secondary.main,
            color: 'white',
          },
          width: 'auto',
        };
      case ALL_WHITE:
        return {
          border: `solid 1px #FFF`,
          fontSize: 13,
          minWidth: 139,
          height: 30,
          padding: '7px 22px 9px 22px',
          background: '#FFF',
          color: theme.palette.primary.main,
          '&:hover': {
            background: '#FFF',
            color: theme.palette.primary.main,
          },
        };
      case WHITE_LINED:
        return {
          border: `solid 1px #FFF`,
          fontSize: 13,
          minWidth: 139,
          height: 30,
          padding: '7px 22px 9px 22px',
          color: theme.palette.primary.contrastText,
          '&:hover': {
            background: '#FFF',
            color: theme.palette.primary.main,
          },
        };
      case ORANGE_LINED:
        return {
          background: 'white',
          border: `1px solid ${theme.palette.secondary.main}`,
          borderRadius: '30px',
          color: theme.palette.secondary.main,
          textTransform: 'capitalize',
          '&:hover': {
            background: theme.palette.secondary.main,
            color: 'white',
          },
          '& span': {
            paddingRight: 34,
            paddingLeft: 34,
          },
          '&:disabled': {
            borderColor: theme.palette.grey[500],
          },
          width: 'auto',
        };
      case SUCCESS:
        return {
          background: theme.palette.success.main,
          borderRadius: '30px',
          color: '#fff',
          '&:hover': {
            background: theme.palette.success.main,
            cursor: 'auto',
          },
          '& .MuiButton-label': {
            paddingRight: 34,
            paddingLeft: 34,
          },
          '& .MuiButton-startIcon': {
            paddingRight: 0,
            paddingLeft: 0,
          },
          width: 'auto',
        };
      case SIMPLE_GRAY:
        return {
          marginLeft: 8,
          fontSize: 15,
          textTransform: 'capitalize',
          display: 'flex',
          color: theme.palette.grey[600],
          '&:hover': {
            background: 'transparent',
            textDecoration: 'underline',
            cursor: 'pointer',
          },
        };
      default:
        return '';
    }
  },
}));

const CustomButton = props => {
  const classes = useStyles(props);
  const { children, variant, icon, className, ...other } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button className={clsx(classes.root, className)} {...other}>
      {icon && icon} {children}
    </Button>
  );
};

CustomButton.defaultProps = {
  icon: null,
  className: '',
};

CustomButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  variant: PropTypes.oneOf([
    WHITE_LINED,
    ORANGE_LINED,
    SIMPLE_GRAY,
    SUCCESS,
    ALL_WHITE,
    ALL_ORANGE,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.element,
  className: PropTypes.string,
};

export default CustomButton;
