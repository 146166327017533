import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const WarningIcon = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <circle opacity="0.2" cx="12" cy="12" r="12" fill="#F87348" />
      <path
        d="M17.3136 14.8514L12.9847 6.87806C12.8701 6.66749 12.7009 6.49172 12.4948 6.36924C12.2887 6.24677 12.0534 6.18213 11.8136 6.18213C11.5739 6.18213 11.3386 6.24677 11.1325 6.36924C10.9264 6.49172 10.7571 6.66749 10.6425 6.87806L6.30919 14.8514C6.19577 15.055 6.13769 15.2847 6.14074 15.5178C6.14379 15.7508 6.20786 15.9789 6.32657 16.1795C6.44528 16.38 6.61448 16.546 6.8173 16.6607C7.02012 16.7755 7.24948 16.8351 7.48252 16.8336H16.1403C16.3714 16.8338 16.5986 16.774 16.7997 16.6599C17.0007 16.5458 17.1686 16.3814 17.2869 16.1829C17.4052 15.9843 17.4699 15.7584 17.4746 15.5273C17.4793 15.2962 17.4238 15.0679 17.3136 14.8647V14.8514ZM11.1492 9.16251C11.1492 8.9857 11.2194 8.81613 11.3445 8.6911C11.4695 8.56608 11.639 8.49584 11.8159 8.49584C11.9927 8.49584 12.1622 8.56608 12.2873 8.6911C12.4123 8.81613 12.4825 8.9857 12.4825 9.16251V12.2247C12.4825 12.4015 12.4123 12.5711 12.2873 12.6961C12.1622 12.8212 11.9927 12.8914 11.8159 12.8914C11.639 12.8914 11.4695 12.8212 11.3445 12.6961C11.2194 12.5711 11.1492 12.4015 11.1492 12.2247V9.16251ZM11.8336 15.167C11.6824 15.167 11.5346 15.1221 11.4089 15.0381C11.2832 14.9541 11.1852 14.8347 11.1274 14.695C11.0695 14.5554 11.0544 14.4017 11.0839 14.2534C11.1134 14.1051 11.1862 13.9689 11.2931 13.862C11.4 13.7551 11.5362 13.6822 11.6845 13.6528C11.8328 13.6233 11.9865 13.6384 12.1262 13.6963C12.2659 13.7541 12.3852 13.8521 12.4692 13.9778C12.5532 14.1035 12.5981 14.2513 12.5981 14.4025C12.5981 14.6053 12.5175 14.7997 12.3742 14.9431C12.2308 15.0864 12.0364 15.167 11.8336 15.167Z"
        fill="#F87348"
      />
    </SvgIcon>
  );
};
export default WarningIcon;
