import React, { useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Topbar from '../Topbar';
import { logoutUser } from '../../store/actions/authenticationActions';

const useStyles = makeStyles({
  logged: {
    marginTop: 56,
  },
  notLogged: {
    marginTop: 76,
  },
});

const AppLayout = props => {
  const { children, isMaintenance } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const isAuth = useSelector(state => state.authState.isAuthenticated);
  const { id } = useSelector(state => state.authState.business);

  useEffect(() => {
    if (isMaintenance) {
      dispatch(logoutUser());
    }
  }, [isMaintenance, dispatch]);

  return (
    <Grid container direction="column">
      <Grid item>
        <Topbar isAuth={isAuth} businessId={id} />
      </Grid>
      <Grid item className={isAuth && id ? classes.logged : classes.notLogged}>
        {children}
      </Grid>
    </Grid>
  );
};

AppLayout.defaultProps = {
  isMaintenance: false,
};

AppLayout.propTypes = {
  children: PropTypes.element.isRequired,
  isMaintenance: PropTypes.bool,
};

export default AppLayout;
