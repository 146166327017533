import {
  PAYMENTS_SET_ACTIVE_TAB,
  PAYMENTS_HANDLE_INVOICES_SELECTION_CHANGE,
  PAYMENTS_CLEAR_INVOICES_SELECTION_CHANGE,
  PAYMENTS_SET_PAGINATION,
  FETCH_PAYMENTS_INVOICES_START,
  FETCH_PAYMENTS_INVOICES_SUCCESS,
  FETCH_PAYMENTS_INVOICES_ERROR,
  ARCHIVE_PAYMENTS_INVOICES_START,
  ARCHIVE_PAYMENTS_INVOICES_SUCCESS,
  ARCHIVE_PAYMENTS_INVOICES_ERROR,
  PAY_PAYMENTS_INVOICES_START,
  PAY_PAYMENTS_INVOICES_SUCCESS,
  PAY_PAYMENTS_INVOICES_ERROR,
  RESTORE_PAYMENTS_INVOICES_START,
  RESTORE_PAYMENTS_INVOICES_SUCCESS,
  RESTORE_PAYMENTS_INVOICES_ERROR,
  CHANGE_PAYMENT_DATE_PAYMENTS_START,
  CHANGE_PAYMENT_DATE_PAYMENTS_ERROR,
  SAVE_PAYROLL_START,
  SAVE_PAYROLL_SUCCESS,
  SAVE_PAYROLL_FAILURE,
  REQUEST_VOUCHER_INVOICE_START,
  REQUEST_VOUCHER_INVOICE_SUCCESS,
  REQUEST_VOUCHER_INVOICE_FAILURE,
} from '../actions/types';
import { PAYMENTS_TABS } from '../../helpers/constants';

import { t13s } from '../../translationKeys';

const DEFAULT_PAGINATION = { totalCount: 0, pageSize: 20, page: 1 };

const initialState = {
  activeTab: PAYMENTS_TABS.INBOX,
  selectedInvoices: [],
  totalSelectedInvoices: 0,
  invoices: [],
  pagination: DEFAULT_PAGINATION,

  archiveInvoicesError: null,
  restoreInvoicesError: null,
  changePaymentDateError: null,

  invoicesAreLoading: false,
  fetchInvoicesError: null,

  savePayrollLoading: false,
  savePayrollError: null,

  requestVoucherInvoiceLoading: false,
  requestVoucherInvoiceError: null,
};

const setActiveTab = (state, payload) => {
  const invoices = [];
  const selectedInvoices = [];
  const totalSelectedInvoices = 0;
  const pagination = {
    ...DEFAULT_PAGINATION,
    pageSize: state.pagination.pageSize,
  };

  return {
    ...state,
    activeTab: payload,
    selectedInvoices,
    totalSelectedInvoices,
    pagination,
    invoices,
  };
};

const paymentsHandleInvoicesSelectionChange = (state, payload) => {
  const { checkedInvoices, nonCheckedInvoices } = payload;

  const selectedIds = state.selectedInvoices.map(invoice => invoice.id);
  const nonCheckedIds = nonCheckedInvoices.map(invoice => invoice.id);

  const selectedInvoices = [
    ...state.selectedInvoices.filter(
      invoice => !nonCheckedIds.includes(invoice.id)
    ),
    ...checkedInvoices.filter(invoice => !selectedIds.includes(invoice.id)),
  ];

  const totalSelectedInvoices = selectedInvoices.reduce(
    (acc, invoice) => acc + Number(invoice.amount),
    0
  );

  return {
    ...state,
    selectedInvoices,
    totalSelectedInvoices,
  };
};

const paymentsClearInvoicesSelectionChange = state => {
  const invoices = [];
  const selectedInvoices = [];
  const totalSelectedInvoices = 0;
  const pagination = DEFAULT_PAGINATION;

  return {
    ...state,
    selectedInvoices,
    totalSelectedInvoices,
    pagination,
    invoices,
  };
};

const paymentsSetPagination = (state, payload) => {
  const pagination = { ...payload };

  return {
    ...state,
    pagination,
  };
};

const fetchPaymentsInvoicesStart = state => {
  return {
    ...state,
    fetchInvoicesError: null,
    invoicesAreLoading: true,
  };
};

const fetchPaymentsInvoicesSuccess = (state, payload) => {
  return {
    ...state,
    invoicesAreLoading: false,
    fetchInvoicesError: null,
    ...payload,
  };
};

const fetchPaymentsInvoicesError = (state, payload) => {
  return {
    ...state,
    invoices: [],
    invoicesAreLoading: false,
    fetchInvoicesError: payload.error,
  };
};

const archivePaymentsInvoicesStart = state => {
  return {
    ...state,
    invoicesAreLoading: true,
    archiveInvoicesError: null,
  };
};

const archivePaymentsInvoicesSuccess = state => {
  return {
    ...state,
    invoicesAreLoading: false,
    archiveInvoicesError: null,
    selectedInvoices: [],
  };
};

const archivePaymentsInvoicesError = (state, payload) => {
  return {
    ...state,
    invoicesAreLoading: false,
    archiveInvoicesError: payload.error,
  };
};

const payPaymentsInvoicesStart = state => {
  return {
    ...state,
    isStatsLoading: true,
    payInvoicesError: null,
  };
};

const payPaymentsInvoicesSuccess = state => {
  return {
    ...state,
    invoicesAreLoading: false,
    selectedInvoices: [],
  };
};

const payPaymentsInvoicesError = (state, payload) => {
  return {
    ...state,
    invoicesAreLoading: false,
    payInvoicesError: payload.error,
  };
};

const restorePaymentsInvoicesStart = state => {
  return {
    ...state,
    restoreInvoicesError: null,
    invoicesAreLoading: true,
  };
};

const restorePaymentsInvoicesSuccess = state => {
  return {
    ...state,
    invoicesAreLoading: false,
  };
};

const restorePaymentsInvoicesError = (state, payload) => {
  return {
    ...state,
    invoicesAreLoading: false,
    restoreInvoicesError: payload.error,
  };
};

const changePaymmentDatePaymentsStart = state => {
  return {
    ...state,
    changePaymentDateError: null,
    invoicesAreLoading: true,
  };
};

const changePaymmentDatePaymentsError = (state, payload) => {
  return {
    ...state,
    invoicesAreLoading: false,
    changePaymentDateError: payload.error,
  };
};

const savePayrollStart = state => {
  return {
    ...state,
    savePayrollError: null,
    savePayrollLoading: true,
  };
};

const savePayrollSuccess = (state, payload) => {
  return {
    ...state,
    selectedInvoices: [],
    savePayrollLoading: false,
    savePayrollError: null,
    ...payload,
  };
};

const savePayrollFailure = (state, payload) => {
  return {
    ...state,
    invoices: [],
    savePayrollLoading: false,
    savePayrollError: payload.error,
  };
};

const requestVoucherInvoiceStart = state => {
  return {
    ...state,
    requestVoucherInvoiceLoading: true,
    requestVoucherInvoiceError: null,
  };
};

const requestVoucherInvoiceSuccess = state => {
  return {
    ...state,
    requestVoucherInvoiceLoading: false,
  };
};

const requestVoucherInvoiceFailure = (state, payload) => {
  const { errorCode } = payload;
  return {
    ...state,
    requestVoucherInvoiceLoading: false,
    requestVoucherInvoiceError: errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SAVE_PAYROLL_START:
      return requestVoucherInvoiceStart(state);
    case SAVE_PAYROLL_SUCCESS:
      return requestVoucherInvoiceSuccess(state);
    case SAVE_PAYROLL_FAILURE:
      return requestVoucherInvoiceFailure(state, payload);

    case REQUEST_VOUCHER_INVOICE_START:
      return savePayrollStart(state);
    case REQUEST_VOUCHER_INVOICE_SUCCESS:
      return savePayrollSuccess(state, payload);
    case REQUEST_VOUCHER_INVOICE_FAILURE:
      return savePayrollFailure(state, payload);

    case CHANGE_PAYMENT_DATE_PAYMENTS_START:
      return changePaymmentDatePaymentsStart(state);
    case CHANGE_PAYMENT_DATE_PAYMENTS_ERROR:
      return changePaymmentDatePaymentsError(state, payload);

    case RESTORE_PAYMENTS_INVOICES_START:
      return restorePaymentsInvoicesStart(state);
    case RESTORE_PAYMENTS_INVOICES_SUCCESS:
      return restorePaymentsInvoicesSuccess(state);
    case RESTORE_PAYMENTS_INVOICES_ERROR:
      return restorePaymentsInvoicesError(state, payload);

    case PAY_PAYMENTS_INVOICES_START:
      return payPaymentsInvoicesStart(state);
    case PAY_PAYMENTS_INVOICES_SUCCESS:
      return payPaymentsInvoicesSuccess(state, payload);
    case PAY_PAYMENTS_INVOICES_ERROR:
      return payPaymentsInvoicesError(state, payload);

    case ARCHIVE_PAYMENTS_INVOICES_START:
      return archivePaymentsInvoicesStart(state);
    case ARCHIVE_PAYMENTS_INVOICES_SUCCESS:
      return archivePaymentsInvoicesSuccess(state, payload);
    case ARCHIVE_PAYMENTS_INVOICES_ERROR:
      return archivePaymentsInvoicesError(state, payload);

    case FETCH_PAYMENTS_INVOICES_START:
      return fetchPaymentsInvoicesStart(state);
    case FETCH_PAYMENTS_INVOICES_SUCCESS:
      return fetchPaymentsInvoicesSuccess(state, payload);
    case FETCH_PAYMENTS_INVOICES_ERROR:
      return fetchPaymentsInvoicesError(state, payload);

    case PAYMENTS_SET_ACTIVE_TAB:
      return setActiveTab(state, payload);

    case PAYMENTS_HANDLE_INVOICES_SELECTION_CHANGE:
      return paymentsHandleInvoicesSelectionChange(state, payload);

    case PAYMENTS_CLEAR_INVOICES_SELECTION_CHANGE:
      return paymentsClearInvoicesSelectionChange(state);

    case PAYMENTS_SET_PAGINATION:
      return paymentsSetPagination(state, payload);
    default:
      return state;
  }
};
