import { makeStyles } from '@material-ui/core';

const archivedTableStyles = makeStyles(() => ({
  card: {
    width: '97.5%',
    padding: '1rem',
  },
  tableInvoicesContainer: {
    alignContent: 'flex-start',
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
    height: '100%',
    paddingBottom: '4rem',
  },
}));

// eslint-disable-next-line import/prefer-default-export
export { archivedTableStyles };
