import { PURGE } from 'redux-persist';
import {
  CREATE_PAYROLL_FAILURE,
  CREATE_PAYROLL_START,
  CREATE_PAYROLL_SUCCESS,
  FETCH_PAYROLLS_FAILURE,
  FETCH_PAYROLLS_START,
  FETCH_PAYROLLS_SUCCESS,
  FETCH_PAYROLL_FAILURE,
  FETCH_PAYROLL_START,
  FETCH_PAYROLL_SUCCESS,
  DELETE_PAYROLL_FAILURE,
  DELETE_PAYROLL_START,
  DELETE_PAYROLL_SUCCESS,
  LOCAL_PURGE,
  RESET_PAYROLLS,
  UPDATE_PAYROLL_FAILURE,
  UPDATE_PAYROLL_START,
  UPDATE_PAYROLL_SUCCESS,
  PAY_PAYROLL_START,
  PAY_PAYROLL_SUCCESS,
  PAY_PAYROLL_FAILURE,
  FINANCING_PAYROLL_START,
  FINANCING_PAYROLL_SUCCESS,
  FINANCING_PAYROLL_FAILURE,
  RESET_FINANCING,
} from '../actions/types';

import { t13s } from '../../translationKeys';

const initialState = {
  payrolls: [],
  pagination: { totalData: 0, pageSize: 20, page: 1 },
  payroll: null,

  fetchingPayrolls: false,
  fetchPayrollsError: null,

  fetchingPayroll: false,
  fetchPayrollError: null,

  creatingPayroll: false,
  payrollCreated: false,
  createPayrollError: null,

  updatingPayroll: false,
  payrollUpdated: false,
  updatePayrollError: null,

  deletePayrollError: null,
  deletingPayroll: false,

  payrollPaid: false,
  payingPayroll: false,
  payPayrollError: null,

  payrollFinanced: false,
  financingPayroll: false,
  financingPayrollError: null,
};

const getPayrolls = ({ statePayrolls, payrollId, newStatus }) => {
  return statePayrolls.map(payroll => {
    if (payroll.id !== payrollId) return payroll;
    return {
      ...payroll,
      status: newStatus,
    };
  });
};

/* ACTIONS */

const fetchPayrollsStart = state => {
  return {
    ...state,
    fetchPayrollsError: null,
    fetchingPayrolls: true,
  };
};

const fetchPayrollsSuccess = (state, payload) => {
  return {
    ...state,
    fetchingPayrolls: false,
    ...payload,
  };
};

const fetchPayrollsFailure = (state, payload) => {
  const { errorCode } = payload;
  return {
    ...state,
    fetchingPayrolls: false,
    fetchPayrollsError: errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const fetchPayrollStart = state => {
  return {
    ...state,
    fetchPayrollError: null,
    fetchingPayroll: true,
  };
};

const fetchPayrollSuccess = (state, payload) => {
  return {
    ...state,
    fetchingPayroll: false,
    payroll: { ...payload },
  };
};

const fetchPayrollFailure = (state, payload) => {
  const { errorCode } = payload;
  return {
    ...state,
    fetchingPayroll: false,
    fetchPayrollError: errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const createPayrollStart = state => {
  return {
    ...state,
    payrollCreated: false,
    createPayrollError: null,
    creatingPayroll: true,
  };
};

const createPayrollSuccess = (state, payload) => {
  const { payrolls: statePayrolls } = state;
  return {
    ...state,
    payrollCreated: true,
    creatingPayroll: false,
    payrolls: [...statePayrolls, ...payload],
  };
};

const createPayrollFailure = (state, payload) => {
  return {
    ...state,
    creatingPayroll: false,
    createPayrollError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const updatePayrollStart = state => {
  return {
    ...state,
    payrollUpdated: false,
    updatePayrollError: null,
    updatingPayroll: true,
  };
};

const updatePayrollSuccess = (state, payload) => {
  const { payrolls: statePayrolls } = state;

  const payrolls = statePayrolls.filter(payroll => payroll.id !== payload.id);

  payrolls.push(payload);

  return {
    ...state,
    payrollUpdated: true,
    updatingPayroll: false,
    payrolls,
  };
};

const updatePayrollFailure = (state, payload) => {
  return {
    ...state,
    updatingPayroll: false,
    updatePayrollError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const deletePayrollStart = state => {
  return {
    ...state,
    deletingPayroll: true,
    deletePayrollError: null,
  };
};

const deletePayrollSuccess = (state, payload) => {
  const { payrollId } = payload;
  const { payrolls: statePayrolls } = state;

  const updatedPayrolls = statePayrolls.filter(
    payroll => payroll.id !== payrollId
  );
  return {
    ...state,
    deletingPayroll: false,
    deletePayrollError: null,
    payrolls: updatedPayrolls,
  };
};

const deletePayrollFailure = (state, payload) => {
  return {
    ...state,
    deletingPayroll: false,
    deletePayrollError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const payrollReset = state => {
  return {
    ...state,
    creatingPayroll: false,
    payrollCreated: false,
    createPayrollError: null,
    updatingPayroll: false,
    payrollUpdated: false,
    updatePayrollError: null,
    payrollPaid: false,
    payPayrollError: null,
    payingPayroll: false,
  };
};

const payPayrollStart = state => {
  return {
    ...state,
    payrollPaid: false,
    payPayrollError: null,
    payingPayroll: true,
  };
};

const payPayrollSuccess = (state, payload) => {
  const { id: payrollId, status: newStatus } = payload;
  const { payrolls: statePayrolls } = state;

  const payrolls = getPayrolls({ statePayrolls, payrollId, newStatus });

  return {
    ...state,
    payrollPaid: true,
    payingPayroll: false,
    payrolls,
  };
};

const payPayrollFailure = (state, payload) => {
  return {
    ...state,
    payingPayroll: false,
    payPayrollError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const financingPayrollStart = state => {
  return {
    ...state,
    payrollFinanced: false,
    financingPayrollError: null,
    financingPayroll: true,
  };
};

const financingPayrollSuccess = (state, payload) => {
  const { id: payrollId, status: newStatus } = payload;
  const { payrolls: statePayrolls } = state;

  const payrolls = getPayrolls({ statePayrolls, payrollId, newStatus });

  return {
    ...state,
    payrollFinanced: true,
    financingPayroll: false,
    payrolls,
  };
};

const financingPayrollFailure = (state, payload) => {
  return {
    ...state,
    financingPayroll: false,
    financingPayrollError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const financingReset = state => {
  return {
    ...state,
    payrollFinanced: false,
    financingPayroll: false,
    financingPayrollError: null,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PAYROLLS_START:
      return fetchPayrollsStart(state);
    case FETCH_PAYROLLS_SUCCESS:
      return fetchPayrollsSuccess(state, payload);
    case FETCH_PAYROLLS_FAILURE:
      return fetchPayrollsFailure(state, payload);
    case FETCH_PAYROLL_START:
      return fetchPayrollStart(state);
    case FETCH_PAYROLL_SUCCESS:
      return fetchPayrollSuccess(state, payload);
    case FETCH_PAYROLL_FAILURE:
      return fetchPayrollFailure(state, payload);
    case CREATE_PAYROLL_START:
      return createPayrollStart(state);
    case CREATE_PAYROLL_SUCCESS:
      return createPayrollSuccess(state, payload);
    case CREATE_PAYROLL_FAILURE:
      return createPayrollFailure(state, payload);
    case UPDATE_PAYROLL_START:
      return updatePayrollStart(state);
    case UPDATE_PAYROLL_SUCCESS:
      return updatePayrollSuccess(state, payload);
    case UPDATE_PAYROLL_FAILURE:
      return updatePayrollFailure(state, payload);
    case DELETE_PAYROLL_START:
      return deletePayrollStart(state);
    case DELETE_PAYROLL_SUCCESS:
      return deletePayrollSuccess(state, payload);
    case DELETE_PAYROLL_FAILURE:
      return deletePayrollFailure(state, payload);
    case RESET_PAYROLLS:
      return payrollReset(state);
    case PAY_PAYROLL_START:
      return payPayrollStart(state);
    case PAY_PAYROLL_SUCCESS:
      return payPayrollSuccess(state, payload);
    case PAY_PAYROLL_FAILURE:
      return payPayrollFailure(state, payload);
    case FINANCING_PAYROLL_START:
      return financingPayrollStart(state);
    case FINANCING_PAYROLL_SUCCESS:
      return financingPayrollSuccess(state, payload);
    case FINANCING_PAYROLL_FAILURE:
      return financingPayrollFailure(state, payload);
    case RESET_FINANCING:
      return financingReset(state);

    case LOCAL_PURGE:
      return initialState;
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
