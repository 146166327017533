import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { WarningTooltip } from '../components/Table/ui/styles';
import WarningIcon from '../../../components/icons/WarningIcon';
import Text1 from './Text1';

const useStyles = makeStyles({
  warningIcon: {
    display: 'flex',
    marginRight: 8,
  },
});

const WarningSupplierName = React.memo(props => {
  const { text } = props;
  const classes = useStyles();

  return (
    <WarningTooltip
      title="Datos de cuenta bancaria incompletos"
      placement="top-start"
    >
      <Grid container alignItems="center" direction="row" wrap="nowrap">
        <Grid item>
          <Box className={classes.warningIcon}>
            <WarningIcon />
          </Box>
        </Grid>
        <Grid item>
          <Text1 text={text} />
        </Grid>
      </Grid>
    </WarningTooltip>
  );
});

WarningSupplierName.defaultProps = {};

WarningSupplierName.propTypes = {
  text: PropTypes.string.isRequired,
};

export default WarningSupplierName;
