import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { t13s } from '../../../../translationKeys';
import * as formatters from '../../../commons/formatters';

const useSharedTableHeaders = () => {
  const { t } = useTranslation();
  const { countryId } = useSelector(state => state.businessState.business);

  const { AmountByCountry, Text1, WarningSupplierName, DateByCountry } =
    formatters;

  const folio = {
    title: 'Folio',
    field: 'folio',
    type: 'string',
    width: 80,
    align: 'left',
    render: invoice => <Text1 text={invoice?.folio} color="textSecondary" />,
  };

  const supplierName = {
    title: 'Proveedor',
    field: 'name',
    type: 'string',
    width: '70%',
    align: 'left',
    render: invoice =>
      !invoice.supplierHasBankAccount ? (
        <WarningSupplierName text={invoice?.name?.toUpperCase()} />
      ) : (
        <Text1 text={invoice?.name?.toUpperCase()} />
      ),
  };

  const identifier = {
    title: t(t13s.LABEL.BUSINESS_IDENTIFIER),
    field: 'identifier',
    type: 'string',
    width: 160,
    align: 'left',
    sort: 'disabled',
    render: invoice => (
      <Text1 text={invoice?.identifier} color="textSecondary" noWrap />
    ),
  };

  const issueDate = {
    title: 'Fecha de Emisión',
    field: 'issueDate',
    type: 'date',
    width: '100%',
    align: 'left',
    render: invoice => {
      return <DateByCountry text={invoice?.issueDate} countryId={countryId} />;
    },
  };

  const paymentDate = {
    title: 'Fecha de Pago',
    field: 'paymentDate',
    type: 'date',
    width: '100%',
    align: 'left',
    render: invoice => {
      const { paymentDate: paymentDateValue, paidAt } = invoice.paymentDetail;
      if (!paymentDate) return null;
      return (
        <DateByCountry
          text={paidAt || paymentDateValue}
          countryId={countryId}
        />
      );
    },
  };

  const paymentMethod = {
    title: 'Tipo',
    field: 'paymentMethod',
    type: 'string',
    width: 125,
    align: 'left',
    sorting: false,
    renderOnlyCountries: ['MX'],
    render: invoice => (
      <Text1 text={invoice?.paymentMethod} color="textSecondary" noWrap />
    ),
  };

  const totalAmount = {
    title: 'Monto Total',
    field: 'amount',
    type: 'numeric',
    width: '100%',
    align: 'left',
    render: invoice => (
      <AmountByCountry text={invoice?.amount} countryId={countryId} />
    ),
  };

  return {
    folio,
    supplierName,
    identifier,
    issueDate,
    paymentDate,
    paymentMethod,
    totalAmount,
  };
};

export default useSharedTableHeaders;
