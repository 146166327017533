import { useDispatch, useSelector } from 'react-redux';
import { paymentsRestoreInvoice } from '../../../../store/actions/paymentsActions';
import { useFetchData, useSearchAndReload } from '../commons';

const useArchivedTable = () => {
  const { id: businessId } = useSelector(state => state.businessState.business);
  const dispatch = useDispatch();
  const { search, onChangeSearch, reloadTable, reloadValue } =
    useSearchAndReload();
  const { fetchData } = useFetchData({
    externalSearch: search,
    option: 'archived',
  });

  const actions = [
    {
      text: 'Devolver a mis facturas',
      action: ({ invoice, closeMenu }) => {
        dispatch(
          paymentsRestoreInvoice(businessId, [invoice], 'archived', reloadTable)
        );
        closeMenu();
      },
    },
  ];

  return {
    actions,
    onChangeSearch,
    reloadTable,
    fetchData,
    reloadValue,
  };
};

export default useArchivedTable;
