import React from 'react';
import { makeStyles, Typography, Box } from '@material-ui/core';
import { PropTypes } from 'prop-types';

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: 16,
  },
}));

const StepperTitle = ({ children }) => {
  const classes = useStyles();
  return (
    <Typography
      variant="h4"
      color="textPrimary"
      component="div"
      className={classes.title}
    >
      <Box fontWeight="fontWeightBold">{children}</Box>
    </Typography>
  );
};

StepperTitle.propTypes = {
  children: PropTypes.string.isRequired,
};

export default StepperTitle;
