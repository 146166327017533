import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';

const DropdownDotsMenu = React.memo(props => {
  const {
    anchorEl,
    handleCloseMenu,
    payrollMenuButtons,
    selectedPayrollId,
    fetchedPayrolls,
  } = props;
  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
    >
      {payrollMenuButtons.map(({ id, text, action, showCondition }) => {
        if (!selectedPayrollId) return null;
        const { status } =
          fetchedPayrolls.find(payroll => payroll.id === selectedPayrollId) ||
          false;
        if (typeof showCondition !== 'undefined' && showCondition(status)) {
          return null;
        }
        return (
          <MenuItem
            key={id}
            onClick={() => action(selectedPayrollId, handleCloseMenu)}
          >
            {text}
          </MenuItem>
        );
      })}
    </Menu>
  );
});

DropdownDotsMenu.propTypes = {
  anchorEl: PropTypes.string.isRequired,
  handleCloseMenu: PropTypes.func.isRequired,
  payrollMenuButtons: PropTypes.func.isRequired,
  selectedPayrollId: PropTypes.string.isRequired,
  fetchedPayrolls: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DropdownDotsMenu;
