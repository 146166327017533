import React from 'react';
import PropTypes from 'prop-types';
import {
  MenuItem as MenuItemMui,
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    margin: '0 -1px',
    height: 36,
  },
  icon: {
    marginRight: 8,
  },
});

const MenuItem = React.forwardRef((props, ref) => {
  const { icon, children, ...menuItemProps } = props;
  const classes = useStyles();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MenuItemMui {...menuItemProps} ref={ref}>
      <Box display="inline-flex" alignItems="center" className={classes.root}>
        {icon && (
          <Box className={classes.icon} display="flex" alignItems="center">
            {icon}
          </Box>
        )}
        <Typography variant="body1" color="textPrimary">
          {children}
        </Typography>
      </Box>
    </MenuItemMui>
  );
});

MenuItem.defaultProps = {
  icon: false,
};

MenuItem.propTypes = {
  icon: PropTypes.element,
  children: PropTypes.string.isRequired,
};

export default MenuItem;
