import { Tooltip, withStyles, makeStyles } from '@material-ui/core';

const tableStyles = makeStyles({
  tableWrapper: {
    borderRadius: 20,
    overflow: 'hidden',
  },
  leftSpace: {
    borderLeft: '4px solid transparent',
  },
});

const paginationStyles = makeStyles({
  root: {
    padding: 0,
    border: 0,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 25,
    paddingBottom: 15,
  },
  labelRowsPerPage: {
    marginRight: 5,
  },
  pagination: {
    margin: '0 40px',
  },
});

const WarningTooltip = withStyles(theme => ({
  tooltip: {
    borderRadius: 12,
    background: '#FFF',
    padding: '12px 16px',
    border: `2px solid ${theme.palette.grey[300]}`,
    fontSize: theme.typography.subtitle2.fontSize,
    color: theme.palette.grey[800],
  },
}))(Tooltip);

export { tableStyles, paginationStyles, WarningTooltip };
