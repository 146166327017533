import { SEGMENT_EVENTS } from '../constants';
import { identifyAndTrack } from '../trackingSegment';

export const trackAccountCreated = (
  business,
  user,
  referralCode,
  country = 'MX'
) => {
  const identifyPayload = {
    business_identifier: business.identifier,
    business_name: business.name,
    user_name: user.name,
    user_email: user.email,
    user_phone: user.phone,
    referal_code: referralCode,
    country,
  };
  identifyAndTrack(
    business.id,
    identifyPayload,
    SEGMENT_EVENTS.ACCOUNT_CREATED
  );
};

export default trackAccountCreated;
