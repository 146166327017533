import { useDispatch, useSelector } from 'react-redux';
import { fetchPayrolls } from '../../../store/actions/payrollActions';

const useFetchData = ({ businessId }) => {
  const dispatch = useDispatch();

  const {
    payrolls: fetchedPayrolls,
    pagination: { page, totalPages, pageSize },
  } = useSelector(state => state.payrollsState);

  const fetchMorePayrolls = () => {
    if (page < totalPages) {
      dispatch(
        fetchPayrolls({
          businessId,
          page: page + 1,
          pageSize,
          option: 'not-paid',
        })
      );
    }
  };

  return {
    page,
    totalPages,
    pageSize,
    fetchedPayrolls,
    fetchMorePayrolls,
  };
};

export default useFetchData;
