import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const buttonStyles = makeStyles(theme => ({
  button: {
    background: theme.palette.secondary.contrastText,
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: '7px 16px',
    borderRadius: 35,
    minHeight: 40,
    margin: '0 16px 0 0',
    color: theme.palette.text.secondary,
    ...theme.typography.button,
    '&:hover': {
      background: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.secondary.main}`,
      color: theme.palette.primary.contrastText,
    },
    '&.active': {
      background: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.secondary.main}`,
      color: theme.palette.primary.contrastText,
    },
  },
}));

const ToolbarButton = React.memo(props => {
  const { id, label, onClick, active } = props;
  const classes = buttonStyles();

  return (
    <Button
      id={id}
      key={id}
      variant="contained"
      color="primary"
      size="medium"
      className={clsx(classes.button, { active })}
      onClick={onClick}
    >
      {label}
    </Button>
  );
});

ToolbarButton.defaultProps = {};

ToolbarButton.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
};

export default ToolbarButton;
