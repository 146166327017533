import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Tab as MuiTab,
  Typography,
  CircularProgress,
  Fade,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridGap: 10,
    gridTemplateColumns: '1fr min-content',
    gridAutoRows: 'min-content',
    alignItems: 'center',
    padding: '0 30px',
  },
  withIcon: {
    gridTemplateColumns: '1fr min-content',
  },
  pill: {
    background: theme.palette.primary.main,
    borderRadius: 50,
    padding: '3px 8px',
    color: '#FFF',
  },
}));

const Tab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    margin: 0,
    padding: 0,
    opacity: 1,
    minWidth: 'max-content',
    '&:focus': {
      opacity: 1,
    },
    outline: 'none',
  },
  selected: {
    color: theme.palette.text.primary,
  },
}))(props => {
  const classes = useStyles();
  const { pillContent = '', isLoading, pillDataQa = null } = props;
  const tabProps = { ...props };
  delete tabProps.pillContent;
  delete tabProps.pillDataQa;
  return (
    <Box className={`${classes.container} ${pillContent && classes.withIcon}`}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <MuiTab disableRipple {...tabProps} />
      {isLoading ? (
        <CircularProgress size={18} />
      ) : (
        <Fade in timeout={400}>
          {!Number.isNaN(pillContent) && (
            <Typography
              variant="caption"
              color="inherit"
              component="div"
              className={classes.pill}
            >
              <Box fontWeight="fontWeightBold" data-qa={pillDataQa}>
                {pillContent}
              </Box>
            </Typography>
          )}
        </Fade>
      )}
    </Box>
  );
});
export default Tab;
