import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MaterialTable from '@material-table/core';
import { useDispatch, useSelector } from 'react-redux';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import {
  makeStyles,
  Menu,
  MenuItem,
  Icon,
  Box,
  Typography,
  Grid,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PaginationMaterialTable from '../elements/PaginationMaterialTable';
import { paymentsHandleInvoicesSelectionChange } from '../../store/actions/paymentsActions';
import { t13s } from '../../translationKeys';
import CountryFormatHelper from '../elements/CountryFormatHelper';
import WarningIcon from '../icons/WarningIcon';

const useStyles = makeStyles(() => ({
  tableWrapper: {
    borderRadius: 20,
    overflow: 'hidden',
  },
  leftSpace: {
    borderLeft: '4px solid transparent',
  },
  invoiceDateColumn: {
    minWidth: 130,
  },
  paymentDateColumn: {
    minWidth: 115,
  },
  totalAmountColumn: {
    minWidth: 100,
  },
  warningIcon: {
    display: 'flex',
    marginRight: 8,
  },
}));

const WarningTooltip = withStyles(theme => ({
  tooltip: {
    borderRadius: 12,
    background: '#FFF',
    padding: '12px 16px',
    border: `2px solid ${theme.palette.grey[300]}`,
    fontSize: theme.typography.subtitle2.fontSize,
    color: theme.palette.grey[800],
  },
}))(Tooltip);

const PaymentsInvoiceTable = React.memo(props => {
  const { rows: customRows, actionsButtons, handleUpdateInvoices } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMenuInvoice, setSelectedMenuInvoice] = useState(null);
  const [tableRows, setTableRows] = useState([]);

  const {
    invoices,
    invoicesAreLoading,
    selectedInvoices,
    pagination: { page, pageSize, totalData },
  } = useSelector(state => state.paymentsState);
  const { countryId } = useSelector(state => state.businessState.business);

  const handleSelectionChange = currentlySelectedRows => {
    const checkedIds = currentlySelectedRows.map(invoice => invoice.id);
    const nonCheckedIds = invoices
      .filter(invoice => !checkedIds.includes(invoice.id))
      .map(invoice => invoice.id);

    dispatch(
      paymentsHandleInvoicesSelectionChange({
        checkedInvoices: invoices.filter(invoice =>
          checkedIds.includes(invoice.id)
        ),
        nonCheckedInvoices: invoices.filter(invoice =>
          nonCheckedIds.includes(invoice.id)
        ),
      })
    );
  };

  const setCheckedRows = (selectedData, data) => {
    const dataCopy = [...data];
    if (selectedData && selectedData.length) {
      selectedData.forEach(row => {
        const found = dataCopy.find(obj => Number(obj.id) === Number(row.id));
        dataCopy[dataCopy.indexOf(found)] = {
          ...found,
          tableData: { checked: true },
        };
      });
    }
    return dataCopy;
  };

  const handleClickMenu = (event, invoice) => {
    event.persist();
    setAnchorEl(event.currentTarget);
    setSelectedMenuInvoice(invoice);
  };

  const handleCloseMenu = event => {
    if (event) event.persist();
    setAnchorEl(null);
    setSelectedMenuInvoice(null);
  };

  const handleChangePage = ({ page, pageSize }) => {
    handleUpdateInvoices(page, pageSize);
  };

  const joinRows = ({ customRows, baseRows }) => {
    if (customRows.length === 0) return baseRows;

    const rowsByFieldKey = [...baseRows, ...customRows].reduce(
      (rowsMerged, row) => {
        // eslint-disable-next-line no-param-reassign
        rowsMerged[row.field] = row;
        return rowsMerged;
      },
      {}
    );

    return Object.values(rowsByFieldKey)
      .sort((a, b) => a.position - b.position)
      .filter(e => !e.hide);
  };

  const baseRows = [
    {
      position: 10,
      title: 'Folio',
      field: 'id',
      type: 'string',
      width: 80,
      align: 'left',
      render: invoice => (
        <Typography variant="body1" color="textSecondary" component="div">
          <Box fontWeight="fontWeightBold">{invoice?.folio}</Box>
        </Typography>
      ),
    },
    {
      position: 20,
      title: 'Proveedor',
      field: 'name',
      type: 'string',
      width: '100%',
      align: 'left',
      render: invoice =>
        !invoice.supplierHasBankAccount ? (
          <WarningTooltip
            title="Datos de cuenta bancaria incompletos"
            placement="top-start"
          >
            <Grid container alignItems="center" direction="row" wrap="nowrap">
              <Grid item>
                <Box className={classes.warningIcon}>
                  <WarningIcon />
                </Box>
              </Grid>
              <Grid item>
                <Typography variant="body1" color="textPrimary" component="div">
                  <Box fontWeight="fontWeightBold">
                    {invoice?.name?.toUpperCase()}
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </WarningTooltip>
        ) : (
          <Typography variant="body1" color="textPrimary" component="div">
            <Box fontWeight="fontWeightBold">
              {invoice?.name?.toUpperCase()}
            </Box>
          </Typography>
        ),
    },
    {
      position: 30,
      title: t(t13s.LABEL.BUSINESS_IDENTIFIER),
      field: 'identifier',
      type: 'string',
      width: 160,
      align: 'left',
      render: invoice => (
        <Typography
          variant="body1"
          color="textSecondary"
          component="div"
          noWrap
        >
          <Box fontWeight="fontWeightBold">{invoice?.identifier}</Box>
        </Typography>
      ),
    },
    {
      position: 40,
      title: 'Fecha de Emisión',
      field: 'paymentDetail.createdAt',
      type: 'date',
      width: '100%',
      align: 'left',
      render: invoice => {
        return (
          <Typography
            variant="body1"
            color="textSecondary"
            component="div"
            noWrap
            className={classes.invoiceDateColumn}
          >
            <Box fontWeight="fontWeightBold">
              <CountryFormatHelper
                value={invoice?.issueDate}
                variant="longdate"
                countryId={countryId}
              />
            </Box>
          </Typography>
        );
      },
    },
    {
      position: 50,
      title: 'Fecha de Pago',
      field: 'paymentDetail.paymentDate',
      type: 'date',
      width: '100%',
      align: 'left',
      render: invoice => {
        const { paymentDate, paidAt } = invoice.paymentDetail;
        if (!paymentDate) return null;
        return (
          <Typography
            variant="body1"
            color="textSecondary"
            component="div"
            noWrap
            className={classes.paymentDateColumn}
          >
            <Box fontWeight="fontWeightBold">
              <CountryFormatHelper
                value={paidAt || paymentDate}
                variant="longdate"
                countryId={countryId}
              />
            </Box>
          </Typography>
        );
      },
    },
    {
      position: 60,
      title: 'Tipo',
      field: 'paymentMethod',
      type: 'string',
      width: 125,
      align: 'left',
      render: invoice => (
        <Typography
          variant="body1"
          color="textSecondary"
          component="div"
          noWrap
        >
          <Box fontWeight="fontWeightBold">{invoice?.paymentMethod}</Box>
        </Typography>
      ),
    },
    {
      position: 70,
      title: 'Monto Total',
      field: 'amount',
      type: 'numeric',
      width: '100%',
      align: 'left',
      render: invoice => (
        <Typography
          variant="body1"
          color="textSecondary"
          component="div"
          noWrap
          className={classes.totalAmountColumn}
        >
          <Box fontWeight="fontWeightBold">
            <CountryFormatHelper
              value={invoice?.amount}
              countryId={countryId}
              variant="currency"
            />
          </Box>
        </Typography>
      ),
    },
  ];

  const actions = [
    {
      icon: () => (
        <Icon aria-label="more" aria-controls="long-menu" aria-haspopup="true">
          <MoreHorizIcon />
        </Icon>
      ),
      tooltip: 'Más opciones',
      position: 'row',
      onClick: (event, rowData) => handleClickMenu(event, rowData),
    },
  ];

  useEffect(() => {
    setTableRows(joinRows({ customRows, baseRows }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customRows]);

  return (
    <Box>
      {actionsButtons.length > 0 && (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          {actionsButtons.map(({ text, action }) => (
            <MenuItem
              key={text}
              onClick={() =>
                action({
                  invoice: selectedMenuInvoice,
                  closeMenu: handleCloseMenu,
                })
              }
            >
              {text}
            </MenuItem>
          ))}
        </Menu>
      )}
      <Box className={classes.tableWrapper}>
        <MaterialTable
          key={pageSize}
          style={{ width: '100%', boxShadow: 'none' }}
          columns={tableRows}
          data={setCheckedRows(selectedInvoices, invoices)}
          isLoading={invoicesAreLoading}
          actions={actionsButtons.length > 0 ? actions : null}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} de {count}',
              labelRowsSelect: 'facturas',
              labelRowsPerPage: 'Facturas por página',
            },
            header: {
              actions: '',
            },
            body: {
              emptyDataSourceMessage: 'No hay facturas para mostrar',
              filterRow: {
                filterTooltip: 'Filtro',
              },
            },
          }}
          onRowsPerPageChange={pageSize =>
            handleChangePage({
              page: 0,
              pageSize,
              totalData: Number(totalData),
            })
          }
          onSelectionChange={handleSelectionChange}
          options={{
            emptyRowsWhenPaging: false,
            showFirstLastPageButtons: false,
            selection: true,
            sorting: true,
            pageSize: Number(pageSize),
            pageSizeOptions: [5, 10, 20, 50, 100],
            showSelectAllCheckbox: true,
            search: false,
            toolbar: false,
            actionsColumnIndex: -1,
            selectionProps: () => ({
              icon: <CircleUnchecked />,
              checkedIcon: <CircleCheckedFilled />,
            }),
            headerSelectionProps: {
              icon: <CircleUnchecked className={classes.leftSpace} />,
              checkedIcon: (
                <CircleCheckedFilled className={classes.leftSpace} />
              ),
              indeterminateIcon: (
                <CircleUnchecked className={classes.leftSpace} />
              ),
            },
          }}
          components={{
            Pagination: props => {
              // eslint-disable-next-line react/prop-types
              return (
                <PaginationMaterialTable
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  rowsPerPage={pageSize}
                  count={totalData}
                  page={page - 1}
                  onChangePage={(event, page) => {
                    handleChangePage({
                      page,
                      pageSize,
                      totalData,
                    });
                  }}
                  onChangeRowsPerPage={event => {
                    const { value } = event.target;
                    handleChangePage({
                      page: 0,
                      pageSize: value,
                      totalData,
                    });
                  }}
                />
              );
            },
          }}
        />
      </Box>
    </Box>
  );
});

PaymentsInvoiceTable.defaultProps = {
  actionsButtons: [],
  rows: [],
};

PaymentsInvoiceTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  actionsButtons: PropTypes.arrayOf(PropTypes.object),
  handleUpdateInvoices: PropTypes.func.isRequired,
};

export default PaymentsInvoiceTable;
