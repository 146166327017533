import axios from '../../helpers/axios';
import {
  CHECK_TAX_SERVICE_CREDENTIAL_START,
  CHECK_TAX_SERVICE_CREDENTIAL_SUCCESS,
  CHECK_TAX_SERVICE_CREDENTIAL_ERROR,
  CREATE_TAX_SERVICE_CREDENTIAL_START,
  CREATE_TAX_SERVICE_CREDENTIAL_SUCCESS,
  CREATE_TAX_SERVICE_CREDENTIAL_ERROR,
  CREATE_TAX_SERVICE_CREDENTIAL_RESET,
} from './types';
import { enqueueSnackbar } from './notificationActions';
import { t13s } from '../../translationKeys';

// eslint-disable-next-line import/prefer-default-export
export const checkTaxServiceCredential = businessId => dispatch => {
  dispatch({ type: CHECK_TAX_SERVICE_CREDENTIAL_START });
  axios
    .get(`/api/business/${businessId}/taxuser/check`)
    .then(res => {
      const taxStatus = res.data;
      dispatch({
        type: CHECK_TAX_SERVICE_CREDENTIAL_SUCCESS,
        payload: { taxStatus },
      });
    })
    .catch(error => {
      const errorCode = error?.response?.data?.errorCode;
      dispatch({
        type: CHECK_TAX_SERVICE_CREDENTIAL_ERROR,
        payload: { errorCode },
      });
    });
};

export const createTaxUser = (businessId, credential) => dispatch => {
  dispatch({
    type: CREATE_TAX_SERVICE_CREDENTIAL_START,
  });
  axios
    .post(`/api/business/${businessId}/taxuser`, credential)
    .then(() => {
      dispatch({ type: CREATE_TAX_SERVICE_CREDENTIAL_SUCCESS });
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.TAX_SERVICE_CREDENTIAL_CREATED_SUCCESS,
          options: {
            variant: 'success',
          },
        })
      );
      dispatch(checkTaxServiceCredential(businessId));
    })
    .catch(error => {
      const errorCode = error?.response?.data?.errorCode;
      dispatch({
        type: CREATE_TAX_SERVICE_CREDENTIAL_ERROR,
        payload: { errorCode },
      });
    });
};

export const createTaxUserReset = () => dispatch => {
  dispatch({
    type: CREATE_TAX_SERVICE_CREDENTIAL_RESET,
  });
};
