import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const LockIcon = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 32 38">
      <path d="M28.0652 15.6956H26.8591V11.1522C26.8591 5.00257 21.9882 0 16.0004 0C10.0126 0 5.14172 5.00257 5.14172 11.1522V15.6956H3.93481C1.93891 15.6956 0.315247 17.3632 0.315247 19.413V34.2826C0.315247 36.3325 1.93891 38 3.93481 38H28.0652C30.0611 38 31.6848 36.3325 31.6848 34.2826V19.413C31.6847 17.3632 30.0611 15.6956 28.0652 15.6956ZM17.2065 27.8585V31.8044C17.2065 32.4887 16.6664 33.0435 16 33.0435C15.3337 33.0435 14.7934 32.4887 14.7934 31.8044V27.8585C13.3912 27.346 12.3817 25.986 12.3804 24.3696C12.382 22.3161 14.0006 20.6538 16 20.6522C17.9994 20.6538 19.618 22.3161 19.6196 24.3696C19.6183 25.986 18.6087 27.346 17.2065 27.8585ZM22.033 15.6956H9.96776V11.1522C9.96776 7.73493 12.673 4.95655 16.0004 4.95655C19.3277 4.95655 22.0329 7.73493 22.0329 11.1522V15.6956H22.033Z" />
    </SvgIcon>
  );
};

export default LockIcon;
