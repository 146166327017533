import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const Text2 = React.memo(props => {
  const { text, className, color, noWrap } = props;
  return (
    <Typography
      variant="body2"
      className={className}
      color={color}
      component="div"
      noWrap={noWrap}
    >
      {text}
    </Typography>
  );
});

Text2.defaultProps = {
  color: 'textPrimary',
  className: '',
  noWrap: false,
};

Text2.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  noWrap: PropTypes.boolean,
};

export default Text2;
