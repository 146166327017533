import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button, Box } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import tour1 from '../../assets/tour1.svg';
import tour2 from '../../assets/tour2.svg';
import tour3 from '../../assets/tour3.svg';
import BaseDialog from './BaseDialog';
import Carrousel from '../elements/Carrousel';
import { t13s } from '../../translationKeys';
import { COUNTRY_CODE_CL, COUNTRY_CODE_MX } from '../../helpers/constants';

const useStyles = makeStyles({
  actions: {
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  textBody: {
    minHeight: 206,
  },
  action: {
    minWidth: 40,
    height: 40,
    borderRadius: 50,
    color: '#FFF',
    padding: 0,
  },
  oneAction: {
    display: 'flex',
    justifyContent: 'center',
  },
  list: {
    listStyle: 'inside',
    padding: 0,
  },
  imageTour: {
    marginLeft: -25,
    marginTop: -10,
  },
  title: {
    marginTop: 25,
    marginBottom: 25,
  },
  body2: {
    marginTop: 20,
  },
});

const WelcomeDialog = ({ open, handleClose }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const { countryId } = useSelector(state => state.businessState.business);

  const beginAction = () => {
    handleClose();
    history.push('/finance');
  };

  const go = () => {
    setStep(step + 1);
  };
  const back = () => {
    setStep(step - 1);
  };

  const getThirdBody = () => {
    switch (countryId) {
      case COUNTRY_CODE_CL:
        return (
          <>
            <Typography variant="h6" color="textSecondary" component="div">
              <Box fontWeight="fontWeightBold">
                Sigue estros tres simples pasos para
                <br />
                financiar tus facturas ¡en minutos!
              </Box>
            </Typography>
            <Typography
              variant="h6"
              color="textSecondary"
              component="div"
              className={classes.body2}
            >
              <Box fontWeight="fontWeightBold">
                <ul className={classes.list}>
                  <li>Selecciona las facturas a financiar.</li>
                  <li>Elige sus fechas de pago.</li>
                  <li>Cede tus facturas y ¡Listo!</li>
                </ul>
              </Box>
            </Typography>
          </>
        );
      case COUNTRY_CODE_MX:
        return (
          <>
            <Typography
              variant="h6"
              color="textSecondary"
              component="div"
              className={classes.body2}
            >
              <Box fontWeight="fontWeightBold">
                <ul className={classes.list}>
                  <li>Selecciona las facturas a financiar.</li>
                  <li>Elige sus fechas de pago.</li>
                </ul>
              </Box>
            </Typography>
            <Typography variant="h6" color="textSecondary" component="div">
              <Box fontWeight="fontWeightBold">
                Y empieza a financiarte en 48 hrs <br />
                de forma 100% digital.
              </Box>
            </Typography>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <BaseDialog isOpen={open} handleClose={handleClose}>
      {step === 1 ? (
        /*
         * BODY STEP 1
         */
        <Grid container direction="column" alignItems="center" align="center">
          <Grid item xs={12}>
            <img
              src={tour1}
              width={460}
              className={classes.imageTour}
              alt="Logo"
            />
          </Grid>
          <Grid item xs={12} className={classes.textBody}>
            <Typography
              variant="h5"
              color="primary"
              className={classes.title}
              component="div"
            >
              <Box fontWeight="fontWeightBold">Bienvenido a Xepelin</Box>
            </Typography>
            <Typography variant="h6" color="textSecondary" component="div">
              <Box fontWeight="fontWeightBold">
                A partir de ahora podrás conseguir el dinero
                <br />
                que necesitas de manera simple, rápida y<br />
                segura.
              </Box>
            </Typography>
            <Typography
              variant="h6"
              color="textSecondary"
              component="div"
              className={classes.body2}
            >
              <Box fontWeight="fontWeightBold">
                Abróchate el cinturón y despega hacia esta
                <br />
                nueva experiencia financiera
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.oneAction}>
            <Carrousel bullets={3} activeBullet={1} />
          </Grid>
          <Grid item xs={12} className={classes.oneAction}>
            <Button
              onClick={() => go()}
              color="primary"
              variant="contained"
              endIcon={<ArrowRightAltIcon fontSize="small" />}
            >
              Siguiente
            </Button>
          </Grid>
        </Grid>
      ) : step === 2 ? (
        /*
         * BODY STEP 2
         */
        <Grid container direction="column" alignItems="center" align="center">
          <Grid item xs={12}>
            <img
              src={tour2}
              width={460}
              className={classes.imageTour}
              alt="Logo"
            />
          </Grid>
          <Grid item xs={12} className={classes.textBody}>
            <Typography
              variant="h5"
              color="primary"
              component="div"
              className={classes.title}
            >
              <Box fontWeight="fontWeightBold">
                ¿Cómo funciona la plataforma?
              </Box>
            </Typography>
            <Typography variant="h6" color="textSecondary" component="div">
              <Box fontWeight="fontWeightBold">
                Sólo debes ingresar tus credenciales del{' '}
                {t(t13s.LABEL.TAX_SERVICE_SHORT_NAME)} y
                <br />
                nosotros nos encargamos de buscar tus
                <br />
                facturas para que puedas financiarte
                <br />
                rápidamente.
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.oneAction}>
            <Carrousel bullets={3} activeBullet={2} />
          </Grid>
          <Grid item xs={12} className={classes.actions}>
            <Button
              variant="contained"
              size="medium"
              className={classes.action}
              onClick={() => back()}
            >
              <ArrowLeftIcon />
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className={classes.action}
              onClick={() => go()}
            >
              <ArrowRightIcon />
            </Button>
          </Grid>
        </Grid>
      ) : (
        /*
         * BODY STEP 3
         */
        <Grid container direction="column" alignItems="center" align="center">
          <Grid item xs={12}>
            <img
              src={tour3}
              width={460}
              className={classes.imageTour}
              alt="Logo"
            />
          </Grid>
          <Grid item xs={12} className={classes.textBody}>
            <Typography
              variant="h5"
              color="primary"
              component="div"
              className={classes.title}
            >
              <Box fontWeight="fontWeightBold">¡Estás listo para empezar!</Box>
            </Typography>
            {getThirdBody()}
          </Grid>
          <Grid item xs={12} className={classes.oneAction}>
            <Carrousel bullets={3} activeBullet={3} />
          </Grid>
          <Grid item xs={12} direction="row" className={classes.actions}>
            <Button
              variant="contained"
              size="medium"
              className={classes.action}
              onClick={() => back()}
            >
              <ArrowLeftIcon />
            </Button>
            <Button onClick={beginAction} color="primary" variant="contained">
              Comenzar
            </Button>
          </Grid>
        </Grid>
      )}
    </BaseDialog>
  );
};

WelcomeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default WelcomeDialog;
