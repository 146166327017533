import React, { useEffect, useContext } from 'react';
import { Grid, makeStyles, Typography, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import CustomButton from './elements/CustomButton';
import LoadingButton from './elements/LoadingButton';
import { StepperContext } from './StepperFlow';
import StepperTitle from './elements/StepperTitle';
import StepperSubtitle from './elements/StepperSubtitle';
import { AVAILABLE_COUNTRIES } from '../helpers/constants';
import CountryFormatHelper from './elements/CountryFormatHelper';
import { requestFinancing } from '../store/actions/payrollActions';

const FLOW_TYPE = 'accountFinancing';

const useStyles = makeStyles(theme => ({
  rootButton: {
    marginTop: 140,
    marginBottom: 28,
  },
  backButton: {
    marginRight: 22,
  },
  totalAmount: {
    color: theme.palette.primary.main,
  },
}));

const PayInvoicesAccountFinancingStep1 = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { updateState } = useContext(StepperContext);
  const { goNext } = useContext(StepperContext);
  const { goBackNested, invoicesTotalAmount, countryId, payrollId } = props;
  const { id: businessId } = useSelector(state => state.businessState.business);
  const { payrollFinanced, financingPayroll } = useSelector(
    state => state.payrollsState
  );

  const handleFinancing = () => {
    dispatch(requestFinancing(businessId, payrollId));
  };

  useEffect(() => {
    updateState({
      flowType: FLOW_TYPE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (payrollFinanced) {
      goNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payrollFinanced]);

  return (
    <Grid container item>
      <StepperTitle>Financiamiento</StepperTitle>
      <StepperSubtitle>
        Este es el financimiento que te ofrecemos para esta operación.
      </StepperSubtitle>

      <Grid container>
        <Grid container item justifyContent="space-between">
          <Grid item>
            <Typography variant="subtitle1" color="textPrimary" component="div">
              <Box fontWeight="fontWeightBold">TOTAL A FINANCIAR</Box>
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h5"
              color="textPrimary"
              component="div"
              className={classes.totalAmount}
            >
              <Box fontWeight="fontWeightMedium">
                <CountryFormatHelper
                  value={invoicesTotalAmount}
                  countryId={countryId}
                  variant="currency"
                />
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.rootButton}>
        <Grid item>
          <CustomButton
            variant="simple-gray"
            color="secondary"
            size="large"
            onClick={goBackNested}
            className={classes.backButton}
          >
            Volver
          </CustomButton>
        </Grid>
        <Grid item>
          <LoadingButton
            variant="contained"
            color="secondary"
            disabled={financingPayroll}
            isLoading={financingPayroll}
            onClick={handleFinancing}
          >
            Enviar solicitud
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

PayInvoicesAccountFinancingStep1.propTypes = {
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  goBackNested: PropTypes.func.isRequired,
  invoicesTotalAmount: PropTypes.number.isRequired,
  payrollId: PropTypes.number.isRequired,
};

export default PayInvoicesAccountFinancingStep1;
