import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchGeoMXColonies,
  fetchGeoMXStates,
  fetchGeoMXStatesMunicipalities,
} from '../../store/actions/geoActions';

const useGeoMX = () => {
  const dispatch = useDispatch();
  const mxStates = useSelector(state => state.geo.mxStates);
  const [selectedState, setSelectedState] = useState('');
  const mxMunicipalities = useSelector(state => state.geo.mxMunicipalities);
  const [selectedMunicipality, setSelectedMunicipality] = useState('');
  const mxColonies = useSelector(state => state.geo.mxColonies);

  useEffect(() => {
    if (!mxStates.length) {
      dispatch(fetchGeoMXStates());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (selectedState && mxStates?.length > 0) {
      dispatch(fetchGeoMXStatesMunicipalities(selectedState));
    }
  }, [selectedState, dispatch, mxStates.length]);

  useEffect(() => {
    if (selectedMunicipality && mxMunicipalities?.length > 0) {
      dispatch(fetchGeoMXColonies(selectedMunicipality));
    }
  }, [selectedMunicipality, dispatch, mxMunicipalities.length]);

  const selectState = value => {
    setSelectedState(value);
  };

  const selectMunicipality = value => {
    setSelectedMunicipality(value);
  };

  return [
    mxStates,
    mxMunicipalities,
    mxColonies,
    selectState,
    selectMunicipality,
  ];
};

export default useGeoMX;
