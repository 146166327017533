import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import PropTypes from 'prop-types';
import { StepperContainer } from './StepperFlow';
import PaymentsOnboardStep1 from './PaymentsOnboardStep1';
import PaymentsOnboardStep2 from './PaymentsOnboardStep2';
import PaymentsOnboardSuccess from './PaymentsOnboardSuccess';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 99,
  },
  paper: {
    width: '100%',
    height: '100vh',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
}));

const PaymentsOnboardStepper = props => {
  const { open, closeModal } = props;
  const classes = useStyles();

  const ONBOARD_STEPS = [
    {
      label: 'Sincronizar Facturas',
      component: <PaymentsOnboardStep1 />,
      customButtons: true,
    },
    {
      label: 'Términos de pago',
      component: <PaymentsOnboardStep2 />,
      customButtons: true,
    },
  ];

  const ONBOARD_STEPS_COMPLETED = {
    component: <PaymentsOnboardSuccess />,
  };

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      closeAfterTransition
      disablePortal
      className={classes.modal}
      open={open}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade style={{ padding: 0 }} in={open}>
        <div className={classes.paper}>
          <StepperContainer
            title="Configura tus cuentas por pagar"
            closeModal={closeModal}
            steps={ONBOARD_STEPS}
            stepCompleted={ONBOARD_STEPS_COMPLETED}
          />
        </div>
      </Fade>
    </Modal>
  );
};

PaymentsOnboardStepper.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default PaymentsOnboardStepper;
