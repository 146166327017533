import React, { useState, useContext, useEffect } from 'react';
import { Grid, TextField, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import OnboardPaymentsStep2Table from './tables/OnboardPaymentsStep2Table';
import StepperTitle from './elements/StepperTitle';
import StepperSubtitle from './elements/StepperSubtitle';
import StepperContext from './StepperFlow/StepperContext';
import LoadingButton from './elements/LoadingButton';
import { saveOnboard } from '../store/actions/onboardActions';

const useStyles = makeStyles(theme => ({
  buttonsRoot: {
    marginTop: 28,
    background: '#fff',
    position: 'sticky',
    bottom: 0,
    padding: '11px 0',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  skipButton: {
    marginRight: 22,
  },
}));

const PaymentsOnboardStep2 = () => {
  const [customPaymentTerms, setCustomPaymentTerms] = useState({});
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const { suppliers: suppliersData } = useSelector(state => state.onboardState);
  const { state, goNext } = useContext(StepperContext);
  const { id: businessId } = useSelector(state => state.businessState.business);
  const { saveOnboardLoading, saveOnboardSuccess } = useSelector(
    state => state.onboardState
  );
  const { onboardDate } = state;
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSavePaymentTerms = () => {
    const transformPaymentsTerms = Object.entries(customPaymentTerms).map(
      ([key, value]) => {
        return { identifier: key, paymentTerm: value.paymentTerm };
      }
    );
    dispatch(saveOnboard(businessId, transformPaymentsTerms, onboardDate));
  };

  const checkStringCoincidense = (fullStr, str) => {
    const fullStrParsed = fullStr.trim().toLowerCase();
    const strParsed = str.trim().toLowerCase();
    if (strParsed === '') return false;
    return fullStrParsed.includes(strParsed);
  };

  const handleChangeSearchInput = e => {
    const { value } = e.currentTarget;
    const newInvoices = suppliersData.filter(invoice => {
      return value.trim() === '' || checkStringCoincidense(invoice.name, value);
    });
    setFilteredInvoices(newInvoices);
  };

  useEffect(() => {
    if (saveOnboardSuccess) {
      goNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveOnboardSuccess]);

  return (
    <Grid container item>
      <StepperTitle>
        Ingresa los términos de pago por cada proveedor
      </StepperTitle>
      <StepperSubtitle>
        Revisa los días de crédito en los que realizas el pago a cada proveedor.
        Si tus pagos son al contado, ingresa la cifra 0(cero).
      </StepperSubtitle>

      <TextField
        fullWidth
        label="Buscar proveedor"
        id="outlined-basic"
        variant="outlined"
        color="secondary"
        onChange={handleChangeSearchInput}
      />

      <OnboardPaymentsStep2Table
        supplierData={
          filteredInvoices.length ? filteredInvoices : suppliersData
        }
        customPaymentTerms={customPaymentTerms}
        setCustomPaymentTerms={setCustomPaymentTerms}
      />

      <Grid container className={classes.buttonsRoot} justifyContent="flex-end">
        <Grid item>
          <LoadingButton
            variant="contained"
            color="secondary"
            disabled={saveOnboardLoading}
            isLoading={saveOnboardLoading}
            onClick={handleSavePaymentTerms}
          >
            Finalizar
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaymentsOnboardStep2;
