import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, Box, makeStyles } from '@material-ui/core';

import CountryFormatHelper from '../elements/CountryFormatHelper';
import { AVAILABLE_COUNTRIES } from '../../helpers/constants';

const useStyles = makeStyles(theme => ({
  totalAmountValue: {
    color: theme.palette.primary.main,
  },
}));

const PayrollResumeTable = props => {
  const {
    countryId,
    invoicesTotalAmount,
    payrollPaymentDate,
    invoicesQuantities,
    providersQuantities,
  } = props;
  const classes = useStyles();

  return (
    <Grid container item spacing={5}>
      <Grid
        container
        item
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Typography variant="subtitle1" color="textSecondary" component="div">
            <Box fontWeight="fontWeightMedium">Nº de facturas</Box>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" color="textPrimary" component="div">
            <Box fontWeight="fontWeightMedium">{invoicesQuantities}</Box>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Typography variant="subtitle1" color="textSecondary" component="div">
            <Box fontWeight="fontWeightMedium">Nº de proveedores</Box>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" color="textPrimary" component="div">
            <Box fontWeight="fontWeightMedium">{providersQuantities}</Box>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Typography variant="subtitle1" color="textSecondary" component="div">
            <Box fontWeight="fontWeightMedium">Fecha de pago</Box>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" color="textPrimary" component="div">
            <Box fontWeight="fontWeightMedium">
              <CountryFormatHelper
                value={payrollPaymentDate}
                variant="longdate"
                countryId={countryId}
              />
            </Box>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Typography variant="subtitle1" color="textSecondary" component="div">
            <Box fontWeight="fontWeightMedium">Costo operación</Box>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" color="textPrimary" component="div">
            <Box fontWeight="fontWeightMedium">$0</Box>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Typography variant="subtitle1" color="textPrimary" component="div">
            <Box fontWeight="fontWeightBold">TOTAL</Box>
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="h5"
            color="inherit"
            component="div"
            className={classes.totalAmountValue}
          >
            <Box fontWeight="fontWeightMedium">
              <CountryFormatHelper
                value={invoicesTotalAmount}
                countryId={countryId}
                variant="currency"
              />
            </Box>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

PayrollResumeTable.propTypes = {
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  invoicesTotalAmount: PropTypes.number.isRequired,
  payrollPaymentDate: PropTypes.string.isRequired,
  invoicesQuantities: PropTypes.number.isRequired,
  providersQuantities: PropTypes.number.isRequired,
};

export default PayrollResumeTable;
