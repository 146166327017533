/* eslint-disable react/jsx-props-no-spreading */
import { Grid, makeStyles, MenuItem, Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import copy from 'copy-to-clipboard';
import { enqueueSnackbar } from '../../store/actions/notificationActions';
import { t13s } from '../../translationKeys';
import bankIcon from '../../assets/bank.svg';
import {
  ACCOUNT_TYPES,
  COUNTRY_CODE_CL,
  COUNTRY_CODE_MX,
} from '../../helpers/constants';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';
import { validateIdentifier } from '../../helpers/validations/businessIdentifier';
import AlertForm from '../elements/AlertForm';
import BaseDialog from './BaseDialog';
import ConfirmAction from '../elements/ConfirmAction';
import { settings } from '../../config/settings';
import { features } from '../../config/features';
import {
  resetBankAccount,
  fetchBanks,
} from '../../store/actions/businessBankAccountsActions';
// import { features } from '../../config/features';

const useStyles = makeStyles({
  rootForm: {
    padding: convertSpacingToCss('sm sm md sm'),
  },
  iconSelect: {
    marginRight: 10,
  },
  button: {
    marginTop: 10,
  },
  link: {
    fontColor: 'secondary',
    textDecoration: 'underline',
  },
});

const BankAccountDialog = ({
  bankAccount,
  open,
  onClose,
  onSubmit,
  isRegister,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    creatingBankAccount,
    updatingBankAccount,
    createBankAccountError,
    updateBankAccountError,
    bankAccountCreated,
    bankAccountUpdated,
    banks,
  } = useSelector(state => state.businessBankAccountsState);
  const {
    countryId: country,
    identifier,
    name,
  } = useSelector(state => state.businessState.business);

  const [bankAccountData, setBankAccountData] = useState({
    bankId: '',
    accountType: ACCOUNT_TYPES[country][0].value,
    accountNumber: '',
    sourceIdentifier: identifier,
    accountHolder: name,
    accountEmail: '',
    alias: '',
    currency: 'MXN',
    ...bankAccount,
  });
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const [bankAccountError, setBankAccountError] = useState(null);
  const [checked, setChecked] = useState(false);
  const { currencyCode } = settings[country];

  const handleClose = () => {
    onClose();
    dispatch(resetBankAccount());
  };

  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(updatingBankAccount || creatingBankAccount);
  }, [updatingBankAccount, creatingBankAccount]);

  useEffect(
    () => {
      if (createBankAccountError || updateBankAccountError) {
        const error = createBankAccountError ?? updateBankAccountError;
        setBankAccountError(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    createBankAccountError,
    updateBankAccountError
  );

  useEffect(() => {
    if (bankAccountCreated || bankAccountUpdated) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAccountCreated, bankAccountUpdated]);

  // TODO: Add validation rules to MX CLABE (stronger CLABE validation) and MX RFC (if necessary)
  useEffect(() => {
    dispatch(resetBankAccount());
    dispatch(fetchBanks(country));
    ValidatorForm.addValidationRule('validateIdentifier', value => {
      return validateIdentifier(value, country);
    });
    ValidatorForm.addValidationRule('validateAccountNumber', value => {
      if (country === COUNTRY_CODE_CL) return true;
      const regex = /^([0-9]{18})$/;
      return regex.test(value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bankAccount?.id) {
      setBankAccountData({ bankId: bankAccount.Bank.id, ...bankAccount });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get bank code from CLABE and set it as selected .MX ONLY
  useEffect(() => {
    if (country === COUNTRY_CODE_MX) {
      if (
        bankAccountData.accountNumber.toString().length >= 3 &&
        !bankAccountData.bankId
      ) {
        const clabeBankCode = bankAccountData.accountNumber
          .toString()
          .substring(0, 3);
        const selectedBank = banks.find(bank => {
          return bank.code === clabeBankCode;
        });

        if (!selectedBank) return;

        setBankAccountData({ ...bankAccountData, bankId: selectedBank.id });
      }
      if (
        bankAccountData.accountNumber.toString().length < 3 &&
        bankAccountData.bankId
      ) {
        setBankAccountData({ ...bankAccountData, bankId: '' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAccountData.accountNumber]);

  const handleInputChange = (field, value) => {
    let finalValue = value;
    if (field === 'accountNumber') {
      finalValue = value.replace(/[^0-9]/g, '');
    }

    setBankAccountData({
      ...bankAccountData,
      [field]: finalValue,
    });
  };

  const handleSubmit = async () => {
    onSubmit(bankAccountData);
  };

  const {
    bankId,
    accountType,
    accountNumber,
    sourceIdentifier,
    accountHolder,
    accountEmail,
    alias,
    currency,
  } = bankAccountData;

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const handleCopyEmail = email => {
    copy(email, { format: 'text/plain' });
    dispatch(
      enqueueSnackbar({
        message: t13s.NOTIFICATION.COPY_EMAIL_SUCCESS,
        options: {
          variant: 'success',
        },
      })
    );
  };

  // TODO: move this to settingsCL and settingsMX
  const currencies = {
    CL: [
      {
        value: currencyCode,
        label: currencyCode,
      },
    ],
    MX: [
      {
        value: currencyCode,
        label: currencyCode,
      },
      { value: 'USD', label: 'USD' },
    ],
  };

  const title = isRegister ? 'Agregar cuenta nueva' : 'Editar cuenta bancaria';
  const description = isRegister ? (
    <>
      Recuerda que las cuentas deben estar asociadas al{' '}
      {t(t13s.LABEL.BUSINESS_IDENTIFIER)} de la empresa. Si necesitas crear una
      cuenta para terceros, comunícate con nosotros a{' '}
      <Link
        color="secondary"
        onClick={() => handleCopyEmail('hola@xepelin.com')}
      >
        hola@xepelin.com
      </Link>
    </>
  ) : null;

  const bankSelectField = (
    <SelectValidator
      fullWidth
      variant="outlined"
      label="Banco"
      name="bankId"
      value={bankId}
      onChange={e => handleInputChange(e.target.name, e.target.value)}
      validators={['required']}
      errorMessages={['Campo requerido.']}
      data-qa="bank-account-dialog-banco-dropdown"
    >
      {banks.map(bank => (
        <MenuItem
          value={bank.id}
          key={bank.name}
          data-qa={`bank-account-dialog-banco-option-${bank.name
            .replace(/\s+/g, '-')
            .toLowerCase()}`}
        >
          {bank.name}
        </MenuItem>
      ))}
    </SelectValidator>
  );

  const accountTypeSelectField = (
    <SelectValidator
      fullWidth
      variant="outlined"
      label="Tipo de cuenta"
      name="accountType"
      value={accountType}
      onChange={e => handleInputChange(e.target.name, e.target.value)}
      validators={['required']}
      errorMessages={['Campo requerido.']}
      data-qa="bank-account-dialog-tipo-cuenta-dropdown"
    >
      {ACCOUNT_TYPES[country].map(type => {
        return (
          <MenuItem
            value={type.value}
            data-qa={`bank-account-dialog-tipo-cuenta-option-${type.value
              .replace(/\s+/g, '-')
              .toLowerCase()}`}
          >
            {type.label}
          </MenuItem>
        );
      })}
    </SelectValidator>
  );

  const accountNumberField = (
    <TextValidator
      fullWidth
      variant="outlined"
      label={t(t13s.LABEL.BANK_ACCOUNT_IDENTIFIER)}
      type="text"
      name="accountNumber"
      value={accountNumber}
      validators={['required', 'validateAccountNumber']}
      errorMessages={['Campo requerido.', t(t13s.INPUT_ERROR.ACCOUNT_NUMBER)]}
      onChange={e => handleInputChange(e.target.name, e.target.value)}
      data-qa="bank-account-dialog-numero-cuenta-input"
    />
  );

  const sourceIdentifierField = (
    <TextValidator
      fullWidth
      variant="outlined"
      label={t(t13s.LABEL.BUSINESS_IDENTIFIER)}
      type="text"
      name="sourceIdentifier"
      value={sourceIdentifier}
      disabled
      data-qa="bank-account-dialog-identifier-input"
    />
  );

  const accountHolderField = (
    <TextValidator
      fullWidth
      variant="outlined"
      label="Nombre o razón social"
      type="text"
      name="accountHolder"
      value={accountHolder}
      disabled
      data-qa="bank-account-dialog-nombre-input"
    />
  );

  let currencySelectField = '';

  if (features[country].bankAccountActions.showCurrencyInput) {
    currencySelectField = (
      <SelectValidator
        fullWidth
        variant="outlined"
        label="Divisa"
        type="text"
        name="currency"
        value={currency}
        onChange={e => handleInputChange(e.target.name, e.target.value)}
        validators={['required']}
        errorMessages={['Campo requerido.']}
        data-qa="bank-account-dialog-currency-dropdown"
      >
        {currencies[country].map(currency => (
          <MenuItem
            value={currency.value}
            data-qa={`bank-account-dialog-divisa-dropdown-option-${currency.value.toLowerCase()}`}
          >
            {currency.label}
          </MenuItem>
        ))}
      </SelectValidator>
    );
  }

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleClose}
      title={title}
      description={description}
      topIcon={bankIcon}
      topIconWidth={114}
      paperProps={{ 'data-qa': 'bank-account-dialog' }}
    >
      <ValidatorForm onSubmit={handleSubmit} className={classes.rootForm}>
        {country === COUNTRY_CODE_CL && (
          <>
            {bankSelectField}
            {accountTypeSelectField}
            {accountNumberField}
            {sourceIdentifierField}
            {accountHolderField}
          </>
        )}

        {country === COUNTRY_CODE_MX && (
          <>
            {sourceIdentifierField}
            {accountHolderField}
            {accountNumberField}
            {bankSelectField}
            <Grid container direction="row" spacing={2}>
              <Grid item xs={9}>
                {accountTypeSelectField}
              </Grid>
              <Grid item xs={3}>
                {currencySelectField}
              </Grid>
            </Grid>
          </>
        )}
        <TextValidator
          fullWidth
          variant="outlined"
          label="Email"
          name="accountEmail"
          value={accountEmail}
          onChange={e => handleInputChange(e.target.name, e.target.value)}
          validators={['required', 'isEmail']}
          errorMessages={['Campo Requerido', 'E-mail inválido']}
          data-qa="bank-account-dialog-email-input"
        />
        <TextValidator
          fullWidth
          variant="outlined"
          label="Alias (opcional)"
          type="text"
          name="alias"
          value={alias}
          onChange={e => handleInputChange(e.target.name, e.target.value)}
          data-qa="bank-account-dialog-alias-input"
        />
        {bankAccountError && (
          <AlertForm message={t(bankAccountError)} variant="error" />
        )}
        <Grid container item className={classes.button} justify="center">
          <ConfirmAction
            checked={checked}
            onChangeCheck={handleCheckboxChange}
            type="submit"
            buttonLabel={isRegister ? 'Agregar cuenta' : 'Actualizar cuenta'}
          />
        </Grid>
      </ValidatorForm>
    </BaseDialog>
  );
};

BankAccountDialog.defaultProps = {
  bankAccount: {},
  isRegister: false,
};

BankAccountDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  bankAccount: PropTypes.objectOf(Object),
  isRegister: PropTypes.bool,
};

export default BankAccountDialog;
