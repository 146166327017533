export const HOME_START_MAIN_PURPLE = '#731DFB';
export const HOME_START_TEXT_PRIMARY = '#707070';
export const HOME_START_FOOTER_LINK = '#EA693D';
export const HOME_START_FOOTER_BACKGROUND = '#3F0188';

export const TOPBAR_PUBLIC_TEXT_MENU = '#2b2350';
export const TOPBAR_PUBLIC_TEXT_MENU_HOVER = '#7052fb';

export const DRAWER_BACKGROUND = '#f6f5f7';

export const BODY_BACKGROUND = '#f3f2f5';

export const TABLE_HEADER_TEXT = '#a8a4b3';

export const TOOLTIP_COLOR = '#575360';

export const CHIP_VARIANTS = {
  blue: {
    code: 'blue',
    color: '#0529F0',
  },
  lightblue: {
    code: 'lightblue',
    color: '#0093FF',
  },
  green: {
    code: 'green',
    color: '#119B41',
  },
  lightgreen: {
    code: 'lightgreen',
    color: '#75B801',
  },
  yellow: {
    code: 'yellow',
    color: '#E29D02',
  },
  purple: {
    code: 'purple',
    color: '#901AE7',
  },
  red: {
    code: 'red',
    color: '#D92F24',
  },
  orange: {
    code: 'orange',
    color: '#FF7F00',
  },
  default: {
    code: 'default',
    color: '#B5B2B7',
  },
};

export const CARD_VARIANTS = {
  blue: {
    code: 'blue',
    background: '#1A73E72B',
    border: '#1a73e7',
  },
  green: {
    code: 'green',
    background: '#E6F8C5',
    border: '#8CC63F',
  },
  grey: {
    code: 'grey',
    background: '#F2F2F2',
    border: '#DBDDEB',
  },
  white: {
    code: 'white',
    background: '#FFF',
    border: '#E7E5EC',
  },
};

export const ORDER_STEP_COMPLETED = '#75B801';
export const ORDER_STEP_WARNING = '#FF7F00';

export const SWITCH_BACKGROUND = '#75B801';
export const SWITCH_HOVER = '#75B80129';

export const SEPARATOR = '#E4E1E9';
