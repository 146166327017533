import {
  FETCH_CREDIT_LINE_START,
  FETCH_CREDIT_LINE_SUCCESS,
  FETCH_CREDIT_LINE_FAILURE,
} from '../actions/types';

import { t13s } from '../../translationKeys';

const initialState = {
  creditLine: {},

  creditLineLoading: false,
  creditLineFailure: null,
};

const fetchCreditLineStart = state => {
  return {
    ...state,
    creditLine: {},
    creditLineLoading: true,
    creditLineFailure: null,
  };
};

const fetchCreditLineSuccess = (state, payload) => {
  const { creditLine } = payload;
  return {
    ...state,
    creditLine,
    creditLineLoading: false,
  };
};

const fetchCreditLineFailure = (state, payload) => {
  const { errorCode } = payload;
  return {
    ...state,
    creditLineLoading: false,
    creditLineFailure: errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CREDIT_LINE_START:
      return fetchCreditLineStart(state);
    case FETCH_CREDIT_LINE_SUCCESS:
      return fetchCreditLineSuccess(state, payload);
    case FETCH_CREDIT_LINE_FAILURE:
      return fetchCreditLineFailure(state, payload);
    default:
      return state;
  }
};
