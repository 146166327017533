import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TwitterIcon = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        id="Trazado_3931"
        data-name="Trazado 3931"
        d="M7.88,21a12.87,12.87,0,0,0,13-13c0-.2,0-.39,0-.59A9.1,9.1,0,0,0,23.1,5.11a9.1,9.1,0,0,1-2.62.72,4.53,4.53,0,0,0,2-2.52A9.17,9.17,0,0,1,19.6,4.42a4.56,4.56,0,0,0-7.77,4.15A12.89,12.89,0,0,1,2.45,3.81,4.56,4.56,0,0,0,3.86,9.89a4.44,4.44,0,0,1-2.07-.57v.06a4.56,4.56,0,0,0,3.66,4.47,4.48,4.48,0,0,1-2.06.07,4.55,4.55,0,0,0,4.25,3.17A9.17,9.17,0,0,1,2,19,9.81,9.81,0,0,1,.9,19a12.9,12.9,0,0,0,7,2.05"
      />
    </SvgIcon>
  );
};

export default TwitterIcon;
