import { makeStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';

const schedulePaymentModalStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 99,
  },
  paper: {
    width: '100%',
    height: '100vh',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
}));

const schedulePaymentSuccessStyles = makeStyles(theme => ({
  buttonsRoot: {
    marginTop: 0,
  },
  check: {
    width: 48,
    height: 48,
    borderRadius: '100%',
    background: alpha(theme.palette.success.main, 0.2),
    marginBottom: 32,
  },
  checkIcon: {
    color: theme.palette.success.main,
  },
}));

const schedulePaymentStep3Styles = makeStyles(() => ({
  buttonsRoot: {
    marginTop: 28,
  },
  backButton: {
    marginRight: 22,
  },
  resumeTable: {
    margin: '40px 0 20px 0',
  },
}));

export {
  schedulePaymentModalStyles,
  schedulePaymentSuccessStyles,
  schedulePaymentStep3Styles,
};
