import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { parseOrderBy, setCheckedRows } from '../utils';
import { paymentsHandleInvoicesSelectionChange } from '../../../../../store/actions/paymentsActions';

const useTable = ({ fetchData, reload }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const tableRef = useRef();

  const dispatch = useDispatch();

  const getData = async query => {
    setIsLoading(true);

    const response = await fetchData({
      orderBy: parseOrderBy(query),
      page: query.page + 1,
      pageSize: query.pageSize,
      search: query.search || undefined,
    });

    const { data: responseData, pagination: responsePagination } = response;

    setData(responseData);
    setPagination(responsePagination);
    setIsLoading(false);

    return {
      data: setCheckedRows(selectedRows, responseData),
      page: responsePagination.page - 1,
      totalCount: responsePagination.totalData,
    };
  };

  const handleSelectionChange = rows => {
    const selectedIds = selectedRows.map(row => row.id);
    const currentPageCheckedIds = rows.map(row => row.id);
    const currentPageNonCheckedIds = data
      .filter(row => !currentPageCheckedIds.includes(row.id))
      .map(row => row.id);

    const newSelectedRows = [
      ...selectedRows.filter(row => !currentPageNonCheckedIds.includes(row.id)),
      ...rows.filter(row => !selectedIds.includes(row.id)),
    ];

    setSelectedRows(newSelectedRows);

    dispatch(
      paymentsHandleInvoicesSelectionChange({
        checkedInvoices: data.filter(invoice =>
          currentPageCheckedIds.includes(invoice.id)
        ),
        nonCheckedInvoices: data.filter(invoice =>
          currentPageNonCheckedIds.includes(invoice.id)
        ),
      })
    );
  };

  useEffect(() => {
    tableRef.current.onQueryChange();
  }, [reload]);

  return {
    data,
    pagination,
    isLoading,
    getData,
    handleSelectionChange,
    tableRef,
  };
};

export default useTable;
