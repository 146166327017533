import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import { useSelector } from 'react-redux';
import SideBarNavigationButton from './elements/SideBarNavigationButton';
import XtrackerIcon from './icons/XtrackerIcon';
import PaymentsIcon from './icons/PaymentsIcon';
import SuppliersIcon from './icons/SuppliersIcon';

const useStyles = makeStyles({
  root: {
    padding: 15,
    minHeight: 'calc(100vh - 50px)',
    backgroundColor: '#fff',
  },
});

const Sidebar = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { entityFeature } = useSelector(state => state.authState);

  const handleButtonClick = newLocation => {
    history.push(newLocation);
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <SideBarNavigationButton
        icon={MonetizationOnOutlinedIcon}
        collapsed
        onClick={() => handleButtonClick('/finance')}
        active={location.pathname.includes('/finance')}
        dataQa="sidebar-navigation-button-financing-action"
      >
        <Typography variant="body2" color="inherit">
          Financiamiento
        </Typography>
      </SideBarNavigationButton>

      {entityFeature.find(entity => entity.featureId === 10) &&
        entityFeature.find(entity => entity.featureId === 10).active && (
          <>
            <SideBarNavigationButton
              icon={SuppliersIcon}
              collapsed
              onClick={() => handleButtonClick('/suppliers')}
              active={location.pathname.includes('/suppliers')}
            >
              <Typography variant="body2" color="inherit">
                Proveedores
              </Typography>
            </SideBarNavigationButton>

            <SideBarNavigationButton
              icon={PaymentsIcon}
              collapsed
              onClick={() => handleButtonClick('/payments')}
              active={location.pathname.includes('/payments')}
            >
              <Typography variant="body2" color="inherit">
                Pagos
              </Typography>
            </SideBarNavigationButton>
          </>
        )}

      <SideBarNavigationButton
        icon={AssignmentIcon}
        collapsed
        onClick={() => handleButtonClick('/orders')}
        active={location.pathname.includes('/orders')}
        dataQa="sidebar-navigation-button-order-status-action"
      >
        <Typography variant="body2" color="inherit">
          Estado de Operaciones
        </Typography>
      </SideBarNavigationButton>

      <SideBarNavigationButton
        icon={XtrackerIcon}
        collapsed
        onClick={() => handleButtonClick('/xtracker')}
        active={location.pathname.includes('/xtracker')}
        dataQa="sidebar-navigation-button-xtracker-action"
      >
        <Typography variant="body2" color="inherit">
          Xtracker
        </Typography>
      </SideBarNavigationButton>
    </Grid>
  );
};

export default Sidebar;
