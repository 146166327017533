import axios from '../../helpers/axios';
import {
  BANK_ACCOUNT_CLEANUP,
  CREATE_BANK_ACCOUNT_FAILURE,
  CREATE_BANK_ACCOUNT_START,
  CREATE_BANK_ACCOUNT_SUCCESS,
  FETCH_BANK_ACCOUNTS_FAILURE,
  FETCH_BANK_ACCOUNTS_START,
  FETCH_BANK_ACCOUNTS_SUCCESS,
  GENERIC_BANK_ACCOUNT_RESET,
  GENERIC_DELETE_BANK_ACCOUNT_FAILURE,
  GENERIC_DELETE_BANK_ACCOUNT_START,
  GENERIC_DELETE_BANK_ACCOUNT_SUCCESS,
  GENERIC_FETCH_BANKS_FAILURE,
  GENERIC_FETCH_BANKS_START,
  GENERIC_FETCH_BANKS_SUCCESS,
  GENERIC_SET_DEFAULT_BANK_ACCOUNT_FAILURE,
  GENERIC_SET_DEFAULT_BANK_ACCOUNT_START,
  GENERIC_SET_DEFAULT_BANK_ACCOUNT_SUCCESS,
  SET_BANK_ACCOUNT_SOURCE,
  UPDATE_BANK_ACCOUNT_FAILURE,
  UPDATE_BANK_ACCOUNT_START,
  UPDATE_BANK_ACCOUNT_SUCCESS,
} from './types';

import { t13s } from '../../translationKeys';

import { enqueueSnackbar } from './notificationActions';

export const fetchBankAccounts = (sourceId, source) => dispatch => {
  dispatch({
    type: FETCH_BANK_ACCOUNTS_START,
    payload: { source, sourceId },
  });
  axios
    .get(`/api/${source}/${sourceId}/bankaccount`)
    .then(res => {
      dispatch({
        type: FETCH_BANK_ACCOUNTS_SUCCESS,
        payload: { bankAccounts: res.data, source, sourceId },
      });
    })
    .catch(error => {
      const errorCode = error?.response?.data?.errorCode;
      dispatch({
        type: FETCH_BANK_ACCOUNTS_FAILURE,
        payload: { errorCode, source, sourceId },
      });
    });
};

export const createBankAccount =
  (sourceId, source, bankAccountData) => dispatch => {
    dispatch({
      type: CREATE_BANK_ACCOUNT_START,
      payload: { source, sourceId },
    });
    axios
      .post(`/api/${source}/${sourceId}/bankaccount`, {
        ...bankAccountData,
      })
      .then(res => {
        dispatch({
          type: CREATE_BANK_ACCOUNT_SUCCESS,
          payload: { ...res.data },
        });
        dispatch(
          enqueueSnackbar({
            message: t13s.NOTIFICATION.CREATE_BANK_ACCOUNT_SUCCESS,
            options: {
              variant: 'success',
            },
          })
        );
      })
      .catch(error => {
        const errorCode = error?.response?.data?.errorCode;
        dispatch({
          type: CREATE_BANK_ACCOUNT_FAILURE,
          payload: { errorCode, source, sourceId },
        });
      });
  };

export const updateBankAccount =
  (sourceId, source, bankAccountId, bankAccountData) => dispatch => {
    dispatch({
      type: UPDATE_BANK_ACCOUNT_START,
      payload: { source, sourceId },
    });
    axios
      .put(
        `/api/${source}/${sourceId}/bankaccount/${bankAccountId}`,
        bankAccountData
      )
      .then(res => {
        dispatch({
          type: UPDATE_BANK_ACCOUNT_SUCCESS,
          payload: { ...res.data },
        });
        dispatch(
          enqueueSnackbar({
            message: t13s.NOTIFICATION.UPDATE_BANK_ACCOUNT_SUCCESS,
            options: {
              variant: 'success',
            },
          })
        );
      })
      .catch(error => {
        const errorCode = error?.response?.data?.errorCode;
        dispatch({
          type: UPDATE_BANK_ACCOUNT_FAILURE,
          payload: { errorCode, source, sourceId },
        });
      });
  };

export const setDefaultBankAccount =
  (sourceId, source, bankAccountId) => dispatch => {
    dispatch({
      type: GENERIC_SET_DEFAULT_BANK_ACCOUNT_START,
      payload: { source, sourceId },
    });
    // This is temporal and its used for sending the right data to each server
    axios
      .put(`/api/${source}/${sourceId}/bankaccount/${bankAccountId}/`, {
        isDefault: true,
      })
      .then(() => {
        dispatch({
          type: GENERIC_SET_DEFAULT_BANK_ACCOUNT_SUCCESS,
          payload: { bankAccountId, source },
        });
        dispatch(
          enqueueSnackbar({
            message: t13s.NOTIFICATION.UPDATE_DEFAULT_BANK_ACCOUNT_SUCCESS,
            options: {
              variant: 'success',
            },
          })
        );
      })
      .catch(error => {
        const errorCode = error?.response?.data?.errorCode;
        dispatch({
          type: GENERIC_SET_DEFAULT_BANK_ACCOUNT_FAILURE,
          payload: { errorCode, source, sourceId },
        });
      });
  };

export const deleteBankAccount =
  (sourceId, source, bankAccountId) => dispatch => {
    dispatch({
      type: GENERIC_DELETE_BANK_ACCOUNT_START,
      payload: { source, sourceId },
    });
    axios
      .delete(`/api/${source}/${sourceId}/bankaccount/${bankAccountId}`)
      .then(() => {
        dispatch({
          type: GENERIC_DELETE_BANK_ACCOUNT_SUCCESS,
          payload: { bankAccountId, source },
        });
        dispatch(
          enqueueSnackbar({
            message: t13s.NOTIFICATION.DELETE_BANK_ACCOUNT_SUCCESS,
            options: {
              variant: 'success',
            },
          })
        );
      })
      .catch(error => {
        const errorCode = error?.response?.data?.errorCode;
        dispatch({
          type: GENERIC_DELETE_BANK_ACCOUNT_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const resetBankAccount = source => dispatch => {
  dispatch({
    type: GENERIC_BANK_ACCOUNT_RESET,
    payload: { source },
  });
};

export const cleanupBankAccount = source => dispatch => {
  dispatch({
    type: BANK_ACCOUNT_CLEANUP,
    payload: { source },
  });
};

export const setBankAccountSourceObject =
  (source, sourceObject) => dispatch => {
    dispatch({
      type: SET_BANK_ACCOUNT_SOURCE,
      payload: { source, sourceObject },
    });
  };

export const fetchBanks = () => dispatch => {
  dispatch({ type: GENERIC_FETCH_BANKS_START });
  axios
    .get('/api/banks')
    .then(res => {
      const resData = res.data;
      dispatch({ type: GENERIC_FETCH_BANKS_SUCCESS, payload: resData });
      return resData;
    })
    .catch(err => {
      dispatch({ type: GENERIC_FETCH_BANKS_FAILURE, payload: err });
    });
};
