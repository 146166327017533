import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import BaseDialog from './BaseDialog';
import ConfirmAction from '../elements/ConfirmAction';

const ConfirmDialog = props => {
  const { isOpen, handleClose, title, message, buttonOnAccept, buttonLabel } =
    props;
  const [checked, setChecked] = useState(false);

  const closeDialogHandler = () => {
    handleClose();
    setChecked(false);
  };

  const confimHandler = () => {
    buttonOnAccept();
    setChecked(false);
  };

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  return (
    <BaseDialog
      title={title}
      isOpen={isOpen}
      handleClose={closeDialogHandler}
      description={message}
    >
      <Grid container direction="column" align="center">
        <Grid item>
          <ConfirmAction
            buttonLabel={buttonLabel}
            buttonOnAccept={confimHandler}
            checked={checked}
            onChangeCheck={handleCheckboxChange}
          />
        </Grid>
      </Grid>
    </BaseDialog>
  );
};

ConfirmDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonOnAccept: PropTypes.func.isRequired,
};

export default ConfirmDialog;
