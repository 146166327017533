import { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  resetFinancing,
  resetPayroll,
} from '../../../store/actions/payrollActions';

import { SEGMENT_EVENTS } from '../../../helpers/segment/constants';
import { trackSegment } from '../../../helpers/segment/trackingSegment';

const usePayInvoices = ({ businessId }) => {
  const dispatch = useDispatch();

  const [showPayInvoicesStepper, setShowPayInvoicesStepper] = useState(false);
  const [dataInvoicesToPay, setDataInvoicesToPay] = useState({
    businessId,
    payrollId: 0,
    invoicesTotalAmount: 0,
    payrollPaymentDate: '',
    invoicesQuantities: 0,
    providersQuantities: 0,
  });

  const handleClosePayModal = () => {
    setShowPayInvoicesStepper(false);
    dispatch(resetFinancing());
    dispatch(resetPayroll());
  };

  const handlePayInvoicesButton = ({
    payrollId,
    totalInvoices,
    totalSuppliers,
    paymentDate,
    summedAmount,
  }) => {
    setDataInvoicesToPay({
      payrollId,
      invoicesTotalAmount: summedAmount,
      payrollPaymentDate: paymentDate,
      invoicesQuantities: totalInvoices,
      providersQuantities: totalSuppliers,
    });
    setShowPayInvoicesStepper(true);
    trackSegment(SEGMENT_EVENTS.PAYMENT_INVOICES_SCHEDULED_CLICKED, {
      businessId,
    });
  };

  return {
    dataInvoicesToPay,
    showPayInvoicesStepper,
    handleClosePayModal,
    handlePayInvoicesButton,
  };
};

export default usePayInvoices;
