import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const LinkedinIcon = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g id="Grupo_776" data-name="Grupo 776">
        <path
          id="Trazado_69"
          data-name="Trazado 69"
          d="M21.66,14.29v7.24H17.47V14.78c0-1.7-.61-2.85-2.13-2.85a2.29,2.29,0,0,0-2.15,1.53,2.74,2.74,0,0,0-.14,1v7.05H8.85s.06-11.43,0-12.62h4.2V10.7l0,0h0v0a4.16,4.16,0,0,1,3.78-2.09c2.76,0,4.83,1.81,4.83,5.68ZM2.34,21.53H6.53V8.91H2.34Zm0,0L6.66,4.63A2.16,2.16,0,1,1,4.5,2.47,2.16,2.16,0,0,1,6.66,4.63h0Z"
        />
      </g>
    </SvgIcon>
  );
};

export default LinkedinIcon;
