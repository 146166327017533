import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const sideButtonStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.grey[600],
    margin: 0,
    width: '100%',
    whiteSpace: 'nowrap',
    borderRadius: 100,
    textTransform: 'none',
    marginRight: 0,
    backgroundColor: theme.palette.transparent,
    boxShadow: 'none',
    transition: '.2s',
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    position: 'relative',
    padding: '10px 18px',
    '&:hover': {
      backgroundColor: theme.palette.transparent,
      color: theme.palette.grey[800],
    },
    '&:active': {
      backgroundColor: theme.palette.transparent,
      boxShadow: 'none',
    },
    '&:focus': {
      backgroundColor: theme.palette.transparent,
    },
  },
  label: {
    justifyContent: 'flex-start',
  },
}));

const SideButton = React.forwardRef((props, ref) => {
  const classes = sideButtonStyles();
  // eslint-disable-next-line react/prop-types
  const { classes: customClasses } = props;
  return (
    <Button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={ref}
      classes={{ ...classes, ...customClasses }}
    />
  );
});

const collapsedButtonStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    gridGap: 10,
    justifyItems: 'center',
    textAlign: 'center',
    fontSize: theme.typography.fontSize,
    cursor: 'pointer',
    padding: '20px 10px',
    borderRadius: '6px',
    color: theme.palette.grey[800],
    textDecoration: 'none',
    gridAutoRows: 'min-content',
    height: 'min-content',
    '&:hover': {
      backgroundColor: theme.palette.transparent,
      textDecoration: 'none',
      color: theme.palette.secondary.main,
    },
    '&:active': {
      backgroundColor: theme.palette.transparent,
    },
    '&:focus': {
      backgroundColor: theme.palette.transparent,
    },
    '&.active': {
      background: theme.palette.grey[200],
      color: theme.palette.secondary.main,
    },
  },
}));

const CollapsedButton = React.forwardRef((props, ref) => {
  const classes = collapsedButtonStyles();
  return (
    <Button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={ref}
      classes={{ ...classes }}
    />
  );
});

const useStyles = makeStyles(theme => ({
  buttonsActive: {
    backgroundColor: theme.palette.grey[300],
    transition: '.2s',
    fontSize: theme.typography.fontSize,
    color: theme.palette.text.primary,
    boxShadow: 'none',
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.grey[300],
    },
    '&:active': {
      backgroundColor: theme.palette.grey[300],
    },
    '&:focus': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  notification: {
    '&:after': {
      content: "''",
      display: 'block',
      position: 'absolute',
      height: 8,
      width: 8,
      borderRadius: 100,
      backgroundColor: theme.palette.secondary.main,
      left: 8,
      top: 'calc(50% - 4px)',
      zIndex: 1,
    },
  },
  hasIcon: {
    display: 'grid',
    gridGap: 5,
    gridTemplateColumns: 'min-content 1fr',
    justifyItems: 'flex-start',
  },
}));

const SideBarNavigationButton = ({
  collapsed,
  icon,
  onClick,
  disabled,
  children,
  active,
  notification,
  dataQa,
}) => {
  const classes = useStyles();

  return (
    <>
      {collapsed ? (
        <CollapsedButton
          color="primary"
          onClick={onClick}
          disabled={disabled}
          className={active ? 'active' : ''}
          data-qa={dataQa}
        >
          {icon && <Icon component={icon} />}
          {children}
        </CollapsedButton>
      ) : (
        <SideButton
          disabled={disabled}
          aria-label="menu"
          classes={{
            label: icon ? classes.hasIcon : '',
          }}
          className={`
        ${active ? classes.buttonsActive : ''} 
        ${notification ? classes.notification : ''}
        ${icon ? classes.hasIcon : ''}
      `}
          onClick={onClick}
          data-qa={dataQa}
        >
          {icon && icon}
          {children}
        </SideButton>
      )}
    </>
  );
};

SideBarNavigationButton.defaultProps = {
  collapsed: false,
  active: false,
  notification: false,
  disabled: false,
  icon: null,
  children: null,
  dataQa: null,
};

SideBarNavigationButton.propTypes = {
  collapsed: PropTypes.bool,
  active: PropTypes.bool,
  notification: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  dataQa: PropTypes.string,
};

export default SideBarNavigationButton;
