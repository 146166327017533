import {
  DRAWER_BACKGROUND,
  TABLE_HEADER_TEXT,
  TOOLTIP_COLOR,
} from './otherColors';
import { palette } from './palette';

const hex2rgba = (hex, a) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);

  return `rgba(${r},${g},${b},${a})`;
};

// eslint-disable-next-line import/prefer-default-export
export const themeOverrides = {
  MuiButton: {
    root: {
      borderRadius: 50,
      textTransform: 'none',
      boxShadow: 'none !important',
    },
    outlined: {
      paddingLeft: 34,
      paddingRight: 34,
    },
    contained: {
      paddingLeft: 34,
      paddingRight: 34,
    },
    containedSecondary: {
      color: 'white',
    },
    outlinedSizeLarge: {
      '& .MuiButton-label': { height: 22 },
      paddingTop: 7,
      paddingBottom: 7,
    },
    containedSizeLarge: {
      '& .MuiButton-label': { height: 22 },
      paddingTop: 7,
      paddingBottom: 7,
    },
  },
  MuiCheckbox: {
    root: {
      padding: '5px',
    },
  },
  MuiChip: {
    clickable: {
      '&:active': {
        boxShadow: 0,
      },
    },
    '&:focus': {
      backgroundColor: 'red',
    },
  },
  MuiDrawer: {
    paper: {
      background: DRAWER_BACKGROUND,
    },
  },
  MuiError: {
    root: {
      color: palette.error.main,
    },
  },
  MuiFormHelperText: {
    root: {
      fontWeight: 600,
    },
  },
  MuiIconButton: {
    colorPrimary: {
      '&:hover': {
        backgroundColor: hex2rgba(palette.primary.main, 0.1),
      },
    },
    colorSecondary: {
      '&:hover': {
        backgroundColor: hex2rgba(palette.secondary.main, 0.1),
      },
    },
    root: {
      padding: 5,
      color: palette.text.primary,
      '&.Mui-disabled': {
        color: palette.text.disabled,
      },
    },
    sizeSmall: {
      '& .MuiSvgIcon-root': {
        fontSize: 16,
      },
    },
  },
  MuiInputAdornment: {
    root: {
      color: 'rgba(0, 0, 0, 0.23)',
    },
    marginDense: {
      '& .MuiSvgIcon-root': {
        fontSize: 16,
      },
    },
  },
  MuiInputLabel: {
    outlined: {
      transform: 'translate(14px, 12px) scale(1)',
    },
    marginDense: {
      transform: 'translate(14px, 4px) scale(1) !important',
    },
    shrink: {
      transform: 'translate(14px, -6px) scale(0.75) !important',
    },
  },
  MuiOutlinedInput: {
    root: {
      '& input:valid + fieldset': {
        borderColor: palette.grey[500],
      },
      '&:hover': {
        borderColor: `${palette.grey[800]} !important`,
      },
      '& .Mui-disabled': {
        background: palette.grey[200],
        borderRadius: 40,
      },
      '& fieldset': {
        borderRadius: '40px',
      },
    },
    input: {
      padding: '10.5px 14px',
    },
    inputMarginDense: {
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  MuiSelect: {
    selectMenu: {
      minHeight: 15,
    },
  },
  MuiSvgIcon: {
    fontSizeSmall: {
      fontSize: 16,
    },
    colorDisabled: {
      color: palette.text.disabled,
    },
    colorAction: {
      color: palette.text.primary,
    },
  },
  MuiTableCell: {
    head: {
      color: TABLE_HEADER_TEXT,
    },
  },
  MuiTableRow: {
    root: {
      transition: 'unset !important',
      '&:hover': {
        backgroundColor: `${palette.grey[200]} !important`,
        '& .MuiStepConnector-line': {
          background: `${palette.grey[400]} !important`,
        },
      },
    },
    footer: {
      textAlign: '-webkit-center',
      '&:hover': {
        backgroundColor: '#FFF !important',
      },
    },
  },
  MuiTextField: {
    root: {
      marginBottom: 15,
    },
    nativeInput: {
      boxSizing: 'inherit',
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: 10,
      backgroundColor: TOOLTIP_COLOR,
    },
  },
  MuiAutocomplete: {
    inputRoot: {
      '&[class*="MuiOutlinedInput-root"]': {
        padding: '1px 9px',
        borderRadius: 50,
      },
      '&.Mui-disabled': {
        background: palette.grey[200],
      },
    },
  },
};
