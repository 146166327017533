import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { getAcronym } from './utils';

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.primary.main,
    marginRight: 16,
  },
}));

const SupplierAvatar = ({ name, identifier }) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="flex-start" alignItems="center">
      <Grid item>
        <Avatar className={classes.avatar}>
          <Box fontWeight="fontWeightBold">{getAcronym(name)}</Box>
        </Avatar>
      </Grid>
      <Grid item>
        <Typography variant="h6" component="div" color="textPrimary">
          <Box fontWeight="fontWeightBold">{name}</Box>
        </Typography>
        <Typography variant="subtitle2" component="div" color="textSecondary">
          <Box fontWeight="fontWeightBold">{identifier}</Box>
        </Typography>
      </Grid>
    </Grid>
  );
};

SupplierAvatar.propTypes = {
  identifier: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default SupplierAvatar;
