const SCHEDULED_READY_TO_PAY = 'SCHEDULED_READY_TO_PAY';

const statusStyles = {
  SCHEDULED_READY_TO_PAY: { color: 'success.main' },
  FUNDING_AWAITING: { color: 'text.primary' },
  FUNDING_COMPLETE: { color: 'text.primary' },
  FUNDING_INCOMPLETE: { color: 'warning.main' },
  PAYMENT_IN_PROCESS: { color: 'text.primary' },
  PAYMENT_PARTIALLY_PAID: { color: 'secondary.main' },
  PAYMENT_PROBLEM: { color: 'secondary.main' },
  REJECTED: { color: 'secondary.main' },
  PAID: { color: 'success.main' },
  FINANCING_REQUESTED: { color: 'text.primary' },
  FINANCING_REJECTED: { color: 'secondary.main' },
  INCOMPLETE_DATA: { color: 'warning.main' },
};

export { SCHEDULED_READY_TO_PAY, statusStyles };
