import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Grid,
  Divider,
  Card,
  IconButton,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { paymentsFetchInvoices } from '../store/actions/paymentsActions';
import { fetchPayroll } from '../store/actions/payrollActions';
import PaymentsInvoiceTable from './tables/PaymentsInvoiceTable';
import Loader from './elements/Loader';
import CountryFormatHelper from './elements/CountryFormatHelper';

const useStyles = makeStyles({
  root: {
    padding: '50px 90px 0px 90px',
  },
  card: {
    width: '100%',
    padding: '30px 65px',
    borderRadius: 20,
  },
  backButton: {
    paddingRight: 15,
  },
  payrollName: {
    paddingBottom: 10,
  },
  detailTextItem: {
    padding: '0 15px 0 15px',
  },
  detailTextLeftItem: {
    paddingRight: 15,
  },
  detailTextRightItem: {
    paddingLeft: 15,
  },
  invoiceTable: {
    width: '100%',
    paddingTop: 20,
  },
});

const PayrollDetails = ({ payrollId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const business = useSelector(state => state.businessState.business);
  const { payroll } = useSelector(state => state.payrollsState);

  const {
    invoices,
    pagination: { page, pageSize },
  } = useSelector(state => state.paymentsState);

  const handleBackButton = () => {
    history.goBack();
  };

  const handleUpdateInvoices = (page, pageSize) => {
    dispatch(
      paymentsFetchInvoices({
        businessId: business.id,
        payrollId,
        pageSize,
        page: page + 1,
      })
    );
  };

  useEffect(() => {
    if (!business) return;
    dispatch(
      paymentsFetchInvoices({
        businessId: business.id,
        payrollId,
        pageSize,
        page,
      })
    );
  }, [business, payrollId, pageSize, page, dispatch]);

  useEffect(() => {
    if (!business) return;
    dispatch(fetchPayroll(payrollId, business.id));
  }, [payrollId, business, dispatch]);

  if (!business) return <Loader />;

  return (
    <Grid container className={classes.root}>
      <Card className={classes.card}>
        <Grid container name="card-root">
          <Grid item name="title">
            <Grid container alignItems="center">
              <Grid item name="back" className={classes.backButton}>
                <IconButton variant="oulined" onClick={handleBackButton}>
                  <ArrowBackIcon />
                </IconButton>
              </Grid>
              <Grid item name="payroll">
                {payroll && (
                  <Grid container direction="column">
                    <Grid
                      item
                      name="payroll-name"
                      className={classes.payrollName}
                    >
                      <Typography variant="h4" component="div">
                        <Box fontWeight="fontWeightBold">{payroll.name}</Box>
                      </Typography>
                    </Grid>
                    <Grid item name="payroll-details">
                      <Grid container>
                        <Grid item className={classes.detailTextLeftItem}>
                          <Typography variant="subtitle2" component="div">
                            <Box color="text.primary">ID: {payroll.id}</Box>
                          </Typography>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item className={classes.detailTextItem}>
                          <Typography variant="subtitle2" component="div">
                            <Box color="text.primary">
                              Fecha de pago:{' '}
                              <CountryFormatHelper
                                value={payroll.paymentDate}
                                variant="longdate"
                                countryId={business.countryId}
                              />
                            </Box>
                          </Typography>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item className={classes.detailTextItem}>
                          <Typography variant="subtitle2" component="div">
                            <Box color="text.primary">
                              Facturas: {payroll.totalInvoices}
                            </Box>
                          </Typography>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item className={classes.detailTextRightItem}>
                          <Typography variant="subtitle2" component="div">
                            <Box color="text.primary">
                              Proveedores: {payroll.totalSuppliers}
                            </Box>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item name="invoice-table" className={classes.invoiceTable}>
            <PaymentsInvoiceTable
              rows={invoices}
              countryId={business.countryId}
              handleUpdateInvoices={handleUpdateInvoices}
            />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

PayrollDetails.propTypes = {
  payrollId: PropTypes.number.isRequired,
};

export default PayrollDetails;
