import React from 'react';
import { Grid, makeStyles, Typography, Box } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    marginTop: 250,
    justifyContent: 'center',
    width: 532,
    margin: '0 auto',
  },
  subtitle: {
    marginTop: 20,
  },
});

const HomeMaintenance = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      item
      className={classes.root}
      align="center"
      direction="column"
    >
      <Typography color="textPrimary" variant="h1" component="div">
        <span role="img" aria-label="rocket emoji">
          🚀
        </span>
      </Typography>
      <Typography color="textPrimary" variant="h3" component="div">
        <Box fontWeight="fontWeightBold">Disculpa las molestias</Box>
      </Typography>
      <Typography
        color="textPrimary"
        variant="h5"
        component="div"
        className={classes.subtitle}
      >
        Estamos realizando una breve mantención en nuestros sistemas.
      </Typography>
    </Grid>
  );
};

export default HomeMaintenance;
