import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, makeStyles } from '@material-ui/core';
import Card from './elements/Card';
import OrdersTable from './tables/OrdersTable';
import { fetchOrders } from '../store/actions/ordersActions';

const useStyles = makeStyles({
  root: {
    margin: 20,
  },
});

const HomeOrders = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const business = useSelector(state => state.businessState.business) || {};
  const orders = useSelector(state => state.ordersState.orders) || [];
  const pagination = useSelector(state => state.ordersState.pagination) || {};
  const ordersIsLoading = useSelector(
    state => state.ordersState.ordersIsLoading
  );

  const { id: businessId, countryId } = business;
  const { page, pageSize } = pagination;

  useEffect(() => {
    dispatch(fetchOrders(businessId, pageSize, page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateOrders = (page, pageSize) => {
    dispatch(fetchOrders(businessId, pageSize, page + 1));
  };

  return (
    <Grid item xs={12} className={classes.root}>
      <Card border variant="white" padding="sm zero">
        <OrdersTable
          countryId={countryId}
          tableData={orders}
          isLoading={ordersIsLoading}
          pagination={pagination}
          updateOrders={handleUpdateOrders}
        />
      </Card>
    </Grid>
  );
};

export default HomeOrders;
