import { useSelector } from 'react-redux';
import { useSharedTableHeaders } from '../commons';
import filterHeaderByCountry from '../../utils/filterHeaderByCountry';

const useArchivedTableHeaders = () => {
  const { countryId } = useSelector(state => state.businessState.business);
  const {
    folio,
    supplierName,
    identifier,
    issueDate,
    paymentDate,
    paymentMethod,
    totalAmount,
  } = useSharedTableHeaders();

  const headers = {
    folio,
    supplierName,
    identifier,
    issueDate,
    paymentDate,
    paymentMethod,
    totalAmount,
  };

  return filterHeaderByCountry({ countryId, headers });
};

export default useArchivedTableHeaders;
