import { FETCH_HOLIDAYS_START, FETCH_HOLIDAYS_SUCCESS } from './types';

// eslint-disable-next-line import/prefer-default-export
export const fetchHolidays = () => dispatch => {
  dispatch({ type: FETCH_HOLIDAYS_START });

  const holidays = [
    { name: 'Día de la Revolución', date: '2021-11-15' },
    { name: 'Navidad', date: '2021-12-25' },
    { name: 'Año Nuevo', date: '2022-01-01' },
    { name: 'Día de la Constitución', date: '2022-02-07' },
    { name: 'Día del Trabajo', date: '2022-05-01' },
    { name: 'Día de la Independencia', date: '2022-09-16' },
    { name: 'Día de la Revolución', date: '2022-11-21' },
    { name: 'Navidad', date: '2022-12-25' },
  ];
  dispatch({ type: FETCH_HOLIDAYS_SUCCESS, payload: { holidays } });
};
