import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const OnboardPageCloudsIcon = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 1229 152">
      <path
        d="M266.434 152C147 93.7333 38.7297 129.895 0 152V10C0 4.47715 4.47714 0 9.99999 0H1219C1224.52 0 1229 4.47715 1229 10V152C1154.48 112.658 1076.05 135.608 1043 152C1023.5 141 998 141.5 980 152C856.5 101.5 743.412 131.137 695 152C654.935 126.369 601.7 141.32 581 152C508.883 104.313 419.393 132.13 383 152C339 126.667 289 138.5 266.434 152Z"
        fill="#E6F0FF"
      />
    </SvgIcon>
  );
};

export default OnboardPageCloudsIcon;
