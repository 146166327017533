import { PURGE } from 'redux-persist';
import {
  BANK_ACCOUNT_CLEANUP,
  CREATE_BANK_ACCOUNT_FAILURE,
  CREATE_BANK_ACCOUNT_START,
  CREATE_BANK_ACCOUNT_SUCCESS,
  FETCH_BANK_ACCOUNTS_FAILURE,
  FETCH_BANK_ACCOUNTS_START,
  FETCH_BANK_ACCOUNTS_SUCCESS,
  GENERIC_BANK_ACCOUNT_RESET,
  GENERIC_DELETE_BANK_ACCOUNT_FAILURE,
  GENERIC_DELETE_BANK_ACCOUNT_START,
  GENERIC_DELETE_BANK_ACCOUNT_SUCCESS,
  GENERIC_FETCH_BANKS_FAILURE,
  GENERIC_FETCH_BANKS_START,
  GENERIC_FETCH_BANKS_SUCCESS,
  GENERIC_SET_DEFAULT_BANK_ACCOUNT_FAILURE,
  GENERIC_SET_DEFAULT_BANK_ACCOUNT_START,
  GENERIC_SET_DEFAULT_BANK_ACCOUNT_SUCCESS,
  LOCAL_PURGE,
  SET_BANK_ACCOUNT_SOURCE,
  UPDATE_BANK_ACCOUNT_FAILURE,
  UPDATE_BANK_ACCOUNT_START,
  UPDATE_BANK_ACCOUNT_SUCCESS,
} from '../actions/types';
import { t13s } from '../../translationKeys';

const initialState = {
  supplier: null,
  supplierBankAccountCreated: false,
  supplierBankAccounts: [],
  supplierBankAccountsError: null,
  supplierBankAccountUpdated: false,
  supplierCreateBankAccountError: null,
  supplierCreatingBankAccount: false,
  supplierDeleteBankAccountError: null,
  supplierDeletingBankAccount: false,
  supplierFetchBankAccountError: null,
  supplierFetchingBankAccounts: false,
  supplierSetDefaultAccountError: null,
  supplierSettingDefaultBankAccount: false,
  supplierUpdateBankAccountError: null,
  supplierUpdatingBankAccount: false,

  banks: [],
  fetchingBanks: false,
  fetchBanksError: false,
};

const fetchBankAccountsStart = (state, payload) => {
  const { source } = payload;
  return {
    ...state,
    [`${source}FetchBankAccountError`]: null,
    [`${source}FetchingBankAccounts`]: true,
  };
};

const fetchBankAccountsSuccess = (state, payload) => {
  const { source, bankAccounts } = payload;
  return {
    ...state,
    [`${source}FetchingBankAccounts`]: false,
    [`${source}BankAccounts`]: bankAccounts,
  };
};

const fetchBankAccountsFailure = (state, payload) => {
  const { source, errorCode } = payload;
  return {
    ...state,
    [`${source}FetchingBankAccounts`]: false,
    [`${source}FetchBankAccountError`]: errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const createBankAccountStart = (state, payload) => {
  const { source } = payload;
  return {
    ...state,
    [`${source}BankAccountCreated`]: false,
    [`${source}CreateBankAccountError`]: null,
    [`${source}CreatingBankAccount`]: true,
  };
};

const createBankAccountSuccess = (state, payload) => {
  const {
    createdAt,
    updatedAt,
    sourceId,
    source,
    countryId,
    ...restOfBankAccount
  } = payload;

  const bank = state.banks.find(b => b.id === payload.bankId);

  const newBankAccount = {
    ...restOfBankAccount,
    Bank: { id: payload.bankId, name: bank.name },
  };

  const { [`${source}BankAccounts`]: stateBankAccounts } = state;
  return {
    ...state,
    [`${source}BankAccountCreated`]: true,
    [`${source}CreatingBankAccount`]: false,
    [`${source}BankAccounts`]: [...stateBankAccounts, newBankAccount],
  };
};

const createBankAccountFailure = (state, payload) => {
  const { source } = payload;
  return {
    ...state,
    [`${source}CreatingBankAccount`]: false,
    [`${source}CreateBankAccountError`]:
      payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const updateBankAccountStart = (state, payload) => {
  const { source } = payload;
  return {
    ...state,
    [`${source}BankAccountUpdated`]: false,
    [`${source}UpdateBankAccountError`]: null,
    [`${source}UpdatingBankAccount`]: true,
  };
};

const updateBankAccountSuccess = (state, payload) => {
  const {
    createdAt,
    updatedAt,
    sourceId,
    source,
    countryId,
    ...restOfBankAccount
  } = payload;

  const bank = state.banks.find(b => b.id === payload.bankId);
  const { [`${source}BankAccounts`]: stateBankAccounts } = state;

  const newBankAccount = {
    ...restOfBankAccount,
    Bank: { id: payload.bankId, name: bank.name },
  };
  const bankAccounts = stateBankAccounts.filter(
    bankAccount => bankAccount.id !== newBankAccount.id
  );

  if (payload.isDefault) {
    bankAccounts.unshift(newBankAccount);
  } else {
    bankAccounts.push(newBankAccount);
  }

  return {
    ...state,
    [`${source}BankAccountUpdated`]: true,
    [`${source}UpdatingBankAccount`]: false,
    [`${source}BankAccounts`]: bankAccounts,
  };
};

const updateBankAccountFailure = (state, payload) => {
  const { source } = payload;
  return {
    ...state,
    [`${source}UpdatingBankAccount`]: false,
    [`${source}UpdateBankAccountError`]:
      payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const fetchBanksStart = state => {
  return {
    ...state,
    fetchBanksError: false,
    fetchingBanks: true,
  };
};

const fetchBanksSuccess = (state, payload) => {
  return {
    ...state,
    fetchBanksError: false,
    fetchingBanks: false,
    banks: payload,
  };
};

const fetchBanksError = (state, payload) => {
  return {
    ...state,
    fetchingBanks: false,
    fetchBanksError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const setDefaultBankAccountStart = (state, payload) => {
  const { source } = payload;
  return {
    ...state,
    [`${source}SettingDefaultBankAccount`]: true,
    [`${source}SetDefaultAccountError`]: null,
  };
};

const setDefaultBankAccountSuccess = (state, payload) => {
  const { source, bankAccountId } = payload;
  const { [`${source}BankAccounts`]: stateBankAccounts } = state;
  const newDefaultBankAccount = stateBankAccounts.find(
    account => account.id === bankAccountId
  );

  const previousDefaultBankAccount = stateBankAccounts.find(account =>
    newDefaultBankAccount.isXepelin
      ? account.isDefault && account.isXepelin
      : account.isDefault && !account.isXepelin
  );
  const bankAccounts = stateBankAccounts.filter(
    account =>
      account.id !== newDefaultBankAccount.id &&
      account.id !== previousDefaultBankAccount.id
  );
  newDefaultBankAccount.isDefault = true;
  previousDefaultBankAccount.isDefault = false;
  return {
    ...state,
    [`${source}SettingDefaultBankAccount`]: false,
    [`${source}SetDefaultAccountError`]: false,
    [`${source}BankAccounts`]: [
      newDefaultBankAccount,
      previousDefaultBankAccount,
      ...bankAccounts,
    ],
  };
};

const setDefaultBankAccountFailure = (state, payload) => {
  const { source } = payload;
  return {
    ...state,
    [`${source}SettingDefaultBankAccount`]: false,
    [`${source}BankAccountError`]:
      payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const deleteBankAccountStart = (state, payload) => {
  const { source } = payload;
  return {
    ...state,
    [`${source}DeletingBankAccount`]: true,
    [`${source}DeleteBankAccountError`]: null,
  };
};

const deleteBankAccountSuccess = (state, payload) => {
  const { source, bankAccountId } = payload;
  const { [`${source}BankAccounts`]: stateBankAccounts } = state;

  const updatedBankAccounts = stateBankAccounts.filter(
    account => account.id !== bankAccountId
  );
  return {
    ...state,
    [`${source}DeletingBankAccount`]: false,
    [`${source}DeleteBankAccountError`]: null,
    [`${source}BankAccounts`]: updatedBankAccounts,
  };
};

const deleteBankAccountFailure = (state, payload) => {
  const { source } = payload;
  return {
    ...state,
    [`${source}DeletingBankAccount`]: false,
    [`${source}DeleteBankAccountError`]:
      payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const bankAccountReset = (state, payload) => {
  const { source } = payload;
  return {
    ...state,
    [`${source}CreatingBankAccount`]: false,
    [`${source}CreateBankAccountError`]: null,
    [`${source}BankAccountCreated`]: false,
    [`${source}UpdatingBankAccount`]: false,
    [`${source}UpdateBankAccountError`]: null,
    [`${source}BankAccountUpdated`]: false,
  };
};

const bankAccountCleanup = (state, payload) => {
  const { source } = payload;
  return {
    ...state,
    [`${source}`]: null,
    [`${source}BankAccounts`]: [],
    [`${source}CreatingBankAccount`]: false,
    [`${source}CreateBankAccountError`]: null,
    [`${source}BankAccountCreated`]: false,
    [`${source}UpdatingBankAccount`]: false,
    [`${source}UpdateBankAccountError`]: null,
    [`${source}BankAccountUpdated`]: false,
  };
};

const setBankAccountSourceObject = (state, payload) => {
  const { source, sourceObject } = payload;
  return {
    ...state,
    [`${source}`]: sourceObject,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_BANK_ACCOUNTS_START:
      return fetchBankAccountsStart(state, payload);
    case FETCH_BANK_ACCOUNTS_SUCCESS:
      return fetchBankAccountsSuccess(state, payload);
    case FETCH_BANK_ACCOUNTS_FAILURE:
      return fetchBankAccountsFailure(state, payload);
    case CREATE_BANK_ACCOUNT_START:
      return createBankAccountStart(state, payload);
    case CREATE_BANK_ACCOUNT_SUCCESS:
      return createBankAccountSuccess(state, payload);
    case CREATE_BANK_ACCOUNT_FAILURE:
      return createBankAccountFailure(state, payload);
    case UPDATE_BANK_ACCOUNT_START:
      return updateBankAccountStart(state, payload);
    case UPDATE_BANK_ACCOUNT_SUCCESS:
      return updateBankAccountSuccess(state, payload);
    case UPDATE_BANK_ACCOUNT_FAILURE:
      return updateBankAccountFailure(state, payload);
    case GENERIC_SET_DEFAULT_BANK_ACCOUNT_START:
      return setDefaultBankAccountStart(state, payload);
    case GENERIC_SET_DEFAULT_BANK_ACCOUNT_SUCCESS:
      return setDefaultBankAccountSuccess(state, payload);
    case GENERIC_SET_DEFAULT_BANK_ACCOUNT_FAILURE:
      return setDefaultBankAccountFailure(state, payload);
    case GENERIC_DELETE_BANK_ACCOUNT_START:
      return deleteBankAccountStart(state, payload);
    case GENERIC_DELETE_BANK_ACCOUNT_SUCCESS:
      return deleteBankAccountSuccess(state, payload);
    case GENERIC_DELETE_BANK_ACCOUNT_FAILURE:
      return deleteBankAccountFailure(state, payload);
    case GENERIC_BANK_ACCOUNT_RESET:
      return bankAccountReset(state, payload);
    case BANK_ACCOUNT_CLEANUP:
      return bankAccountCleanup(state, payload);
    case GENERIC_FETCH_BANKS_START:
      return fetchBanksStart(state);
    case GENERIC_FETCH_BANKS_SUCCESS:
      return fetchBanksSuccess(state, payload);
    case GENERIC_FETCH_BANKS_FAILURE:
      return fetchBanksError(state, payload);
    case SET_BANK_ACCOUNT_SOURCE:
      return setBankAccountSourceObject(state, payload);

    case LOCAL_PURGE:
      return initialState;
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
