import React, { useState, useRef, cloneElement, Children } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import PropTypes from 'prop-types';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { palette } from '../../theme/palette';
import { typography } from '../../theme/typography';
import { themeOverrides } from '../../theme/themeOverrides';

const theme = createMuiTheme({
  palette: {
    primary: palette.secondary,
  },
  typography,
  overrides: themeOverrides,
});

const PaymentsTableDatePicker = props => {
  const { children, onChange, date, invoice } = props;
  const [isOpenPicker, setIsOpenPicker] = useState(false);
  const [selectedDate, handleDateChange] = useState(moment.utc(date));
  const ToggleComponentRef = useRef();

  const togglePicker = () => setIsOpenPicker(!isOpenPicker);

  const handlePicker = event => {
    event.preventDefault();
    setIsOpenPicker(!isOpenPicker);
  };

  const customChangePicker = newDate =>
    onChange({ newDate, handleDateChange, togglePicker, invoice });

  // children has to be an element (not div or span) ie: button or anchor
  const ChildComponent = () =>
    Children.map(children, child => {
      return cloneElement(child, {
        ref: ToggleComponentRef,
        onClick: handlePicker,
      });
    });

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={theme}>
        <DatePicker
          disableToolbar
          variant="inline"
          value={selectedDate}
          open={isOpenPicker}
          onOpen={togglePicker}
          onChange={customChangePicker}
          onClose={togglePicker}
          // eslint-disable-next-line react/jsx-props-no-spreading
          TextFieldComponent={props => <ChildComponent {...props} />}
          PopoverProps={{
            anchorEl: () => ToggleComponentRef.current,
          }}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

PaymentsTableDatePicker.defaultProps = {
  date: new Date(),
};

PaymentsTableDatePicker.propTypes = {
  children: PropTypes.element.isRequired,
  onChange: PropTypes.func.isRequired,
  date: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any),
  ]),
  invoice: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default PaymentsTableDatePicker;
