import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Grid, Typography, makeStyles, Box, Link } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Card from './Card';
import {
  AVAILABLE_COUNTRIES,
  COUNTRY_CODE_CL,
  COUNTRY_CODE_MX,
} from '../../helpers/constants';
import CountryFormatHelper from './CountryFormatHelper';
import { t13s } from '../../translationKeys';
import DicomIcon from '../icons/DicomIcon';
import BuroIcon from '../icons/BuroIcon';
import { HashKey } from '../../helpers/hashKeys';
import LockIcon from '../icons/LockIcon';

const useStyles = makeStyles({
  rootCard: {
    display: 'inline-flex',
    boxSizing: 'border-box',
    minHeight: 161,
    minWidth: 260,
    width: 260,
    padding: '20px 25px',
  },
  cantConsultRoot: {
    padding: '10px 36px',
    alignItems: 'center',
  },
  icon: {
    marginRight: 20,
  },
  title: {
    marginBottom: 15,
  },
  label: {
    marginTop: 20,
  },
  lockIcon: {
    height: 33,
  },
  link: {
    marginTop: 5,
    textDecoration: 'underline',
  },
});

const LOADING = 'LOADING';
const CANT_CONSULT = 'CANT_CONSULT';
const NOT_CONSULTED = 'NOT_CONSULTED';
const CONSULTED = 'CONSULTED';

const DebtServiceCard = ({ countryId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { canConsult, debtService, fetchIsLoading } = useSelector(
    state => state.debtServiceState
  );
  const businessId = useSelector(state => state.businessState.business.id);

  const { totalAmount, createdAt } = debtService || {};
  const variant = fetchIsLoading
    ? LOADING
    : !canConsult
    ? CANT_CONSULT
    : totalAmount !== undefined
    ? CONSULTED
    : NOT_CONSULTED;

  const icon = {
    [COUNTRY_CODE_MX]: <BuroIcon className={classes.icon} color="secondary" />,
    [COUNTRY_CODE_CL]: <DicomIcon className={classes.icon} color="secondary" />,
  };
  const bodyText = {
    [CANT_CONSULT]: `Para ver tu información del ${t(
      t13s.LABEL.DEBT_SERVICE
    )} aquí, ingresa los datos para autorizarnos.`,
    [NOT_CONSULTED]: `Aún no hemos consultado tu estado en el ${t(
      t13s.LABEL.DEBT_SERVICE
    )}.`,
  };

  const getLink = () => {
    switch (countryId) {
      case COUNTRY_CODE_MX: {
        const hashedBusinessId = HashKey(businessId);
        const personType = HashKey(0);
        return `${process.env.REACT_APP_CLIENT_LEGAL}/form?id=${hashedBusinessId}&type=${personType}`;
      }
      default:
        return '';
    }
  };

  const getBody = () => {
    switch (variant) {
      case CANT_CONSULT:
        return (
          <Grid
            container
            direction="column"
            align="center"
            className={clsx(classes.rootCard, classes.cantConsultRoot)}
          >
            <LockIcon className={classes.lockIcon} color="secondary" />
            <Typography variant="h6" color="textPrimary" component="div">
              <Box fontWeight="fontWeightBold">
                {t(t13s.LABEL.DEBT_SERVICE)}
              </Box>
            </Typography>
            <Typography variant="caption" color="textPrimary">
              Para ver tu información del {t(t13s.LABEL.DEBT_SERVICE)} aquí,
              ingresa los datos para autorizarnos.
            </Typography>
            <Link
              variant="body2"
              color="secondary"
              href={getLink()}
              target="_blank"
            >
              <Box fontWeight="fontWeightBold" className={classes.link}>
                Autorizar
              </Box>
            </Link>
          </Grid>
        );
      case LOADING:
        return (
          <Box className={classes.rootCard}>
            <Box>{icon[countryId]}</Box>
            <Grid container direction="column">
              <Typography
                variant="h6"
                color="textPrimary"
                component="div"
                className={classes.title}
              >
                <Box fontWeight="fontWeightBold">
                  {t(t13s.LABEL.DEBT_SERVICE)}
                </Box>
              </Typography>
              <Skeleton variant="text" />
            </Grid>
          </Box>
        );
      case NOT_CONSULTED:
        return (
          <Box className={classes.rootCard}>
            <Box>{icon[countryId]}</Box>
            <Grid container direction="column">
              <Typography
                variant="h6"
                color="textPrimary"
                component="div"
                className={classes.title}
              >
                <Box fontWeight="fontWeightBold">
                  {t(t13s.LABEL.DEBT_SERVICE)}
                </Box>
              </Typography>
              <Typography variant="caption" color="textPrimary">
                {bodyText[variant]}
              </Typography>
            </Grid>
          </Box>
        );
      case CONSULTED:
        return (
          <Box className={classes.rootCard}>
            <Box>{icon[countryId]}</Box>
            <Grid container direction="column">
              <Typography
                variant="h6"
                color="textPrimary"
                component="div"
                className={classes.title}
              >
                <Box fontWeight="fontWeightBold">
                  {t(t13s.LABEL.DEBT_SERVICE)}
                </Box>
              </Typography>

              <Typography
                variant="subtitle1"
                color="textPrimary"
                component="div"
              >
                <Box fontWeight="fontWeightBold">
                  <CountryFormatHelper
                    value={totalAmount}
                    countryId={countryId}
                    variant="currency"
                  />
                </Box>
              </Typography>

              <Typography
                variant="caption"
                color="textPrimary"
                className={classes.label}
              >
                Última consulta
              </Typography>
              <Typography variant="body1" color="textPrimary">
                <CountryFormatHelper
                  value={createdAt}
                  variant="longdate"
                  countryId={countryId}
                />
              </Typography>
            </Grid>
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <Card variant="white" border>
      {getBody()}
    </Card>
  );
};

DebtServiceCard.propTypes = {
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
};
export default DebtServiceCard;
