const filterHeaderByCountry = ({ countryId, headers }) => {
  const filteredHeaders = Object.entries(headers).filter(header => {
    const renderOnlyCountries = header[1]?.renderOnlyCountries;

    if (!renderOnlyCountries) return true;

    return renderOnlyCountries.includes(countryId);
  });

  return filteredHeaders.map(header => header[1]);
};

export default filterHeaderByCountry;
