// eslint-disable-next-line import/prefer-default-export
export const LABEL = {
  TAX_SERVICE_LONG_NAME: 'LABEL_TAX_SERVICE_LONG_NAME',
  TAX_SERVICE_SHORT_NAME: 'LABEL_TAX_SERVICE_SHORT_NAME',
  BUSINESS_IDENTIFIER: 'LABEL_BUSINESS_IDENTIFIER',
  TOTAL_TO_TRANSFER: 'LABEL_TOTAL_TO_TRANSFER',
  COMPANY_ADDRESS: 'LABEL_COMPANY_ADDRESS',
  TAX_SERVICE_CREDENTIAL: 'LABEL_TAX_SERVICE_CREDENTIAL',
  BANK_ACCOUNT_IDENTIFIER: 'LABEL_BANK_ACCOUNT_IDENTIFIER',
  DEBT_SERVICE: 'LABEL_DEBT_SERVICE',
};
