import axios from '../../helpers/axios';
import {
  FETCH_ONBOARD_START,
  FETCH_ONBOARD_SUCCESS,
  FETCH_ONBOARD_FAILURE,
  FETCH_ONBOARD_SUPPLIERS_START,
  FETCH_ONBOARD_SUPPLIERS_SUCCESS,
  FETCH_ONBOARD_SUPPLIERS_FAILURE,
  SAVE_ONBOARD_START,
  SAVE_ONBOARD_SUCCESS,
  SAVE_ONBOARD_FAILURE,
  RESET_ONBOARD,
} from './types';
import { t13s } from '../../translationKeys';

import { enqueueSnackbar } from './notificationActions';

// eslint-disable-next-line import/prefer-default-export
export const fetchOnboard = businessId => async dispatch => {
  dispatch({ type: FETCH_ONBOARD_START });

  try {
    const onboardResponse = await axios.get(
      `/api/payment/business/${businessId}/onboard`
    );
    const { onboard: isOnboarded } = onboardResponse.data;

    dispatch({
      type: FETCH_ONBOARD_SUCCESS,
      payload: { isOnboarded },
    });
  } catch (error) {
    const errorCode = error?.response?.data?.errorCode;

    dispatch({ type: FETCH_ONBOARD_FAILURE, payload: errorCode });
    dispatch(
      enqueueSnackbar({
        message: t13s.NOTIFICATION.FETCH_ONBOARD_FAILURE,
        options: {
          variant: 'error',
        },
      })
    );
  }
};

export const fetchOnboardSuppliers =
  (businessId, onboardDate) => async dispatch => {
    dispatch({ type: FETCH_ONBOARD_SUPPLIERS_START });

    try {
      const onboardResponse = await axios.get(
        `/api/payment/business/${businessId}/onboard/supplier`,
        { params: { startDate: onboardDate } }
      );
      const suppliers = onboardResponse.data;

      dispatch({
        type: FETCH_ONBOARD_SUPPLIERS_SUCCESS,
        payload: { suppliers },
      });
    } catch (error) {
      const errorCode = error?.response?.data?.errorCode;

      dispatch({
        type: FETCH_ONBOARD_SUPPLIERS_FAILURE,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.FETCH_ONBOARD_SUPPLIERS_FAILURE,
          options: {
            variant: 'error',
          },
        })
      );
    }
  };

export const saveOnboard =
  (businessId, suppliers, onboardDate) => async dispatch => {
    dispatch({ type: SAVE_ONBOARD_START });

    try {
      await axios.post(`/api/payment/business/${businessId}/onboard`, {
        startDate: onboardDate,
        suppliers,
      });

      dispatch({
        type: SAVE_ONBOARD_SUCCESS,
      });
    } catch (error) {
      const errorCode = error?.response?.data?.errorCode;

      dispatch({
        type: SAVE_ONBOARD_FAILURE,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.SAVE_ONBOARD_FAILURE,
          options: {
            variant: 'error',
          },
        })
      );
    }
  };

export const resetOnboard = () => async dispatch => {
  dispatch({ type: RESET_ONBOARD });
};
