import React from 'react';
import TourStepComponent from './TourStepComponent';

const TourStep =
  ({ title, description }) =>
  props => {
    const data = { title, description, ...props };
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <TourStepComponent {...data} />;
  };

export default TourStep;
