import React, { useEffect, useRef, useState } from 'react';
import {
  Menu,
  Typography,
  IconButton,
  Box,
  CircularProgress,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MaterialTable from 'material-table';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import { t13s } from '../translationKeys';
import { editStakeholders } from '../store/actions/stakeholdersActions';
import StakeholderDialogMx from './dialogs/StakeholderDialogMx';
import MenuItem from './elements/MenuItem';
import ViewIcon from './icons/ViewIcon';
import StakeholderDocuments from './StakeholderDocuments';
import SuccessIcon from './icons/CheckIcon';
import CloseIcon from './icons/CloseIcon';
import { COUNTRY_CODE_CL, COUNTRY_CODE_MX } from '../helpers/constants';
import StakeholderDialogCL from './dialogs/StakeholderDialogCL';
import { verifyStatusDocuments } from '../store/actions/documentsActions';
import { features } from '../config/features';

const UserStakeholders = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedStakeholder, setSelectedStakeholder] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editStakeholder, setEditStakeholder] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const stakeholderTableRef = useRef();

  const {
    stakeholders,
    fetchStakeholdersIsLoading,
    updateStakeholdersIsLoading,
    updateStakeholdersIsDone,
    updateStakeholdersError,
  } = useSelector(state => state.stakeholdersState);
  const { stakeholderVerifyDocuments } = useSelector(
    state => state.documentsState
  );
  const { countryId } = useSelector(state => state.businessState.business);
  const countryFeatures = features[countryId];

  const handleMenuAnchor = (e, stakeholder) => {
    setSelectedStakeholder(stakeholder);
    setAnchorEl(e.currentTarget);
  };

  useEffect(() => {
    if (
      stakeholders.length > 0 &&
      countryFeatures.legal.stakeholdersHasDocuments
    ) {
      stakeholders.forEach(stakeholder => {
        dispatch(
          verifyStatusDocuments(stakeholder.stakeholderId, 'stakeholder')
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stakeholders]);

  useEffect(() => {
    const stakeholderVerifyDocumentsValues = Object.values(
      stakeholderVerifyDocuments
    );

    // We need this conditional to enable collapse by default, due to rendering issues
    if (
      stakeholders.length &&
      stakeholderVerifyDocumentsValues.length === stakeholders.length
    ) {
      const handleShowDetailPanel =
        stakeholderTableRef?.current?.props?.detailPanel;

      let hasDetailPanelExpanded = false;
      const isLoadingSomeVerifyDocument = stakeholderVerifyDocumentsValues.some(
        stakeholder => stakeholder.allDocumentsCompleted === undefined
      );

      if (!isLoadingSomeVerifyDocument) {
        const hasDocumentsIncomplete = stakeholderVerifyDocumentsValues.some(
          stakeholder => !stakeholder.allDocumentsCompleted
        );

        if (hasDocumentsIncomplete) {
          stakeholders.forEach((stakeholder, index) => {
            const documentsVerified =
              stakeholderVerifyDocuments[stakeholder.id];
            if (
              !documentsVerified?.allDocumentsCompleted &&
              !hasDetailPanelExpanded
            ) {
              hasDetailPanelExpanded = true;
              stakeholderTableRef.current.onToggleDetailPanel(
                [index],
                handleShowDetailPanel
              );
            }
          });
        } else {
          stakeholderTableRef.current.onToggleDetailPanel(
            [0],
            handleShowDetailPanel
          );
        }
      }
    }
  }, [stakeholders, stakeholderVerifyDocuments]);

  useEffect(() => {
    if (updateStakeholdersIsDone && !updateStakeholdersIsLoading) {
      setIsDialogOpen(false);
      setEditStakeholder(false);
    }
  }, [updateStakeholdersIsLoading, updateStakeholdersIsDone]);

  const handleStakeholderSubmit = stakeholder => {
    const formatedStakeholder = { ...stakeholder };
    delete formatedStakeholder.id;
    delete formatedStakeholder.createdAt;
    delete formatedStakeholder.updatedAt;
    delete formatedStakeholder.tableData;
    dispatch(
      editStakeholders({ ...formatedStakeholder }, () => {
        setEditStakeholder(false);
        setIsDialogOpen(false);
      })
    );
  };

  const columns = [
    {
      title: 'Nombres y apellidos',
      field: 'name',
      render: stakeholder => {
        const { name, fatherLastName, motherLastName } = stakeholder;
        return (
          <Typography variant="body1" color="textPrimary">
            {`${name} ${fatherLastName} ${motherLastName}`}
          </Typography>
        );
      },
    },
    {
      title: `${t(t13s.LABEL.BUSINESS_IDENTIFIER)}`,
      field: 'identifier',
      width: '120px',
      render: stakeholder => (
        <Typography variant="body1" color="textPrimary">
          {stakeholder.identifier}
        </Typography>
      ),
    },
    {
      title: 'Nº teléfono',
      field: 'phone',
      width: '130px',
      render: stakeholder => (
        <Typography variant="body1" color="textPrimary">
          {stakeholder.phone}
        </Typography>
      ),
    },
    {
      title: 'Email',
      field: 'email',
      width: '300px',
      render: stakeholder => (
        <Typography variant="body1" color="textPrimary">
          {stakeholder.email}
        </Typography>
      ),
    },
    {
      hidden: !countryFeatures.legal.stakeholdersHasDocuments,
      title: 'Docs.',
      width: '50px',
      render: stakeholder => {
        const documentsVerified = stakeholderVerifyDocuments[stakeholder.id];
        return (
          <>
            {documentsVerified?.verifyIsLoading ? (
              <CircularProgress color="primary" size={22} />
            ) : (
              <Box
                color={
                  documentsVerified?.allDocumentsCompleted
                    ? 'success.main'
                    : 'error.main'
                }
                display="flex"
                alignItems="center"
              >
                {documentsVerified?.allDocumentsCompleted ? (
                  <SuccessIcon />
                ) : (
                  <CloseIcon />
                )}
              </Box>
            )}
          </>
        );
      },
    },
    {
      sorting: false,
      width: '62px',
      align: 'right',
      render: stakeholder => (
        <IconButton onClick={e => handleMenuAnchor(e, stakeholder)}>
          <MoreVertIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <MaterialTable
        tableRef={stakeholderTableRef}
        style={{ width: '100%', boxShadow: 'none' }}
        columns={columns}
        data={stakeholders}
        isLoading={fetchStakeholdersIsLoading}
        options={{
          emptyRowsWhenPaging: false,
          sorting: true,
          pageSize: 100,
          detailPanelType: 'single',
        }}
        components={{
          Toolbar: () => null,
          Pagination: () => null,
        }}
        localization={{
          pagination: {
            labelRowsSelect: 'representantes',
            labelRowsPerPage: 'Representantes por página',
            labelDisplayedRows: '{count} representantes totales',
          },
          body: {
            emptyDataSourceMessage: 'No hay representantes para mostrar',
          },
        }}
        // if in the country the stakeholders haven't documents, we don't pass detailPanel to table
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(countryFeatures.legal.stakeholdersHasDocuments
          ? {
              detailPanel: ({ id }) => (
                <StakeholderDocuments stakeholderId={id} />
              ),
            }
          : {})}
      />
      <Menu
        id="long-menu-stakeholders"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          key="ver"
          icon={<ViewIcon />}
          onClick={() => {
            setIsDialogOpen(true);
            setAnchorEl(null);
          }}
        >
          Ver toda la información
        </MenuItem>
        <MenuItem
          key="editar"
          icon={<EditIcon />}
          onClick={() => {
            setIsDialogOpen(true);
            setAnchorEl(null);
            setEditStakeholder(true);
          }}
        >
          Editar Representante Legal
        </MenuItem>
      </Menu>

      {isDialogOpen && countryId === COUNTRY_CODE_MX && (
        <StakeholderDialogMx
          selectedStakeholder={selectedStakeholder}
          isOpen={isDialogOpen}
          handleClose={() => {
            setIsDialogOpen(false);
            setEditStakeholder(false);
          }}
          loading={updateStakeholdersIsLoading}
          editStakeholder={editStakeholder}
          onSubmit={handleStakeholderSubmit}
          error={updateStakeholdersError}
        />
      )}
      {isDialogOpen && countryId === COUNTRY_CODE_CL && (
        <StakeholderDialogCL
          selectedStakeholder={selectedStakeholder}
          isOpen={isDialogOpen}
          handleClose={() => {
            setIsDialogOpen(false);
            setEditStakeholder(false);
          }}
          loading={updateStakeholdersIsLoading}
          error={updateStakeholdersError}
          disableSave={!editStakeholder}
          onSubmit={handleStakeholderSubmit}
        />
      )}
    </>
  );
};

export default UserStakeholders;
