import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import UserDocumentSection from './UserDocumentSection';
import { fetchDocument } from '../store/actions/documentsActions';

const StakeholderDocuments = ({ stakeholderId }) => {
  const dispatch = useDispatch();

  const {
    stakeholderDocuments,
    stakeholderDocumentsIsLoading,
    stakeholderIdDocumentsOwner,
  } = useSelector(state => state.documentsState);

  useEffect(() => {
    if (stakeholderId !== stakeholderIdDocumentsOwner) {
      dispatch(fetchDocument(stakeholderId, 'stakeholder'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stakeholderId, dispatch]);

  return (
    <Grid item container xs={12}>
      <UserDocumentSection
        documents={stakeholderDocuments}
        documentsAreLoading={stakeholderDocumentsIsLoading}
        documentableType="stakeholder"
      />
    </Grid>
  );
};

StakeholderDocuments.propTypes = {
  stakeholderId: PropTypes.number.isRequired,
};

export default StakeholderDocuments;
