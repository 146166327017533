import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  paymentsChangePaymentDate,
  paymentsArchiveInvoice,
  paymentsPayInvoice,
} from '../../../../store/actions/paymentsActions';
import { useFetchData, useSearchAndReload } from '../commons';

const useInboxTable = () => {
  const { id: businessId } = useSelector(state => state.businessState.business);
  const dispatch = useDispatch();

  const { selectedInvoices, totalSelectedInvoices } = useSelector(
    state => state.paymentsState
  );

  const { search, onChangeSearch, reloadTable, reloadValue } =
    useSearchAndReload();
  const { fetchData } = useFetchData({
    externalSearch: search,
    option: 'inbox',
  });

  const handleArchived = () => {
    dispatch(
      paymentsArchiveInvoice(
        businessId,
        selectedInvoices,
        moment().format(),
        reloadTable
      )
    );
  };
  const handlePaid = () => {
    dispatch(
      paymentsPayInvoice(
        businessId,
        selectedInvoices,
        moment().format(),
        reloadTable
      )
    );
  };

  const onChangeDatePicker = ({
    newDate,
    handleDateChange,
    togglePicker,
    invoice,
  }) => {
    const { id: invoiceId } = invoice;
    const formatedDate = newDate.format();
    dispatch(
      paymentsChangePaymentDate(businessId, invoiceId, formatedDate, 'inbox')
    );
    handleDateChange(newDate);
    togglePicker();
  };

  return {
    onChangeDatePicker,
    onChangeSearch,
    reloadTable,
    fetchData,
    reloadValue,
    handleArchived,
    handlePaid,
    selectedInvoices,
    totalSelectedInvoices,
  };
};

export default useInboxTable;
