import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Box } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeftOutlined';
import { logoutUser } from '../store/actions/authenticationActions';
import UserProfileSidebar from './UserProfileSidebar';
import UserPersonalInfo from './UserPersonalInfo';
import UserLegal from './UserLegal';
import Loader from './elements/Loader';
import { fetchBusiness } from '../store/actions/businessActions';
import UserSat from './UserSat';
import UserBankAccounts from './UserBankAccounts';
import UserSii from './UserSii';

const useStyles = makeStyles({
  root: {
    padding: '0px 40px 20px',
    height: '100%',
    display: 'grid',
    gridGap: 20,
    gridTemplateRows: '70px auto',
    gridTemplateColumns: '20% auto',
    width: 1280,
    margin: '0 auto',
  },
  backButton: {
    gridRow: '1 / 2',
    gridColumn: '1 / 2',
    justifyContent: 'flex-start',
    width: 10,
    marginBottom: 55,
    marginTop: 20,
  },
  sideBar: {
    gridRow: '2 / 3',
    gridColumn: '1 / 2',
    justifyContent: 'flex-start',
    display: 'flex',
  },
  content: {
    gridRow: '2 / 3',
    gridColumn: '2 / 3',
    justifyContent: 'flex-start',
  },
});

const HomeUserProfile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user, business: { id: businessId } = {} } = useSelector(
    state => state.authState
  );
  const { business, businessIsLoading } = useSelector(
    state => state.businessState
  );

  useEffect(() => {
    if (!business) {
      dispatch(fetchBusiness(businessId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !business || businessIsLoading ? (
    <Loader />
  ) : (
    <Grid container className={classes.root}>
      <Box position="fixed">
        <Grid item className={classes.backButton}>
          <Button
            variant="text"
            component={Link}
            to="/"
            data-qa="user-profile-back-button-action"
          >
            <ChevronLeftIcon /> Volver
          </Button>
        </Grid>
        <Grid item className={classes.sideBar}>
          <UserProfileSidebar
            handleLogout={() => dispatch(logoutUser())}
            countryId={business.countryId}
          />
        </Grid>
      </Box>

      <Grid item className={classes.content}>
        <Switch>
          {/*
          <Route exact path="/user/profile/previred" component={UserPrevired} />
          <Route
            exact
            path="/user/profile/notifications"
            render={() => {
              return <UserNotifications user={user} />;
            }}
          /> */}

          <Route exact path="/user/profile/sii" component={UserSii} />

          <Route exact path="/user/profile/sat" component={UserSat} />

          <Route exact path="/user/profile/legal" component={UserLegal} />

          <Route
            exact
            path="/user/profile/bankaccounts"
            component={UserBankAccounts}
          />

          <Route
            render={() => {
              return <UserPersonalInfo userId={user.id} />;
            }}
          />
        </Switch>
      </Grid>
    </Grid>
  );
};

export default HomeUserProfile;
