import { TourStep } from '../../../components/GuidedTour';

const TOUR_NAME = 'PAYMENTS_GUIDEDTOUR';

const TOUR_STEPS = [
  {
    selector: '[data-tour="PAYMENTS_GUIDEDTOUR_INBOX"]',
    action: () => {
      document.querySelector('html').scrollLeft = 0;
    },
    content: TourStep({
      title: 'Revisa tus últimas facturas',
      description:
        'En esta pestaña, encontrarás tus últimas facturas generadas dentro del sistema y podrás pagarlas o programarlas.',
    }),
  },
  {
    selector: '[data-tour="PAYMENTS_GUIDEDTOUR_ARCHIVED"]',
    content: TourStep({
      title: 'Archivo de facturas',
      description:
        'En esta sección, encontrarás tus facturas archivadas y el record histórico de todas las que hayas sincronizado al enrolarte en nuestra plataforma.',
    }),
  },
  {
    selector: '.MuiTable-root thead .MuiTableCell-root:nth-child(6)',
    action: () => {
      document.querySelector('html').scrollLeft = 1000;
    },
    content: TourStep({
      title: 'Fecha de pago estimada',
      description:
        'Esta fecha señala el día de pago que estimaste al crear la nómina; pero recuerda que puedes modificarla en cualquier momento.',
    }),
  },
];

export { TOUR_NAME, TOUR_STEPS };
