import { PURGE, REHYDRATE } from 'redux-persist';
import {
  WAS_IN,
  LOCAL_PURGE,
  SET_UTM_INFO,
  SET_SUDO_USER,
  SET_REFERRAL_CODE,
  SET_SHOW_TOUR_POST_REGISTER,
} from '../actions/types';

const initialState = {
  wasInFinance: false,
  wasInLogin: false,
  utmInfo: { utmCampaign: null, utmMedium: null, utmSource: null },
  referralCode: null,
  isSudoUser: false,

  // when complete register is successfully this will change to 'true'
  // when close de the tour this will change to 'false'
  showTourPostRegister: false,
};

const wasIn = (state, payload) => {
  return {
    ...state,
    ...payload,
  };
};

const setUtmInfo = (state, payload) => {
  return {
    ...state,
    utmInfo: payload.utmInfo,
  };
};

const setReferralCode = (state, payload) => {
  return {
    ...state,
    referralCode: payload.referralCode,
  };
};

const setSudoUser = (state, payload) => {
  return {
    ...state,
    isSudoUser: payload.isSudoUser,
  };
};

const rehydrate = payload => {
  return {
    ...initialState,
    isSudoUser: payload?.uiState?.isSudoUser || false,
  };
};

const setShowTourPostRegister = (state, payload) => {
  return {
    ...state,
    showTourPostRegister: payload.showTour,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case WAS_IN:
      return wasIn(state, payload);
    case SET_UTM_INFO:
      return setUtmInfo(state, payload);
    case SET_REFERRAL_CODE:
      return setReferralCode(state, payload);
    case SET_SUDO_USER:
      return setSudoUser(state, payload);
    case SET_SHOW_TOUR_POST_REGISTER:
      return setShowTourPostRegister(state, payload);
    case REHYDRATE:
      return rehydrate(payload);
    case PURGE:
      return initialState;
    case LOCAL_PURGE:
      return initialState;
    default:
      return state;
  }
};
