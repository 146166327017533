import React, { useState } from 'react';
import {
  makeStyles,
  Grid,
  Button,
  IconButton,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { convertSpacingToCss } from '../helpers/stylesHelpers';
import DeleteIcon from './icons/DeleteIcon';
import BaseDialog from './dialogs/BaseDialog';
import Panel from './elements/Panel';
import Chip from './elements/Chip';
import EyeCoseIcon from './icons/EyeCloseIcon';

const useStyles = makeStyles({
  container: {
    padding: convertSpacingToCss('xl'),
  },
  form: {
    paddingTop: convertSpacingToCss('xs'),
  },
});

const HomeDesign = () => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(true);

  return (
    <Grid
      container
      direction="column"
      className={classes.container}
      spacing={4}
    >
      {showDialog && (
        <BaseDialog
          isOpen={showDialog}
          handleClose={() => setShowDialog(false)}
          title="Lorem Ipsum"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin pharetra est vitae mauris tincidunt blandit. Mauris aliquam quam ut leo egestas blandit. In hac habitasse platea dictumst."
        >
          <ValidatorForm className={classes.form}>
            <TextValidator
              label="label"
              placeholder="placeholder"
              variant="outlined"
              fullWidth
              size="small"
              name="valid"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <EyeCoseIcon className={classes.showPassword} />
                  </InputAdornment>
                ),
              }}
            />
            <TextValidator
              label="label"
              placeholder="placeholder"
              variant="outlined"
              disabled
              fullWidth
              name="valid"
            />
            <Button variant="contained" color="primary" size="large" fullWidth>
              Button
            </Button>
          </ValidatorForm>
        </BaseDialog>
      )}
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<DeleteIcon color="inherit" />}
          onClick={() => setShowDialog(true)}
        >
          Button Dialog
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          startIcon={<DeleteIcon color="inherit" />}
        >
          Button example
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<DeleteIcon color="inherit" />}
        >
          Button example
        </Button>
      </Grid>
      <Grid item>
        <IconButton aria-label="delete" size="small" color="primary">
          <DeleteIcon />
        </IconButton>
        <IconButton aria-label="delete" size="medium" color="primary">
          <DeleteIcon />
        </IconButton>
        <IconButton aria-label="delete" size="small" color="secondary">
          <DeleteIcon />
        </IconButton>
        <IconButton aria-label="delete" size="medium" color="secondary">
          <DeleteIcon />
        </IconButton>
        <IconButton aria-label="delete" size="small" disabled>
          <DeleteIcon />
        </IconButton>
        <IconButton aria-label="delete" size="medium" disabled>
          <DeleteIcon />
        </IconButton>
        <IconButton aria-label="delete" size="small" color="default">
          <DeleteIcon />
        </IconButton>
        <IconButton aria-label="delete" size="medium" color="default">
          <DeleteIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <DeleteIcon fontSize="small" />
        <DeleteIcon fontSize="medium" />
        <DeleteIcon fontSize="large" />
        <DeleteIcon fontSize="small" color="primary" />
        <DeleteIcon fontSize="medium" color="primary" />
        <DeleteIcon fontSize="large" color="primary" />
        <DeleteIcon fontSize="small" color="secondary" />
        <DeleteIcon fontSize="medium" color="secondary" />
        <DeleteIcon fontSize="large" color="secondary" />
        <DeleteIcon color="action" fontSize="small" />
        <DeleteIcon color="action" fontSize="medium" />
        <DeleteIcon color="action" fontSize="large" />
        <DeleteIcon color="disabled" fontSize="small" />
        <DeleteIcon color="disabled" fontSize="medium" />
        <DeleteIcon color="disabled" fontSize="large" />
        <DeleteIcon color="error" fontSize="small" />
        <DeleteIcon color="error" fontSize="medium" />
        <DeleteIcon color="error" fontSize="large" />
      </Grid>
      <Grid item xs={12}>
        <Panel
          title="Titulo del panel"
          actions={
            <Button variant="contained" color="primary">
              Acción
            </Button>
          }
        >
          <Typography variant="body1" color="textPrimary">
            Hola hola
          </Typography>
        </Panel>
      </Grid>
      <Grid item xs={12}>
        <Chip label="En revisión" variant="yellow" />
        <Chip label="En revisión" variant="yellow" onClick={() => {}} />
        <Chip label="En revisión" variant="yellow" onDelete={() => {}} />
      </Grid>
      <Grid item xs={12}>
        <Chip size="small" label="En revisión" variant="yellow" />
        <Chip
          size="small"
          label="En revisión"
          variant="yellow"
          onClick={() => {}}
        />
        <Chip
          size="small"
          label="En revisión"
          variant="yellow"
          onDelete={() => {}}
        />
      </Grid>
    </Grid>
  );
};

export default HomeDesign;
