import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles, CircularProgress } from '@material-ui/core';
import BaseDialog from './BaseDialog';
import Dropzone from '../elements/Dropzone/Dropzone';
import AlertForm from '../elements/AlertForm';

const useStyles = makeStyles({
  error: {
    marginTop: 20,
  },
});
const UploadFileDialog = props => {
  const {
    open,
    handleCloseDialog,
    title,
    accept,
    handleSubmit,
    isLoading,
    error,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const handleSubmitFile = files => {
    handleSubmit(files);
  };

  return (
    <BaseDialog
      size="lg"
      isOpen={open}
      handleClose={handleCloseDialog}
      title={title}
    >
      <Grid item xs={12} align="center">
        {isLoading ? (
          <CircularProgress size={60} color="primary" />
        ) : (
          <Dropzone
            handleSubmit={handleSubmitFile}
            accept={accept}
            maxFiles={1}
            minSize={100}
            isLoading={isLoading}
          />
        )}
      </Grid>
      {error && (
        <Grid item xs={12} align="center" className={classes.error}>
          <AlertForm variant="error" message={t(error)} />
        </Grid>
      )}
    </BaseDialog>
  );
};

UploadFileDialog.defaultProps = {
  error: null,
};

UploadFileDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  accept: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

export default UploadFileDialog;
