// eslint-disable-next-line import/prefer-default-export
export const CL = {
  decimalSeparator: ',',
  thousandSeparator: '.',
  localization: 'America/Santiago',
  currencySymbol: '$',
  currencyCode: 'CLP',
  currencyDecimalPoint: 0,
  shordateformat: 'DD/MM/YYYY',
  longdateformat: 'DD MMMM YYYY',
  phoneformat: '#########',
};
