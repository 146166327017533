const getAcronym = text => {
  if (!text) return '';
  return text
    .split(/\s/)
    .slice(0, 2)
    .reduce((acc, word) => {
      return acc + word.slice(0, 1);
    }, '');
};

export default getAcronym;
