import React from 'react';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { IconButton, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PaginationMaterialTable from '../elements/PaginationMaterialTable';
import {
  DIRECT_FINANCING,
  DIRECT_FINANCING_LABEL,
  CONFIRMING,
  CONFIRMING_LABEL,
  EARLY_PAYMENT,
  EARLY_PAYMENT_LABEL,
  AVAILABLE_COUNTRIES,
  COUNTRY_CODE_MX,
} from '../../helpers/constants';

import TransferStatusSteps from '../elements/TransferStatusSteps';
import CountryFormatHelper from '../elements/CountryFormatHelper';
import SurplusChip from '../elements/SurplusChip';

const OrdersTable = props => {
  const {
    countryId,
    tableData,
    isLoading,
    updateOrders,
    pagination: { page, pageSize, totalData } = {},
  } = props;

  const handleChangePage = (selectedPage, pageSize) => {
    updateOrders(selectedPage, pageSize);
  };

  const getOrderType = orderType => {
    let text = '';
    switch (orderType) {
      case DIRECT_FINANCING:
        text = DIRECT_FINANCING_LABEL;
        break;
      case EARLY_PAYMENT:
        text = EARLY_PAYMENT_LABEL;
        break;
      case CONFIRMING:
        text = CONFIRMING_LABEL;
        break;
      default:
        break;
    }
    return text;
  };

  const getColumns = () => {
    return [
      {
        title: '',
        width: '20px',
        sorting: false,
        render: order => {
          return (
            <IconButton
              component={Link}
              to={`orders/${order.id}`}
              data-qa={`orders-table-icon-button-action-${order?.tableData?.id}`}
            >
              <DescriptionOutlinedIcon color="secondary" />
            </IconButton>
          );
        },
      },
      {
        title: 'Operación',
        field: 'id',
        type: 'numeric',
        width: '20px',
        align: 'left',
        render: order => {
          return (
            <Typography variant="body1" color="textPrimary">
              {order.id}
            </Typography>
          );
        },
        cellStyle: {
          maxWidth: 100,
        },
      },
      {
        title: 'Tipo',
        field: 'orderType',
        type: 'string',
        width: '150px',
        align: 'left',
        render: order => {
          return (
            <Typography variant="body1" color="textPrimary">
              {getOrderType(order.orderType)}
            </Typography>
          );
        },
      },
      {
        title: 'Fecha solicitud',
        field: 'issuedDate',
        type: 'datetime',
        width: '100px',
        align: 'left',
        render: order => {
          return (
            <Typography variant="body1" color="textPrimary" noWrap>
              <CountryFormatHelper
                value={order.issuedDate}
                variant="longdate"
                countryId={countryId}
              />
            </Typography>
          );
        },
      },
      {
        title: 'Excedentes vigentes',
        field: 'currentSurplus',
        type: 'string',
        width: '100px',
        align: 'center',
        hidden: countryId === COUNTRY_CODE_MX,
        headerStyle: {
          textAlign: 'center !important',
        },
        render: order => {
          return (
            <SurplusChip
              currentSurplus={order.currentSurplus}
              percentageSurplus={order.percentageSurplus}
              countryId={countryId}
            />
          );
        },
      },
      {
        title: 'Monto',
        field: 'amount',
        type: 'number',
        align: 'right',
        width: '100px',
        render: order => {
          return (
            <Typography variant="body1" color="textPrimary" noWrap>
              <CountryFormatHelper
                value={order?.totalAmount}
                variant="currency"
                countryId={countryId}
              />
            </Typography>
          );
        },
      },
      {
        title: 'Estado',
        sorting: false,
        align: 'center',
        width: '300px',
        cellStyle: { padding: 0 },
        render: order => {
          return <TransferStatusSteps order={order} />;
        },
      },
    ];
  };

  return (
    <MaterialTable
      style={{ width: '100%', boxShadow: 'none' }}
      title=""
      columns={getColumns()}
      data={tableData}
      isLoading={isLoading}
      onChangeRowsPerPage={pageSize => handleChangePage(0, pageSize)}
      localization={{
        pagination: {
          labelRowsSelect: 'órdenes',
          labelRowsPerPage: 'Órdenes por página',
          labelDisplayedRows: '{count} órdenes totales',
        },
        toolbar: {
          searchTooltip: 'Buscar',
          searchPlaceholder: 'Buscar',
        },
        header: {
          actions: 'Acciones',
        },
        body: {
          emptyDataSourceMessage: 'No hay órdenes para mostrar',
          filterRow: {
            filterTooltip: 'Filtro',
          },
        },
      }}
      options={{
        emptyRowsWhenPaging: false,
        showFirstLastPageButtons: false,
        sorting: true,
        toolbar: false,
        pageSize,
        pageSizeOptions: [5, 10, 20, 50, 100],
        showSelectAllCheckbox: true,
      }}
      components={{
        Pagination: props => (
          <PaginationMaterialTable
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            count={totalData}
            page={page - 1}
            onChangePage={(e, page) => {
              // eslint-disable-next-line react/prop-types
              handleChangePage(page, props.rowsPerPage);
            }}
          />
        ),
      }}
    />
  );
};

OrdersTable.propTypes = {
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  isLoading: PropTypes.bool.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateOrders: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number,
    pageSize: PropTypes.number,
    totalData: PropTypes.number,
  }).isRequired,
};

export default OrdersTable;
