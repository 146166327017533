import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Fade, makeStyles } from '@material-ui/core';
import CartSteps from './elements/CartSteps';
import {
  CART_STEPS,
  DIRECT_FINANCING,
  EARLY_PAYMENT,
} from '../helpers/constants';
import CartStepOne from './CartStepOne';
import CartStepTwo from './CartStepTwo';
import {
  setDemoData,
  fetchCartInvoicesCount,
  resetSelectedInvoices,
} from '../store/actions/cartActions';

const useStyles = makeStyles({
  content: {
    paddingTop: 30,
  },
});

const HomeCart = ({ operationType, demo }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { countryId, id: businessId } = useSelector(
    state => state.businessState.business
  );

  const [step, setStep] = useState(0);

  const demoInvoices = {
    CL: [
      {
        id: 123400,
        folio: 1,
        payerName: 'Nombre del Pagador',
        payerIdentifier: '17009552-9',
        issueDate: moment().add(-10, 'days'),
        amount: 150000,
        rate: 1.3,
        advancePercentage: 98,
        expirationDate: moment().add(20, 'days'),
        averageDaysLate: 30,
        extraData: [
          {
            code: 'INVOICE_STATE',
            value: '',
          },
        ],
      },
      {
        id: 123401,
        folio: 10,
        payerName: 'Nombre del Pagador',
        payerIdentifier: '17009552-9',
        issueDate: moment().add(-10, 'days'),
        amount: 233000,
        rate: 1.3,
        advancePercentage: 98,
        expirationDate: moment().add(20, 'days'),
        averageDaysLate: 30,
        extraData: [
          {
            code: 'INVOICE_STATE',
            value: '',
          },
        ],
      },
      {
        id: 123402,
        folio: 11,
        payerName: 'Nombre del Pagador',
        payerIdentifier: '17009552-9',
        issueDate: moment().add(-10, 'days'),
        amount: 430500,
        rate: 1.3,
        advancePercentage: 98,
        expirationDate: moment().add(20, 'days'),
        averageDaysLate: 30,
        extraData: [
          {
            code: 'INVOICE_STATE',
            value: 'Acuse Recibo',
          },
        ],
      },
      {
        id: 123403,
        folio: 100,
        payerName: 'Nombre del Pagador',
        payerIdentifier: '17009552-9',
        issueDate: moment().add(-10, 'days'),
        amount: 540321,
        rate: 1.3,
        advancePercentage: 98,
        expirationDate: moment().add(20, 'days'),
        averageDaysLate: 30,
        extraData: [
          {
            code: 'INVOICE_STATE',
            value: 'Acuse Recibo',
          },
        ],
      },
    ],
    MX: [
      {
        id: 123404,
        folio: 1,
        payerName: 'Pagador importante',
        payerIdentifier: 'ACE123456F54',
        issueDate: moment().add(-10, 'days'),
        amount: 150000,
        rate: 1.3,
        advancePercentage: 98,
        expirationDate: moment().add(20, 'days'),
        averageDaysLate: 30,
        extraData: [
          {
            code: 'INVOICE_TYPE',
            value: 'PPD',
          },
        ],
      },
      {
        id: 123405,
        folio: 10,
        payerName: 'Pagador importante',
        payerIdentifier: 'ACE123456F54',
        issueDate: moment().add(-10, 'days'),
        amount: 233000,
        rate: 1.3,
        advancePercentage: 98,
        expirationDate: moment().add(20, 'days'),
        averageDaysLate: 30,
        extraData: [
          {
            code: 'INVOICE_TYPE',
            value: 'PPD',
          },
        ],
      },
      {
        id: 123406,
        folio: 11,
        payerName: 'Seguros Alas Verdes',
        payerIdentifier: 'QWE987654K94',
        issueDate: moment().add(-10, 'days'),
        amount: 430500,
        rate: 1.3,
        advancePercentage: 98,
        expirationDate: moment().add(20, 'days'),
        averageDaysLate: 30,
        extraData: [
          {
            code: 'INVOICE_TYPE',
            value: 'PUE',
          },
        ],
      },
      {
        id: 123407,
        folio: 100,
        payerName: 'Grupo Importante',
        payerIdentifier: 'MGR654983MD3',
        issueDate: moment().add(-10, 'days'),
        amount: 540321,
        rate: 1.3,
        advancePercentage: 98,
        expirationDate: moment().add(20, 'days'),
        averageDaysLate: 30,
        extraData: [
          {
            code: 'INVOICE_TYPE',
            value: 'PUE',
          },
        ],
      },
    ],
  };

  const demoExtraColumns = {
    CL: [
      {
        code: 'INVOICE_STATE',
        type: 'string',
        label: 'Estado',
      },
    ],
    MX: [
      {
        code: 'INVOICE_TYPE',
        type: 'string',
        label: 'Tipo',
      },
    ],
  };

  useEffect(() => {
    setStep(0);
    dispatch(fetchCartInvoicesCount(businessId, DIRECT_FINANCING));
    dispatch(fetchCartInvoicesCount(businessId, EARLY_PAYMENT));
    dispatch(resetSelectedInvoices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationType]);

  useEffect(() => {
    const extraColumns = demoExtraColumns[countryId];
    if (demo) {
      const invoices = JSON.parse(JSON.stringify(demoInvoices[countryId]));
      if (operationType === DIRECT_FINANCING) {
        dispatch(
          setDemoData({
            invoices,
            extraColumns,
            earlyPaymentCount: 0,
            directFinancingCount: invoices.length,
          })
        );
      } else {
        dispatch(
          setDemoData({
            invoices: [],
            extraColumns,
            earlyPaymentCount: 0,
            directFinancingCount: invoices.length,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationType, demo, dispatch]);

  const navigationRender = () => {
    switch (step) {
      case 0:
        return (
          <CartStepOne
            operationType={operationType}
            handleStepChange={step => setStep(step)}
          />
        );
      case 1:
      case 2:
        return <CartStepTwo demo={demo} operationType={operationType} />;
      default:
        return (
          <span>
            {' '}
            CONTENT STEP: {step} | TYPE: {operationType} | DEMO?: {demo}
          </span>
        );
    }
  };

  return (
    <Fade in>
      <div className={classes.content}>
        {step !== 4 && <CartSteps step={step} steps={CART_STEPS} />}
        <div className={classes.content}>{navigationRender(step)}</div>
      </div>
    </Fade>
  );
};

HomeCart.propTypes = {
  operationType: PropTypes.string.isRequired,
  demo: PropTypes.bool.isRequired,
};

export default HomeCart;
