import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const InfoIcon = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 5 16">
      <path
        d="M4.27695 5.433L0.245954 5.938L0.100954 6.608L0.894954 6.753C1.41095 6.876 1.51395 7.062 1.39995 7.577L0.100954 13.68C-0.239046 15.258 0.286954 16 1.52395 16C2.48295 16 3.59595 15.557 4.10095 14.948L4.25595 14.216C3.90595 14.526 3.38995 14.65 3.04995 14.65C2.56495 14.65 2.38995 14.31 2.51395 13.711L4.27695 5.433ZM4.39895 1.76C4.39895 1.99113 4.35343 2.21999 4.26498 2.43352C4.17653 2.64706 4.04689 2.84108 3.88346 3.00451C3.72003 3.16794 3.52601 3.29758 3.31248 3.38603C3.09894 3.47448 2.87008 3.52 2.63895 3.52C2.40783 3.52 2.17896 3.47448 1.96543 3.38603C1.7519 3.29758 1.55788 3.16794 1.39445 3.00451C1.23101 2.84108 1.10137 2.64706 1.01293 2.43352C0.924478 2.21999 0.878954 1.99113 0.878954 1.76C0.878954 1.29322 1.06438 0.845556 1.39445 0.515492C1.72451 0.185428 2.17217 0 2.63895 0C3.10573 0 3.5534 0.185428 3.88346 0.515492C4.21353 0.845556 4.39895 1.29322 4.39895 1.76Z"
        fill="#0066FF"
      />
    </SvgIcon>
  );
};

export default InfoIcon;
