import {
  FETCH_PAYMENTS_STATS_START,
  FETCH_PAYMENTS_STATS_SUCCESS,
  FETCH_PAYMENTS_STATS_ERROR,
} from '../actions/types';

const initialState = {
  stats: null,
  isStatsLoading: false,
  fetchStatsError: null,
};

const fetchPaymentsStatsStart = state => {
  return {
    ...state,
    fetchStatsError: null,
    isStatsLoading: true,
  };
};

const fetchPaymentsStatsSuccess = (state, payload) => {
  return {
    ...state,
    isStatsLoading: false,
    stats: { ...payload.stats },
  };
};

const fetchPaymentsStatsError = (state, payload) => {
  return {
    ...state,
    isStatsLoading: false,
    fetchStatsError: payload.error,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PAYMENTS_STATS_START:
      return fetchPaymentsStatsStart(state, payload);
    case FETCH_PAYMENTS_STATS_SUCCESS:
      return fetchPaymentsStatsSuccess(state, payload);
    case FETCH_PAYMENTS_STATS_ERROR:
      return fetchPaymentsStatsError(state, payload);

    default:
      return state;
  }
};
