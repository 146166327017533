import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import TagManager from 'react-gtm-module';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import Loader from './components/elements/Loader';

// GOOGLE TAG
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
};
TagManager.initialize(tagManagerArgs);

// SEGMENT
window.analytics.load(process.env.REACT_APP_SEGMENT_KEY);

ReactDOM.render(
  <BrowserRouter>
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
