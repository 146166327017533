import { PURGE } from 'redux-persist';
import {
  WS_CONNECTED_STORE,
  WS_DISCONNECTED_STORE,
  WS_BUSINESS_NOTIFIED_STORE,
  WS_TAX_SCRAPE_UPDATED_STORE,
  LOCAL_PURGE,
} from '../actions/types';

const initialState = {
  wsconnected: false,
  loadStatus: '',
  loadPercentage: 1,
  wsBusinessNotified: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case WS_CONNECTED_STORE:
      return { ...state, wsconnected: true };
    case WS_DISCONNECTED_STORE:
      return {
        ...state,
        wsconnected: false,
        wsBusinessNotified: false,
      };
    case WS_BUSINESS_NOTIFIED_STORE: {
      return {
        ...state,
        wsBusinessNotified: true,
      };
    }
    case WS_TAX_SCRAPE_UPDATED_STORE:
      return {
        ...state,
        loadStatus: payload.status,
        loadPercentage: payload.completion,
      };
    case LOCAL_PURGE:
      return initialState;
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
