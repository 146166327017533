import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Drawer, Grid, Typography, IconButton, Box } from '@material-ui/core';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import NotificationTaskDrawer from './elements/NotificationTaskDrawer';

const useStyles = makeStyles(theme => ({
  gridRoot: {
    margin: '25px 27px',
    width: 456,
  },
  title: {
    marginBottom: 20,
  },
  linePreCircle: {
    width: 2,
    background: theme.palette.grey[400],
    position: 'relative',
    left: '50%',
    top: '-8px',
    height: 'calc(50%)',
  },
  circle: {
    height: 13,
    width: 13,
    background: theme.palette.secondary.main,
    borderRadius: 50,
    position: 'relative',
    left: '50%',
    top: '-8px',
  },
  linePostCircle: {
    width: 2,
    position: 'relative',
    left: '50%',
    background: theme.palette.grey[400],
    height: 'calc(50% + 2.5px)',
    top: '-8px',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 12,
    color: theme.palette.primary.main,
  },
}));

const TasksDrawer = props => {
  const { open, toggleDrawer, tasks } = props;
  const classes = useStyles();
  const history = useHistory();

  const redirectTo = path => {
    if (path) {
      history.push(path);
    }
  };

  const getPoint = () => {
    return (
      <div style={{ height: '100%' }}>
        <div className={`${classes.linePreCircle} ${classes.notVisible}`} />
        <div className={classes.circle} />
        <div className={classes.linePostCircle} />
      </div>
    );
  };

  return (
    <div>
      <React.Fragment key="right">
        <Drawer
          anchor="right"
          open={open}
          onClose={toggleDrawer}
          className={classes.drawer}
        >
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={toggleDrawer}
          >
            <CancelRoundedIcon />
          </IconButton>
          <Grid
            container
            direction="column"
            className={classes.gridRoot}
            spacing={2}
          >
            <Grid item>
              <Typography variant="h5" color="textPrimary" component="div">
                <Box fontWeight="fontWeightBold">
                  Tareas Pendientes ({tasks.length})
                </Box>
              </Typography>
            </Grid>
            {tasks.map(task => {
              const { title, detail, hasAction, pathAction } = task;
              return (
                <Grid item key={title}>
                  <Grid container direction="row" align="center">
                    <Grid item xs={11}>
                      <NotificationTaskDrawer
                        title={title}
                        detail={detail}
                        hasAction={hasAction}
                        onAction={() => redirectTo(pathAction)}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      {getPoint()}
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Drawer>
      </React.Fragment>
    </div>
  );
};

TasksDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      detail: PropTypes.string.isRequired,
      hasAction: PropTypes.bool.isRequired,
      pathAction: PropTypes.string,
    })
  ).isRequired,
};

export default TasksDrawer;
