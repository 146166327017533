// eslint-disable-next-line import/prefer-default-export
export const getEmailMask = email => {
  const maskedEmail = email.replace(/([^@.])/g, '*').split('');
  let previous = '';
  for (let i = 0; i < maskedEmail.length; i++) {
    if (i <= 1 || previous === '.' || previous === '@') {
      maskedEmail[i] = email[i];
    }
    previous = email[i];
  }
  return maskedEmail.join('');
};

export const verifyEmail = emailValue => {
  // Una implementación del Estandard Official: RFC 5322 (valida en el 99.99% de los emails existentes)
  const regexEmail =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
  const secondRegexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const emailValidator = emailValue.includes('@')
    ? emailValue.split('@')
    : emailValue.split('.');
  const urlEmailValidator = emailValidator[1]?.split('.');
  if (
    !regexEmail.test(emailValue) ||
    !secondRegexEmail.test(emailValue) ||
    emailValidator[2]?.length > 3 ||
    urlEmailValidator[1]?.length > 3 ||
    urlEmailValidator.length > 3 ||
    emailValidator.length > 3
  ) {
    return false;
  }
  return true;
};
