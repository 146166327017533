import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const XepelinQuestionMarkIcon = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 203 109">
      <g clipPath="url(#clip0)">
        <path
          d="M188.514 28.9294C187.958 26.5639 187.907 24.5816 188.36 22.9825C188.886 21.2109 189.666 19.5263 190.674 17.9816C191.49 16.8298 192.19 15.5996 192.766 14.3087C193.16 13.3455 193.211 12.2375 192.918 10.9847C192.813 10.4808 192.601 10.0059 192.296 9.59317C191.99 9.18041 191.599 8.83973 191.15 8.59491C190.334 8.09642 189.361 7.93214 188.429 8.13588C187.044 8.46415 186.048 9.17259 185.441 10.2612C185.23 10.6421 185.047 11.0377 184.893 11.4451C184.593 12.2397 184.022 12.9002 183.282 13.3081C182.543 13.7159 181.683 13.8444 180.857 13.6705C180.358 13.5653 179.887 13.3526 179.477 13.047C179.066 12.7414 178.726 12.3501 178.479 11.8999C178.233 11.4496 178.085 10.951 178.047 10.4382C178.009 9.92532 178.081 9.41032 178.259 8.92837L178.363 8.65262C178.966 7.07315 180.016 5.54829 181.51 4.07805C183.005 2.60781 184.994 1.57749 187.478 0.987093C190.707 0.222754 193.519 0.426036 195.912 1.59694C198.306 2.76784 199.896 5.03786 200.682 8.40699C201.227 10.7362 201.29 12.6493 200.871 14.1465C200.522 15.489 199.983 16.7738 199.272 17.9615C198.615 19.0066 198.193 19.6834 198.007 19.9921C197.201 21.2599 196.596 22.6472 196.215 24.1037C195.886 25.4283 195.94 27.0218 196.378 28.8841L196.64 30.013L189.18 31.7801L188.514 28.9294ZM192.162 41.9211C191.683 41.679 191.266 41.3301 190.941 40.9009C190.617 40.4718 190.394 39.9737 190.289 39.4446C190.156 38.9164 190.141 38.3653 190.244 37.8307C190.348 37.2962 190.568 36.7915 190.888 36.3529C191.568 35.3882 192.587 34.7211 193.739 34.4867C194.982 34.1906 196.091 34.3243 197.066 34.8878C197.535 35.1486 197.943 35.5078 198.262 35.9413C198.582 36.3747 198.805 36.8722 198.918 37.4001C199.059 37.9151 199.085 38.4553 198.995 38.9816C198.904 39.508 198.699 40.0074 198.394 40.4439C197.774 41.3268 196.826 41.9165 195.549 42.2128C194.273 42.5091 193.143 42.4119 192.162 41.9211Z"
          fill="#7300FF"
        />
      </g>
      <g clipPath="url(#clip1)">
        <path
          d="M17.2556 46.3881C16.9033 48.1889 16.3229 49.5693 15.5143 50.5293C14.6015 51.5851 13.5416 52.5024 12.368 53.2526C11.4488 53.792 10.5843 54.4211 9.7869 55.1307C9.21649 55.6713 8.83757 56.4183 8.65015 57.3716C8.56559 57.7509 8.5642 58.1437 8.64609 58.5227C8.72798 58.9016 8.89116 59.2575 9.12425 59.5655C9.53095 60.1621 10.1503 60.5777 10.8549 60.7269C11.9102 60.9311 12.8158 60.7529 13.5718 60.1922C13.835 59.9954 14.0839 59.7799 14.3166 59.5474C14.7699 59.0937 15.3682 58.8164 16.004 58.7655C16.6398 58.7145 17.2715 58.8932 17.7857 59.2694C18.0967 59.4968 18.3547 59.7895 18.5422 60.1273C18.7298 60.4651 18.8424 60.8401 18.8723 61.2267C18.9023 61.6132 18.8489 62.0022 18.7159 62.3671C18.5828 62.7319 18.3732 63.064 18.1013 63.3406L17.9439 63.498C17.0378 64.3977 15.842 65.1214 14.3564 65.669C12.8709 66.2167 11.1817 66.308 9.28889 65.9429C6.82842 65.4657 4.95637 64.4525 3.67273 62.9031C2.3891 61.3537 1.99985 59.2973 2.50499 56.7339C2.85364 54.9616 3.40464 53.6252 4.15797 52.7247C4.81541 51.9092 5.58524 51.1922 6.44392 50.5957C7.22056 50.0803 7.72099 49.7454 7.94519 49.5909C8.89384 48.9686 9.7409 48.2015 10.4557 47.3174C11.0935 46.5078 11.5512 45.3942 11.8288 43.9765L11.9992 43.1181L17.6829 44.2191L17.2556 46.3881ZM18.7805 36.3127C19.0345 36.6279 19.2133 36.9977 19.3034 37.3939C19.3934 37.7901 19.3924 38.2023 19.3002 38.599C19.2275 39.0038 19.0667 39.3879 18.8294 39.7237C18.5921 40.0594 18.2841 40.3386 17.9277 40.5411C17.1595 40.9936 16.2507 41.1362 15.3857 40.94C14.4379 40.7576 13.7161 40.321 13.22 39.6303C12.9783 39.3052 12.8091 38.9312 12.7238 38.5336C12.6386 38.136 12.6393 37.7242 12.7259 37.3259C12.7885 36.9274 12.9381 36.5474 13.1639 36.2133C13.3897 35.8791 13.6861 35.5991 14.0318 35.3935C14.7325 34.9782 15.5683 34.8669 16.5392 35.0595C17.5101 35.2522 18.2572 35.6699 18.7805 36.3127Z"
          fill="#7300FF"
        />
      </g>
      <path
        d="M175.622 48.7848V48.4532L175.537 48.3882C175.112 46.7932 174.501 45.2527 173.715 43.7979C173.249 42.9267 172.808 42.231 172.44 41.6719C171.999 41.0217 171.519 40.3715 170.987 39.7213L170.888 39.5978C163.899 31.113 150.682 26.4512 133.58 26.4512C116.898 26.4512 98.2903 31.0024 83.7984 38.6095C77.0902 42.1431 70.8638 46.5061 65.2692 51.5936C64.5412 51.1174 63.786 50.6832 63.0073 50.2932C60.8688 49.255 58.5829 48.5447 56.2282 48.1866C55.2113 48.0375 54.1768 48.0506 53.1641 48.2257L42.2557 50.0527C41.3098 50.2098 40.4126 50.5784 39.6327 51.1305C38.8527 51.6825 38.2106 52.4034 37.7552 53.2381C37.2998 54.0729 37.0432 54.9994 37.005 55.9471C36.9668 56.8948 37.1479 57.8386 37.5346 58.7065L45.425 76.4694L45.2737 76.7295C44.8332 77.5032 44.4058 78.2769 43.9981 79.0376C43.6422 79.6983 43.5521 80.4676 43.746 81.1912C43.9399 81.9148 44.4035 82.5391 45.0436 82.9387C45.7011 83.3613 46.4178 83.7839 47.1214 84.1936C47.825 84.6032 48.4365 84.9543 49.094 85.3314L50.626 102.932C50.7711 104.481 51.4934 105.922 52.6526 106.975C53.8118 108.027 55.3251 108.616 56.8989 108.627C57.6066 108.628 58.3093 108.509 58.9766 108.276L69.4051 104.681C70.3792 104.352 71.2938 103.872 72.1141 103.257C74.0076 101.83 75.6685 100.124 77.039 98.1984C77.3875 97.7043 77.7294 97.1841 78.045 96.6445C83.569 97.8891 89.197 98.6291 94.858 98.8551L95.3511 99.4663C97.0621 101.646 99.2557 103.409 101.763 104.62C104.271 105.83 107.026 106.456 109.817 106.449C111.37 106.449 112.916 106.255 114.419 105.871L132.429 101.274C135.275 100.539 137.809 98.9248 139.662 96.6665C141.516 94.4082 142.591 91.6238 142.733 88.7188C159.454 81.0987 171.158 70.8519 174.873 60.5661C174.894 60.5154 174.912 60.4633 174.925 60.41C176.084 56.6432 176.31 52.6559 175.583 48.7848H175.622Z"
        fill="white"
      />
      <path
        d="M47.7327 78.1793C47.2922 78.9465 46.8779 79.7007 46.4834 80.4289L172.499 49.2593C172.13 47.8431 171.594 46.4748 170.901 45.1827C170.46 44.3635 170.046 43.7263 169.757 43.2842C169.356 42.6795 168.922 42.0944 168.442 41.5287C143.114 36.2038 104.214 42.8941 75.3489 56.7819C67.2874 60.6697 59.6245 65.3184 52.4669 70.6632C51.954 71.4109 51.4543 72.1521 50.9809 72.8803C49.7842 74.6943 48.6993 76.5148 47.7327 78.1793Z"
        fill="#F4AC73"
      />
      <path
        d="M168.461 41.5285L168.396 41.4505C154.239 24.2207 112.696 26.6849 85.0276 41.2164C69.7401 49.2461 59.3511 60.6177 52.4668 70.663C59.6257 65.3294 67.2886 60.6917 75.3488 56.8142C104.214 42.8939 143.114 36.2035 168.461 41.5285Z"
        fill="#F7CAA5"
      />
      <path
        d="M84.1196 87.7302C75.3835 88.1528 66.6267 87.7916 57.9565 86.6509C67.8984 91.3257 80.9898 95.6038 96.1721 95.9354L97.4872 97.6454C99.3336 99.98 101.829 101.731 104.667 102.684C107.505 103.637 110.563 103.75 113.465 103.009L131.481 98.4061C134.021 97.7776 136.243 96.2564 137.731 94.1266C139.219 91.9969 139.873 89.4042 139.569 86.8329C155.35 79.9865 168.165 70.0258 171.946 59.5644C151.562 74.8176 115.24 86.2673 84.1196 87.7302Z"
        fill="#EF5D28"
      />
      <path
        d="M171.914 59.5645C151.563 74.8177 115.241 86.2673 84.1203 87.7302C75.3843 88.1529 66.6274 87.7916 57.9573 86.6509C55.4192 85.4611 53.0718 84.2452 50.9612 83.0619C50.1064 82.5873 49.291 82.1127 48.5086 81.651C47.7261 81.1894 47.1278 80.8253 46.4834 80.4287L172.505 49.2591C173.142 52.6917 172.939 56.2252 171.914 59.5645Z"
        fill="#F18056"
      />
      <path
        d="M65.38 55.4559C64.1902 54.485 62.8906 53.6537 61.5072 52.9787C59.6399 52.0745 57.6446 51.4563 55.5894 51.1452C54.8856 51.0379 54.1694 51.0379 53.4656 51.1452L42.5835 52.9722C42.0839 53.0507 41.6094 53.2422 41.1969 53.5317C40.7844 53.8212 40.445 54.2009 40.2051 54.6413C39.9653 55.0817 39.8314 55.5708 39.814 56.0707C39.7965 56.5706 39.896 57.0678 40.1046 57.5234L48.5867 76.5802C49.2443 75.5009 49.9018 74.3956 50.6514 73.2578C54.8349 66.771 59.7787 60.7957 65.38 55.4559Z"
        fill="#7900FF"
      />
      <path
        d="M53.4333 102.672C53.4725 103.17 53.6274 103.653 53.8859 104.082C54.1444 104.512 54.4996 104.877 54.9239 105.148C55.3482 105.42 55.8301 105.591 56.3322 105.649C56.8343 105.707 57.3431 105.649 57.8191 105.48L68.2606 101.872C68.9325 101.642 69.5646 101.311 70.1346 100.89C71.7849 99.6435 73.2328 98.1548 74.4282 96.4755C75.175 95.4238 75.8159 94.3023 76.3416 93.1271C67.7653 90.9792 59.5076 87.738 51.7764 83.4849C52.3331 89.8827 52.8854 96.2783 53.4333 102.672Z"
        fill="#7900FF"
      />
      <defs>
        <clipPath id="clip0">
          <rect
            width="23"
            height="42"
            fill="white"
            transform="translate(177.562 1.23242) rotate(-3.0698)"
          />
        </clipPath>
        <clipPath id="clip1">
          <rect
            width="17.3686"
            height="31.7166"
            fill="white"
            transform="translate(16.1909 68.8535) rotate(-158.78)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default XepelinQuestionMarkIcon;
