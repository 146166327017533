import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import imgChampagne from '../../assets/champagne.svg';
import BaseDialog from './BaseDialog';

const SubmitedOrderChampagne = ({ open, handleClose }) => {
  const history = useHistory();

  const handleConfirm = () => {
    history.push('/');
  };

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleClose}
      topIcon={imgChampagne}
      topIconWidth={169}
      hideCloseButton
      title="¡Hemos recibido tu solicitud!"
      description={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <>
          Un ejecutivo se contactará contigo para
          <br />
          completar tu operación
        </>
      }
      paperProps={{ 'data-qa': 'submitted-order-champagne-dialog' }}
    >
      <Grid item xs={12} align="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleConfirm}
          data-qa="submitted-order-champagne-dialog-close-button-action"
        >
          Listo
        </Button>
      </Grid>
    </BaseDialog>
  );
};

SubmitedOrderChampagne.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default SubmitedOrderChampagne;
