import { withStyles } from '@material-ui/core/styles';
import StepConnector from '@material-ui/core/StepConnector';

const CustomStepConnector = withStyles({
  root: {
    width: 32,
    margin: '0 3px',
    padding: '0',
  },
  active: {
    '& $line': {
      backgroundColor: '#F87348',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#F87348',
    },
  },
  line: {
    width: 3,
    border: 0,
    backgroundColor: '#DAD9DB',
    borderRadius: 0,
    margin: '0 auto',
  },
})(StepConnector);

export default CustomStepConnector;
