const setCheckedRows = (selectedData, data) => {
  const dataCopy = [...data];
  if (selectedData && selectedData.length) {
    selectedData.forEach(row => {
      const found = dataCopy.find(obj => Number(obj.id) === Number(row.id));
      dataCopy[dataCopy.indexOf(found)] = {
        ...found,
        tableData: { checked: true },
      };
    });
  }
  return dataCopy;
};

export default setCheckedRows;
