import moment from 'moment';
import axios from '../../helpers/axios';
import {
  FETCH_CART_INVOICES_SUCCESS,
  FETCH_CART_INVOICES_ERROR,
  FETCH_CART_INVOICES_START,
  FETCH_CART_INVOICES_COUNT_SUCCESS,
  FETCH_CART_INVOICES_COUNT_ERROR,
  FETCH_CART_INVOICES_COUNT_START,
  FETCH_CART_INVOICES_TOTAL_AMOUNT_START,
  FETCH_CART_INVOICES_TOTAL_AMOUNT_SUCCESS,
  FETCH_CART_INVOICES_TOTAL_AMOUNT_ERROR,
  HANDLE_INVOICES_SELECTION_CHANGE,
  RESET_CART_INVOICES,
  REMOVE_FROM_CART,
  HANDLE_INVOICES_DATE_CHANGE,
  CART_SIMULATE_START,
  CART_SIMULATE_SUCCESS,
  CART_SIMULATE_ERROR,
  SET_IS_FIRST_SIMULATION,
  ORDER_SUBMIT_START,
  ORDER_SUBMIT_SUCCESS,
  ORDER_SUBMIT_ERROR,
  RESET_SELECTED_INVOICES,
} from './types';
import { DIRECT_FINANCING } from '../../helpers/constants';
import { enqueueSnackbar } from './notificationActions';
import { t13s } from '../../translationKeys';
import { extraColumns } from '../../helpers/demoData';
import { trackOrderStarted } from '../../helpers/segment/tracking/orderStarted';
import { trackOrderSubmitted } from '../../helpers/segment/tracking/orderSubmitted';

export const fetchCartInvoices =
  (
    businessId,
    countryId,
    orderType,
    { page, size, filter, order } = {},
    holidays
  ) =>
  dispatch => {
    dispatch({ type: FETCH_CART_INVOICES_START });
    axios
      .get(`/api/business/${businessId}/invoice`, {
        params: {
          orderType,
          page,
          size,
          filter,
          order,
        },
      })
      .then(res => {
        const { data, pagination } = res.data;

        const cartExtraColumns = [...extraColumns[countryId]];
        dispatch({
          type: FETCH_CART_INVOICES_SUCCESS,
          payload: {
            data,
            pagination,
            extraColumns: cartExtraColumns,
            holidays,
          },
        });
      })
      .catch(() => {
        const errorCode = t13s.NOTIFICATION.CAN_NOT_FETCH_CART_INVOICES;
        dispatch({
          type: FETCH_CART_INVOICES_ERROR,
          payload: { errorCode },
        });
        dispatch(
          enqueueSnackbar({
            message: errorCode,
            options: {
              variant: 'warning',
            },
          })
        );
      });
  };

export const fetchCartInvoicesCount = (businessId, orderType) => dispatch => {
  dispatch({ type: FETCH_CART_INVOICES_COUNT_START });
  axios
    .get(`/api/business/${businessId}/invoice/count`, {
      params: {
        orderType,
      },
    })
    .then(({ data }) => {
      const { count } = data;
      dispatch({
        type: FETCH_CART_INVOICES_COUNT_SUCCESS,
        payload: { orderType, count },
      });
    })
    .catch(() => {
      const errorCode = t13s.NOTIFICATION.CAN_NOT_FETCH_CART_INVOICES_COUNT;
      dispatch({
        type: FETCH_CART_INVOICES_COUNT_ERROR,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar({
          message: errorCode,
          options: {
            variant: 'warning',
          },
        })
      );
    });
};

export const fetchCartInvoicesTotalAmount =
  (businessId, orderType) => dispatch => {
    dispatch({ type: FETCH_CART_INVOICES_TOTAL_AMOUNT_START });
    axios
      .get(`/api/business/${businessId}/invoice/totalamount`, {
        params: {
          orderType,
        },
      })
      .then(({ data }) => {
        const { totalAmount } = data;
        dispatch({
          type: FETCH_CART_INVOICES_TOTAL_AMOUNT_SUCCESS,
          payload: { totalAmount },
        });
      })
      .catch(() => {
        const errorCode =
          t13s.NOTIFICATION.CAN_NOT_FETCH_CART_INVOICES_TOTAL_AMOUNT;
        dispatch({
          type: FETCH_CART_INVOICES_TOTAL_AMOUNT_ERROR,
          payload: { errorCode },
        });
        dispatch(
          enqueueSnackbar({
            message: errorCode,
            options: {
              variant: 'warning',
            },
          })
        );
      });
  };

export const setDemoData = data => dispatch => {
  dispatch({ type: FETCH_CART_INVOICES_START });
  dispatch({
    type: FETCH_CART_INVOICES_SUCCESS,
    payload: { ...data },
  });
};

export const handleInvoicesSelectionChange = selectedInvoices => dispatch => {
  dispatch({
    type: HANDLE_INVOICES_SELECTION_CHANGE,
    payload: { ...selectedInvoices },
  });
};

export const resetSelectedInvoices = () => dispatch => {
  dispatch({
    type: RESET_SELECTED_INVOICES,
  });
};

export const handleInvoicesDateChange = (invoices, date) => dispatch => {
  if (date) {
    dispatch({
      type: HANDLE_INVOICES_DATE_CHANGE,
      payload: { invoices, date },
    });
  }
};

export const resetCart = () => dispatch => {
  dispatch({
    type: RESET_CART_INVOICES,
  });
};

export const removeFromCart =
  (invoices, operationType, isSudoUser = false) =>
  dispatch => {
    dispatch({
      type: REMOVE_FROM_CART,
      payload: { invoices, operationType },
    });

    // TRACK SEGMENT
    // I need to send track for each invoice
    invoices.forEach(invoice => {
      const {
        folio,
        payerIdentifier: debtorIdentifier,
        payerName: debtorName,
        amount: totalAmount,
        issueDate,
        expirationDate,
      } = invoice;

      const trackPayload = {
        operationType: operationType === DIRECT_FINANCING ? 'FD' : 'PP',
        folio,
        issueDate: moment
          .utc(new Date(issueDate))
          .locale('es')
          .format('DD-MM-YYYY'),
        debtorIdentifier,
        debtorName,
        totalAmount,
      };

      if (expirationDate) {
        trackPayload.paymentDate = moment
          .utc(new Date(expirationDate))
          .locale('es')
          .format('DD-MM-YYYY');
      }
      if (isSudoUser) {
        trackPayload.source = 'sudo backoffice';
      }
    });
  };

export const cartSimulate =
  (
    invoices,
    businessId,
    isFirstSimulation,
    orderType,
    availableInvoicesCount,
    availableInvoicesAmount
  ) =>
  dispatch => {
    if (invoices.length === 0) {
      return;
    }
    dispatch({
      type: CART_SIMULATE_START,
    });
    axios
      .post(`/api/cart/calculate?orderType=${orderType}`, {
        businessId,
        orderInvoices: invoices.map(invoice => {
          return {
            invoiceId: invoice.id,
            expirationDate: invoice.expirationDate,
          };
        }),
      })
      .then(res => {
        const simulation = res.data;
        dispatch({
          type: CART_SIMULATE_SUCCESS,
          payload: { simulation },
        });
        if (isFirstSimulation) {
          trackOrderStarted(
            orderType,
            invoices,
            simulation,
            availableInvoicesCount,
            availableInvoicesAmount
          );
          dispatch({
            type: SET_IS_FIRST_SIMULATION,
            payload: { isFirstSimulation: false },
          });
        }
      })
      .catch(error => {
        const errorCode = error?.response?.data?.errorCode;
        dispatch({
          type: CART_SIMULATE_ERROR,
          payload: { errorCode },
        });
      });
  };

export const submitOrder =
  (
    businessId,
    selectedInvoices,
    preselectedInvoices,
    orderType,
    simulation,
    availableInvoicesCount,
    availableInvoicesAmount
  ) =>
  dispatch => {
    const orderInvoices = selectedInvoices.map(invoice => {
      return { invoiceId: invoice.id, expirationDate: invoice.expirationDate };
    });
    const preselectedInvoicesIds = preselectedInvoices.map(
      invoice => invoice.id
    );

    dispatch({ type: ORDER_SUBMIT_START });
    axios
      .post(`api/business/${businessId}/order?orderType=${orderType}`, {
        orderInvoices,
        preselectedInvoicesIds,
      })
      .then(res => {
        dispatch({ type: ORDER_SUBMIT_SUCCESS });
        dispatch({ type: RESET_CART_INVOICES });

        const { id = 0 } = res.data.createdOrder;

        trackOrderSubmitted(
          id,
          orderType,
          selectedInvoices,
          simulation,
          availableInvoicesCount,
          availableInvoicesAmount
        );

        // Create documents set
        axios.post(`api/order/${id}/document`);
      })
      .catch(error => {
        const errorCode = error?.response?.data?.errorCode;
        dispatch({
          type: ORDER_SUBMIT_ERROR,
          payload: { errorCode },
        });
      });
  };
