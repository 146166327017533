import { Select, FormControl, MenuItem, TableCell } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import PropTypes from 'prop-types';
import * as React from 'react';
import { paginationStyles } from './styles';
import { Text2 } from '../../../formatters';

const PaginationMaterialTable = React.memo(props => {
  const {
    count,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    onRowsPerPageChange,
    labelRowsPerPage,
    labelDisplayedRows,
    onPageChange,
  } = props;

  const classes = paginationStyles();

  const maxPages = Math.ceil(count / rowsPerPage);
  const from = page * rowsPerPage + 1;
  const to = Math.min(page * rowsPerPage + rowsPerPage, count);

  const handleChangePage = (event, selectedPage) => {
    onPageChange(event, selectedPage - 1);
  };

  return (
    <TableCell className={classes.root}>
      <div className={classes.container}>
        <Text2 text={labelRowsPerPage} className={classes.labelRowsPerPage} />
        <FormControl size="small" variant="outlined">
          <Select
            labelId="rows-per-page-label"
            id="rows-per-page"
            value={rowsPerPage}
            onChange={onRowsPerPageChange}
            data-qa="material-table-pagination-select"
          >
            {rowsPerPageOptions.map(option => (
              <MenuItem
                key={option}
                value={option}
                selected={option === rowsPerPage}
              >
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Pagination
          count={maxPages}
          page={page + 1}
          onChange={handleChangePage}
          size="small"
          color="secondary"
          className={classes.pagination}
        />
        <Text2
          text={labelDisplayedRows({ from, to, count })}
          className={classes.labelRowsPerPage}
        />
      </div>
    </TableCell>
  );
});

PaginationMaterialTable.defaultProps = {
  labelDisplayedRows: () => '{count} registros totales',
  labelRowsPerPage: 'Registros por página',
  rowsPerPageOptions: [5, 10, 20, 50, 100],
  count: 0,
};

PaginationMaterialTable.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  labelDisplayedRows: PropTypes.func,
  labelRowsPerPage: PropTypes.string,
  onRowsPerPageChange: PropTypes.func.isRequired,
};

export default PaginationMaterialTable;
