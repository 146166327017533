import { makeStyles } from '@material-ui/core';

const toolbarStyles = makeStyles({
  toolbar: {
    margin: '0 0 24px 0',
    alignItems: ({ leftButtons, rightButtons }) =>
      leftButtons === null && rightButtons === null ? 'flex-end' : 'center',
  },
  toolbarWithButtons: {
    marginTop: 32,
  },
});

const titleDescriptionStyles = makeStyles(theme => ({
  title: {
    ...theme.typography.h5,
    margin: '0 0 16px 0',
  },
  description: {
    ...theme.typography.h6,
    margin: 0,
  },
}));

export { toolbarStyles, titleDescriptionStyles };
