import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Card from './Card';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    flexWrap: 'nowrap',
  },
  panelTitle: {
    padding: props => convertSpacingToCss(props.titlePadding),
    margin: 0,
  },
  panelBody: {
    padding: props => convertSpacingToCss(props.contentPadding),
    margin: 0,
  },
  title: {
    verticalAlign: 'middle',
    padding: props => (props.actions ? '6px 0' : 0),
    color: props =>
      props.variant === 'green-title'
        ? theme.palette.text.contrast1
        : theme.palette.primary,
    marginRight: props => (props.titleAction ? '7px' : 0),
  },
}));

const Panel = props => {
  const classes = useStyles(props);
  const {
    title,
    subtitle,
    titleAlign,
    actions,
    children,
    noBordered,
    titleAction,
  } = props;

  const alignToFlex = align => {
    switch (align) {
      case 'left':
        return 'flex-start';
      case 'center':
        return 'center';
      case 'right':
        return 'flex-end';
      default:
        return null;
    }
  };

  const getTitle = variant => {
    return (
      <Grid item>
        <Typography
          variant={variant === 'green-title' ? 'h6' : 'h5'}
          align={titleAlign}
          className={classes.title}
        >
          {variant === 'green-title' ? (
            title
          ) : (
            <Box fontWeight="fontWeightBold">{title}</Box>
          )}
        </Typography>
        <Typography variant="body2" component="div">
          <Box fontWeight="fontWeightBold">{subtitle}</Box>
        </Typography>
      </Grid>
    );
  };

  return (
    <Card variant="white" border={!noBordered}>
      <Grid container direction="column" className={classes.gridContainer}>
        <Grid item xs={12} className={classes.panelTitle}>
          <Grid container direction="row">
            <Grid
              container
              item
              xs={actions ? 6 : 12}
              direction="row"
              alignItems="center"
              justifyContent={alignToFlex(titleAlign)}
            >
              {getTitle(props.variant)}
              {titleAction}
            </Grid>
            {actions && (
              <Grid item xs={6} align="right">
                {actions}
              </Grid>
            )}
          </Grid>
        </Grid>
        {children && (
          <Grid item xs={12} className={classes.panelBody}>
            {children}
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

Panel.defaultProps = {
  variant: 'normal',
  titleAlign: 'left',
  children: null,
  actions: null,
  titleAction: null,
  noBordered: false,
  titlePadding: 'xl',
  contentPadding: 'zero xl xl xl',
  subtitle: null,
};

Panel.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  variant: PropTypes.oneOf(['green-title', 'normal']),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  titleAlign: PropTypes.oneOf(['left', 'center', 'rigth']),
  // eslint-disable-next-line react/no-unused-prop-types
  titlePadding: PropTypes.string,
  actions: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  // eslint-disable-next-line react/no-unused-prop-types
  contentPadding: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  noBordered: PropTypes.bool,
  titleAction: PropTypes.element,
};

export default Panel;
