import { Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import Panel from './elements/Panel';

const useStyles = makeStyles({
  rootContainer: {
    padding: 20,
  },
});

const HomeXtracker = ({ businessId }) => {
  const classes = useStyles();
  const xtrackerUrl = `https://datastudio.google.com/report-framed/reporting/89da2521-3372-4dff-afc4-b0ea3c5e4250/page/p_blvm9x7xnc?params=%7B%22df55%22:%22include%25EE%2580%25801%25EE%2580%2580EQ%25EE%2580%2580${businessId}%22%7D`;
  return (
    <Grid container className={classes.rootContainer}>
      <Grid item xs={12}>
        <Panel titlePadding="zero" contentPadding="lg zero">
          <iframe
            title="xtracker-iframe"
            allowFullScreen=""
            frameBorder="0"
            src={xtrackerUrl}
            width="100%"
            height="2000"
          />
        </Panel>
      </Grid>
    </Grid>
  );
};

HomeXtracker.propTypes = {
  businessId: PropTypes.number.isRequired,
};

export default HomeXtracker;
