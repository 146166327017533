import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Table, TableToolbar } from '../../../commons/components';
import Card from '../../../../components/elements/Card';

import { SEGMENT_EVENTS } from '../../../../helpers/segment/constants';
import { trackSegment } from '../../../../helpers/segment/trackingSegment';
import { archivedTableStyles } from './styles';
import {
  useArchivedTable,
  useArchivedTableHeaders,
} from '../../adapters/ArchivedTable';

const ArchivedTable = React.memo(props => {
  const { businessId } = props;
  const classes = archivedTableStyles();

  const { actions, onChangeSearch, fetchData, reloadValue } =
    useArchivedTable();
  const rows = useArchivedTableHeaders();

  useEffect(() => {
    trackSegment(SEGMENT_EVENTS.PAYMENT_ARCHIVED_VIEWED, {
      businessId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container item className={classes.tableInvoicesContainer}>
      <TableToolbar
        title="Historial de facturas archivadas"
        description="En este historial podrás encontrar las facturas que fueron archivadas o reportadas."
        onChangeSearch={onChangeSearch}
      />

      <Card variant="white" border className={classes.card}>
        <Table
          reload={reloadValue}
          columns={rows}
          actionButtons={actions}
          fetchData={fetchData}
        />
      </Card>
    </Grid>
  );
});

ArchivedTable.defaultProps = {};

ArchivedTable.propTypes = {
  businessId: PropTypes.number.isRequired,
};

export default ArchivedTable;
