import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import {
  VALIDATION_TYPES,
  VALIDATION_MESSAGES,
} from '../../../../helpers/constants';
import InfoField from '../../../../components/elements/InfoField';
import Panel from '../../../../components/elements/Panel';
import SupplierAvatar from '../commons/SupplierAvatar';
import BankAccounts from './BankAccounts';
import ContactDetails from './ContactDetails';

import useSupplierProfile from '../../adapters/SupplierProfile/useSupplierProfile';
import { supplierProfileStyles } from './styles';

const SOURCE = 'supplier';
const CONTACTABLE_TYPE = 'supplier';

const SupplierProfile = React.memo(() => {
  const classes = supplierProfileStyles();

  const {
    supplier,
    supplierUpdateIsLoading,
    handleUpdateSupplierPaymentTerm,
    handleUnmount,
  } = useSupplierProfile();

  const { REQUIRED } = VALIDATION_TYPES;
  const { REQUIRED_MSG } = VALIDATION_MESSAGES;

  const { id: sourceId, name, identifier, paymentTerm } = supplier;

  useEffect(() => {
    return handleUnmount;
  }, [handleUnmount]);

  return (
    <Grid container alignContent="flex-start" className={classes.root}>
      <Grid item className={classes.avatar}>
        <SupplierAvatar name={name} identifier={identifier} />
      </Grid>

      <Grid item xs={12} className={classes.panel}>
        <Panel
          variant="green-title"
          title="Términos de pago"
          subtitle="Ingresa cada cuántos días realizas los pagos a este proveedor"
        >
          {sourceId ? (
            <InfoField
              label="Días de crédito"
              labelVariant="caption"
              labelColor="textPrimary"
              data={paymentTerm}
              loading={supplierUpdateIsLoading}
              dataVariant="body1"
              dataColor="textPrimary"
              dataFontWeight="fontWeightBold"
              onAccept={handleUpdateSupplierPaymentTerm}
              editable
              inputValidators={[REQUIRED]}
              inputErrorMessages={[REQUIRED_MSG]}
            />
          ) : (
            <Typography variant="caption" component="div">
              Días de crédito
            </Typography>
          )}
        </Panel>
      </Grid>

      <Grid item xs={12} className={classes.panel}>
        <BankAccounts
          sourceId={sourceId}
          source={SOURCE}
          title="Cuentas Bancarias"
        />
      </Grid>

      <Grid item xs={12} className={classes.panel}>
        <ContactDetails
          contactableId={sourceId}
          contactableType={CONTACTABLE_TYPE}
          title="Contacto"
        />
      </Grid>
    </Grid>
  );
});

export default SupplierProfile;
