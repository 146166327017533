import isEmpty from './validations/isEmpty';
import * as styles from '../theme/otherConstants';

export const convertSpacingToCss = spec => {
  let value = spec;
  if (isEmpty(value)) {
    value = 'md';
  }

  return value
    .replaceAll('zero', '0')
    .replaceAll('xs', `${styles.XS}px`)
    .replaceAll('sm', `${styles.SM}px`)
    .replaceAll('md', `${styles.MD}px`)
    .replaceAll('lg', `${styles.LG}px`)
    .replaceAll('xl', `${styles.XL}px`);
};

export const getThemeColor = (theme, foundColor) => {
  let color;
  switch (foundColor) {
    case 'primary':
      color = theme.palette.primary.main;
      break;
    case 'secondary':
      color = theme.palette.secondary.main;
      break;
    case 'textPrimary':
      color = theme.palette.text.primary;
      break;
    case 'textsecondary':
      color = theme.palette.text.secondary;
      break;
    case 'contrast1':
      color = theme.palette.text.contrast1;
      break;
    case 'contrast2':
      color = theme.palette.text.contrast2;
      break;
    default:
      color = foundColor;
      break;
  }
  return color;
};
