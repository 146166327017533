import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SearchBar from '../../../../../components/elements/SearchBar';
import { toolbarStyles } from './styles';
import TitleAndDescription from './TitleAndDescription';

const TableToolbar = React.memo(props => {
  const { title, description, buttons, onChangeSearch } = props;
  const classes = toolbarStyles(props);
  const [buttonElements, setButtonElements] = useState([]);

  useEffect(() => {
    if (buttons) {
      const list = [...buttons];
      const half = Math.ceil(list.length / 2);
      const firstHalf = list.splice(0, half);
      const secondHalf = list.splice(-half);
      const newButtons = [firstHalf, secondHalf];
      setButtonElements(newButtons);
    }
  }, [buttons]);

  const areButtonsAvailable = () => buttonElements?.length > 0;

  const handleSearchBarChange = value => {
    onChangeSearch(value);
  };

  return (
    <Box>
      {areButtonsAvailable() && (
        <TitleAndDescription title={title} description={description} />
      )}
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={clsx(classes.toolbar, {
          [classes.toolbarWithButtons]: areButtonsAvailable(),
        })}
      >
        <Grid item>
          {!areButtonsAvailable() && (
            <TitleAndDescription title={title} description={description} />
          )}
          {buttonElements?.[0]}
        </Grid>
        <Grid item display="flex" alignItems="center">
          {buttonElements?.[1]}
          <SearchBar handleChangeInput={handleSearchBarChange} />
        </Grid>
      </Grid>
    </Box>
  );
});

TableToolbar.defaultProps = {
  description: <>&nbsp;</>,
  buttons: null,
};

TableToolbar.propTypes = {
  title: PropTypes.string.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.element),
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default TableToolbar;
