import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loginWithToken } from '../store/actions/authenticationActions';
import Loader from './elements/Loader';
import { setIsSudoUser } from '../store/actions/uiStateActions';
import { analyticsReset } from '../helpers/segment/trackingSegment';

const Impersonate = () => {
  const dispatch = useDispatch();
  const { token } = useParams();
  analyticsReset();
  useEffect(() => {
    dispatch(setIsSudoUser(true));
    dispatch(loginWithToken(token, true));
  }, [token, dispatch]);

  return <Loader />;
};

export default Impersonate;
