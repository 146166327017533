import {
  FETCH_DEBT_SERVICE_START,
  FETCH_DEBT_SERVICE_SUCCESS,
  FETCH_DEBT_SERVICE_FAILURE,
} from '../actions/types';

const initialState = {
  debtService: {},
  canConsult: false,
  fetchIsLoading: false,
};

const fetchDebtServiceStart = state => {
  return {
    ...state,
    debtService: {},
    fetchIsLoading: true,
  };
};
const fetchDebtServiceSuccess = (state, payload) => {
  return {
    ...state,
    ...payload,
    fetchIsLoading: false,
  };
};
const fetchDebtServiceFailure = state => {
  return {
    ...state,
    debtService: {},
    canConsult: false,
    fetchIsLoading: false,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_DEBT_SERVICE_START:
      return fetchDebtServiceStart(state);
    case FETCH_DEBT_SERVICE_SUCCESS:
      return fetchDebtServiceSuccess(state, payload);
    case FETCH_DEBT_SERVICE_FAILURE:
      return fetchDebtServiceFailure(state);
    default:
      return state;
  }
};
