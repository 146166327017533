import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Box, Grid, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import InfoField from './elements/InfoField';
import UserPasswordDialog from './dialogs/UserPasswordDialog';
import CustomButton from './elements/CustomButton';
import Panel from './elements/Panel';
import { updateUser, fetchUser } from '../store/actions/userActions';
import { VALIDATION_TYPES, VALIDATION_MESSAGES } from '../helpers/constants';
import { updateBusiness } from '../store/actions/businessActions';
import { convertSpacingToCss } from '../helpers/stylesHelpers';
import UserSectionHeader from './elements/UserSectionHeader';
import { t13s } from '../translationKeys';

const useStyles = makeStyles({
  containerBody: {
    display: 'grid',
    gridGap: convertSpacingToCss('lg'),
  },
  subtitle: {
    marginTop: 10,
  },
});

const UserPersonalInfo = ({ userId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { passwordUpdated, updateUserIsLoading, fetchUserIsLoading, user } =
    useSelector(state => state.userState);
  const business = useSelector(state => state.businessState.business);

  const [showUserPasswordDialog, setShowUserPasswordDialog] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);

  const { REQUIRED } = VALIDATION_TYPES;
  const { REQUIRED_MSG } = VALIDATION_MESSAGES;
  const { name, email, phone } = user;
  const {
    name: businessName,
    identifier,
    address = '',
    id: businessId,
  } = business;

  useEffect(() => {
    dispatch(fetchUser(userId));
  }, [userId, dispatch]);

  useEffect(() => {
    if (passwordUpdated) {
      setShowUserPasswordDialog(false);
    }
  }, [passwordUpdated]);

  const handleUpdateUserInfo = (inputName, value) => {
    const newUser = { phone, email, name, [inputName]: value };
    dispatch(updateUser(userId, newUser, user));
  };

  const handleUpdateBusinessInfo = address => {
    dispatch(updateBusiness(businessId, { address }));
  };

  const handleShowUserPasswordDialog = () => {
    setShowUserPasswordDialog(true);
  };

  const handleCloseUserPasswordDialog = () => {
    setShowUserPasswordDialog(false);
  };

  return (
    <Fade in timeout={400}>
      <Grid item>
        {showUserPasswordDialog && (
          <UserPasswordDialog
            open={showUserPasswordDialog}
            handleCloseDialog={handleCloseUserPasswordDialog}
          />
        )}

        <UserSectionHeader setHeaderHeight={setHeaderHeight}>
          <Typography variant="h5" color="textPrimary">
            <Box fontWeight="fontWeightBold">Perfil de usuario</Box>
          </Typography>

          <Typography
            variant="body1"
            color="textSecondary"
            className={classes.subtitle}
          >
            Esta es tu información personal y de tu empresa.
          </Typography>
        </UserSectionHeader>

        <Box className={classes.containerBody} marginTop={headerHeight}>
          <Panel
            title="Información personal"
            variant="green-title"
            titlePadding="xl xl lg"
          >
            <Grid container direction="row" spacing={2}>
              <Grid item xs={9}>
                <InfoField
                  label="Nombre y apellido"
                  labelVariant="caption"
                  labelColor="textPrimary"
                  data={name}
                  loading={fetchUserIsLoading || updateUserIsLoading}
                  dataVariant="body1"
                  dataColor="textPrimary"
                  dataFontWeight="fontWeightBold"
                  onAccept={value => handleUpdateUserInfo('name', value)}
                  editable
                  inputValidators={[REQUIRED]}
                  inputErrorMessages={[REQUIRED_MSG]}
                />
              </Grid>
              <Grid item xs={3}>
                <InfoField
                  label="Número de contacto"
                  labelVariant="caption"
                  labelColor="textPrimary"
                  data={phone}
                  loading={fetchUserIsLoading || updateUserIsLoading}
                  dataVariant="body1"
                  dataColor="textPrimary"
                  dataFontWeight="fontWeightBold"
                  onAccept={value => handleUpdateUserInfo('phone', value)}
                  editable
                  inputValidators={[REQUIRED]}
                  inputErrorMessages={[REQUIRED_MSG]}
                />
              </Grid>
            </Grid>
          </Panel>

          <Panel
            title="Información de la empresa"
            variant="green-title"
            titlePadding="xl xl lg"
          >
            <Grid container direction="column">
              <Grid item xs={12}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={9}>
                    <InfoField
                      label="Razón social"
                      labelVariant="caption"
                      labelColor="textPrimary"
                      data={businessName}
                      dataVariant="body1"
                      dataColor="textPrimary"
                      dataFontWeight="fontWeightBold"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InfoField
                      label={`${t(t13s.LABEL.BUSINESS_IDENTIFIER)} empresa`}
                      labelVariant="caption"
                      labelColor="textPrimary"
                      data={identifier}
                      dataVariant="body1"
                      dataColor="textPrimary"
                      dataFontWeight="fontWeightBold"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={9}>
                    <InfoField
                      label={t(t13s.LABEL.COMPANY_ADDRESS)}
                      labelVariant="caption"
                      labelColor="textPrimary"
                      data={address}
                      dataVariant="body1"
                      dataColor="textPrimary"
                      dataFontWeight="fontWeightBold"
                      editable
                      onAccept={handleUpdateBusinessInfo}
                      inputValidators={[REQUIRED]}
                      inputErrorMessages={[REQUIRED_MSG]}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InfoField
                      label="Email"
                      labelVariant="caption"
                      labelColor="textPrimary"
                      data={email}
                      loading={fetchUserIsLoading}
                      dataVariant="body1"
                      dataColor="textPrimary"
                      dataFontWeight="fontWeightBold"
                      labelStyles={useStyles.label}
                      dataStyles={useStyles.info}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Panel>

          <Panel
            variant="green-title"
            title="Contraseña de la cuenta"
            actions={
              <CustomButton
                variant="orange-lined"
                onClick={handleShowUserPasswordDialog}
                data-qa="personal-info-custom-button-cambiar-contrasena-action"
              >
                Cambiar contraseña
              </CustomButton>
            }
          />
        </Box>
      </Grid>
    </Fade>
  );
};

UserPersonalInfo.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default UserPersonalInfo;
