import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Box,
  Grid,
  makeStyles,
  InputAdornment,
  Tooltip,
  IconButton,
  createTheme,
  ThemeProvider,
  CircularProgress,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import CalendarIcon from '../icons/CalendarIcon';
import CircleCheckedIcon from '../icons/CircleCheckedIcon';
import CircleUncheckedIcon from '../icons/CircleUncheckedIcon';
import PaginationMaterialTable from '../elements/PaginationMaterialTable';
import {
  EARLY_PAYMENT,
  DIRECT_FINANCING,
  LONG_DATE_FORMAT,
  AVAILABLE_COUNTRIES,
} from '../../helpers/constants';
import { t13s } from '../../translationKeys';
import ToolbarDatePicker from '../elements/ToolbarDatePicker';
import {
  removeFromCart,
  handleInvoicesDateChange,
} from '../../store/actions/cartActions';
import { typography } from '../../theme/typography';
import { palette } from '../../theme/palette';
import { TABLE_HEADER_TEXT } from '../../theme/otherColors';
import CountryFormatHelper from '../elements/CountryFormatHelper';
import DeleteIcon from '../icons/DeleteIcon';

const outerTheme = createTheme({
  typography,
  palette: {
    ...palette,
    primary: { ...palette.secondary },
    secondary: { ...palette.primary },
  },
});

const useStyles = makeStyles(theme => ({
  folio: {
    maxWidth: 50,
  },
  leftSpace: {
    borderLeft: '4px solid transparent',
  },
  deleteIconInactive: {
    color: theme.palette.text.secondary,
    padding: 0,
  },
  deleteIconActive: {
    padding: 0,
    color: theme.palette.secondary.main,
  },
  deleIconRows: {
    color: theme.palette.text.secondary,
    padding: 0,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  underline: {
    minWidth: 130,
    color: theme.palette.secondary.main,
    '&:before': {
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
      '& span': {
        color: theme.palette.secondary.main,
      },
    },
  },
  underlineDisabled: {
    minWidth: 130,
    '&:before': {
      borderBottom: '2px solid #B2AEBD',
    },
  },
  underlineDisabledHeader: {
    minWidth: 130,
    fontWeight: 700,
    fontSize: '12px',
    '& svg': {
      color: TABLE_HEADER_TEXT,
    },
  },
  underlineHeader: {
    minWidth: 130,
    fontWeight: 700,
    fontSize: '12px',
    color: theme.palette.secondary.main,
    '&:before': {
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
    '& svg': {
      color: theme.palette.secondary.main,
    },
  },
  inputRoot: {
    margin: 0,
  },
}));

const InvoiceTableStepTwo = ({ operationType, tableData, countryId }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    fetchCartInvoicesIsLoading,
    earlyPaymentCount,
    directFinancingCount,
    simulationIsLoading,
  } = useSelector(state => state.cartState);
  const { isSudoUser } = useSelector(state => state.uiState);
  const { holidays } = useSelector(state => state.holidaysState);

  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [bulkDate] = useState(null);

  const handleDelete = selectedInvoices => {
    dispatch(removeFromCart(selectedInvoices, operationType, isSudoUser));
    setSelectedInvoices([]);
  };

  const handleDate = (date, invoices) => {
    dispatch(handleInvoicesDateChange(invoices, date.toDate()));
    setSelectedInvoices([]);
  };

  const handleDisabledDates = date => {
    // Disable saturday and sunday on DatePickers
    return (
      moment(date).isoWeekday() === 6 ||
      moment(date).isoWeekday() === 7 ||
      holidays.some(h => moment(date).isSame(moment(h.date), 'day'))
    );
  };

  const calculateAdvancePercentage = retentionPct => {
    return !Number.isNaN(retentionPct) ? 100 - retentionPct : null;
  };

  const getColumns = () => {
    const columnsArray = [
      {
        width: '54px',
        sorting: false,
        type: 'string',
        field: 'id',
        title: (
          <Tooltip title="Selecciona las facturas que deseas eliminar">
            <IconButton
              className={
                selectedInvoices.length
                  ? classes.deleteIconActive
                  : classes.deleteIconInactive
              }
              onClick={() => handleDelete(selectedInvoices)}
              data-qa="invoice-table-step-two-delete-all-icon-button"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ),
        render: invoice => {
          return (
            <IconButton
              className={classes.deleIconRows}
              onClick={() => handleDelete([invoice])}
              data-qa={`invoice-table-step-two-delete-icon-button-${invoice?.tableData?.id}`}
            >
              <DeleteIcon />
            </IconButton>
          );
        },
        cellStyle: {
          alignContent: 'center',
          padding: '11px 16px',
        },
      },
      {
        title: 'Folio',
        field: 'folio',
        type: 'string',
        width: '80px',
        align: 'left',
        render: invoice => (
          <Typography variant="body2" color="textPrimary">
            {invoice?.folio}
          </Typography>
        ),
      },
      {
        title: 'Nombre Pagador',
        field: 'payerName',
        type: 'string',
        align: 'left',
        render: invoice => (
          <Typography variant="body2" color="textPrimary" component="div">
            <Box fontWeight="fontWeightBold">
              {invoice?.payerName ? invoice?.payerName.toUpperCase() : '-'}
            </Box>
          </Typography>
        ),
      },
      {
        title: `${t(t13s.LABEL.BUSINESS_IDENTIFIER)} Pagador`,
        field: 'payerIdentifier',
        type: 'string',
        width: '160px',
        align: 'left',
        render: invoice => (
          <Typography
            variant="body2"
            color="textSecondary"
            component="div"
            noWrap
          >
            <Box fontWeight="fontWeightBold">{invoice?.payerIdentifier}</Box>
          </Typography>
        ),
      },
      {
        title: 'Fecha emisión',
        field: 'issueDate',
        type: 'datetime',
        width: '140px',
        align: 'left',
        render: invoice => (
          <Typography
            variant="body2"
            color="textSecondary"
            component="div"
            noWrap
          >
            <Box fontWeight="fontWeightBold">
              <CountryFormatHelper
                value={invoice?.issueDate}
                variant="longdate"
                countryId={countryId}
              />
            </Box>
          </Typography>
        ),
      },
      {
        title: '% anticipo',
        type: 'number',
        width: '130px',
        align: 'left',
        hidden: operationType === DIRECT_FINANCING,
        render: invoice => (
          <Typography variant="body2" color="textSecondary" component="div">
            {simulationIsLoading ? (
              <CircularProgress size={20} />
            ) : (
              <Box fontWeight="fontWeightBold">
                <CountryFormatHelper
                  value={calculateAdvancePercentage(invoice?.retentionPct)}
                  variant="percentage"
                  countryId={countryId}
                />
              </Box>
            )}
          </Typography>
        ),
      },
      {
        cellStyle: {
          padding: '11px 16px',
        },
        type: 'datetime',
        width: '140px',
        align: 'left',
        title: (
          <ThemeProvider theme={outerTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
              <Grid container justify="space-around">
                <DatePicker
                  variant="inline"
                  placeholder="Fecha de Pago *"
                  format={LONG_DATE_FORMAT}
                  margin="normal"
                  value={bulkDate}
                  onChange={date => handleDate(date, selectedInvoices)}
                  className={classes.inputRoot}
                  leftArrowButtonProps={{
                    'data-qa': 'date-picker-calendar-all-left-arrow-action',
                  }}
                  rightArrowButtonProps={{
                    'data-qa': 'date-picker-calendar-all-right-arrow-action',
                  }}
                  PopoverProps={{
                    'data-qa': 'date-picker-calendar-popover',
                  }}
                  InputProps={{
                    className: selectedInvoices.length
                      ? classes.underlineHeader
                      : classes.underlineDisabledHeader,
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarIcon />
                      </InputAdornment>
                    ),
                    'data-qa': 'invoice-table-step-two-date-input-all',
                  }}
                  shouldDisableDate={date => handleDisabledDates(date)}
                  // onMonthChange={date => handleMonthChange(date)}
                  minDateMessage="No se puede seleccionar fechas pasadas"
                  autoOk
                  disablePast
                  disableToolbar
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        ),
        render: invoice => {
          const { expirationDate, averageDaysLate } = invoice;
          const message = (
            <Typography variant="body2" component="div">
              <Box fontWeight="fontWeightBold">
                Dado el historial de pago de tu proveedor
                <br />
                la fecha sugerida es de {averageDaysLate} días
              </Box>
            </Typography>
          );

          return (
            <ThemeProvider theme={outerTheme}>
              <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                <Grid container justify="space-around">
                  <DatePicker
                    variant="inline"
                    placeholder="Fecha de Pago"
                    format={LONG_DATE_FORMAT}
                    margin="normal"
                    value={moment.utc(expirationDate)}
                    onChange={date => handleDate(date, [invoice])}
                    className={classes.inputRoot}
                    shouldDisableDate={date => handleDisabledDates(date)}
                    minDateMessage="No se puede seleccionar fechas pasadas"
                    InputProps={{
                      className: expirationDate
                        ? classes.underline
                        : classes.underlineDisabled,
                      'data-qa': `invoice-table-step-two-date-input-${invoice?.tableData?.id}`,
                    }}
                    autoOk
                    disablePast
                    ToolbarComponent={() => (
                      <ToolbarDatePicker message={message} />
                    )}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          );
        },
      },
      {
        title: 'Tasa',
        type: 'number',
        width: '130px',
        align: 'left',
        render: invoice => (
          <Typography variant="body2" color="textSecondary" component="div">
            {simulationIsLoading ? (
              <CircularProgress size={20} />
            ) : (
              <Box fontWeight="fontWeightBold">
                <CountryFormatHelper
                  value={invoice?.rate}
                  variant="percentage"
                  useFixedDecimalPrecision={2}
                  countryId={countryId}
                />
              </Box>
            )}
          </Typography>
        ),
      },
      {
        title: 'Monto Total',
        field: 'amount',
        type: 'numeric',
        width: '150px',
        render: invoice => (
          <Typography
            variant="body2"
            color="textSecondary"
            component="div"
            noWrap
          >
            <Box fontWeight="fontWeightBold">
              <CountryFormatHelper
                value={invoice.amount}
                countryId={countryId}
                variant="currency"
              />
            </Box>
          </Typography>
        ),
      },
    ];

    return columnsArray;
  };

  const getPageSizeOptions = () => {
    const pageSizeOptions = [5, 10, 20, 50, 100];
    const maxPageSize =
      earlyPaymentCount > directFinancingCount
        ? earlyPaymentCount
        : directFinancingCount;
    if (maxPageSize > 100) pageSizeOptions.push(maxPageSize);
    return pageSizeOptions;
  };

  return (
    <MaterialTable
      style={{ width: '100%', boxShadow: 'none' }}
      columns={getColumns()}
      data={tableData}
      isLoading={fetchCartInvoicesIsLoading}
      localization={{
        pagination: {
          labelDisplayedRows: '{count} Facturas en total',
          labelRowsSelect: 'facturas',
          labelRowsPerPage: 'Facturas por página',
        },
        toolbar: {
          nRowsSelected: count =>
            count > 1
              ? `${count} facturas seleccionadas`
              : '1 factura seleccionada',
          searchTooltip: 'Buscar',
          searchPlaceholder: 'Buscar',
        },
        header: {
          actions: 'Acciones',
        },
        body: {
          emptyDataSourceMessage: 'No hay facturas para mostrar',
          filterRow: {
            filterTooltip: 'Filtro',
          },
        },
      }}
      onSelectionChange={rows => setSelectedInvoices(rows)}
      options={{
        showTitle: false,
        search: false,
        emptyRowsWhenPaging: false,
        showFirstLastPageButtons: false,
        selection: true,
        sorting: true,
        pageSize: 20,
        maxBodyHeight: 420,
        pageSizeOptions: getPageSizeOptions(),
        showSelectAllCheckbox: true,
        selectionProps: invoice => ({
          icon: (
            <CircleUncheckedIcon
              data-qa={`invoice-table-step-two-check-icon-${invoice?.tableData?.id}`}
            />
          ),
          checkedIcon: (
            <CircleCheckedIcon
              data-qa={`invoice-table-step-two-check-icon-${invoice?.tableData?.id}`}
            />
          ),
        }),
        headerSelectionProps: {
          icon: (
            <CircleUncheckedIcon
              className={classes.leftSpace}
              data-qa="invoice-table-step-two-check-all-icon"
            />
          ),
          checkedIcon: (
            <CircleCheckedIcon
              className={classes.leftSpace}
              data-qa="invoice-table-step-two-check-all-icon"
            />
          ),
          indeterminateIcon: (
            <CircleUncheckedIcon
              className={classes.leftSpace}
              data-qa="invoice-table-step-two-check-all-icon"
            />
          ),
        },
        rowStyle: invoice => {
          const {
            tableData: { checked = false },
          } = invoice;
          return {
            backgroundColor: checked ? '#F3F2F5' : '',
            borderLeft: checked ? '8px solid #f77240' : '8px solid transparent',
          };
        },
      }}
      components={{
        // eslint-disable-next-line react/jsx-props-no-spreading
        Pagination: props => <PaginationMaterialTable {...props} />,
      }}
    />
  );
};

InvoiceTableStepTwo.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  operationType: PropTypes.oneOf([DIRECT_FINANCING, EARLY_PAYMENT]).isRequired,
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
};

export default InvoiceTableStepTwo;
