// - STATE MID -----------
let delayQueueActions = [];

let trackerMiddleware = (store, next, action) => {
  const data = { prevState: store.getState(), action };
  const result = next(action);
  data.nextState = store.getState();
  delayQueueActions.push(JSON.stringify(data));
  return result;
};

const middleware = store => {
  return next => action => {
    try {
      return trackerMiddleware(store, next, action);
    } catch (e) {
      /* SILENT ERROR */
    }
    return next(action);
  };
};

// - HTTP INTERCEPTORS -----------
let delayQueueFetch = [];

let trackerOnResponse = response =>
  delayQueueFetch.push(JSON.stringify(response));
let trackerOnRequest = d => d;

setTimeout(() => {
  delayQueueFetch = []; /* CLEAR QUEUE */
  delayQueueActions = [];
}, 1000 * 120); /* WAIT 2 MINS FOR GTM SCRIPT */

const onRequest = config => {
  try {
    trackerOnRequest(config);
  } catch (e) {
    /* SILENT ERROR */
  }
  return config;
};

const onResponse = response => {
  try {
    trackerOnResponse(response);
  } catch (e) {
    /* SILENT ERROR */
  }
  return response;
};

// - ON READY -----------
window.growthTrackerOnReady = (fnOnRequest, fnOnResponse, fnMiddleware) => {
  trackerOnRequest = fnOnRequest;
  trackerOnResponse = fnOnResponse;
  trackerMiddleware = fnMiddleware;

  return { delayQueueFetch, delayQueueActions };
};

export default { onRequest, onResponse, middleware };
