import { makeStyles } from '@material-ui/core/styles';
import { convertSpacingToCss } from '../../../../helpers/stylesHelpers';
import { SEPARATOR } from '../../../../theme/otherColors';

const bankAccountsStyles = makeStyles({
  containerBody: {
    display: 'grid',
    gridGap: convertSpacingToCss('lg'),
  },
  subtitle: {
    marginTop: convertSpacingToCss('sm'),
  },
  separatorBottom: {
    height: 1,
    width: '100%',
    backgroundColor: SEPARATOR,
  },
  emptyContainer: {
    paddingLeft: 30,
  },
});

const contactDetailsStyles = makeStyles({
  containerBody: {
    display: 'grid',
    gridGap: convertSpacingToCss('lg'),
  },
  subtitle: {
    marginTop: convertSpacingToCss('sm'),
  },
  separatorBottom: {
    height: 1,
    width: '100%',
    backgroundColor: SEPARATOR,
  },
  emptyContainer: {
    paddingLeft: 30,
  },
});

const supplierProfileStyles = makeStyles(() => ({
  root: {
    height: '100%',
    padding: 40,
    overflow: 'auto',
    background: 'white',
  },
  avatar: {
    paddingBottom: 40,
    width: '100%',
  },
  paymentTerm: {
    paddingBottom: 30,
    width: '100%',
  },
  bankAccounts: {
    paddingBottom: 30,
    width: '100%',
  },
  contactDetails: {
    width: '100%',
  },
  panel: {
    marginBottom: 30,
  },
}));

export { bankAccountsStyles, contactDetailsStyles, supplierProfileStyles };
