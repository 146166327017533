import { combineReducers } from 'redux';
import uiStatesReducer from './uiStatesReducer';
import authenticationReducer from './authenticationReducer';
import notificationReducer from './notificationReducer';
import businessReducer from './businessReducer';
import taxServiceReducer from './taxServiceReducer';
import cartReducer from './cartReducer';
import webSocketReducer from './webSocketReducer';
import tasksReducer from './tasksReducer';
import holidaysReducer from './holidaysReducer';
import orderReducer from './orderReducer';
import ordersReducer from './ordersReducer';
import userReducer from './userReducer';
import stakeholdersReducer from './stakeholdersReducer';
import businessBankAccountsReducer from './businessBankAccountsReducer';
import geoReducer from './geoReducer';
import documentsReducer from './documentsReducer';
import debtServiceReducer from './debtServiceReducer';
import paymentsReducer from './paymentsReducer';
import suppliersReducer from './suppliersReducer';
import bankAccountsReducer from './bankAccountsReducer';
import payrollsReducer from './payrollsReducer';
import contactDetailsReducer from './contactDetailsReducer';
import paymentDocumentsReducer from './paymentDocumentsReducer';
import paymentsStatsReducer from './paymentsStatsReducer';
import onboardReducer from './onboardReducer';
import pfxReducer from './pfxReducer';
import creditLineReducer from './creditLineReducer';
import walletReducer from './walletReducer';

const appReducer = combineReducers({
  authState: authenticationReducer,
  businessState: businessReducer,
  cartState: cartReducer,
  debtServiceState: debtServiceReducer,
  holidaysState: holidaysReducer,
  notificationState: notificationReducer,
  orderState: orderReducer,
  ordersState: ordersReducer,
  stakeholdersState: stakeholdersReducer,
  tasksState: tasksReducer,
  taxServiceState: taxServiceReducer,
  uiState: uiStatesReducer,
  userState: userReducer,
  wsState: webSocketReducer,
  businessBankAccountsState: businessBankAccountsReducer,
  geo: geoReducer,
  documentsState: documentsReducer,
  paymentsState: paymentsReducer,
  suppliersState: suppliersReducer,
  bankAccountsState: bankAccountsReducer,
  payrollsState: payrollsReducer,
  contactDetailsState: contactDetailsReducer,
  paymentDocumentsState: paymentDocumentsReducer,
  paymentsStatsState: paymentsStatsReducer,
  onboardState: onboardReducer,
  pfxState: pfxReducer,
  creditLineState: creditLineReducer,
  walletState: walletReducer,
});

export default appReducer;
