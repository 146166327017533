import React from 'react';
import PropTypes from 'prop-types';

import CardActionArea from '@material-ui/core/CardActionArea';
import Grid from '@material-ui/core/Grid';

import Card from '../../../../components/elements/Card';
import SupplierAvatar from '../commons/SupplierAvatar';
import { useSupplierBookEntry } from '../../adapters/SupplierBook';
import { supplierBookEntryStyles } from './styles';

const SupplierBookEntry = React.memo(({ supplier }) => {
  const classes = supplierBookEntryStyles();
  const { handleOnSelectSupplier } = useSupplierBookEntry();
  return (
    <Grid item xs={12} key={supplier.id}>
      <Card variant="white" border className={classes.card}>
        <CardActionArea
          onClick={handleOnSelectSupplier(supplier)}
          className={classes.cardAction}
        >
          <SupplierAvatar
            name={supplier.name}
            identifier={supplier.identifier}
          />
        </CardActionArea>
      </Card>
    </Grid>
  );
});

SupplierBookEntry.propTypes = {
  supplier: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    identifier: PropTypes.string.isRequired,
  }).isRequired,
};

export default SupplierBookEntry;
