import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import Sidebar from './Sidebar';
import HomeFinance from './HomeFinance';
import HomeOrders from './HomeOrders';
import { HomePayments } from '../payments/pages';
import HomeSuppliers from './HomeSuppliers';
import HomeXtracker from './HomeXtracker';
import OrderDetails from './OrderDetails';
import Loader from './elements/Loader';
import WelcomeDialog from './dialogs/WelcomeDialog';
import {
  wsCreateConnection,
  wsConnect,
} from '../store/actions/websocketActions';
import { fetchHolidays } from '../store/actions/holidaysActions';
import { setShowTourPostRegister } from '../store/actions/uiStateActions';

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'auto',
    gridTemplateColumns: '140px minmax(1140px, auto)',
  },
  sideBar: {
    gridRow: '1 / 2',
    gridColumn: '1 / 2',
    boxShadow: '0px 0px 7px #00000014',
    zIndex: 50,
    position: 'fixed',
    width: 140,
  },
  content: {
    gridRow: '1 / 2',
    gridColumn: '2 / 3',
    justifyContent: 'flex-end',
  },
});

const HomeMain = () => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const [showTourDialog, setShowTourDialog] = useState(false);

  const { businessIsLoading, business } = useSelector(
    state => state.businessState
  );
  const { wsBusinessNotified, wsconnected } = useSelector(
    state => state.wsState
  );
  const { showTourPostRegister } = useSelector(state => state.uiState);

  useEffect(() => {
    if (!wsconnected) {
      dispatch(wsConnect());
      dispatch(fetchHolidays());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showTourPostRegister) {
      setShowTourDialog(true);
    }
  }, [showTourPostRegister]);

  useEffect(() => {
    if (wsconnected && business?.id && !wsBusinessNotified) {
      dispatch(wsCreateConnection(business.id));
    }
  }, [wsconnected, business, wsBusinessNotified, dispatch]);

  useEffect(() => {
    if (business?.countryId && i18n.language !== business.countryId) {
      i18n.changeLanguage(business.countryId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business]);

  const handleCloseTour = () => {
    setShowTourDialog(false);
    dispatch(setShowTourPostRegister(false));
  };

  return businessIsLoading ? (
    <Loader />
  ) : (
    <Grid container className={classes.root}>
      {showTourDialog && (
        <WelcomeDialog open={showTourDialog} handleClose={handleCloseTour} />
      )}

      <Grid item className={classes.sideBar}>
        <Sidebar />
      </Grid>
      <Grid item className={classes.content}>
        <Switch>
          <Route exact path="/orders" component={HomeOrders} />
          <Route
            exact
            path="/orders/:id"
            render={({ match }) => {
              return /^\d+$/.test(match.params.id) ? (
                <OrderDetails orderId={match.params.id} />
              ) : (
                <span>NOT FOUND</span>
              );
            }}
          />
          <Route
            exact
            path="/finance"
            render={() => {
              return <Redirect to="/finance/directo" />;
            }}
          />
          <Route path="/finance" component={HomeFinance} />

          <Route exact path="/payments" component={HomePayments} />
          <Route exact path="/suppliers" component={HomeSuppliers} />
          {/* {entityFeature.find(entity => entity.featureId === 10) &&
            entityFeature.find(entity => entity.featureId === 10).active && (
              <>
                <Route exact path="/payments" component={HomePayments} />
                <Route exact path="/suppliers" component={HomeSuppliers} />
              </>
            )} */}

          <Route
            exact
            path="/xtracker"
            render={() => <HomeXtracker businessId={business.id} />}
          />
        </Switch>
      </Grid>
    </Grid>
  );
};

export default HomeMain;
