import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  withStyles,
} from '@material-ui/core';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';

const styles = {
  root: {
    marginBottom: convertSpacingToCss('md'),
  },
};

class CheckboxValidator extends ValidatorComponent {
  renderValidatorComponent() {
    /* eslint-disable no-unused-vars */
    const {
      classes,
      error,
      errorMessages,
      validators,
      requiredError,
      validatorListener,
      value,
      label,
      ...rest
    } = this.props;
    const { isValid } = this.state;

    return (
      <div className={classes.root}>
        <FormControlLabel
          label={label}
          control={
            <Checkbox
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...rest}
              ref={r => {
                this.input = r;
              }}
              icon={<CircleUnchecked />}
              checkedIcon={<CircleCheckedFilled />}
              indeterminateIcon={<CircleUnchecked />}
            />
          }
        />
        {!isValid && (
          <FormHelperText variant="filled" className="Mui-error" align="left">
            {this.getErrorMessage()}
          </FormHelperText>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(CheckboxValidator);
