import { makeStyles } from '@material-ui/core';

const statsStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridGap: 20,
    width: '100%',
    padding: '32px 20px',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: '182px',
  },
}));

const statElementStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
    padding: '24px 16px 0 16px',
    boxSizing: 'border-box',
  },
  title: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  price: {
    fontSize: '24px',
    lineHeight: '48px',
  },
  filterContainer: {
    margin: '18px 0 28px 0',
  },
  filterButton: {
    minWidth: '50px',
    background: 'transparent',
    color: theme.palette.text.secondary,
    borderRadius: 20,
    border: 0,
    fontSize: '10px',
    lineHeight: '16px',
    textAlign: 'center',
    padding: '3px 8px',
    cursor: 'pointer',
    '&.active': {
      background: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  icon: {
    width: 48,
    height: 48,
    margin: '0 5px 0 0',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
}));

const emptyStatElementStyles = makeStyles(() => ({
  filterContainer: {
    margin: '18px 0 28px 0',
  },
  icon: {
    width: 48,
    height: 48,
    margin: '0 16px 0 0',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
}));

export { statsStyles, statElementStyles, emptyStatElementStyles };
