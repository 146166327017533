export const SEGMENT_EVENTS = {
  ACCOUNT_CREATED: 'Account Created',
  ACCOUNT_AUTHENTICATED: 'Account Authenticated',
  ORDER_STARTED: 'Order Started',
  ORDER_SUBMITTED: 'Order Submitted',
  PAYMENT_INBOX_VIEWED: 'Payments Inbox Viewed',
  PAYMENT_SCHEDULED_VIEWED: 'Payments Scheduled Viewed ',
  PAYMENT_PAID_VIEWED: 'Payments Paid Viewed',
  PAYMENT_ARCHIVED_VIEWED: 'Payments Archived Viewed',
  PAYMENT_INVOICE_SELECTED: 'Payments Invoices Selected',
  PAYMENT_DATE_COMPLETED: 'Payments Date Completed',
  PAYMENT_NAME_COMPLETED: 'Payments Name Completed',
  PAYMENT_ORDER_SCHEDULED: 'Payments Order Scheduled',
  PAYMENT_INVOICES_SCHEDULED_CLICKED: 'Payments Flow Started',
  PAYMENT_PAY_NOW: 'Payments Overview Accepted',
  PAYMENT_METHOD: 'Payments Method Selected',
  PAYMENT_ORDER_PAID: 'Payments Order Paid',
};

export default SEGMENT_EVENTS;
