import { REHYDRATE } from 'redux-persist';
import {
  FETCH_SUPPLIERS_START,
  FETCH_SUPPLIERS_SUCCESS,
  FETCH_SUPPLIERS_ERROR,
  UPDATE_SUPPLIER_START,
  UPDATE_SUPPLIER_SUCCESS,
  UPDATE_SUPPLIER_ERROR,
  SET_SUPPLIER,
  LOCAL_PURGE,
} from '../actions/types';

const initialState = {
  suppliers: [],
  pagination: { totalData: 0, pageSize: 20, page: 1 },
  supplier: null,

  suppliersIsLoading: false,
  suppliersError: null,

  supplierUpdateIsLoading: false,
  supplierUpdated: false,
  supplierUpdateError: null,
};

const fetchSuppliersStart = state => {
  return {
    ...state,
    suppliersIsLoading: true,
  };
};

const fetchSuppliersSuccess = (state, payload) => {
  return {
    ...state,
    suppliersIsLoading: false,
    ...payload,
  };
};

const fetchSuppliersError = (state, payload) => {
  return {
    ...state,
    suppliers: [],
    suppliersIsLoading: false,
    suppliersError: payload.error,
  };
};

const updateSupplierStart = state => {
  return {
    ...state,
    supplierUpdateIsLoading: true,
  };
};

const updateSupplierSuccess = (state, payload) => {
  const suppliers = state.suppliers.map(supplier => {
    if (supplier.id === payload.id) return payload;
    return supplier;
  });
  return {
    ...state,
    supplier: payload,
    supplierUpdateIsLoading: false,
    suppliers,
  };
};

const updateSupplierError = (state, payload) => {
  return {
    ...state,
    supplierUpdateError: payload.error,
  };
};

const setSupplier = (state, payload) => {
  return { ...state, supplier: payload };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_SUPPLIERS_START:
      return fetchSuppliersStart(state);
    case FETCH_SUPPLIERS_SUCCESS:
      return fetchSuppliersSuccess(state, payload);
    case FETCH_SUPPLIERS_ERROR:
      return fetchSuppliersError(state, payload);

    case UPDATE_SUPPLIER_START:
      return updateSupplierStart(state);
    case UPDATE_SUPPLIER_SUCCESS:
      return updateSupplierSuccess(state, payload);
    case UPDATE_SUPPLIER_ERROR:
      return updateSupplierError(state, payload);

    case SET_SUPPLIER:
      return setSupplier(state, payload);

    case LOCAL_PURGE:
      return initialState;
    case REHYDRATE: {
      return initialState;
    }
    default:
      return state;
  }
};
