import axios from '../../helpers/axios';
import {
  FETCH_GEO_MX_COLONY_FAILURE,
  FETCH_GEO_MX_COLONY_START,
  FETCH_GEO_MX_COLONY_SUCCESS,
  FETCH_GEO_MX_MUNICIPALITY_FAILURE,
  FETCH_GEO_MX_MUNICIPALITY_START,
  FETCH_GEO_MX_MUNICIPALITY_SUCCESS,
  FETCH_GEO_MX_STATES_FAILURE,
  FETCH_GEO_MX_STATES_START,
  FETCH_GEO_MX_STATES_SUCCESS,
} from './types';

export const fetchGeoMXStates = () => dispatch => {
  dispatch({ type: FETCH_GEO_MX_STATES_START });
  axios
    .get(`/api/geo/mx/state`)
    .then(res => {
      const mxStates = res.data;
      dispatch({
        type: FETCH_GEO_MX_STATES_SUCCESS,
        payload: { mxStates },
      });
    })
    .catch(err => {
      const errorCode = err?.response?.data?.error?.errorCode;
      dispatch({
        type: FETCH_GEO_MX_STATES_FAILURE,
        payload: { error: errorCode },
      });
    });
};

export const fetchGeoMXStatesMunicipalities = state => dispatch => {
  dispatch({ type: FETCH_GEO_MX_MUNICIPALITY_START });
  axios
    .get(`/api/geo/mx/state/${state}/municipality`)
    .then(res => {
      const mxMunicipalities = res.data;
      dispatch({
        type: FETCH_GEO_MX_MUNICIPALITY_SUCCESS,
        payload: { mxMunicipalities },
      });
    })
    .catch(err => {
      const errorCode = err?.response?.data?.error?.errorCode;
      dispatch({
        type: FETCH_GEO_MX_MUNICIPALITY_FAILURE,
        payload: { error: errorCode },
      });
    });
};

export const fetchGeoMXColonies = municipality => dispatch => {
  dispatch({ type: FETCH_GEO_MX_COLONY_START });
  axios
    .get(`/api/geo/mx/municipality/${municipality}/colony`)
    .then(res => {
      const mxColonies = res.data;
      dispatch({
        type: FETCH_GEO_MX_COLONY_SUCCESS,
        payload: { mxColonies },
      });
    })
    .catch(err => {
      const errorCode = err?.response?.data?.error?.errorCode;
      dispatch({
        type: FETCH_GEO_MX_COLONY_FAILURE,
        payload: { error: errorCode },
      });
    });
};
