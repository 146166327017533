import { REHYDRATE } from 'redux-persist';
import {
  FETCH_ORDERS_START,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_ERROR,
  LOCAL_PURGE,
} from '../actions/types';

const initialState = {
  orders: [],
  pagination: { totalCount: 0, pageSize: 20, page: 1 },
  ordersIsLoading: false,
  ordersError: null,
};

const fetchOrdersStart = state => {
  return {
    ...state,
    ordersIsLoading: true,
  };
};

const fetchOrdersSuccess = (state, payload) => {
  return {
    ...state,
    ordersIsLoading: false,
    ...payload,
  };
};

const fetchOrdersError = (state, payload) => {
  return {
    ...state,
    orders: [],
    ordersIsLoading: false,
    ordersError: payload.error,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ORDERS_START:
      return fetchOrdersStart(state);
    case FETCH_ORDERS_SUCCESS:
      return fetchOrdersSuccess(state, payload);
    case FETCH_ORDERS_ERROR:
      return fetchOrdersError(state, payload);
    case LOCAL_PURGE:
      return initialState;
    case REHYDRATE: {
      return initialState;
    }
    default:
      return state;
  }
};
