import React, { useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import OnboardPage from './OnboardPage';
import XepelinQuestionMarkIcon from './icons/XepelinQuestionMarkIcon';
import PaymentsOnboardStepper from './PaymentsOnboardStepper';
import CustomButton from './elements/CustomButton';
import { saveOnboard, resetOnboard } from '../store/actions/onboardActions';

const useStyles = makeStyles(() => ({
  skipButton: {
    marginRight: 15,
  },
}));

const OnboardPageScheduleInvoices = () => {
  const [showOnboardStepper, setShowOnboardStepper] = useState(false);
  const { id: businessId } = useSelector(state => state.businessState.business);
  const dispatch = useDispatch();
  const classes = useStyles();

  const DEFAULT_ONBOARD_DAYS = 30;

  const handleSkipOnboard = () => {
    const defaultOnboardDate = moment()
      .subtract(DEFAULT_ONBOARD_DAYS, 'days')
      .format('YYYY-MM-DD');
    dispatch(saveOnboard(businessId, [], defaultOnboardDate));
  };

  const handleCloseModal = () => {
    setShowOnboardStepper(false);
    dispatch(resetOnboard());
  };

  return (
    <Grid container item>
      <OnboardPage
        icon={XepelinQuestionMarkIcon}
        title="Programa tus facturas"
        info="Para empezar a generar tus pagos a proveedores, debes hacer una pequeña configuración inicial."
        showOnboardStepper={showOnboardStepper}
      >
        <CustomButton
          variant="simple-gray"
          color="secondary"
          size="large"
          onClick={handleSkipOnboard}
          className={classes.skipButton}
        >
          Omitir
        </CustomButton>
        <CustomButton
          variant="all-orange"
          color="secondary"
          size="large"
          onClick={() => setShowOnboardStepper(true)}
        >
          Empezar
        </CustomButton>
      </OnboardPage>

      <PaymentsOnboardStepper
        open={showOnboardStepper}
        closeModal={handleCloseModal}
      />
    </Grid>
  );
};

export default OnboardPageScheduleInvoices;
